import PropTypes from 'prop-types';
import React from 'react';
import Helper from '../Utils/Helper';

class Translations extends React.Component {
  static contextTypes = {
    t: PropTypes.func,
  };

  static propTypes = {
    children: PropTypes.any,
    params: PropTypes.object,
    uppercase: PropTypes.bool,
    titlecase: PropTypes.bool,
  };

  render() {
    const {children, params, titlecase, uppercase} = this.props;
    const {t} = this.context;

    return uppercase ? t(children, params).toUpperCase() : titlecase ? Helper.titleCase(t(children, params)) : t(children, params);
  }
}

export default Translations;
