import PropTypes from 'prop-types';
import React from 'react';
import {connectRefinementList} from 'react-instantsearch-dom';
import {Link} from 'react-router-dom';

class ToggleSelect extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    refine: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const {items, refine, className} = this.props;

    return (
      <div className={`flex items-center ${className || ''}`}>
        <div className={`flex flex-wrap`}>
          {items.map((item, key) => {
            return (
              <Link
                to=""
                tabIndex="0"
                onClick={(e) => {
                  e.preventDefault();
                  refine(item.value);
                }}
                key={key}
                className={`py-2 px-3 mb-auto mr-2 block focus:outline-none focus:shadow-inner text-sm border rounded border-gray-15${item.isRefined ? ' bg-positive text-white' : ' bg-white text-gray-60'}`}
              >
                {item.label}
              </Link>
            );
          })
          }
        </div>
      </div>
    );
  }
}

export default connectRefinementList(ToggleSelect);
