import {call, put} from 'redux-saga/effects'
import CardActions from '../../Redux/Billing/CardRedux'
import {callApi} from '../CallApiSaga'

export function* updateCard(api, {cardObj}) {
  const apiCall = yield call(api.updateCard, cardObj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(CardActions.updateCardSuccess(response.data));
  } else {
    yield put(CardActions.updateCardFailure('WRONG'))
  }
}
