import PropTypes from 'prop-types';
import React from 'react';
import Title from '../Text/Title';
import TextButton from '../Buttons/TextButton';
import Card from '../Card';
import LabeledContent from '../Text/LabeledContent';
import I18n from '../../Utils/I18n';
import SingleImage from '../Images/SingleImage';
import Helper from '../../Utils/Helper';

class ProjectItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    project: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {className, project} = this.props;
    const {t} = this.context;

    return (
      <Card className={`pt-8 h-full -mb-px ${className || ''}`}>
        <div className="px-6 flex flex-col h-full">
          <div className="flex justify-between">
            <div>
              <Title className="pb-3">{project ? project.title : null}</Title>
              <LabeledContent label={`${t('project type')}:`}>{project ? project.type.title : null}</LabeledContent>
              {project ?
                <LabeledContent className="whitespace-no-wrap" label={`${t('project duration')}:`}>
                  {`${project.starts_at && project.ends_at ? Helper.getDateDiff(project.starts_at, project.ends_at) : t('unknown')}`}
                </LabeledContent>
                : null
              }
              {project ?
                <LabeledContent className="whitespace-no-wrap" label={`${t('production dates')}:`}>
                  {`${project.starts_at && project.ends_at ? (`${Helper.formatDate(project.starts_at)} - ${Helper.formatDate(project.ends_at)}`) : t('unknown')}`}
                </LabeledContent>
                : null
              }
            </div>
            <SingleImage alt={project.title} className="max-w-20 max-h-20 ml-4 w-full">{project.logo}</SingleImage>
          </div>

          <div className="w-full flex pb-8 pt-2 mt-auto">
            <TextButton to={`/agency/projects/${project ? project.id : null}`} icon="chevron-right" className="mt-4 ml-auto text-gray-40 text-center">
              {I18n.t('Project details')}
            </TextButton>
          </div>
        </div>
      </Card>
    );
  }
}

export default ProjectItem;
