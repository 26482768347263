import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

class NotificationItem extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        notification: PropTypes.any,
    };

    render() {
        const {className, notification} = this.props;

        return (
            <Link to={notification.data.url} className={`block md:flex justify-between border-t border-gray-10 py-6 w-full ${className || ''}`}>
                <div className="pr-10">
                    <h4 className="text-sm font-bold">{notification.data.title}</h4>
                    <p className="text-sm">{notification.data.description}</p>
                </div>
                <p className="text-gray-40 md:text-gray-60 pt-3 md:pt-0 md:pl-2 font-normal text-xs md:whitespace-no-wrap">{moment(notification.created_at).fromNow()}</p>
            </Link>
        );
    }
}

export default NotificationItem;
