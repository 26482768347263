import React from 'react';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import LabeledContent from '../../../../Components/Text/LabeledContent';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import RoleActions from '../../../../Redux/Project/RoleRedux';
import PropTypes from 'prop-types';
import Helper from '../../../../Utils/Helper';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getRoleById: PropTypes.func,
    role: PropTypes.any,
    project: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  addBreaksToText(text){
    return text.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      })
  }

  render() {
    const {role, project} = this.props, {t} = this.context;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
        <div className="sm:flex">
          <div className="sm:w-7/12">
            <Title className="text-xl pb-2">{project && project.title}</Title>
            <LabeledContent className="w-32" label={`${Helper.titleCase(t('type of project role'))}:`}>{role && role.type && I18n.t(role.type)}</LabeledContent>
            <LabeledContent className="w-32" label={`${Helper.titleCase(t('status'))}:`}>{role && role.status && I18n.t(role.status)}</LabeledContent>
            <LabeledContent className="w-32" label={`${Helper.titleCase(t('chosen profiles'))}:`}>
              {role && `${role.castings_count || 0} / ${role.amount}`}
            </LabeledContent>

            <Title className="text-xl pt-8 pb-2">{I18n.t('Role information')}</Title>
            {role
              ? <Body>{Helper.addBreaksToText(role.description)}</Body>
              : <Loading/>
            }
          </div>
          <div className="pt-16 sm:pt-0 sm:w-5/12 sm:pl-10">
            <Title className="text-xl pb-2">{I18n.t('production dates', null, false, true)}</Title>
            {role
              ? (role.dates.length > 0
                ? role.dates.map((date, key) => {
                  return (
                    <div key={key} className="mb-4">
                      <LabeledContent className="w-20" label={`${Helper.titleCase(t('from'))}:`}>{Helper.formatDate(date.starts_at)}</LabeledContent>
                      <LabeledContent className="w-20" label={`${Helper.titleCase(t('to'))}:`}>{Helper.formatDate(date.ends_at)}</LabeledContent>
                      <LabeledContent className="w-20" label={`${Helper.titleCase(t('status'))}:`}>{I18n.t(date.status)}</LabeledContent>
                    </div>
                  );
                })
                : null)
              : <Loading/>
            }
          </div>
        </div>
        <div className="flex mt-10">
          <FilledButton to={`/agency/projects/${project && project.id}/project-roles/${role && role.id}/overview/edit`} icon="edit" className="bg-secondary-40 text-white rounded">
            {I18n.t('Edit')}
          </FilledButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    role: state.role.role,
    role_fetching: state.role.fetching,
    role_error: state.role.error,

    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleById: (obj) => dispatch(RoleActions.roleByIdRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
