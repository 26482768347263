import { call, put } from 'redux-saga/effects'
import ExperienceActions from '../../Redux/Talent/ExperienceRedux'
import { callApi } from '../CallApiSaga'

export function * createExperience (api,{obj}) {

  const apiCall = yield call(api.createExperience,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceActions.experienceCreateSuccess(response.data));
  } else {
    yield put(ExperienceActions.experienceCreateFailure(response.data ? response.data: 'WRONG'))
  }
}

export function * deleteExperience (api,{obj}) {

  const apiCall = yield call(api.deleteExperience,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceActions.experienceDeleteSuccess(true));
  } else {
    yield put(ExperienceActions.experienceDeleteFailure(response.data ? response.data: 'WRONG'))
  }
}

export function * getExperienceById(api,{obj}) {
  const apiCall = yield call(api.getExperienceById,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceActions.experienceByIdSuccess(response.data));
  } else {
    yield put(ExperienceActions.experienceByIdFailure(response.data ? response.data: 'WRONG'))
  }
}

export function * updateExperience(api,{obj}) {
  const apiCall = yield call(api.updateExperience,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceActions.experienceUpdateSuccess(response.data));
  } else {
    yield put(ExperienceActions.experienceUpdateFailure(response.data ? response.data: 'WRONG'))
  }
}
