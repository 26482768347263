import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';

class NumberField extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.any,
    max: PropTypes.number,
    meta: PropTypes.object,
    min: PropTypes.number,
    placeholder: PropTypes.any,
    step: PropTypes.number,
  };

  renderNumberInput(error = false) {
    const {className, input, max, min, placeholder, step} = this.props;
    return <input {...input} type="number" id={input.name} max={max} min={min} step={step} aria-labelledby={input.name} placeholder={placeholder} className={`input-field${error ? ' has-error' : ''} ${className || ''}`}/>;
  }

  render() {
    const {meta} = this.props;

    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderNumberInput(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderNumberInput();
  }
}

export default NumberField;
