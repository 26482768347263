import {call, put} from 'redux-saga/effects'
import SubscriptionActions from '../../Redux/Billing/SubscriptionRedux'
import {callApi} from '../CallApiSaga'

export function* chooseSubscription(api, {obj}) {
  const apiCall = yield call(api.chooseSubscription, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SubscriptionActions.createSubscriptionSuccess(true));
  } else {
    yield put(SubscriptionActions.createSubscriptionFailure('WRONG'));
  }
}

export function* deleteSubscription(api) {
  const apiCall = yield call(api.deleteSubscription);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SubscriptionActions.deleteSubscriptionSuccess(response.data));
  } else {
    yield put(SubscriptionActions.deleteSubscriptionFailure('WRONG'))
  }
}

export function* changeSubscription(api, {obj}) {
  const apiCall = yield call(api.changeSubscription, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SubscriptionActions.changeSubscriptionSuccess(true));
  } else {
    yield put(SubscriptionActions.changeSubscriptionFailure('WRONG'))
  }
}

