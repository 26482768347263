import {call, put} from 'redux-saga/effects'
import CastingsActions from '../../Redux/Talent/CastingsRedux'
import {callApi} from '../CallApiSaga'

export function* getCastings(api, {obj}) {
  const apiCall = yield call(api.getCastings, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    if (obj.status === 'new') {
      yield put(CastingsActions.newCastingsSuccess(response.data.data));
    } else {
      yield put(CastingsActions.currentCastingsSuccess(response.data.data));
    }
  } else {
    if (obj.status === 'new') {
      yield put(CastingsActions.newCastingsFailure('WRONG'))
    } else {
      yield put(CastingsActions.currentCastingsFailure('WRONG'))
    }
  }
}
