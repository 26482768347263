import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga'



export default (rootReducer, rootSaga) => {
  const reactRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const enhancers = [];
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
    sagaMiddleware
  ];

  enhancers.push(applyMiddleware(...middlewares));

  const store = createStore(
    rootReducer, // root reducer with router state
    compose(...enhancers)
  );

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware
  }

}
