import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TextArea from '../../../../Components/Form/TextArea';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import I18n from '../../../../Utils/I18n';
import {required} from '../../../../Validators/Form/Form';

class AboutMePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true, saved: false}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  render() {
    const {account} = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        validateOnBlur
        initialValues={{description: account.description}}
      >
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16">
            <Title className="text-xl pb-2">{I18n.t('Enter a short description about yourself')}</Title>

            <div className="flex flex-wrap items-center">
              <Field name="description" validate={required}>
                {({input, meta}) => (
                  <div className="pb-3 w-full max-w-full sm:pr-5">
                    <Label input={input}>{I18n.t('Description', null, true)}</Label>
                    <TextArea input={input} meta={meta} className="w-full h-32"/>
                  </div>
                )}
              </Field>
              <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                {I18n.t('save', null, false, true)}
              </FilledButton>
            </div>

          </form>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutMePage);
