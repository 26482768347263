import React from 'react';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Title from '../../../../Components/Text/Title';
import PasswordField from '../../../../Components/Form/PasswordField';
import PropTypes from 'prop-types';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';

class PasswordPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  validate() {
    const {updating} = this.state, {account_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = account_error ? {...account_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form onSubmit={this.onSubmit} validate={this.validate.bind(this)} validateOnBlur>
          {({handleSubmit, form}) => {
            this.form = form;
            return <form onSubmit={handleSubmit}>
              <Title className="text-xl pb-2">{I18n.t('Reset Password')}</Title>

              <Field name="password" validate={required}>
                {({input, meta}) => (
                  <div className="w-full">
                    <Label input={input} className="">{I18n.t('Password', null, true)}</Label>
                    <PasswordField input={input} meta={meta} className="max-w-md block" placeholder="******"/>
                  </div>
                )}
              </Field>

              <Field name="newpassword" validate={required}>
                {({input, meta}) => (
                  <div className="w-full">
                    <Label input={input} className="">{I18n.t('New password', null, true)}</Label>
                    <PasswordField input={input} meta={meta} className="max-w-md block" placeholder="******"/>
                  </div>
                )}
              </Field>

              <Field name="newpassword_confirmation" validate={required}>
                {({input, meta}) => (
                  <div className="w-full">
                    <Label input={input} className="">{I18n.t('new password confirmation', null, true)}</Label>
                    <PasswordField input={input} meta={meta} className="max-w-md block" placeholder="******"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('update password', null, false, true)}
                </FilledButton>
              </div>
            </form>;
          }}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
