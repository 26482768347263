import PropTypes from 'prop-types';
import React from 'react';

class Label extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.any,
  };

  render() {
    const {children, className, disabled, input} = this.props;

    return (
      <label htmlFor={input.name} aria-disabled={disabled} aria-label={input.name} className={`block text-xs text-gray-80 pl-4 pb-1 ${disabled ? ' opacity-0' : ''} ${className || ''}`}>
        {children}
      </label>
    );
  }
}

export default Label;
