import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import AccountActions from '../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';

class Footer extends React.Component {
  static propTypes = {
    account: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {account} = this.props;
    const {t} = this.context;

    return (
      <footer className={`mt-auto py-4 w-full text-xs font-semibold text-gray-40 flex flex-wrap justify-between items-center ${account ? 'relative px-4 pt-8 bg-gray-10-gradient' : 'px-8'}`}>
        {account && <div className="absolute bg-white rounded-b-full w-full h-4 -mx-4 top-0"/>}
        <p className="py-4 sm:py-0">© {new Date().getFullYear()} The Casting Director</p>

        <div className="flex">
          <a href={t('iub_pc')} target="_blank" rel="noopener noreferrer" className="hover:text-gray-20 block sm:ml-5">{t('Cookies')}</a>
          <a href={t('iub_pp')} target="_blank" rel="noopener noreferrer" className="hover:text-gray-20 block ml-5">{t('Privacy Policy')}</a>
          <a href={t('terms_url')} target="_blank" rel="noopener noreferrer" className="hover:text-gray-20 block ml-5">{t('terms & conditions')}</a>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
