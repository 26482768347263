import React from 'react';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import LabeledContent from '../../../../Components/Text/LabeledContent';
import Helper from '../../../../Utils/Helper';
import Loading from '../../../../Components/Loading';

class YourRolePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    casting: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {casting} = this.props;
    const {t} = this.context;
    const role = casting ? casting.role : null;

    return (
      <div className="flex-1 sm:flex pl-3 sm:px-10 pb-16">
        <div className="sm:w-7/12 mb-10">
          <Title className="text-xl pb-2">{role && I18n.t(role.type)}</Title>
          <Body>{role ? Helper.addBreaksToText(role.description) : null}</Body>
        </div>
        <div className="sm:w-5/12 sm:pl-10">
          <Title className="text-xl pb-2">{I18n.t('casting dates', null, false, true)}</Title>
          <LabeledContent className="w-20" label={`${Helper.titleCase(t('from'))}:`}>{role ? Helper.formatDate(role.starts_at) : null}</LabeledContent>
          <LabeledContent className="w-20" label={`${Helper.titleCase(t('to'))}:`}>{role ? Helper.formatDate(role.ends_at) : null}</LabeledContent>
          <LabeledContent className="w-20" label={`${t('Duration')}:`}>{`${role ? Helper.getDateDiff(role.starts_at, role.ends_at) : 0} days`}</LabeledContent>

          <Title className="text-xl pt-8 pb-2">{I18n.t('Shooting dates')}</Title>
          {role
            ? (role.dates.length > 0
              ? role.dates.map((date, key) => {
                return (
                  <div key={key}>
                    <LabeledContent className="w-20" label={`${Helper.titleCase(t('from'))}:`}>{Helper.formatDate(date.starts_at)}</LabeledContent>
                    <LabeledContent className="w-20" label={`${Helper.titleCase(t('to'))}:`}>{Helper.formatDate(date.ends_at)}</LabeledContent>
                    <LabeledContent className="w-20" label={`${Helper.titleCase(t('status'))}:`}>{date.status}</LabeledContent>
                  </div>
                );
              }) : null)
            : <Loading/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    casting: state.casting.casting,
    casting_fetching: state.casting.fetching,
    casting_error: state.casting.error,
  };
};

export default connect(mapStateToProps)(YourRolePage);
