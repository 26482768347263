import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    projectsRequest: [],
    projectsSuccess: ['projects'],
    projectsFailure: ['error'],

    clearProjectsState:[],
});

export const ProjectsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    projects: null,
    error: null,
    fetching: false,
});

/* ------------- Reducers ------------- */

// we're attempting to fetch projects
export const request = (state) => state.merge({ fetching: true });

// we've successfully fetched the projects
export const success = (state, data) => {
    const { projects } = data;
    return state.merge({ fetching: false, error: null, projects })
};

export const failure = (state, { error }) => state.merge({ fetching: false, projects: null, error });

export const clear_projects_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROJECTS_REQUEST]: request,
    [Types.PROJECTS_SUCCESS]: success,
    [Types.PROJECTS_FAILURE]: failure,

    [Types.CLEAR_PROJECTS_STATE]: clear_projects_state,

});
