import PropTypes from 'prop-types';
import React from 'react';
import {connectRange} from 'react-instantsearch-dom';
import Icon from '../Icon';
import Helper from "../../Utils/Helper";

class AgeRange extends React.Component {
  static propTypes = {
    currentRefinement: PropTypes.any,
    refine: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const {currentRefinement, refine} = this.props;
    const min = Helper.getAgeFromUnix(this.props.min);
    return (
      <div className="flex flex-wrap">
        <div className="flex-1 -mb-3">
          <input
            type="number"
            className="input-field"
            onChange={event =>
              refine({
                ...currentRefinement,
                max: Helper.getUnixFromAge(event.currentTarget.value),
              })} placeholder={0} min={0} step={1} max={100}/>
        </div>

        <Icon className="mx-2 my-auto text-gray-20">angle-double-right</Icon>

        <div className="flex-1 -mb-3">
          <input
            type="number"
            className="input-field"
            onChange={event =>
              refine({
                ...currentRefinement,
                min: Helper.getUnixFromAge(+event.currentTarget.value + 1),
              })} placeholder={min || 100} min={0} step={1} max={100}/>
        </div>
      </div>
    );
  }
}

export default connectRange(AgeRange);
