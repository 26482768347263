import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import TalentRoute from '../../Components/Routes/TalentRoute';
// Routes
import PersonalInfoPage from '../../Containers/Talent/Profile/SubPages/PersonalInfoPage';
import AboutMePage from '../../Containers/Talent/Profile/SubPages/AboutMePage';
import PhotosPage from '../../Containers/Talent/Profile/SubPages/PhotosPage';
import SkillsPage from '../../Containers/Talent/Profile/SubPages/SkillsPage';
import SocialMediaPage from '../../Containers/Talent/Profile/SubPages/SocialMediaPage';
import EducationPage from '../../Containers/Talent/Profile/SubPages/EducationPage';
import PageLayoutPage from '../../Containers/Talent/Profile/SubPages/PageLayoutPage';
import AppearancePage from '../../Containers/Talent/Profile/SubPages/AppearancePage';

class ProfileRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <TalentRoute exact path="/talent/profile/personalia" params={{name: 'Profile'}} component={PersonalInfoPage}/>
        <TalentRoute exact path="/talent/profile/about-me" params={{name: 'Profile'}} component={AboutMePage}/>
        <TalentRoute exact path="/talent/profile/photos" params={{name: 'Profile'}} component={PhotosPage}/>
        <TalentRoute exact path="/talent/profile/appearance" params={{name: 'Profile'}} component={AppearancePage}/>
        <TalentRoute exact path="/talent/profile/skills" params={{name: 'Profile'}} component={SkillsPage}/>
        <TalentRoute exact path="/talent/profile/social-media" params={{name: 'Profile'}} component={SocialMediaPage}/>
        <TalentRoute exact path="/talent/profile/education" params={{name: 'Profile'}} component={EducationPage}/>
        <TalentRoute exact path="/talent/profile/profile-layout" params={{name: 'Profile'}} component={PageLayoutPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(ProfileRoutingComponent));
