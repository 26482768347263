import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  CreateCastingsRequest: ['obj'],
  CreateCastingsSuccess: ['castings'],
  CreateCastingsFailure: ['error'],

  GetCastingsRequest: ['obj'],
  GetCastingsSuccess: ['response'],
  GetCastingsFailure: ['error'],

  GetPageCastingsRequest: ['obj'],
  GetPageCastingsSuccess: ['response'],
  GetPageCastingsFailure: ['error'],

  GetDeclinedCastingsRequest: ['obj'],
  GetDeclinedCastingsSuccess: ['response'],
  GetDeclinedCastingsFailure: ['error'],

  GetDeclinedPageCastingsRequest: ['obj'],
  GetDeclinedPageCastingsSuccess: ['response'],
  GetDeclinedPageCastingsFailure: ['error'],

  GetCastingRequest: ['obj'],
  GetCastingSuccess: ['casting'],
  GetCastingFailure: ['error'],

  clearCastingsState: [],

});

export const AgencyCastingsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  creating: false,
  castings: null,
  casting: null,
  error: null,
  fetching: false,
  meta: null,
  links:null,
  declinedCastings: null,
  declinedLinks: null,
  declinedMeta: null
});

/* ------------- Reducers ------------- */

export const create_castings_request = (state) => state.merge({creating: true, error: null});

export const create_castings_success = (state, data) => {
  const {castings} = data;
  return state.merge({creating: false, error: null, castings})
};

export const create_castings_failure = (state, {error}) => state.merge({creating: false, castings: null, error});


export const get_castings_request = (state) => state.merge({fetching: true, error: null});

export const get_castings_success = (state, data) => {
  const {response} = data;
  return state.merge({fetching: false, error: null, castings:response.data, links: response.links, meta: response.meta})
};

export const get_castings_failure = (state, {error}) => state.merge({fetching: false, castings: null, error});


export const get_page_castings_request = (state) => state.merge({fetching: true, error: null});

export const get_page_castings_success = (state, data) => {
  const {response} = data;
  return state.merge({fetching: false, error: null, castings: response.data, links: response.links, meta: response.meta})
};

export const get_page_castings_failure = (state, {error}) => state.merge({fetching: false, castings: null, error});


export const get_declined_castings_request = (state) => state.merge({fetching: true, error: null});

export const get_declined_castings_success = (state, data) => {
  const {response} = data;
  return state.merge({fetching: false, error: null, declinedCastings: response.data, declinedLinks: response.links, declinedMeta: response.meta})
};

export const get_declined_castings_failure = (state, {error}) => state.merge({fetching: false, castings: null, error});


export const get_declined_page_castings_request = (state) => state.merge({fetching: true, error: null});

export const get_declined_page_castings_success = (state, data) => {
  const {response} = data;
  return state.merge({fetching: false, error: null, declinedCastings: response.data, declinedLinks: response.links, declinedMeta: response.meta})
};

export const get_declined_page_castings_failure = (state, {error}) => state.merge({fetching: false, castings: null, error});


export const get_casting_request = (state) => state.merge({fetching: true, error: null});

export const get_casting_success = (state, data) => {
  const {casting} = data;
  return state.merge({fetching: false, error: null, casting})
};

export const get_casting_failure = (state, {error}) => state.merge({fetching: false, casting: null, error});


export const clearAgencyCastingsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_CASTINGS_REQUEST]: create_castings_request,
  [Types.CREATE_CASTINGS_SUCCESS]: create_castings_success,
  [Types.CREATE_CASTINGS_FAILURE]: create_castings_failure,

  [Types.GET_CASTINGS_REQUEST]: get_castings_request,
  [Types.GET_CASTINGS_SUCCESS]: get_castings_success,
  [Types.GET_CASTINGS_FAILURE]: get_castings_failure,

  [Types.GET_PAGE_CASTINGS_REQUEST]: get_page_castings_request,
  [Types.GET_PAGE_CASTINGS_SUCCESS]: get_page_castings_success,
  [Types.GET_PAGE_CASTINGS_FAILURE]: get_page_castings_failure,

  [Types.GET_DECLINED_CASTINGS_REQUEST]: get_declined_castings_request,
  [Types.GET_DECLINED_CASTINGS_SUCCESS]: get_declined_castings_success,
  [Types.GET_DECLINED_CASTINGS_FAILURE]: get_declined_castings_failure,

  [Types.GET_DECLINED_PAGE_CASTINGS_REQUEST]: get_declined_page_castings_request,
  [Types.GET_DECLINED_PAGE_CASTINGS_SUCCESS]: get_declined_page_castings_success,
  [Types.GET_DECLINED_PAGE_CASTINGS_FAILURE]: get_declined_page_castings_failure,

  [Types.GET_CASTING_REQUEST]: get_casting_request,
  [Types.GET_CASTING_SUCCESS]: get_casting_success,
  [Types.GET_CASTING_FAILURE]: get_casting_failure,

  [Types.CLEAR_CASTINGS_STATE]: clearAgencyCastingsState,
});
