import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ConnectedRouter} from 'connected-react-router';
import {connect, Provider} from 'react-redux';
import App from '../App';
import StartupActions from '../../Redux/Startup/StartupRedux'
import I18n from "redux-i18n"

class RootContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
    };
  }

  componentDidMount() {
    this.props.startup();
  }

  UNSAFE_componentWillReceiveProps() {

  }


  render() {
    const {store, history} = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <I18n translations={{}} useReducer={true} fallbackLang="en">
            <App/>
          </I18n>
        </ConnectedRouter>
      </Provider>
    );
  }
}

RootContainer.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  startup: PropTypes.func,
  getTranslations: PropTypes.func,
  loginLoaded: PropTypes.any,
  logged_in: PropTypes.any,
  locale: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    loginLoaded: state.login.loginLoaded,
    logged_in: state.login.authToken,
    locale: state.translations.locale,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(StartupActions.startup())
});

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
