import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import OverviewPage from '../../../Containers/Agency/Project/Add/OverviewPage';

class AgencyAddProjectRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/projects/add/overview" params={{name: 'Projects'}} component={OverviewPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencyAddProjectRoutingComponent));
