import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
    invoicesRequest: [],
    invoicesSuccess: ['invoices'],
    invoicesFailure: ['error'],

    clearInvoicesState: [],
});

export const InvoicesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    invoices: null,
    error: null,
    fetching: false,
});

/* ------------- Reducers ------------- */

// we're attempting to fetch invoices
export const request = (state) => state.merge({fetching: true});

// we've successfully fetched the invoices
export const success = (state, data) => {
    const {invoices} = data;
    return state.merge({fetching: false, error: null, invoices});
};

export const failure = (state, {error}) => state.merge({fetching: false, invoices: null, error});

export const clear_invoices_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.INVOICES_REQUEST]: request,
    [Types.INVOICES_SUCCESS]: success,
    [Types.INVOICES_FAILURE]: failure,

    [Types.CLEAR_INVOICES_STATE]: clear_invoices_state,

});
