import PropTypes from 'prop-types';
import React from 'react';

class Checkbox extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.any,
  };

  render() {
    const {children, className, input} = this.props;

    return <label className={`block ${className || ''}`}><input {...input} type="checkbox" className="mr-2"/>
      {children}
    </label>;
  }
}

export default Checkbox;
