import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import I18n from '../../Utils/I18n';
import {url} from "../../Validators/Form/Form";
import Helper from '../../Utils/Helper';
import TextField from '../../Components/Form/TextField';
import Icon from '../Icon';

class AddUrl extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        image: PropTypes.string,
        uploadUrl: PropTypes.func,
        hideTitle: PropTypes.bool
    };

    static contextTypes = {
        t: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            hideTitle: true
        };

        this.form = null;
    }

    onSubmit = (value) => {
        const { uploadUrl } = this.props;
        uploadUrl(Helper.formatUrl(value.url));
    };

    render() {
        const {t} = this.context;
        const { className, hideTitle } = this.props;
        return (
            <Form
                onSubmit={this.onSubmit}
                validateOnBlur
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        {hideTitle ? '' : <Title className="text-xl pb-2">{I18n.t('url_label_description')}</Title>}

                        <div className="flex flex-row">
                            <Field name="url" validate={url}>
                                {({input, meta}) => (
                                    <div className="w-full max-w-full sm:pr-5">
                                        <TextField input={input} meta={meta} placeholder={t('url_placeholder')}/>
                                    </div>
                                )}
                            </Field>
                            <div className="flex-none pb-3">
                                <button className={`cursor-pointer${className ? ` ${className}` : ''}`}>
                                    <div className="flex items-center px-4 py-2 bg-secondary-40 text-white rounded">
                                        <Icon className="mr-3 text-sm">plus</Icon>
                                        <p className="font-bold text-sm w-full">{I18n.t('Add url')}</p>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </form>
                )}
            </Form>
        )

    }
}

export default AddUrl;
