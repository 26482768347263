import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import OverviewPage from '../../../Containers/Agency/Project/SubPages/OverviewPage';
import ExtraInfoPage from '../../../Containers/Agency/Project/SubPages/ExtraInfoPage';
import RolesPage from '../../../Containers/Agency/Project/SubPages/RolesPage';
import DeletePage from '../../../Containers/Agency/Project/SubPages/DeletePage';

class AgencyProjectRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/projects/:project/overview" params={{name: 'Projects'}} component={OverviewPage}/>
        <AgencyRoute exact path="/agency/projects/:project/extra-info" params={{name: 'Projects'}} component={ExtraInfoPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles" params={{name: 'Projects'}} component={RolesPage}/>
        <AgencyRoute exact path="/agency/projects/:project/delete" params={{name: 'Projects'}} component={DeletePage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencyProjectRoutingComponent));
