import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../Icon';

class DeletableItem extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.any,
  };

  render() {
    const {children, className, onClick} = this.props;

    return (
      <div className={`flex mr-2 mb-2 py-2 px-4 rounded bg-primary-10 border border-gray-10 ${className || ''}`}>
        <div className="text-sm text-gray-80">{children}</div>
        <Link to="#" onClick={onClick} className="block ml-2"><Icon className="text-gray-40 hover:text-negative">trash-alt</Icon></Link>
      </div>
    );
  }
}

export default DeletableItem;
