import {call, put} from 'redux-saga/effects'
import ExperienceTypesActions from '../../Redux/ExperienceTypes/ExperienceTypesRedux'
import {callApi} from '../CallApiSaga'

export function* getExperienceTypes(api) {
  const apiCall = yield call(api.getExperienceTypes);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceTypesActions.experienceTypesSuccess(response.data.data));
  } else {
    yield put(ExperienceTypesActions.experienceTypesFailure('WRONG'))
  }
}

export function* getExperienceTypeById(api, {id}) {
  const apiCall = yield call(api.getExperienceTypeById, id);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ExperienceTypesActions.experienceTypeByIdSuccess(response.data));
  } else {
    yield put(ExperienceTypesActions.experienceTypeByIdFailure('WRONG'))
  }
}
