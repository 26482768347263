import PropTypes from 'prop-types';
import React from 'react';

class Card extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
  };

  render() {
    const {className, children} = this.props;

    return (
      <div className={`shadow bg-white-transparent rounded overflow-hidden${className ? ` ${className}` : ''}`}>
        {children}
      </div>
    );
  }
}

export default Card;
