import React from 'react';
import Card from '../../../Components/Card';
import I18n from '../../../Utils/I18n';
import Helper from '../../../Utils/Helper';
import PropTypes from 'prop-types';

class AgencyRegisterConfirm extends React.Component {
  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {t} = this.context;

    return (
      <div className="flex justify-center px-4">

        <Card className="w-full max-w-5xl mx-4 p-16 mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center pb-6">{I18n.t('Thank you!')}</h2>

          <p className="font-light text-center text-lg max-w-md mx-auto">
            {I18n.t('We\'ll check you\'re registration and :action to confirm your register', {action: Helper.makeSemibold(t('activate your account as soon as possible'))})}
          </p>
        </Card>

      </div>
    );
  }
}

export default AgencyRegisterConfirm;
