import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import TalentRoute from '../../Components/Routes/TalentRoute';
// Routes
import UsernamePage from '../../Containers/Talent/Settings/SubPages/UsernamePage';
import PasswordPage from '../../Containers/Talent/Settings/SubPages/PasswordPage';
import SubscriptionPage from '../../Containers/Talent/Settings/SubPages/SubscriptionPage';
import SubscriptionCheckoutPage from '../../Containers/Talent/Settings/SubPages/SubscriptionCheckoutPage';
import InvoicePage from '../../Containers/Talent/Settings/SubPages/InvoicesPage';
import NotificationsPage from '../../Containers/Talent/Settings/SubPages/NotificationsPage';
import LanguagePage from '../../Containers/Talent/Settings/SubPages/LanguagePage';
import DeletePage from '../../Containers/Talent/Settings/SubPages/DeletePage';
import VisibilityPage from '../../Containers/Talent/Settings/SubPages/VisibilityPage';
import BillingDetailsPage from '../../Containers/Talent/Settings/SubPages/BillingDetailsPage';

class SettingsRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <TalentRoute exact path="/talent/settings/username" params={{name: 'Settings'}} component={UsernamePage}/>
        <TalentRoute exact path="/talent/settings/password" params={{name: 'Settings'}} component={PasswordPage}/>
        <TalentRoute exact path="/talent/settings/subscription" params={{name: 'Settings'}} component={SubscriptionPage}/>
        <TalentRoute exact path="/talent/settings/subscription/:planId/checkout" params={{name: 'Settings'}} component={SubscriptionCheckoutPage}/>
        <TalentRoute exact path="/talent/settings/billing-details" params={{name: 'Settings'}} component={BillingDetailsPage}/>
        <TalentRoute exact path="/talent/settings/invoices" params={{name: 'Settings'}} component={InvoicePage}/>
        <TalentRoute exact path="/talent/settings/notifications" params={{name: 'Settings'}} component={NotificationsPage}/>
        <TalentRoute exact path="/talent/settings/visibility" params={{name: 'Settings'}} component={VisibilityPage}/>
        <TalentRoute exact path="/talent/settings/language" params={{name: 'Settings'}} component={LanguagePage}/>
        <TalentRoute exact path="/talent/settings/delete" params={{name: 'Settings'}} component={DeletePage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(SettingsRoutingComponent));
