import React from 'react';
import {Configure, InstantSearch, Pagination, Stats} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import {orderBy} from 'lodash';
import Title from '../../../../Components/Text/Title';
import CustomHits from '../../../../Components/Algolia/CustomHits';
import PropertyTypesActions from '../../../../Redux/PropertyTypes/PropertyTypesRedux';
import {connect} from 'react-redux';
import DropdownList from '../../../../Components/Algolia/DropdownList';
import ColorSelector from '../../../../Components/Algolia/ColorSelector';
import NumberRange from '../../../../Components/Algolia/NumberRange';
import Label from '../../../../Components/Algolia/Label';
import ToggleSelect from '../../../../Components/Algolia/ToggleSelect';
import IconSelect from '../../../../Components/Algolia/IconSelect';
import SearchField from '../../../../Components/Algolia/SearchField';
import AgeRange from '../../../../Components/Algolia/AgeRange';
import skillsetCategoriesActions from '../../../../Redux/SkillsetCategories/SkillsetCategoriesRedux';
import MultiSelect from '../../../../Components/Form/MultiSelect';
import AgencyCastingsActions from '../../../../Redux/Castings/AgencyCastingsRedux';
import Body from '../../../../Components/Text/Body';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import TextButton from '../../../../Components/Buttons/TextButton';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';
import SelectAllButton from '../../../../Components/Algolia/SelectAllButton';


class AlgoliaSearchPage extends React.Component {
  static contextTypes = {
    t: PropTypes.func,
  };

  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    match: PropTypes.any,
    property_types: PropTypes.any,
    property_types_fetching: PropTypes.bool,
    property_types_error: PropTypes.any,
    skillset_categories: PropTypes.any,
    getPropertyTypes: PropTypes.func,
    getSkillsetCategories: PropTypes.func,
    facetFilters: PropTypes.any,
    selected: PropTypes.any,
    castings_creating: PropTypes.bool,
    creating: PropTypes.bool,
    castings: PropTypes.any,
    castings_error: PropTypes.any,
    createCastings: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      facetFilters: null,
      skills: {},
      selected: [],
      creating: false,
    };
    this.searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_SEARCH_ONLY_KEY);
  }

  componentDidMount() {
    this.props.getPropertyTypes();
    this.props.getSkillsetCategories();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.castings_creating) {
      if (this.props.castings !== prevProps.castings && this.state.creating) {
        this.setState({creating: false}, function () {
          const {project, role} = this.props.match.params;
          this.props.history.push(`/agency/projects/${project}/project-roles/${role}/people/contacted`);
        });
      } else if ((this.props.castings_error !== prevProps.castings_error) && this.state.creating) {
        this.setState({creating: false});
      }
    }
  }

  createCastingsForSelected = (e) => {
    e.preventDefault();
    const {selected} = this.state;
    const {project, role} = this.props.match.params;
    const obj = {users: selected, project_id: project, role_id: role};

    if (!selected.length) {
      return;
    }

    if (!project || !role) {
      return;
    }

    this.setState({creating: true}, function () {
      this.props.createCastings(obj);
    });
  };

  //Convert skillsets from api to something usable for algolia
  convertSkillset(skillset_categories) {
    let filteredForDropdown = [];

    if (!skillset_categories) {
      return;
    }

    skillset_categories.forEach((category) => {
      let options = [];

      category.skillsets.forEach((skill) => {
        if (skill.options.length > 1) {
          skill.options.forEach((option) => {
            options.push({
              label: skill.title + ' - ' + option.title,
              value: 'skills.' + skill.id + ':' + option.id,
            });
          });
        } else {
          const option = skill.options[0];
          option ?
            options.push({
              label: skill.title,
              value: 'skills.' + skill.id + ':' + option.id,
            }) : null;
        }
      });

      filteredForDropdown.push({
        title: category.title,
        id: category.id,
        options: options,
      });
    });

    return filteredForDropdown;
  }

  _renderYesNo(items) {
    items = orderBy(items, ['label', 'count'], ['asc', 'desc']);

    return (items.map(item => ({...item, label: item.label === '1' ? 'Yes' : 'No'})));
  }

  renderColorField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-4">
        <Label name={attribute}>{label}</Label>
        <ColorSelector attribute={attribute}
                       transformItems={items => orderBy(items, ['label', 'count'], ['asc', 'desc'])}/>
      </div>
    );
  }

  renderDropdownField(attribute, label) {
    const {t} = this.context;
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute} id={attribute}>{label}</Label>
        <DropdownList attribute={attribute}
                      transformItems={items => {
                        items = items.map(item => ({...item, label: t(item.label)}));
                        return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                      }} limit={1000}/>
      </div>
    );
  }

  renderNumberField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute}>{label}</Label>
        <NumberRange attribute={attribute}/>
      </div>
    );
  }

  renderLightswitchField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute}>{label}</Label>
        <ToggleSelect attribute={attribute} transformItems={this._renderYesNo}/>
      </div>
    );
  }

  renderButtonsField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute}>{label}</Label>
        <IconSelect attribute={attribute}/>
      </div>
    );
  }

  renderSearchField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute}>{label}</Label>
        <SearchField attribute={attribute}/>
      </div>
    );
  }

  renderAgeField(attribute, label) {
    return (
      <div key={attribute} className="md:pr-4 mb-5">
        <Label name={attribute}>{label}</Label>
        <AgeRange attribute={attribute}/>
      </div>
    );
  }

  selectSkill = (values, action) => {
    let skills = this.state.skills;

    skills[action.name] = values ? values.map(obj => obj.value) : [];

    this.setState({skills: skills});

    let facetFilters = Object.values(skills).filter(x => x.length);

    facetFilters = facetFilters.length ? facetFilters : null;

    this.setState({facetFilters: facetFilters});
  };

  selectUser = (person) => {
    let selected = this.state.selected;
    const position = selected.indexOf(person.id);

    if (position === -1) {
      selected.push(person.id);
    } else {
      selected.splice(position, 1);
    }

    let uniqueItems = [...new Set(selected)];

    this.setState({selected: uniqueItems});
  };

  selectAllHits = (hits) => {
    let selected = this.state.selected;
    let finalArray = hits.map(function (obj) {
      const position = selected.indexOf(obj.id);
      if (position === -1) {
        return obj.id;
      }
    });

    this.setState({selected: [...selected, ...finalArray]});
  };

  deselectAllUsers = (e) => {
    e.preventDefault();

    this.setState({selected: []});
  };

  render() {
    let {property_types} = this.props;
    const {facetFilters, selected} = this.state,
      skill_categories = this.convertSkillset(this.props.skillset_categories),
      {t} = this.context;

    property_types = property_types ? property_types : [];

    return (
      <div className="flex-1">
        <InstantSearch searchClient={this.searchClient} indexName={process.env.ALGOLIA_INDEX}>
          <Configure
            hitsPerPage={30}
            facetFilters={facetFilters}
          />
          <div className="flex-1 pl-3 sm:px-10 pb-16 xl2:flex justify-between">
            <div className="w-full">

              <div className="flex justify-between items-center min-w-64">
                <Title className="flex-1 text-xl pb-2">{I18n.t('Search')}</Title>

                <Stats translations={{
                  stats(nbHits) {
                    return <Body className="md:pr-4">{nbHits} {I18n.t('search results')}</Body>;
                  },
                }}/>
              </div>

              <div className="md:flex mb-5">
                <div className="flex-1">
                  <div>
                    <h3 className="text-sm text-gray-40 pb-2 font-semibold">{I18n.t('Profile', null, true)}</h3>
                    <div className="flex-1 mb-10">
                      {this.renderSearchField('name', I18n.t('Search on name', null, true))}
                      {this.renderDropdownField('country', I18n.t('country', null, true))}
                      {this.renderAgeField('birthday', I18n.t('age', null, true))}
                      {this.renderButtonsField('gender', I18n.t('gender', null, true))}
                    </div>
                  </div>

                  <div className="pt-8">
                    <h3 className="text-sm text-gray-40 pb-2 font-semibold">{I18n.t('Skills', null, true)}</h3>
                    <div className="flex-1 mb-10">
                      {skill_categories
                        ? skill_categories.map((category, key) =>
                          <div key={category.title} className="md:pr-4 mb-5">
                            <Label name={category.title}>{I18n.t(category.title, null, true)}</Label>
                            <MultiSelect key={key} input={{name: 'cat-' + category.id, onChange: this.selectSkill}}>
                              {category.options}
                            </MultiSelect>
                          </div>)
                        : <Loading/>
                      }
                    </div>

                  </div>
                </div>
                <div className="flex-1">
                  <h3 className="text-sm text-gray-40 pb-2 font-semibold w-full">{I18n.t('Appearance', null, true)}</h3>
                  <div>
                    {property_types
                      ? property_types.map((property_type) => {
                        switch (property_type.type) {
                          case 'number':
                            return (this.renderNumberField(`properties.${property_type.id}`, t(property_type.title).toUpperCase()));
                          case 'colors':
                            return (this.renderColorField(`properties.${property_type.id}`, t(property_type.title).toUpperCase()));
                          case 'lightswitch':
                            return (this.renderLightswitchField(`properties.${property_type.id}`, t(property_type.title).toUpperCase()));
                          case 'buttons':
                            return (this.renderButtonsField(`properties.${property_type.id}`, t(property_type.title).toUpperCase()));
                          case 'dropdown':
                          default:
                            return (this.renderDropdownField(`properties.${property_type.id}`, t(property_type.title).toUpperCase()));
                        }
                      })
                      : <Loading/>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-gray-60 text-lg font-semibold">{I18n.t('search results', null, false, true)}</h3>
              {selected
                ? selected.length
                  ? <div className="mb-8">
                    <Body>
                      {I18n.t('Move the selected')} <strong>
                      {selected.length > 1 ? selected.length : null}&nbsp;{I18n.t(['user', 'users', 'user_count'], {user_count: selected.length})}
                    </strong> {I18n.t('to contacted')}
                    </Body>
                    <div className="flex flex-wrap">
                      <SelectAllButton selectAllHits={this.selectAllHits} className="my-auto"/>
                      <FilledButton type={'button'} onClick={this.createCastingsForSelected}
                                    disabled={this.state.creating} className="bg-primary-40 rounded text-white my-4 ml-2"
                                    iconRight icon="chevron-right">
                        {I18n.t('move-selected-users.contacted')}
                      </FilledButton>
                    </div>
                    <TextButton onClick={this.deselectAllUsers} className="text-gray-40 hover:text-negative mt-4"
                                iconLeft icon="times">
                      {I18n.t('Deselect all')}
                    </TextButton>
                  </div>
                  : <div>
                    <Body className="mb-8">{I18n.t('next-action.contacted')}</Body>
                    <SelectAllButton selectAllHits={this.selectAllHits} className="-mt-2 mb-6"/>
                  </div>
                : <Loading/>
              }
              <CustomHits className="min-w-48 md:w-1/2 md:pr-4" onClick={(hit) => this.selectUser(hit)}
                          selected={selected}/>

              <div className="mt-4">
                <Pagination/>
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    property_types: state.property_types.property_types,
    skillset_categories: state.skillset_categories.skillset_categories,
    castings_creating: state.agency_castings.creating,
    castings: state.agency_castings.castings,
    castings_error: state.agency_castings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPropertyTypes: () => dispatch(PropertyTypesActions.propertyTypesRequest()),
    getSkillsetCategories: () => dispatch(skillsetCategoriesActions.skillsetCategoriesRequest()),
    createCastings: (obj) => dispatch(AgencyCastingsActions.CreateCastingsRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlgoliaSearchPage);
