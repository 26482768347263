import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import RoleActions from '../../../Redux/Project/RoleRedux';
import AgencyProjectRoleRoutingComponent from '../../../Components/Routing/Agency/AgencyProjectRoleRoutingComponent';
import ProjectActions from '../../../Redux/Project/ProjectRedux';
import I18n from '../../../Utils/I18n';

class RolePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getRoleById: PropTypes.func,
    getProjectId: PropTypes.func,
    project: PropTypes.any,
    role: PropTypes.any,
    role_fetching: PropTypes.bool,
    role_error: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {project, role} = this.props.match.params;
    this.props.getRoleById({project_id: project, role_id: role});
    this.props.getProjectId(project);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.role_fetching) {
      if ((this.props.role_error !== prevProps.role_error)) {
        this.props.history.push(`/agency/projects/`);
      }
    }
  }

  render() {
    const {project, role} = this.props.match.params;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to={`/agency/projects/${project}`} noActiveStyle icon="chevron-left" className="-mt-1">{I18n.t('Project details')}</SubNavItem>

              <hr className="m-3 sm:mr-5 ml-0 border-0 h-px bg-gray-10"/>

              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/overview`} icon="info-circle">{I18n.t('Overview')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/briefing`} icon="file-pdf">{I18n.t('Role briefing')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people`} icon="users">{I18n.t('View talents')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/delete`} icon="trash-alt">{I18n.t('delete', null, false, true)}</SubNavItem>
            </SubNav>

            <AgencyProjectRoleRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    role: state.role.role,
    role_fetching: state.role.fetching,
    role_error: state.role.error,

    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleById: (obj) => dispatch(RoleActions.roleByIdRequest(obj)),
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolePage);
