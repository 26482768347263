import PropTypes from 'prop-types';
import React from 'react';
import {Field, Form} from 'react-final-form';
import Label from './Form/Label';
import TextField from './Form/TextField';
import FilledButton from './Buttons/FilledButton';
import Card from './Card';
import I18n from '../Utils/I18n';

class ChatWindow extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    addMessage: PropTypes.func,
  };

  componentDidMount() {
    const chatWindow = document.getElementById('ChatWindow');
    chatWindow.scrollTo(0, chatWindow.scrollHeight);
  }

  onSubmit = (value) => {
    this.props.addMessage(value.message)
  }

  validate(){

  }

  render() {
    const {children, className} = this.props;

    return (
      <Card className={`flex flex-col ${className || ''}`}>

        <div className="px-3 mb-auto pb-3 pt-2 overflow-y-scroll h-full" id="ChatWindow">
          {children}
        </div>

        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          validateOnBlur
          initialValues={{}}
        >
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="mt-auto">
              <div className="flex shadow">
                <Field name="message">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input} className="absolute h-0 opacity-0">{I18n.t('New Message', null, true)}</Label>
                      <TextField input={input} meta={meta} className="chat"/>
                    </div>
                  )}
                </Field>
                <FilledButton type="submit" className="bg-primary-40 text-white justify-center" noText icon="paper-plane"/>
              </div>
            </form>
          )}
        </Form>
      </Card>
    );
  }
}

export default ChatWindow;
