import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import FormError from './FormError';

class MultiSelect extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  renderSelect() {
    const {children, className, input} = this.props;
    const {t} = this.context;

    return <Select {...input} isMulti aria-labelledby={input.name} name={input.name} inputId={input.name} options={children} placeholder={`${t('Select')}...`} className={` ${className || ''}`} theme={theme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'rgba(66, 153, 225, 0.5)',
        primary75: '#81A0FF',
        primary50: '#DDE6FB',
        primary25: '#EBF1FF',
        danger: '#FF3F3F',
        dangerLight: '#D0D0D9',
        neutral0: '#fff',
        neutral5: '#E9E9F1',
        neutral10: '#D0D0D9',
        neutral20: '#D0D0D9',
        neutral30: '#9FA5BA',
        neutral40: '#6A7084',
        neutral50: '#484C59',
        neutral60: '#262933',
        neutral70: '#262933',
        neutral80: '#141519',
        neutral90: '#000',
      },
    })}/>;
  }

  render() {
    const {meta} = this.props;

    return meta && meta.error && meta.touched ?
      <div>
        {this.renderSelect(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderSelect();
  }
}

export default MultiSelect;
