import React from 'react';
import Title from '../../../../Components/Text/Title';
import InvoiceItem from '../../../../Components/ListItems/InvoiceItem';
import I18n from '../../../../Utils/I18n';
import InvoiceActions from '../../../../Redux/Billing/InvoicesRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../../Components/Loading';
import Body from '../../../../Components/Text/Body';

class InvoicePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    getInvoices: PropTypes.func,
    invoices: PropTypes.any,
  };

  componentDidMount() {
    this.props.getInvoices();
  }

  render() {
    const {invoices} = this.props;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Title className="text-xl pb-4">{I18n.t('Invoices')}</Title>
        {invoices
          ? invoices.length
          ? invoices.map((invoice, key) => <InvoiceItem className="mb-6 max-w-xl" key={key} invoice={invoice}/>)
            :<Body>{I18n.t('No invoices')}</Body>
          : <Loading/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices.invoices ? state.invoices.invoices : [],
    fetching_invoices: state.invoices.fetching,
    error_invoices: state.invoices.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoices: () => dispatch(InvoiceActions.invoicesRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
