import React from 'react';
import Card from '../../Components/Card';
import Title from '../../Components/Text/Title';
import TextButton from '../../Components/Buttons/TextButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class Error404 extends React.Component {
  static propTypes = {
    account: PropTypes.any,
  };

  render() {
    const {account} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className={`mx-auto flex flex-wrap justify-center items-center px-6 py-8 mb-5 md:min-h-128 ${account ? 'w-full' : 'md:w-1/3 mt-6 lg:mt-24'}`}>
          <div className="text-center">
            <Title>Pagina niet gevonden</Title>
            <TextButton to={account ? `/dashboard` : '/login'} className="pt-4 text-gray-40" icon="chevron-left" iconLeft>{account ? 'Terug naar het Dashboard' : 'Terug naar de loginpagina'}</TextButton>
          </div>
        </Card>
      </main>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    account: state.account.account,
  };
};

export default connect(mapStateToProps)(Error404);
