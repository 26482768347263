import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import {Link} from 'react-router-dom';
import Icon from '../Icon';
import RoundedImage from '../Images/RoundedImage';
import Helper from '../../Utils/Helper';
import FilledButton from '../Buttons/FilledButton';
import I18n from '../../Utils/I18n';
import Castings from '../../Utils/Castings';
import CastingActions from '../../Redux/Talent/CastingRedux';
import {connect} from 'react-redux';

class SearchResultItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    result: PropTypes.object,
    onClick: PropTypes.any,
    singleSelect: PropTypes.bool,
    match: PropTypes.any,

    updateCasting: PropTypes.func,
    casting: PropTypes.any,
    casting_updating: PropTypes.bool,
    casting_updated: PropTypes.bool,
    casting_error: PropTypes.any,
    updating: PropTypes.bool,

    onCastingUpdated: PropTypes.func,
    acceptText: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      updating: false,
    };
  }

  componentDidUpdate(prevProps) {
    const self = this;
    if (!this.props.casting_updating) {
      if (this.props.casting_updated && (this.props.casting_updated !== prevProps.casting_updated) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.onCastingUpdated();
        });
      } else if ((this.props.casting_error !== prevProps.casting_error) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.onCastingUpdated();
        });
      }
    }
  }

  onCastingUpdated = () => {
    this.props.onCastingUpdated();
  };

  changeStatusOfCasting(status) {
    if (this.state.updating) {
      return;
    }
    const casting = this.props.result;
    const obj = {
      project: casting.role.project_id,
      role: casting.role.id,
      casting: casting.id,
      data: {
        status: status,
      },
    };
    this.setState({updating: true}, function () {
      this.props.updateCasting(obj);
    });
  }

  onRejected = (e) => {
    e.preventDefault();

    this.changeStatusOfCasting('archived');
  };

  onAccepted = (e) => {
    const casting = this.props.result, nextStatus = Castings.nextStatusForCurrentStatus(casting.status);

    e.preventDefault();

    this.changeStatusOfCasting(nextStatus);
  };

  render() {
    const {className, onClick, result, singleSelect, acceptText} = this.props;
    if (!result.role && !result.talent) {
      return ('');
    }

    return (
      <div className={className || ''}>
        <Card className="h-full flex flex-wrap">
          <div className="xl:flex items-center pt-px w-full relative" data-style-id={result.id}>
            <Link to={`/agency/projects/${result.role.project_id}/project-roles/${result.role.id}/castings/${result.id}`}
                  className="block m-4 mb-0 xl:mb-4 rounded-full group">
              <RoundedImage className="w-20 h-20 m-auto pointer-events-none bg-white" hoverable src={result.talent.avatar}/>
            </Link>
            <Link to={!onClick ? `/agency/projects/${result.role.project_id}/project-roles/${result.role.id}/castings/${result.id}` : '#'} className="block p-4 pt-0 xl:pl-0 xl:pt-8 xl:pb-8 flex-1" data-id={result.id} onClick={onClick}>
              <div className="pointer-events-none flex-1">
                <p className="text-lg text-center xl:text-left">{result.talent.firstname} {result.talent.lastname}</p>
                <div className="flex items-center justify-center xl:justify-start">
                  <Icon className="text-lg mr-2 text-gray-40">{Helper.renderGenderIcon(result.talent.gender)}</Icon>
                  <p className="text-gray-40 text-sm">{result.talent.city ? `${result.talent.city} | ` : null}{result.talent.birthday ? `${Helper.getAgeFromDate(result.talent.birthday)} | ` : null}{result.talent.locale.toUpperCase()}</p>
                </div>
                {result.media
                  ? <div className="bg-gray-20 flex justify-end w-12 h-12 absolute right-0 top-0 rounded-bl-full p-3">
                    <Icon className="text-white text-sm">photo-video</Icon></div>
                  : null
                }
              </div>
            </Link>
          </div>
          {singleSelect && !this.state.updating && result && (['selected', 'audition', 'shortlist'].indexOf(result.status) !== -1) ?
            <div className="flex w-full mt-auto">
              <FilledButton type={'button'} onClick={this.onRejected} className="w-full text-center bg-gray-10 text-gray-40 hover:bg-gray-15 hover:text-gray-60 sm:break-all">{I18n.t('archive', null, true)}</FilledButton>
              <FilledButton type={'button'} onClick={this.onAccepted} className="w-full text-center bg-secondary-30 text-secondary-60 hover:bg-secondary-40 hover:text-secondary-80">{I18n.t(acceptText || 'apply', null, true)}</FilledButton>
            </div>
            : null
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    casting: state.agency_castings.casting,

    casting_error: state.casting.error,

    casting_updating: state.casting.updating,
    casting_updated: state.casting.casting_updated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCasting: (obj) => dispatch(CastingActions.updateCastingRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultItem);
