import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  forgotPasswordRequest: ['forgotObj'],
  forgotPasswordSuccess: ['response'],
  forgotPasswordFailure: ['error'],
});

export const ForgotPasswordTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  resetting: false,
});

/* ------------- Reducers ------------- */

// we're attempting to account
export const request = (state) => state.merge({resetting: true, error: null});

// we've successfully logged in
export const success = (state, data) => {
  const {response} = data;
  return state.merge({resetting: false, error: null, response});
};

export const failure = (state, {error}) => state.merge({resetting: false, response: null, error});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FORGOT_PASSWORD_REQUEST]: request,
  [Types.FORGOT_PASSWORD_SUCCESS]: success,
  [Types.FORGOT_PASSWORD_FAILURE]: failure,
});
