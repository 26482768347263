import React from 'react';
import Title from '../../../../Components/Text/Title';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CastingActions from '../../../../Redux/Talent/CastingRedux';
import MediaCollection from '../../../../Components/Lists/MediaCollection';
import Helper from '../../../../Utils/Helper';
import I18n from '../../../../Utils/I18n';
import NoModalMediaCollection from '../../../../Components/Lists/NoModalMediaCollection';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddMedia from '../../../../Components/Images/AddMedia';
import AddUrl from '../../../../Components/Images/AddUrl';

class AuditionPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getCastingById: PropTypes.func,
    updateCasting: PropTypes.func,
    casting: PropTypes.any,
    casting_updating: PropTypes.bool,
    casting_updated: PropTypes.bool,
    casting_error: PropTypes.any,
    updating: PropTypes.bool,
    uploadSingleImage: PropTypes.func,
    clearUploadCareState: PropTypes.func,
    file_uploading: PropTypes.bool,
    file_uploaded: PropTypes.bool,
    file: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      file_uploading: false,
      uploadedFileType: null,
    };
  }

  getCasting() {
    const {account} = this.props;
    const {casting} = this.props.match.params;
    this.props.getCastingById({username: account.username, casting_id: casting});
  }

  componentDidUpdate(prevProps) {
    const self = this;
    const {casting} = this.props;
    if (casting && casting.status !== 'audition') {
      this.props.history.push(`/talent/projects/${casting.id}`);
    }

    if (!this.props.casting_updating) {
      if (this.props.casting_updated && (this.props.casting_updated !== prevProps.casting_updated) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.getCasting();
        });
      } else if ((this.props.casting_error !== prevProps.casting_error) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.getCasting();
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUploadCareState();
  }

  uploadedFile = (file) => {
    this.setState({updating: true}, function () {
      const {casting} = this.props;
      const files = casting ? (casting.media != null ? casting.media : []) : [];
      let mediaArray = [];
      files.map((file) => {
        mediaArray.push(file);
      });

      mediaArray.push({url: file.cdnUrl, type: Helper.fileType(file.mimeType)});

      const obj = {
        project: casting.role.project.id,
        role: casting.role.id,
        casting: casting.id,
        data: {
          media: mediaArray,
        },
      };

      this.props.updateCasting(obj);

    });
  };

  uploadUrl = (url) => {
    this.setState({updating: true}, function () {
      const {casting} = this.props;
      const files = casting ? (casting.media != null ? casting.media : []) : [];
      let mediaArray = [];
      files.map((file) => {
        mediaArray.push(file);
      });

      mediaArray.push({url: url, type: 'url'});

      const obj = {
        project: casting.role.project.id,
        role: casting.role.id,
        casting: casting.id,
        data: {
          media: mediaArray,
        },
      };

      this.props.updateCasting(obj);

    });
  };

  deleteMedia = (path) => {
    const {casting} = this.props;
    if (casting.media) {
      const mediaArray = casting.media.filter((media) => media.url !== path);
      const obj = {
        project: casting.role.project.id,
        role: casting.role.id,
        casting: casting.id,
        data: {
          media: mediaArray,
        },
      };

      this.setState({updating: true}, function () {
        this.props.updateCasting(obj);
      });
    }
  };

  render() {
    const {casting} = this.props;
    const {file_uploading} = this.state;
    return (
      <div className="flex-1 sm:flex pl-3 sm:px-10 pb-16">
        <div className="w-full">

          <Title className="text-xl mb-10">{I18n.t('Audition')}</Title>

          <div className="mb-20">

            <Title className="text-l pb-2">{I18n.t('Media')}</Title>
            {casting &&
            <div>
              <MediaCollection
                deleteMedia={this.deleteMedia}
                collection={Helper.getImagesAndVideoFromMedia(casting.media)}
                deletable
              />
            </div>
            }

            <AddMedia onlyImages={false} className="max-w-xl" uploadedFile={this.uploadedFile} disabled={file_uploading}/>

          </div>

          <div>

            <Title className="text-l pb-2">{I18n.t('Links')}</Title>

            {casting &&
              <div>
                <NoModalMediaCollection
                  deleteMedia={this.deleteMedia}
                  collection={Helper.getAudioAndUrlFromMedia(casting.media)}
                  deletable
                />
              </div>
            }

            <AddUrl hideTitle={true} uploadUrl={this.uploadUrl} />

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    casting: state.casting.casting,
    casting_fetching: state.casting.fetching,
    casting_error: state.casting.error,

    casting_updating: state.casting.updating,
    casting_updated: state.casting.casting_updated,

    file: state.upload_care.file,
    file_uploading: state.upload_care.uploading,
    file_uploaded: state.upload_care.uploaded,
    file_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCastingById: (obj) => dispatch(CastingActions.castingByIdRequest(obj)),
    updateCasting: (obj) => dispatch(CastingActions.updateCastingRequest(obj)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditionPage);
