import React from 'react';
import MemberItem from '../../../Components/ListItems/MemberItem';
import {Field, Form} from 'react-final-form';
import Label from '../../../Components/Form/Label';
import TextField from '../../../Components/Form/TextField';
import FilledButton from '../../../Components/Buttons/FilledButton';
import Card from '../../../Components/Card';
import {connect} from 'react-redux';
import InvitationsActions from '../../../Redux/Account/InvitationsRedux';
import UsersActions from '../../../Redux/Account/UsersRedux';
import PropTypes from 'prop-types';
import Title from '../../../Components/Text/Title';
import {required} from '../../../Validators/Form/Form';
import I18n from '../../../Utils/I18n';
import Loading from '../../../Components/Loading';

class StaffPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    createInvitation: PropTypes.func,
    created: PropTypes.bool,
    creating: PropTypes.bool,
    saving: PropTypes.bool,
    create_error: PropTypes.any,
    getInvitations: PropTypes.func,
    getUsers: PropTypes.func,
    invitations: PropTypes.array,
    users: PropTypes.array,
    deleteInvitation: PropTypes.func,
    deleted: PropTypes.bool,
    deleting: PropTypes.bool,
    deleting_invitation: PropTypes.bool,
    delete_error: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      deleting_invitation: false,
    };

    this.form = null;
  }

  componentDidMount() {
    this.props.getInvitations();
    this.props.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.creating) {
      if ((this.props.created !== prevProps.created) && this.state.saving) {
        this.setState({saving: false}, function () {
          this.props.getInvitations();
        });
      } else if ((this.props.create_error !== prevProps.create_error) && this.state.saving) {
        this.setState({saving: false}, function () {
        });
      }
    }

    if (!this.props.deleting) {
      if ((this.props.deleted !== prevProps.deleted) && this.state.deleting_invitation) {
        this.setState({deleting_invitation: false}, function () {
          this.props.getInvitations();
        });
      } else if ((this.props.delete_error !== prevProps.delete_error) && this.state.deleting_invitation) {
        this.setState({deleting_invitation: false}, function () {
        });
      }
    }
  }

  onSubmit = (values) => {
    this.setState({saving: true}, function () {
      this.props.createInvitation(values.email);
    });
  };

  onDelete = (invitation) => {
    this.setState({deleting_invitation: true}, function () {
      this.props.deleteInvitation(invitation.code);
    });
  };

  validate() {
    const {saving} = this.state, {create_error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = create_error ? {...create_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {invitations, users} = this.props, {t} = this.context;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Title className="text-center lg:text-left pt-2 pl-6">{I18n.t('verified employees', null, false, true)}</Title>
        {users
          ? users.length > 0
            ? <div className="mt-5 mb-12">
              {users && users.map((user, key) => {
                return <MemberItem invitation={false} member={user} key={key} to={`/agency/employees/${user.username}`} className="max-w-2xl mx-auto lg:mx-0"/>;
              })}
            </div>
            : null
          : <Loading/>
        }

        <h3 className="font-semibold text-gray-40 pb-5 pl-6 text-center lg:text-left">{I18n.t('Invitations', null, true)}</h3>

        {invitations
          ? invitations.map((invitation, key) =>
            <MemberItem invitation={true} member={invitation} key={key} onClick={(e) => {
              e.preventDefault();
              this.onDelete(invitation);
            }} className="max-w-2xl mx-auto lg:mx-0"/>)
          : <Loading/>
        }
        <Card className="mb-4 p-6 max-w-2xl mx-auto lg:mx-0">
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate.bind(this)}
            validateOnBlur
          >
            {({handleSubmit, form}) => {
              this.form = form;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="flex justify-between">
                    <div className="flex-1 mr-4 sm:mr-8">
                      <Field name="email" validate={required}>
                        {({input, meta}) => (
                          <div className="-mb-3">
                            <Label input={input}>{I18n.t('employee email address', null, true)}</Label>
                            <TextField input={input} meta={meta} placeholder={t('e.g. john.doe@thecastingdirector.eu')}/>
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="mt-6 mb-auto">
                      <FilledButton type="submit" disabled={this.state.saving} icon="envelope" className="rounded text-white bg-secondary-40">
                        {I18n.t('invite employee', null, false, true)}
                      </FilledButton>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invitations: state.invitations.invitations,
    users: state.users.users,
    created: state.invitations.created,
    creating: state.invitations.creating,
    create_error: state.invitations.create_error,
    deleted: state.invitations.deleted,
    deleting: state.invitations.deleting,
    delete_error: state.invitations.delete_error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createInvitation: (email) => dispatch(InvitationsActions.createInvitationRequest(email)),
    deleteInvitation: (invitation_code) => dispatch(InvitationsActions.deleteInvitationRequest(invitation_code)),
    getInvitations: () => dispatch(InvitationsActions.getInvitationsRequest()),
    getUsers: () => dispatch(UsersActions.getUsersRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffPage);
