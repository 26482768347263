import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Icon from '../Icon';
import {Link} from 'react-router-dom';
import TextButton from '../Buttons/TextButton';
import RoundedImage from '../Images/RoundedImage';
import LoginActions from '../../Redux/Auth/LoginRedux';
import I18n from '../../Utils/I18n';

class UserArea extends React.Component {
  static propTypes = {
    account: PropTypes.any,
    className: PropTypes.string,
    logout: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {UserDropdownOpen: false};
  }


  toggleDropdown = (e) => {
    e.preventDefault();

    this.setState({UserDropdownOpen: !this.state.UserDropdownOpen});
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    const {className, account} = this.props;
    const {UserDropdownOpen} = this.state;

    return (
      <div className="relative">
        <Link to=""
              onClick={this.toggleDropdown}
              className={`flex items-center ${className || ''}`}
              aria-haspopup="listbox"
              aria-labelledby="user-dropdown"
              id="user-dropdown"
        >
          <RoundedImage className="w-8 h-8 mx-3 text-3xl bg-white" src={account ? account.avatar : null} alt="username"/>
          <p className="text-sm text-gray-80">{account ? account.username : ''}</p>
          <Icon className="ml-3 text-sm mb-1">sort-down</Icon>
        </Link>
        <div
          className={`absolute bg-white rounded shadow p-2 my-2 right-0 -mr-3${UserDropdownOpen ? '' : ' hidden'}`}
          onBlur={this.toggleDropdown}
          tabIndex={UserDropdownOpen ? '0' : '-1'}
          role="listbox"
          aria-expanded={UserDropdownOpen ? true : null}
          aria-labelledby="user-dropdown"
        >
          <ul>
            <li className="flex" role="option">
              <TextButton onClick={this.logout} iconLeft icon="power-off" className="rounded hover:bg-primary-10 w-full p-2">
                {I18n.t('Sign out')}
              </TextButton>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(LoginActions.logoutRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserArea);
