import PropTypes from 'prop-types';
import React from 'react';

class SingleImage extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    children: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string,
    uploadable: PropTypes.bool,
    uploadedFile: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    const {children, className, image, disabled, alt, uploadable, uploadedFile} = this.props,
      imageSrc = image || children;

    return uploadable
      ? <div className={disabled ? 'opacity-25' : ''}>
        <label htmlFor="file">
          <div className={`flex bg-white overflow-hidden${className ? ` ${className}` : ''}`}>
            <img src={imageSrc} alt={alt} className="block mt-auto mr-auto object-cover"/>
          </div>
        </label>
        <input type="file" name="file" id="file" className="appearance-none hidden" onChange={uploadedFile}/>
      </div>
      :
      <div className={`flex bg-white overflow-hidden${className ? ` ${className}` : ''}`}>
        <img src={imageSrc} alt={alt} className="block mb-auto mr-auto object-cover"/>
      </div>;
  }
}

export default SingleImage;
