import PropTypes from 'prop-types';
import React from 'react';
import TextButton from '../Buttons/TextButton';
import I18n from '../../Utils/I18n';

class NoModalMediaCollection extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    collection: PropTypes.array,
    deletable: PropTypes.bool,
    deleteMedia: PropTypes.func,
  };

  renderDeleteButton(mediaSrc) {
    const {deleteMedia} = this.props;

    return <TextButton
      onClick={() => { deleteMedia(mediaSrc); }}
      icon="trash-alt"
      iconLeft
      className="text-gray-40 hover:text-negative xl:pl-4 py-1"
    >
      <span className="xl:hidden">{I18n.t('delete')}</span>
    </TextButton>;
  }

  youtubeID(link) {

    const myregexp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
    const exp = link.match(myregexp);
    return exp ? exp[1] : false;

  }

  getThumb(url) {

    const ytid = this.youtubeID(url);
    return ytid ? 'https://img.youtube.com/vi/' + ytid + '/default.jpg' : 'https://www.google.com/s2/favicons?domain=' + url;

  }

  render() {
    const {children, className, collection, deletable} = this.props;
    const mediaCollection = collection || children;

    return (
      <div className={`${className ? ` ${className}` : ''}`}>
        {
          mediaCollection.map((media, key) =>
            media.type === 'audio' ? (
              <div key={key} className="xl:flex mb-5">
                <audio src={media.src} controls/>
                { deletable ? this.renderDeleteButton(media.src) : null }
              </div>
            ) : (
              <div key={key} className="xl:flex mb-5">

                <div className="w-16 h-12 mr-4 flex flex-col content-center justify-center border border-gray-10 rounded rounded-lg overflow-hidden">
                  <div className="flex-initial mx-auto"><img src={ this.getThumb(media.src) } /></div>
                </div>

                <TextButton external to={media.src} className="text-secondary-40" iconLeft icon="link">{ media.src }</TextButton>

                { deletable ? this.renderDeleteButton(media.src) : null }

              </div>
            )
          )
        }
      </div>
    );
  }
}

export default NoModalMediaCollection;
