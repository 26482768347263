import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import I18n from '../../Utils/I18n';
import Card from '../Card';
import uploadcare from 'uploadcare-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';

class AddSingleImage extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    aspectRatio: PropTypes.number,
    children: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string,
    uploadable: PropTypes.bool,
    uploadedFile: PropTypes.func,
    disabled: PropTypes.bool,
    avatar: PropTypes.bool,
  };

  constructor() {
    super();
  }

  selectUploadCareImage = async (uploadedFile, image) => {
    uploadcare.start({publicKey: process.env.UPLOADCARE_PUBLIC_KEY, effects: 'blur,sharp,grayscale'});
    uploadcare.registerTab('preview', uploadcareTabEffects);
    try {
      const file = await uploadcare.openDialog(
        image ? uploadcare.fileFrom('uploaded', image) : null,
        {imagesOnly: true, crop: '', rotate: ''},
      );

      uploadedFile(file.cdnUrl);
    } catch (e) {
      //
    }

  };

  render() {
    const {alt, avatar, children, className, disabled, image, uploadedFile} = this.props,
      imageSrc = image || children;

    return <div className={`flex${disabled ? ' opacity-25' : ''}`}>
      <a onClick={() => this.selectUploadCareImage(uploadedFile, image)} className={`group cursor-pointer${avatar ? ' flex justify-end w-full' : ''}`}>
        {imageSrc ?
          <div className={`flex bg-white relative overflow-hidden${className ? ` ${className}` : ''}`}>
            <img src={imageSrc} alt={alt} className={`block object-cover ${!avatar ? 'mb-auto mr-auto' : 'min-w-full'}`}/>
            <div className={`opacity-0 group-hover:opacity-100 absolute inset-0 bg-black-transparent transition-o${avatar ? ' rounded-full' : ''}`}/>
          </div>
          :
          <Card className={`flex justify-center items-center p-6${className ? ` ${className}` : ''}`}>
            <Icon className="text-positive text-4xl">plus</Icon>
            <p className="absolute h-0 opacity-0">{I18n.t('Add')}</p>
          </Card>
        }
      </a>
    </div>;
  }
}

export default AddSingleImage;
