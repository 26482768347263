import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchGroupProjectRequest: ['obj'],
  fetchGroupProjectSuccess: ['files'],
  fetchGroupProjectFailure: ['error'],

  fetchGroupRoleRequest: ['obj'],
  fetchGroupRoleSuccess: ['files'],
  fetchGroupRoleFailure: ['error'],

  clearFetchGroupState: [],
});

export const FetchGroupTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  project_files: null,
  role_files:null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

export const request_project = (state) => state.merge({ fetching: true });

export const success_project = (state, data) => {
  const { files } = data;
  return state.merge({ fetching: false, error: null, project_files:files })
};

export const failure_project = (state, { error }) => state.merge({ fetching: false, project_files: null, error });

export const requestRole = (state) => state.merge({ fetching: true });

export const successRole = (state, data) => {
  const { files } = data;
  return state.merge({ fetching: false, error: null, role_files:files })
};

export const failureRole = (state, { error }) => state.merge({ fetching: false, role_files: null, error });

export const clear_fetch_group_state = () => {
  return INITIAL_STATE;
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_GROUP_PROJECT_REQUEST]: request_project,
  [Types.FETCH_GROUP_PROJECT_SUCCESS]: success_project,
  [Types.FETCH_GROUP_PROJECT_FAILURE]: failure_project,

  [Types.FETCH_GROUP_ROLE_REQUEST]: requestRole,
  [Types.FETCH_GROUP_ROLE_SUCCESS]: successRole,
  [Types.FETCH_GROUP_ROLE_FAILURE]: failureRole,

  [Types.CLEAR_FETCH_GROUP_STATE]: clear_fetch_group_state,
});
