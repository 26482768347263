import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import Title from '../Text/Title';
import Body from '../Text/Body';
import TextButton from '../Buttons/TextButton';
import I18n from '../../Utils/I18n';
import FilledButton from '../Buttons/FilledButton';

class RoleItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    role: PropTypes.object,
  };

  render() {
    const {className, onClick, role} = this.props;

    return (
      <div className={`${className || ''}`}>
        <Card className="py-8">
          <div className="px-6">
            <Title className="pb-3">{I18n.t(role.type)}</Title>
            <div className="w-full flex flex-wrap justify-between items-bottom h-full">
              <Body className="leading-relaxed max-h-16 overflow-hidden">{role.description}</Body>
              <div className="w-full flex justify-between">
                <FilledButton to={`/agency/projects/${role.project_id}/project-roles/${role.id}/people/search`} onClick={onClick} className="rounded bg-gray-40 mt-auto mt-6 -mb-2 mr-auto text-white text-center">{I18n.t('View casting')}</FilledButton>
                <TextButton to={`/agency/projects/${role.project_id}/project-roles/${role.id}`} onClick={onClick} icon="chevron-right" className="mt-auto pt-6 ml-auto text-gray-40 text-center">{I18n.t('Role details')}</TextButton>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default RoleItem;
