import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import {Link} from 'react-router-dom';
import I18n from '../../Utils/I18n';

class IconSelect extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
    off: PropTypes.string,
    on: PropTypes.string,
    offValue: PropTypes.string,
    onValue: PropTypes.string,
    options: PropTypes.array,
  };

  onChange(e) {
    const {input} = this.props,
      target = e.target,
      options = document.querySelectorAll(`#options-${input.name} > a`),
      inputField = document.getElementById(input.name);

    input.onChange(target.dataset.option);
    inputField.value = target.dataset.option;

    for (let i = 0, ilen = options.length; i < ilen; ++i) {
      options[i].classList.remove('bg-positive');
      options[i].classList.remove('text-white');
      options[i].classList.add('bg-white');
      options[i].classList.add('text-gray-60');
    }

    target.classList.remove('bg-white');
    target.classList.remove('text-gray-60');
    target.classList.add('bg-positive');
    target.classList.add('text-white');
  }

  renderRadioInput(error = false) {
    const {className, input, off, on, offValue, onValue} = this.props;
    return (<div className={`flex items-center ${className || ''}`}>
      <input {...input} type="hidden"/>
      <div id={`options-${input.name}`} className={`flex rounded overflow-hidden w-full mb-3`}>
        <Link
          to=""
          tabIndex="0"
          onClick={(e) => {
            e.preventDefault();
            this.onChange(e);
          }}
          id={input.name}
          data-option={onValue || '1'}
          className={`py-2 px-4 block focus:outline-none text-sm focus:shadow-inner border rounded-l flex-1 text-center ${error ? 'bg-negative-transparent border-negative' : 'border-gray-15'} ${(input.value === (onValue || '1')) ? 'bg-positive text-white' : 'bg-white text-gray-60'}`}
        >
          {on || I18n.t('yes')}
        </Link>
        <Link
          to=""
          tabIndex="0"
          onClick={(e) => {
            e.preventDefault();
            this.onChange(e);
          }}
          data-option={offValue || '0'}
          className={`py-2 px-4 block focus:outline-none text-sm focus:shadow-inner border border-l-0 rounded-r flex-1 text-center ${error ? 'bg-negative-transparent border-negative' : 'border-gray-15'} ${(input.value === (offValue || '0')) ? 'bg-positive text-white' : 'bg-white text-gray-60'}`}
        >
          {off || I18n.t('no')}
        </Link>
      </div>
    </div>);
  }

  render() {
    const {meta} = this.props;

    return meta && meta.error && meta.touched ?
      <div>
        {this.renderRadioInput(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderRadioInput();
  }
}

export default IconSelect;
