import PropTypes from 'prop-types';
import React from 'react';
import Helper from '../../Utils/Helper';
import TextButton from '../Buttons/TextButton';
import Icon from '../Icon';
import Card from '../Card';
import I18n from '../../Utils/I18n';

class InvoiceItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    invoice: PropTypes.object,
  };

  render() {
    const {className, invoice} = this.props;

    return (
      <Card className={`px-6 py-5 flex justify-between items-baseline ${className || ''}`}>
        <p className="text-sm">{Helper.formatDate(invoice.date)}</p>
        <p className="text-md px-3 sm:px-5">€&nbsp;{Helper.formatFloatValue(invoice.total.replace('€',''))}</p>
        <TextButton to={invoice.url} external={true} icon="download" className="hidden sm:flex text-gray-40 hover:text-primary-40">{I18n.t('download')}</TextButton>
        <a href={invoice.url} download className="block sm:hidden self-center text-gray-40 hover:text-primary-40">
          <Icon>download</Icon>
        </a>
      </Card>
    );
  }
}

export default InvoiceItem;
