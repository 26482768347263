import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import BaseButton from './BaseButton';

class IconButton extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    className: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  render() {
    const {children, className, icon, onClick, to} = this.props;

    return (
      <BaseButton to={to} onClick={onClick} className={`rounded-full w-6 h-6 justify-center items-center ${className || ''}`}>
        <Icon>{icon || children}</Icon>
      </BaseButton>
    );
  }
}

export default IconButton;
