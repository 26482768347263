import React from 'react';
import Card from '../../Components/Card';
import Title from '../../Components/Text/Title';
import Body from '../../Components/Text/Body';
import TextButton from '../../Components/Buttons/TextButton';
import OutlineButton from '../../Components/Buttons/OutlineButton';
import ProgressBar from '../../Components/ProgressBar';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CastingItem from '../../Components/ListItems/CastingItem';
import CastingsActions from '../../Redux/Talent/CastingsRedux';
import Helper from '../../Utils/Helper';
import I18n from '../../Utils/I18n';

class DashboardPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.object,
    getNewCastings: PropTypes.func,
    getCurrentCastings: PropTypes.func,
    new_castings: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {account} = this.props;
    this.setState({fetchedProjects: true}, function () {
      this.props.getNewCastings({username: account.username, status: 'new', page: 1, per_page: 2});
      this.props.getCurrentCastings({
        username: account.username,
        status: ['selected', 'audition', 'shortlist'],
        page: 1,
        per_page: 3,
      });
    });
  }

  completionLink(completions) {
    if (!completions.profile) {
      return '/talent/profile/personalia';
    }

    if (!completions.my_account) {
      return '/talent/settings/username';
    }

    if (!completions.skills) {
      return '/talent/profile/skills';
    }

    if (!completions.properties) {
      return '/talent/profile/appearance';
    }

    if (!completions.about_me) {
      return '/talent/profile/about-me';
    }

    if (!completions.educations) {
      return '/talent/profile/education';
    }

    if (!completions.experiences) {
      return '/talent/experiences';
    }

    if (!completions.subscription) {
      return '/talent/settings/subscription';
    }

    return null;
  }

  render() {
    const {account, new_castings} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full md:flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-10 max-w-6xl">
          <div className="flex-1 pr-2 md:pr-8">
            <Title>{I18n.t('Complete your profile')}</Title>
            <Body>{I18n.t('dashboard.complete_profile.introduction')}</Body>

            <ProgressBar className="w-full mt-10 mb-6 lg:max-w-lg" progress={`${account ? Helper.formatFloatValue(account.completion.total) : 0}%`}/>

            {this.completionLink(account.completion) &&
            <div className="flex">
              <TextButton to={this.completionLink(account.completion)} icon="chevron-right" className="text-gray-40 mr-auto mt-4">{I18n.t('Complete now')}</TextButton>
            </div>
            }
          </div>
          <div className="flex flex-wrap mt-12 mb-4 md:my-0 md:w-1/2 mx-auto md:mx-0 max-w-md">
            <div className="w-1/2">

              <OutlineButton to="/talent/profile/personalia" className="mb-2 mr-2" completed={account && account.completion.profile}>{I18n.t('Personalia')}</OutlineButton>
              <OutlineButton to="/talent/settings/username" className="mb-2 mr-2" completed={account && account.completion.my_account}>{I18n.t('My account')}</OutlineButton>
              <OutlineButton to="/talent/profile/skills" className="mb-2 mr-2" completed={account && account.completion.skills}>{I18n.t('Skills')}</OutlineButton>
              <OutlineButton to="/talent/profile/appearance" className="mb-2 mr-2" completed={account && account.completion.properties}>{I18n.t('Appearance')}</OutlineButton>
            </div>
            <div className="w-1/2">
              <OutlineButton to="/talent/profile/about-me" className="mb-2 ml-2" completed={account && account.completion.about_me}>{I18n.t('About me')}</OutlineButton>
              <OutlineButton to="/talent/profile/education" className="mb-2 ml-2" completed={account && account.completion.educations}>{I18n.t('Education')}</OutlineButton>
              <OutlineButton to="/talent/experiences" className="mb-2 ml-2" completed={account && account.completion.experiences}>{I18n.t('Experiences')}</OutlineButton>
              <OutlineButton to="/talent/settings/subscription" className="mb-2 ml-2" completed={account && account.completion.subscription}>{I18n.t('Subscription')}</OutlineButton>
            </div>
          </div>
        </Card>

        <Title className="pl-6 py-2">{I18n.t('New Projects')}</Title>
        <Body className="pl-6 pb-6">{I18n.t('Check out these projects and apply if you’re interested.')}</Body>

        <div className="sm:flex flex-wrap -mt-8 max-w-6xl">
          {new_castings.length ? new_castings.map((casting, key) => {
              return <div key={key} className="w-full sm:w-1/2 xl:w-1/3 mt-8 sm:pr-5 mb-5">
                <CastingItem className="w-full h-full mb-5 flex flex-col" casting={casting} history={this.props.history}/>
              </div>;
            })
            : <Body className="ml-6 mt-8">{I18n.t('You have no projects yet')}</Body>
          }
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    new_castings: state.castings.new_castings ? state.castings.new_castings : [],
    new_castings_fetching: state.castings.fetching,
    new_castings_error: state.castings.error,

    current_castings: state.castings.current_castings ? state.castings.current_castings : [],
    current_castings_fetching: state.castings.fetching,
    current_castings_error: state.castings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewCastings: (obj) => dispatch(CastingsActions.newCastingsRequest(obj)),
    getCurrentCastings: (obj) => dispatch(CastingsActions.currentCastingsRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
