import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  skillUpdateRequest: ['obj'],
  skillUpdateSuccess: ['skill'],
  skillUpdateFailure: ['error'],
  skillDeleteRequest: ['obj'],
  skillDeleteSuccess: [],
  skillDeleteFailure: ['error'],
  skillSaveRequest: ['obj'],
  skillSaveSuccess: [],
  skillSaveFailure: ['error'],
});

export const SkillTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  skill: null,
  error: null,
  updating: false,
  deleting: false,
  saving: false,
  saveSuccess: false,
  deleteSuccess: false,
});

/* ------------- Reducers ------------- */

export const updateRequest = (state) => state.merge({updating: true});

export const updateSuccess = (state, data) => {
  const {skill} = data;
  return state.merge({updating: false, error: null, skill})
};

export const updateFailure = (state, {error}) => state.merge({updating: false, skill: null, error});

export const deleteRequest = (state) => state.merge({deleting: true, deleteSuccess: false});

export const deleteSuccess = (state) => {
  return state.merge({deleting: false, error: null, deleteSuccess: true})
};

export const deleteFailure = (state, {error}) => state.merge({deleting: false, error, deleteSuccess: false});

export const saveRequest = (state) => state.merge({saving: true, saveSuccess: false});

export const saveSuccess = (state) => {
  return state.merge({saving: false, error: null, saveSuccess: true})
};

export const saveFailure = (state, {error}) => state.merge({saving: false, error, saveSuccess: false});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SKILL_UPDATE_REQUEST]: updateRequest,
  [Types.SKILL_UPDATE_SUCCESS]: updateSuccess,
  [Types.SKILL_UPDATE_FAILURE]: updateFailure,

  [Types.SKILL_DELETE_REQUEST]: deleteRequest,
  [Types.SKILL_DELETE_SUCCESS]: deleteSuccess,
  [Types.SKILL_DELETE_FAILURE]: deleteFailure,

  [Types.SKILL_SAVE_REQUEST]: saveRequest,
  [Types.SKILL_SAVE_SUCCESS]: saveSuccess,
  [Types.SKILL_SAVE_FAILURE]: saveFailure,
});
