import React from 'react';
import FilledButton from '../../../Components/Buttons/FilledButton';
import Card from '../../../Components/Card';
import {Field, Form} from 'react-final-form';
import Label from '../../../Components/Form/Label';
import TextField from '../../../Components/Form/TextField';
import Checkbox from '../../../Components/Form/Checkbox';
import PasswordField from '../../../Components/Form/PasswordField';
import PropTypes from 'prop-types';
import RegisterActions from '../../../Redux/Auth/RegisterRedux';
import {connect} from 'react-redux';
import I18n from '../../../Utils/I18n';
import {required} from '../../../Validators/Form/Form';

class InvitationRegisterPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    register: PropTypes.func,
    response: PropTypes.any,
    error: PropTypes.any,
    store: PropTypes.bool,
    saving: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.store) {
      if ((this.props.response !== prevProps.response) && this.state.saving) {
        this.setState({saving: false}, function () {
          this.props.history.push('/register/invitation/thanks');
        });
      } else if ((this.props.error !== prevProps.error) && this.state.saving) {
        this.setState({saving: false});
      }
    }
  }

  onSubmit = (values) => {
    const {invitation} = this.props.match.params;
    values['invitation_code'] = invitation;
    this.setState({saving: true}, function () {
      this.props.register(values);
    });
  };

  validate() {
    const {saving} = this.state, {error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = error ? {...error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
        try {
          delete errors['invitation_code'];
        } catch (e) {
          //
        }
      });
    }
    return errors;
  }

  render() {
    const {t} = this.context;
    //const {invitation} = this.props.match.params;

    return (
      <div className="flex flex-wrap lg:flex-no-wrap lg:justify-center px-4">

        <Card className="w-full max-w-sm mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('You\'re invited to join')}</h2>
          <h3 className="font-semibold text-primary-80 text-2xl text-center pb-6">The Casting Director</h3>
          <img src="/styles/graphics/logo_black.svg" alt="The Casting Director logo" className="block mt-12"/>
        </Card>

        <Card className="w-full max-w-md mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center pb-6">{I18n.t('Register by')} <span
            className="font-semibold">{I18n.t('email')}</span>
          </h2>

          <Form onSubmit={this.onSubmit} validate={this.validate.bind(this)}>
            {({handleSubmit, form}) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit} className="max-w-xs mx-auto">
                  <Field name="username" validate={required}>
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute h-0 opacity-0">{I18n.t('Username', null, true)}</Label>
                        <TextField input={input} meta={meta} placeholder={t('Username')}/>
                      </div>
                    )}
                  </Field>

                  <Field name="email" validate={required}>
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute h-0 opacity-0">{I18n.t('email', null, true)}</Label>
                        <TextField input={input} meta={meta} placeholder={t('email')}/>
                      </div>
                    )}
                  </Field>

                  <Field name="password" validate={required}>
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute h-0 opacity-0">{I18n.t('Password', null, true)}</Label>
                        <PasswordField input={input} meta={meta} placeholder={t('Password')} className="mt-3"/>
                      </div>
                    )}
                  </Field>

                  <Field name="password_confirmation" validate={required}>
                    {({input, meta}) => (
                      <div>
                        <Label input={input}
                               className="absolute h-0 opacity-0">{I18n.t('Confirm your password', null, true)}</Label>
                        <PasswordField input={input} meta={meta} placeholder={t('Confirm your password')}/>
                      </div>
                    )}
                  </Field>

                  <Field name="accept">
                    {({input}) => (
                      <Checkbox input={input} className="text-sm mt-2 mb-5">
                        {I18n.t('Yes, I agree to the')}
                        <a href={t('terms_url')} rel="noopener noreferrer" target="_blank" className="underline pl-1">
                          {I18n.t('terms & conditions')}
                        </a>
                      </Checkbox>
                    )}
                  </Field>

                  <FilledButton type="submit" disabled={this.saving} iconRight icon="chevron-right"
                                className="ml-auto rounded text-white bg-primary-40">{I18n.t('Register')}</FilledButton>
                </form>
              );
            }}
          </Form>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.register.response,
    store: state.register.store,
    error: state.register.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (obj) => dispatch(RegisterActions.registerRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationRegisterPage);
