// CheckoutForm.js
import React from 'react';
import SubscriptionActions from '../../Redux/Billing/SubscriptionRedux';
import AccountActions from '../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CardElement} from 'react-stripe-elements';
import Title from '../Text/Title';
import FilledButton from '../Buttons/FilledButton';
import I18n from '../../Utils/I18n';
import SubscriptionItem from '../ListItems/SubscriptionItem';

class CheckoutForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    match: PropTypes.any,
    stripe: PropTypes.any,
    createSubscription: PropTypes.func,
    coupon: PropTypes.any,
    planId: PropTypes.any,
    couponCode: PropTypes.any,
    plans: PropTypes.any,
    subscription_creating: PropTypes.any,
    subscription_created: PropTypes.bool,
    subscription: PropTypes.any,
    subscription_error: PropTypes.any,
    clearSubscriptionState: PropTypes.func,
    getAccount: PropTypes.func,
    selectedPlan: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitSubscription: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this.props.getAccount();
    this.props.clearSubscriptionState();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.subscription_creating) {
      if ((this.props.subscription !== prevProps.subscription) && this.state.submitSubscription) {
        this.props.history.push('/talent/settings/subscription');
        //this.onSuccess();
      } else if ((this.props.subscription_error !== prevProps.subscription_error) && this.state.submitSubscription) {
        //this.onError(this.props.subscription_error)
      }
    }
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    const {planId, couponCode} = this.props;
    // Within the context of `Elements`, this call to createPaymentMethod knows from which Element to
    // create the PaymentMethod, since there's only one in this group.
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    this.props.stripe.createToken({type: 'card'}).then((response) => {
      if(response.token) {
        this.setState({submitSubscription: true}, function () {
          this.props.createSubscription({token: response.token.id, plan_id: planId, ...((couponCode) && {coupon: couponCode})});
        });
      }
    });
  };

  render() {
    const { selectedPlan } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        {selectedPlan
          ? <SubscriptionItem subscription={selectedPlan} selected={true}/>
          : null
        }
        <label>
          <Title className="pb-3">{I18n.t('Billing details')}</Title>
          <CardElement className="input-field" hidePostalCode={true} style={{base: {fontSize: '16px'}}}/>
        </label>
        <div className="flex mt-10">
          <FilledButton className="bg-secondary-40 rounded text-white" type="submit">{I18n.t(selectedPlan ? 'Checkout' : 'Update card')}</FilledButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    coupon: state.coupon.coupon,
    coupon_fetching: state.coupon.fetching,
    coupon_error: state.coupon.error,
    couponCode: state.coupon.couponCode,

    subscription: state.subscription.response,
    subscription_creating: state.subscription.creating,
    subscription_created: state.subscription.created,
    subscription_error: state.subscription.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscription: (obj) => dispatch(SubscriptionActions.createSubscriptionRequest(obj)),
    clearSubscriptionState: () => dispatch(SubscriptionActions.clearSubscriptionState()),
    getAccount: () => dispatch(AccountActions.accountRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
