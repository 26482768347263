import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import NotificationItem from '../../../../Components/ListItems/NotificationItem';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import UserNotificationsActions from '../../../../Redux/Talent/UserNotificationsRedux';
import I18n from '../../../../Utils/I18n';

class NewMessagesPage extends React.Component {
  static propTypes = {
    account: PropTypes.any,
    history: PropTypes.any,
    unread_notifications: PropTypes.any,
    markAsRead: PropTypes.func,
    mark_read_success: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    mark_read_posting: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (!this.props.mark_read_posting) {
      if (this.props.mark_read_success !== prevProps.mark_read_success) {
        this.props.history.push('/notifications/read');
      }
    }
  }

  markAllAsRead = () => {
    this.props.markAsRead(this.props.account.username);
  };


  render() {
    const {unread_notifications} = this.props;

    return (
      <div className="flex-1 pl-3 sm:px-8">
        <h2 className="text-gray-60 text-lg font-semibold pb-6">New messages</h2>
        {unread_notifications && unread_notifications.length > 0
          ? <div>
            {unread_notifications.map((notification, key) => {
              return <NotificationItem
                key={key}
                className={unread_notifications[key + 1] ? '' : 'border-b'}
                notification={notification}
              />;
            })}
            <div className="justify-end flex">
              <FilledButton onClick={this.markAllAsRead} icon="eye" className="rounded text-white bg-primary-40 my-6">
                {I18n.t('Mark all notifications as read')}
              </FilledButton>
            </div>
          </div>
          : <div className="flex justify-center items-center h-full"><p
            className="text-gray-40 text-lg">{I18n.t('No new notifications')}</p></div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    unread_notifications: state.user_notifications.unread_notifications,
    mark_read_success: state.user_notifications.mark_read_success,
    mark_read_error: state.user_notifications.mark_read_error,
    mark_read_posting: state.user_notifications.mark_read_posting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAsRead: (username) => dispatch(UserNotificationsActions.markReadRequest(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessagesPage);
