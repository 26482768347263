import PropTypes from 'prop-types';
import React from 'react';

class Pagination extends React.Component {
    static propTypes = {
      links: PropTypes.object,
      handlePagination: PropTypes.func,
    };

    render() {
        const {links, handlePagination} = this.props;

        return links && (links.next || links.prev) &&
          <ul className="flex" role="navigation">
            {links && links.prev ?
              <li className="page-item">
                <a className="border py-1 px-3 rounded-l border-primary-30 text-primary-40 hover:bg-primary-10" href="#" onClick={() => {
                  handlePagination(links.prev);
                }} rel="prev">{'‹'}</a>
              </li>
              :
              <li className="page-item disabled" aria-disabled="true">
                <span className="border py-1 px-3 rounded-l border-gray-10 text-gray-40 bg-gray-10">{'‹'}</span>
              </li>
            }

            {links && links.next ?
              <li className="page-item">
                <a className="border -ml-px py-1 px-3 rounded-r border-primary-30 text-primary-40 hover:bg-primary-10" href="#" onClick={() => {
                  handlePagination(links.next);
                }} rel="next">{'›'}</a>
              </li>
              :
              <li className="page-item disabled" aria-disabled="true">
                <span className="border -ml-px py-1 px-3 rounded-r border-gray-10 text-gray-40 bg-gray-10">{'›'}</span>
              </li>
            }
          </ul>
        ;
    }
}

export default Pagination;
