import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
    setAccessToken: ['access_token'],
    clearAccessTokenState: [],
});

export const TokenTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    access_token: null,
    expires_in: null,
});

/* ------------- Reducers ------------- */

// update card success
export const setAccessToken = (state, data) => {
    const {access_token} = data;
    return state.merge({access_token: access_token, expires_in: null});
};

export const clearAccessTokenState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ACCESS_TOKEN]: setAccessToken,
    [Types.CLEAR_ACCESS_TOKEN_STATE]: clearAccessTokenState,

});
