import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';

class PasswordField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    input: PropTypes.any,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
  };

  renderPasswordField(error = false) {
    const {className, input, placeholder} = this.props;

    return <input {...input} type="password" id={input.name} placeholder={placeholder} aria-labelledby={input.name} className={`input-field${error ? ' has-error' : ''} ${className || ''}`}/>;
  }

  render() {
    const {meta} = this.props;

    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderPasswordField(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderPasswordField();
  }
}

export default PasswordField;
