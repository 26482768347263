import {call, put} from 'redux-saga/effects'
import InvitationsActions from '../../Redux/Account/InvitationsRedux'
import {callApi} from '../CallApiSaga'

export function* createInvitation(api, {email}) {
  const apiCall = yield call(api.createInvitation, email);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(InvitationsActions.createInvitationSuccess());
  } else {
    yield put(InvitationsActions.createInvitationFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

export function* getInvitations(api) {
  const apiCall = yield call(api.getInvitations);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(InvitationsActions.getInvitationsSuccess(response.data));
  } else {
    yield put(InvitationsActions.getInvitationsFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

export function* deleteInvitation(api, {invitation_code}) {
  const apiCall = yield call(api.deleteInvitation, invitation_code);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(InvitationsActions.deleteInvitationSuccess());
  } else {
    yield put(InvitationsActions.deleteInvitationFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}
