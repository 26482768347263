import React from 'react';
import Title from '../../../../Components/Text/Title';
import RoundedImage from '../../../../Components/Images/RoundedImage';
import Icon from '../../../../Components/Icon';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import MediaCollection from '../../../../Components/Lists/MediaCollection';
import Body from '../../../../Components/Text/Body';
import ChatMessage from '../../../../Components/ListItems/ChatMessage';
import ChatWindow from '../../../../Components/ChatWindow';
import I18n from '../../../../Utils/I18n';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helper from '../../../../Utils/Helper';
import Castings from '../../../../Utils/Castings';
import CastingActions from '../../../../Redux/Talent/CastingRedux';
import AgencyCastingActions from '../../../../Redux/Castings/AgencyCastingsRedux';
import {ChatManager, TokenProvider} from '@pusher/chatkit-client';
import LabeledContent from '../../../../Components/Text/LabeledContent';
import IconButton from '../../../../Components/Buttons/IconButton';
import Loading from '../../../../Components/Loading';
import NoModalMediaCollection from '../../../../Components/Lists/NoModalMediaCollection';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    getCastingById: PropTypes.func,
    updateCasting: PropTypes.func,
    casting: PropTypes.any,
    casting_updating: PropTypes.bool,
    casting_updated: PropTypes.bool,
    casting_error: PropTypes.any,
    updating: PropTypes.bool,
    account: PropTypes.any,
    authToken: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      currentUser: null,
      currentRoom: {users: []},
      messages: [],
      users: [],
      chatInitialized: false,
    };
  }

  componentDidUpdate(prevProps) {
    const self = this;

    if (!this.props.casting_updating) {
      if (this.props.casting_updated && (this.props.casting_updated !== prevProps.casting_updated) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.getCasting();
        });
      } else if ((this.props.casting_error !== prevProps.casting_error) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.getCasting();
        });
      }
    }

    if (this.props.casting && !this.state.chatInitialized) {
      if (this.props.casting.chatroom_id) {
        this.setState({chatInitialized: true}, function () {
          const chatManager = new ChatManager({
            instanceLocator: process.env.CHATKIT_INSTANCE_LOCATOR,
            userId: this.props.account ? this.props.account.uuid : null,
            tokenProvider: new TokenProvider({
              url: process.env.CHATKIT_URL,
              headers: {
                Authorization: 'Bearer ' + this.props.authToken,
              },
            }),
          });

          chatManager
            .connect()
            .then(currentUser => {
              this.setState({currentUser: currentUser});

              return currentUser.subscribeToRoom({
                roomId: this.props.casting ? this.props.casting.chatroom_id : null,
                messageLimit: 100,
                hooks: {
                  onMessage: message => {
                    this.setState({
                      messages: [...this.state.messages, message],
                    }, function () {
                      const chatWindow = document.getElementById('ChatWindow');
                      chatWindow.scrollTo(0, chatWindow.scrollHeight);
                    });
                  },
                },
              });
            })
            .then(currentRoom => {
              this.setState({
                currentRoom,
                users: currentRoom.userIds,
              });
            })
            .catch(error => window.console.error(error));
        });
      }
    }
  }

  addMessage = (text) => {
    this.state.currentUser.sendMessage({
      text,
      roomId: this.state.currentRoom.id,
    })
      .catch(error => window.console.error('error', error));
  };

  getCasting() {
    const {project, role, casting} = this.props.match.params;

    this.props.getCastingById({project_id: project, role_id: role, casting_id: casting});
  }

  changeStatusOfCasting(status) {
    if (this.state.updating) {
      return;
    }

    const {casting} = this.props;
    const obj = {
      project: casting.role.project.id,
      role: casting.role.id,
      casting: casting.id,
      data: {
        status: status,
      },
    };

    this.setState({updating: true}, function () {
      this.props.updateCasting(obj);
    });
  }

  onRejected = (e) => {
    e.preventDefault();

    this.changeStatusOfCasting('archived');
  };

  onAccepted = (e) => {
    const {casting} = this.props, nextStatus = Castings.nextStatusForCurrentStatus(casting.status);

    e.preventDefault();

    this.changeStatusOfCasting(nextStatus);
  };

  render() {
    const {casting, account} = this.props;
    const {messages} = this.state;
    const {t} = this.context;

    if (!casting) {
      return '';
    }

    const {talent} = casting;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <div className="xl:flex flex-wrap justify-between max-w-4xl">
          <div className="mb-2">
            <div className="flex items-center">
              <RoundedImage className="w-20 h-20 mr-6 bg-white text-6xl" src={talent.avatar}/>
              <div>
                <Title className="text-xl">{talent.firstname} {talent.lastname}</Title>
                <div className="flex items-center justify-center xl:justify-start">
                  <Icon className="text-lg mr-2 text-gray-40">{Helper.renderGenderIcon(talent.gender)}</Icon>
                  <p className="text-gray-40 text-sm">{talent.city ? `${talent.city} | ` : null}{talent.birthday ? `${talent.birthday} | ` : null}{talent.locale}</p>
                </div>
              </div>
            </div>

            <div className="max-w-xs">
              <div className="mt-4">
                <LabeledContent label={`${Helper.titleCase(t('status'))}:`}>{casting.status}</LabeledContent>
              </div>
              <div className="flex flex-wrap mt-4">
                {talent.email &&
                <FilledButton to={`mailto:${talent.email}`} external icon="envelope" className="text-gray-40 hover:bg-gray-10 rounded border border-gray-40 mr-4 mb-3">{talent.email}</FilledButton>}
                {talent.phone &&
                <FilledButton to={`tel:${talent.phone}`} external icon="phone-alt" className="text-gray-40 hover:bg-gray-10 rounded border border-gray-40 mr-4 mb-3">{talent.phone}</FilledButton>}
                {talent.mobile &&
                <FilledButton to={`tel:${talent.mobile}`} external icon="phone-alt" className="text-gray-40 hover:bg-gray-10 rounded border border-gray-40 mb-3">{talent.mobile}</FilledButton>}
              </div>
              {talent.username &&
              <div className="flex">
                <FilledButton to={`https://talents.thecastingdirector.eu/${talent.username}`} external icon="user" className="text-gray-40 hover:bg-gray-10 rounded border border-gray-40 mb-3 break-all">{talent.username}</FilledButton>
              </div>
              }
            </div>
            {!this.state.updating && casting && (['selected', 'audition', 'shortlist'].indexOf(casting.status) !== -1) && (
              <div className="flex flex-wrap mt-4">
                <div className="mr-6 block">
                  <IconButton onClick={this.onAccepted} className="w-8 h-8 sm:w-10 sm:h-10 text-lg sm:text-xl bg-secondary-40 text-white mb-2 mx-auto">check</IconButton>
                  <p className="text-sm">  {I18n.t(`to ${Castings.nextStatusForCurrentStatus(casting.status)}`)}</p>
                </div>
                <div className="mr-6 block">
                  <IconButton onClick={this.onRejected} className="w-8 h-8 sm:w-10 sm:h-10 text-lg sm:text-xl bg-gray-15 text-gray-60 mb-2 mx-auto">times</IconButton>
                  <p className="text-sm">{I18n.t('archive')}</p>
                </div>
              </div>
            )}
            {this.state.updating && (['selected', 'audition', 'shortlist'].indexOf(casting.status) !== -1)
              ? <Loading/>
              : null
            }
          </div>

          {casting.chatroom_id &&
          <ChatWindow addMessage={this.addMessage} className="xl:ml-8 w-full sm:w-auto sm:min-w-48 max-w-128 min-h-64 max-h-128">
            {messages.length
              ? messages.map((message, key) =>
                <ChatMessage ownMessage={message.senderId === account.uuid} key={key}>{message.text}</ChatMessage>)
              : <Body>{I18n.t('No new messages')}</Body>
            }
          </ChatWindow>
          }
        </div>
        <div>
          <h3 className="mt-8 text-xl text-gray-60">{I18n.t('Audition')}</h3>
          {casting
            ? casting.media
              ? <div>
                <MediaCollection collection={Helper.getImagesAndVideoFromMedia(casting.media)} className="mt-4"/>
                <NoModalMediaCollection collection={Helper.getAudioAndUrlFromMedia(casting.media)} className="mt-4"/>
              </div>
              : <Body>{I18n.t('No auditions yet')}</Body>
            : <Loading/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    casting: state.agency_castings.casting,

    casting_error: state.casting.error,

    casting_updating: state.casting.updating,
    casting_updated: state.casting.casting_updated,
    authToken: state.login.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCasting: (obj) => dispatch(CastingActions.updateCastingRequest(obj)),
    getCastingById: (obj) => dispatch(AgencyCastingActions.GetCastingRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
