import React from 'react';
import BackButton from '../../Components/Buttons/BackButton';
import Card from '../../Components/Card';
import {Field, Form} from 'react-final-form';
import Label from '../../Components/Form/Label';
import EmailField from '../../Components/Form/EmailField';
import FilledButton from '../../Components/Buttons/FilledButton';
import PropTypes from 'prop-types';
import I18n from '../../Utils/I18n';
import ResetPassActions from "../../Redux/Auth/ResetPasswordRedux";
import {connect} from "react-redux";
import {required} from "../../Validators/Form/Form";
import PasswordField from "../../Components/Form/PasswordField";
import TextField from "../../Components/Form/TextField";
import FormError from "../../Components/Form/FormError";

class ResetPasswordPage extends React.Component {
  static contextTypes = {t: PropTypes.func};
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    sendReset: PropTypes.any,
    response: PropTypes.any,
    error: PropTypes.any,
    resetting: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      reset: false
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.resetting) {
      if ((this.props.response !== prevProps.response) && this.state.saving) {
        this.setState({saving: false, reset: false}, () => {
          alert(this.context.t('Your password was reset successfully'));
          this.props.history.push('/login');
        });
      } else if ((this.props.error !== prevProps.error) && this.state.saving) {
        this.setState({saving: false, reset: true});
      }
    }
  }

  onSubmit = (values) => {
    this.setState({saving: true, reset: false}, function () {
      this.props.sendReset(values);
    });
  };

  validate() {
    const {saving} = this.state, {error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = error ? {...error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {t} = this.context;
    const {token} = this.props.match.params;

    return (
      <div className="flex flex-wrap lg:flex-no-wrap lg:justify-center px-4">

        <BackButton to="/login" className="mx-4 lg:mx-0 lg:mt-24"/>

        <Card className="w-full max-w-5xl mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('Forgot your password?')}</h2>
          <h3
            className="font-semibold text-primary-80 text-2xl text-center pb-6">{I18n.t('Enter your e-mail address and new password below')}.</h3>

          <Form onSubmit={this.onSubmit} validate={this.validate.bind(this)} validateOnBlur
                initialValues={{token: token}}>
            {({handleSubmit, form}) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="max-w-md mx-auto w-full">

                    <Field name="email" validate={required}>
                      {({input, meta}) => (
                        <div>
                          <Label input={input} className="absolute h-0 opacity-0">{I18n.t('email', null, true)}</Label>
                          <EmailField input={input} meta={meta} placeholder={t('e.g. john.doe@thecastingdirector.eu')}/>
                        </div>
                      )}
                    </Field>

                    <Field name="password" validate={required}>
                      {({input, meta}) => (
                        <div className="w-full">
                          <Label input={input} className="absolute h-0 opacity-0">{I18n.t('New password', null, true)}</Label>
                          <PasswordField input={input} meta={meta} className="max-w-md block" placeholder="******"/>
                        </div>
                      )}
                    </Field>

                    <Field name="password_confirmation" validate={required}>
                      {({input, meta}) => (
                        <div className="w-full">
                          <Label input={input} className="absolute h-0 opacity-0">{I18n.t('new password confirmation', null, true)}</Label>
                          <PasswordField input={input} meta={meta} className="max-w-md block" placeholder="******"/>
                        </div>
                      )}
                    </Field>

                    <Field name="token" validate={required} type={'hidden'}>
                      {({input, meta}) => (
                        <div className="w-full">
                          <TextField input={input} meta={meta} className="max-w-md block"/>
                        </div>
                      )}
                    </Field>

                    <FormError meta={{touched: (this.props.error && !this.state.saving), error: 'Whoops, something went wrong. Please check your input and try again'}}/>

                    <FilledButton type="submit" disabled={this.state.saving} iconRight icon="chevron-right" className="ml-auto rounded text-white bg-primary-40">
                      {I18n.t('Reset your password')}
                    </FilledButton>

                  </div>
                </form>
              );
            }}
          </Form>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.reset_password.response,
    error: state.reset_password.error,
    resetting: state.reset_password.resetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendReset: (obj) => dispatch(ResetPassActions.resetPasswordRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
