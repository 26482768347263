import PropTypes from 'prop-types';
import React from 'react';
import {connectRefinementList} from 'react-instantsearch-dom';
import {Link} from 'react-router-dom';
import Icon from '../Icon';

const Color = require('color');

class ColorSelector extends React.Component {
  static propTypes = {
    attribute: PropTypes.string,
    items: PropTypes.array,
    refine: PropTypes.func,
    className: PropTypes.string,
  };

  isDark(color) {
    const parsedColor = Color(color);

    return parsedColor.contrast(Color('white')) > 4;
  }

  render() {
    const {attribute, items, refine, className} = this.props;

    return (
      <div className={`flex items-center ${className || ''}`}>
        <div className="flex flex-wrap">
          {items.map((item, key) => {
            return <Link
              to=""
              id={key === 0 ? attribute : null}
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                refine(item.value);
              }}
              key={key}
              style={{backgroundColor: item.label}}
              className="flex justify-center items-center w-10 h-10 focus:outline-none focus:shadow-inner border border-gray-15 rounded mb-1 mr-1"
            >
              <Icon className={`text-xl pointer-events-none ${item.isRefined ? 'block' : 'hidden'} ${this.isDark(item.label) ? 'text-white' : 'text-black'}`}>check</Icon>
            </Link>;
          })
          }
        </div>
      </div>
    );
  }
}

export default connectRefinementList(ColorSelector);
