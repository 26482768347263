import React from 'react';
import {Field, Form} from 'react-final-form';
import Title from '../../../Components/Text/Title';
import Label from '../../../Components/Form/Label';
import TextField from '../../../Components/Form/TextField';
import EmailField from '../../../Components/Form/EmailField';
import Select from '../../../Components/Form/Select';
import FilledButton from '../../../Components/Buttons/FilledButton';
import Card from '../../../Components/Card';
import PropTypes from 'prop-types';
import AccountActions from '../../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import {email, required, url} from '../../../Validators/Form/Form';
import I18n from '../../../Utils/I18n';
import {country} from '../../../Constants/PersonalInfo';
import Helper from '../../../Utils/Helper';
import UploadCareActions from '../../../Redux/UploadCare/UploadCareRedux';
import AddSingleImage from '../../../Components/Images/AddSingleImage';
import PhoneNumber from '../../../Components/Form/PhoneNumber';
import VatField from '../../../Components/Form/VatField';

class AgencyInfoPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
    uploadSingleImage: PropTypes.func,
    avatar_uploading: PropTypes.bool,
    avatar_uploaded: PropTypes.bool,
    avatar: PropTypes.any,
    clearUploadCareState: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      avatar_uploading: false,
      saved: false,
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true, saved: false}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  uploadedFile = (file) => {
    this.setState({updating: true}, function () {
      let newObj = Object.assign({}, this.props.account);
      newObj.avatar = file;
      this.props.updateAccount({account: newObj, username: this.props.account.username});
    });
  };

  validate() {
    const {updating} = this.state, {account_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = account_error ? {...account_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {account} = this.props, {t} = this.context, {avatar_uploading} = this.state;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5 max-w-6xl">
        <Card className="pt-3 sm:pt-8">
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate.bind(this)}
            validateOnBlur
            initialValues={{
              mobile: account.mobile,
              email: account.email,
              street: account.street,
              number: account.number,
              bus: account.bus,
              city: account.city,
              postcode: account.postcode,
              country: account.country,
              vat: account.account.vat,
              company: account.company,
              website: account.website,
            }}
          >
            {({handleSubmit, form}) => {
              this.form = form;

              return (
                <form onSubmit={handleSubmit} className="flex-1 px-3 sm:px-10 pb-16">
                  <Title className="text-xl pb-2">{I18n.t('Complete personal info')}</Title>
                  <div className="sm:flex">
                    <div className="flex-1">
                      <Field name="company" validate={required}>
                        {({input, meta}) => (
                          <div className="sm:pb-3">
                            <Label input={input}>{I18n.t('company name', null, true)}</Label>
                            <TextField input={input} meta={meta} placeholder={t('company name')}/>
                          </div>
                        )}
                      </Field>
                      <Field name="vat" validate={required}>
                        {({input, meta}) => (
                          <div className="pb-3">
                            <Label input={input}>{I18n.t('vat', null, true)}</Label>
                            <VatField input={input} meta={meta} placeholder={t('e.g. BE0999999999')}/>
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="sm:w-48 sm:pl-4">
                      <Label className="pr-4 w-32 ml-auto text-center" input={{name: t('avatar')}}>
                        {I18n.t('avatar', null, true)}
                      </Label>

                      <AddSingleImage
                        alt={t('avatar')}
                        disabled={avatar_uploading}
                        avatar
                        uploadable
                        uploadedFile={this.uploadedFile}
                        className="w-32 h-32 rounded-full"
                        image={account.avatar}
                      />
                    </div>
                  </div>

                  <Title className="text-xl pt-10 pb-2">{I18n.t('Contact info')}</Title>
                  <div className="sm:flex pb-3">
                    <Field name="mobile" validate={required}>
                      {({input, meta}) => (
                        <div className="flex-1 sm:pr-5">
                          <Label input={input}>{I18n.t('phone', null, true)}</Label>
                          <PhoneNumber input={input} meta={meta} placeholder={t('phone')}/>
                        </div>
                      )}
                    </Field>
                    <Field name="email" validate={email}>
                      {({input, meta}) => (
                        <div className="flex-1 sm:pb-3">
                          <Label input={input}>{I18n.t('email', null, true)}</Label>
                          <EmailField input={input} meta={meta} placeholder={t('e.g. john.doe@thecastingdirector.eu')}/>
                        </div>
                      )}
                    </Field>
                  </div>
                  <Field name="website" validate={url}>
                    {({input, meta}) => (
                      <div className="sm:w-1/2">
                        <Label input={input}>{I18n.t('website', null, true)}</Label>
                        <TextField input={input} meta={meta} placeholder="https://example.com"/>
                      </div>
                    )}
                  </Field>

                  <Title className="text-xl pt-10 pb-2">{I18n.t('address', null, false, true)}</Title>
                  <div className="sm:flex pb-3">
                    <Field name="street" validate={required}>
                      {({input, meta}) => (
                        <div className="sm:w-1/2">
                          <Label input={input}>{I18n.t('address', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('street'))}/>
                        </div>
                      )}
                    </Field>
                    <Field name="number" validate={required}>
                      {({input, meta}) => (
                        <div className="flex-1 sm:px-5">
                          <Label input={input}>{I18n.t('number', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder="10"/>
                        </div>
                      )}
                    </Field>
                    <Field name="bus">
                      {({input, meta}) => (
                        <div className="flex-1">
                          <Label input={input}>{I18n.t('box', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder="1"/>
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="sm:flex">
                    <Field name="postcode" validate={required}>
                      {({input, meta}) => (
                        <div className="flex-1">
                          <Label input={input}>{I18n.t('zipcode', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder="1000"/>
                        </div>
                      )}
                    </Field>
                    <Field name="city" validate={required}>
                      {({input, meta}) => (
                        <div className="flex-1 sm:px-5">
                          <Label input={input}>{I18n.t('city', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('city'))}/>
                        </div>
                      )}
                    </Field>
                    <Field name="country" validate={required}>
                      {({input, meta}) => (
                        <div className="flex-1">
                          <Label input={input}>{I18n.t('country', null, true)}</Label>
                          <Select input={input} meta={meta} className="w-full">
                            <option value="">—</option>
                            {country.map(country => <option key={country} value={country}>{t(country)}</option>)}
                          </Select>
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="mt-10">
                    <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                      {I18n.t('save', null, false, true)}
                    </FilledButton>
                  </div>
                </form>
              );
            }}
          </Form>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    avatar: state.upload_care.file,
    avatar_uploading: state.upload_care.uploading,
    avatar_uploaded: state.upload_care.uploaded,
    avatar_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyInfoPage);
