import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({

  propertyUpdateRequest: ['obj'],
  propertyUpdateSuccess: ['property'],
  propertyUpdateFailure: ['error'],

  clearPropertyState:[],
});

export const PropertyTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  property: null,
  property_error: null,
  property_updating: false,
  property_updated: false,
});

/* ------------- Reducers ------------- */


export const updateRequest = (state) => state.merge({updating:true, updated:false});

export const updateSuccess = (state, data) => {
  const { property } = data;
  return state.merge({updating:false,error:null, property, updated:true})
};

export const updateFailure = (state, {error}) => state.merge({updating:false, property:null, error, updated:false});

export const clearPropertyState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.PROPERTY_UPDATE_REQUEST]: updateRequest,
  [Types.PROPERTY_UPDATE_SUCCESS]: updateSuccess,
  [Types.PROPERTY_UPDATE_FAILURE]: updateFailure,

  [Types.CLEAR_PROPERTY_STATE]: clearPropertyState,

});
