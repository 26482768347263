import React from 'react';
import Title from '../../../../Components/Text/Title';
import I18n from '../../../../Utils/I18n';
import PropTypes from 'prop-types';
import Castings from '../../../../Utils/Castings';
import AgencyCastingsActions from '../../../../Redux/Castings/AgencyCastingsRedux';
import {connect} from 'react-redux';
import SearchResultItem from '../../../../Components/ListItems/SearchResultItem';
import Body from '../../../../Components/Text/Body';
import Loading from '../../../../Components/Loading';
import PDFDownloadButton from '../../../../Components/Buttons/PDFDownloadButton';
import Pagination from '../../../../Components/Navigation/Pagination';

class AuditionsPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    selected: PropTypes.any,
    castings_fetching: PropTypes.bool,
    castings: PropTypes.any,
    castings_error: PropTypes.any,
    getAllCastingsForRole: PropTypes.func,
    getPageCastingsForRole: PropTypes.func,
    clearCastingsState: PropTypes.func,
    links: PropTypes.object,
    meta: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getCastings();
  }

  componentWillUnmount() {
    this.props.clearCastingsState();
  }

  getCastings = () => {
    const {project, role} = this.props.match.params, obj = {project_id: project, role_id: role, status: ['audition']};

    this.props.getAllCastingsForRole(obj);
  };

  handlePagination = link => {
    const obj = {link: link};

    this.props.getPageCastingsForRole(obj);
  };

  render() {
    let {castings, meta, links} = this.props;
    const {castings_fetching} = this.props;
    const {project, role} = this.props.match.params;

    castings = Castings.filterCastingByStatus(castings, 'audition');

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16 flex flex-col-reverse lg:flex-row max-w-6xl">
        <div className="xl:pr-4 flex-1">

          <div className="flex justify-between">
            <div className="flex items-baseline min-w-64">
              <Title className="text-lg pb-4">{I18n.t('Audition')}</Title>
              {castings && !castings_fetching &&
              <Body className="md:pr-4 ml-2">{meta && meta.total} {I18n.t('search results')}</Body>
              }
            </div>

            {castings && !castings_fetching && castings.length
              ? <PDFDownloadButton project_id={parseInt(project)} role_id={parseInt(role)} status={'audition'}/>
              : null
            }
          </div>

          <div className="pb-4">
            <div className="md:flex flex-wrap flex-1">
              {castings && !castings_fetching
                ? castings.length
                  ? castings.map((result, key) =>
                    <SearchResultItem key={key} result={result} className="min-w-48 md:w-1/2 md:pr-4 text-6xl mb-4" singleSelect={true} acceptText="to shortlist" onCastingUpdated={this.getCastings}/>)
                  : <Body>{I18n.t('Currently no talents in this stage')}</Body>
                : <Loading/>
              }
            </div>
          </div>

          <Pagination links={links} handlePagination={this.handlePagination}/>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    castings: state.agency_castings.castings,
    links: state.agency_castings.links,
    meta: state.agency_castings.meta,
    castings_error: state.agency_castings.castings,
    castings_fetching: state.agency_castings.castings_fetching,

    role: state.role.role,
    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetCastingsRequest(obj)),
    getPageCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetPageCastingsRequest(obj)),
    clearCastingsState: () => dispatch(AgencyCastingsActions.clearCastingsState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditionsPage);
