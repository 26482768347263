import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PortfolioRoutingComponent from '../../../Components/Routing/PortfolioRoutingComponent';
import ExperienceActions from '../../../Redux/Talent/ExperienceRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../Utils/I18n';
import Loading from '../../../Components/Loading';

class PortfolioPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getExperienceById: PropTypes.func,
    experience: PropTypes.any,
  };

  componentDidMount() {
    const {account} = this.props;
    const {experiences} = this.props.match.params;
    this.props.getExperienceById({username: account.username, experience: experiences});
  }

  render() {
    const {experience} = this.props;
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              {experience
                ? <div>
                  <SubNavItem to={`/talent/experiences/${experience.id}/overview`} icon="info-circle">{I18n.t('Overview')}</SubNavItem>
                  <SubNavItem to={`/talent/experiences/${experience.id}/media`} icon="images">{I18n.t('media', null, false, true)}</SubNavItem>
                  <SubNavItem to={`/talent/experiences/${experience.id}/links`} icon="link">{I18n.t('links', null, false, true)}</SubNavItem>
                  <SubNavItem to={`/talent/experiences/${experience.id}/delete`} icon="trash-alt">{I18n.t('delete', null, false, true)}</SubNavItem>
                </div>
                : <Loading/>
              }
            </SubNav>

            <PortfolioRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    experience: state.experience.experience,
    experience_fetching: state.experience.fetching,
    experience_error: state.experience.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExperienceById: (obj) => dispatch(ExperienceActions.experienceByIdRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);
