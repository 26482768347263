import {call, put} from 'redux-saga/effects'
import ProjectTypeActiosn from '../../Redux/ProjectTypes/ProjectTypesRedux'
import {callApi} from '../CallApiSaga'

export function* getProjectTypes(api) {
  const apiCall = yield call(api.getProjectTypes);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ProjectTypeActiosn.projectTypesSuccess(response.data.data));
  } else {
    yield put(ProjectTypeActiosn.projectTypesFailure('WRONG'))
  }
}

export function* getProjectTypeById(api, {id}) {
  const apiCall = yield call(api.getProjectTypeById, id);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(ProjectTypeActiosn.projectTypeByIdSuccess(response.data));
  } else {
    yield put(ProjectTypeActiosn.projectTypeByIdFailure('WRONG'))
  }
}
