import {call, put} from 'redux-saga/effects'
import {callApi} from '../CallApiSaga'
import EducationsActions from "../../Redux/Talent/EducationsRedux";

export function* getEducations(api, {username}) {
  const apiCall = yield call(api.getEducations, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(EducationsActions.educationsSuccess(response.data.data));
  } else {
    yield put(EducationsActions.educationsFailure('WRONG'))
  }
}
