import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import InputMask from 'react-input-mask';

class VatField extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        input: PropTypes.object,
        meta: PropTypes.any,
        placeholder: PropTypes.string,
    };

    renderDatePicker(error = false) {
        const {className, input, placeholder} = this.props;
        return <div className="relative inline-block w-full">
            <InputMask
                {...input}
                mask="aa9999999999"
                onBlur={input.onBlur}
                aria-labelledby={input.name}
                className={`input-field${error ? ' has-error' : ''} ${className || ''}`}
                placeholder={placeholder}
            />
        </div>;
    }

    render() {
        const {meta} = this.props;
        return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
            <div>
                {this.renderDatePicker(meta.error)}
                <FormError meta={meta}/>
            </div>
            : this.renderDatePicker();
    }
}

export default VatField;
