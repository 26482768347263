import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import TextButton from '../Buttons/TextButton';
import I18n from '../../Utils/I18n';

class ProjectFileItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    deleteFile: PropTypes.func,
    file: PropTypes.object,
  };

  render() {
    const {className, deleteFile, file} = this.props;

    return (
      <Card className={`w-full flex justify-between px-6 py-5 ${className || ''}`}>
        <a href={file && file.original_file_url} className="underline hover:no-underline text-gray-40 break-all pr-2 flex-1">{file.original_filename}</a>
        <TextButton className="text-gray-40 hover:text-negative pl-1" onClick={() => deleteFile(file.uuid)} icon="trash-alt" iconLeft><span className="hidden sm:block">{I18n.t('delete')}</span></TextButton>
      </Card>
    );
  }
}

export default ProjectFileItem;
