import PropTypes from 'prop-types';
import React from 'react';
import Logo from './Logo';
import UserArea from './UserArea';
import Notifications from './Notifications';
import BreadCrumbs from './BreadCrumbs';
import AccountActions from '../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import LanguageSwitch from './LanguageSwitch';
import {withRouter} from 'react-router-dom';

class Header extends React.Component {
  static propTypes = {
    account: PropTypes.any,
    history: PropTypes.any,
    match: PropTypes.any,
    getAccount: PropTypes.func,
    location: PropTypes.object,
    unread_notifications: PropTypes.any,
  };

  render() {
    const {account, unread_notifications} = this.props;
    return (
      <header className={`flex justify-between ${account ? 'py-8' : 'py-8 sm:py-12'}`}>
        <div
          className={`flex transition-w absolute -z-10 gradient-fix-r inset-x-0 top-0 max-h-full${account ? ' w-64 lg:w-1/4' : ' w-full'}`}>
          <svg className={`transition-w block gradient absolute -z-10 inset-0 max-h-screen${account ? '' : ' pb-12'}`}
               viewBox="0 0 400 400" preserveAspectRatio="none" role="presentation" xmlns="http://www.w3.org/2000/svg">
            <path d="m400 400h-400c190.78-98.72 214.53-400 400-400z" fill="#fff"/>
          </svg>
        </div>
        <div className="w-48 md:w-auto lg:w-1/4">
          <Logo className={`h-12${!account ? ' pl-6 md:ml-3 h-20' : ' xl:pl-6 ml-3'}`}/>
        </div>
        {
          account ?
            <div className="flex flex-wrap md:flex-no-wrap justify-end sm:justify-between w-full px-6 sm:mr-6 ml-10">
              <BreadCrumbs/>
              <div className="items-center mb-4 hidden sm:flex">
                <Notifications className="mr-3" amount={unread_notifications ? unread_notifications.length : 0}/>
                <UserArea/>
              </div>
            </div>
            :
            <div className="flex items-center px-6 mr-3 md:mr-6">
              <LanguageSwitch/>
            </div>
        }
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
    unread_notifications: state.user_notifications.unread_notifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
