import PropTypes from 'prop-types';
import React from 'react';

class Label extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    name: PropTypes.any,
  };

  render() {
    const {children, className, name} = this.props;

    return (
      <label htmlFor={name} className={`block text-xs text-gray-80 pl-4 pb-1 ${className || ''}`}>
        {children}
      </label>
    );
  }
}

export default Label;
