import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    setLastRoute: ['route'],
    clearRouteState: [],
});

export const RouteTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    last_route: null,
});

/* ------------- Reducers ------------- */

// we're attempting to fetch projects
export const setLastRouteState = (state,data) => {
    return state.merge({last_route:data.route});
};

export const clear_route_state = () => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_LAST_ROUTE]: setLastRouteState,
    [Types.CLEAR_ROUTE_STATE]: clear_route_state,
});
