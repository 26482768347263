import React from 'react';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import PropTypes from 'prop-types';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import I18n from '../../../../Utils/I18n';
import Toggle from '../../../../Components/Form/Toggle';

class VisibilityPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true, saved: false}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  render() {
    const {account} = this.props;
    const {t} = this.context;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form
          onSubmit={this.onSubmit}
          validateOnBlur
          initialValues={{visibility: account.visibility}}
        >
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <Field name="visibility">
                {({input, meta}) => (
                  <div className="w-full">
                    <Label input={input} className="text-xl font-semibold -ml-4 pb-2">{I18n.t('Visibility of your profile')}</Label>
                    <p className="text-sm break-all text-gray-40 -mt-2 pb-6">{I18n.t('visibility_introduction')}</p>
                    <Toggle input={input} meta={meta} className="max-w-sm mb-12" on={t('public').toUpperCase()} onValue="public" off={t('private').toUpperCase()} offValue="private"/>
                  </div>
                )}
              </Field>
              <FilledButton type="submit" icon="edit" disabled={this.state.updating} saved={this.state.saved} className="rounded text-white bg-secondary-40">
                {I18n.t('save', null, false, true)}
              </FilledButton>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityPage);
