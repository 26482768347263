import React from 'react';
import NavItem from '../../../Components/Navigation/NavItem';
import UserArea from '../../Header/UserArea';
import I18n from '../../../Utils/I18n';

class TalentNav extends React.Component {

  render() {

    return (
      <ul>
        <NavItem to="/talent/dashboard" icon="home">{I18n.t('Dashboard')}</NavItem>
        <NavItem to="/talent/projects" icon="icons">{I18n.t('Projects')}</NavItem>
        <NavItem to="/talent/experiences" icon="briefcase">{I18n.t('Experiences')}</NavItem>
        <NavItem to="/talent/profile" icon="user">{I18n.t('Profile')}</NavItem>
        <NavItem to="/talent/settings" icon="cog">{I18n.t('Settings')}</NavItem>

        <div className="sm:hidden">
          <NavItem to="/notifications" icon="bell" className="mt-6">{I18n.t('Notifications')}</NavItem>
          <li className="flex">
            <UserArea className="pt-4"/>
          </li>
        </div>
      </ul>
    );
  }
}

export default TalentNav;
