import PropTypes from 'prop-types';
import React from 'react';

class ChatMessage extends React.Component {
  static propTypes = {
    ownMessage: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
  };

  render() {
    const {ownMessage, children, className} = this.props;

    return (
      <div className={`flex relative mt-2${ownMessage ? ' justify-end' : ' justify-start'} ${className || ''}`}>
        <p className={`rounded-xl max-w-xs ${ownMessage ? 'bg-primary-20' : 'bg-gray-10'} px-3 py-1`}>{children}</p>
      </div>
    );
  }
}

export default ChatMessage;
