import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  experiencesRequest: ['username'],
  experiencesSuccess: ['experiences'],
  experiencesFailure: ['error'],

  clearExperiencesState:[],
});

export const ExperiencesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  experiences: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// Experiences request
export const experiences_request = (state) => state.merge({ fetching: true });

// Experience Types success
export const experiences_success = (state, data) => {
  const { experiences } = data;
  return state.merge({ fetching: false, error: null, experiences })
};

export const experiences_failure = (state, { error }) => state.merge({ fetching: false, experiences: null, error });

export const clear_experiences_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EXPERIENCES_REQUEST]: experiences_request,
  [Types.EXPERIENCES_SUCCESS]: experiences_success,
  [Types.EXPERIENCES_FAILURE]: experiences_failure,

  [Types.CLEAR_EXPERIENCES_STATE]: clear_experiences_state,

});
