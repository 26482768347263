import React from 'react';
import {connect} from 'react-redux';
import I18n from '../../../Utils/I18n';
import PropTypes from 'prop-types';
import Helper from "../../../Utils/Helper";
import {maxLength} from "../../../Constants/Breadcrumbs";

const AgencyCastingBreadcrumb = ({name}) => <span>{name ? Helper.ellipsis(name, maxLength) : I18n.t('add', null, false, true)}</span>;

AgencyCastingBreadcrumb.propTypes = {
  name: PropTypes.string,
};

// find the casting in the store with the `id` from the route
function mapStateToProps(state) {
  const name = state.agency_castings.casting ? state.agency_castings.casting.talent.firstname : null;
  return {
    name,
  };
}

export default connect(mapStateToProps)(AgencyCastingBreadcrumb);
