import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import IconButton from '../../../Components/Buttons/IconButton';
import ProjectRoutingComponent from '../../../Components/Routing/ProjectRoutingComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CastingActions from '../../../Redux/Talent/CastingRedux';
import I18n from '../../../Utils/I18n';

import API_UPLOAD_CARE from '../../../Services/ApiUploadCare';
import Icon from '../../../Components/Icon';

const api_upload_care = API_UPLOAD_CARE.create();

//But actually a casting page...
class ProjectPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getCastingById: PropTypes.func,
    updateCasting: PropTypes.func,
    casting: PropTypes.any,
    casting_updating: PropTypes.bool,
    casting_updated: PropTypes.bool,
    casting_error: PropTypes.any,
    updating: PropTypes.bool,
    casting_fetching: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      extra_project_files: [],
      extra_role_files: [],
      fetching: false,
    };
  }

  componentDidMount() {
    this.getCasting();
  }

  getCasting() {
    const {account} = this.props;
    const {casting} = this.props.match.params;
    this.setState({fetching: true}, function () {
      this.props.getCastingById({username: account.username, casting_id: casting});
    });
  }

  componentDidUpdate(prevProps) {
    const self = this;
    if (!this.props.casting_updating) {
      if (this.props.casting_updated && (this.props.casting_updated !== prevProps.casting_updated) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.getCasting();
        });
      } else if ((this.props.casting_error !== prevProps.casting_error) && this.state.updating) {
        this.setState({updating: false}, function () {
          alert(this.props.casting_error.message);
          self.getCasting();
        });
      }
    }

    if (!this.props.casting_fetching) {
      if ((this.props.casting !== prevProps.casting) && this.state.fetching) {
        const {casting} = this.props;
        this.fetchUploadCareFiles(casting.role.files, 'role');
        this.fetchUploadCareFiles(casting.role.project.files, 'project');
      }
    }
  }

  acceptCasting = (e) => {
    e.preventDefault();
    this.changeStatusOfCasting('selected');
  };

  declineCasting = (e) => {
    e.preventDefault();
    this.changeStatusOfCasting('declined');
  };

  changeStatusOfCasting(status) {
    const {casting} = this.props;
    const obj = {
      project: casting.role.project.id,
      role: casting.role.id,
      casting: casting.id,
      data: {
        status: status,
      },
    };
    this.setState({updating: true}, function () {
      this.props.updateCasting(obj);
    });
  }

  fetchUploadCareFiles = (files, type) => {
    if (files) {
      let uuid = files.split('/')[3];

      api_upload_care.fetchGroupMedia({group_id: uuid}).then((response) => {
        if (type === 'role') {
          this.setState({extra_role_files: response.data.files});
        } else if (type === 'project') {
          this.setState({extra_project_files: response.data.files});
        }
      });
    }
  };

  render() {
    const casting_id = this.props.match.params.casting;
    const {casting} = this.props;
    const role = casting ? casting.role : null;
    const project = role ? role.project : null;
    const {extra_project_files, extra_role_files} = this.state;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full sm:flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">
          <div className="flex w-full">
            <SubNav>

              <SubNavItem to={`/talent/projects/${casting_id}/overview`} icon="info-circle">{I18n.t('Overview')}</SubNavItem>
              <SubNavItem to={`/talent/projects/${casting_id}/your-role`} icon="user-ninja">{I18n.t('your role', null, false, true)}</SubNavItem>

              {casting && casting.status === 'audition' && (
                <SubNavItem to={`/talent/projects/${casting_id}/audition`} icon="photo-video">{I18n.t('Audition')}</SubNavItem>
              )}

              {casting && casting.chatroom_id && (
                <SubNavItem to={`/talent/projects/${casting_id}/chatroom`} icon="comment-dots">{I18n.t('Chat')}</SubNavItem>
              )}

              {
                ((role && role.files) || (project && project.files)) &&
                  <li className="mt-10">
                    <p className="hidden sm:block text-gray-40 text-xs font-semibold pb-2">{I18n.t('downloads', null, true)}</p>

                    {extra_role_files && extra_role_files.map((file, key) => {
                      return (
                        <a target='_blank' rel="noopener noreferrer" key={key} href={file.original_file_url}
                          className="flex items-center py-1 mb-1 pr-3 sm:pr-0 rounded text-gray-40">
                          <div className="w-4 flex mr-3">
                            <Icon className="w-full text-xl">file-pdf</Icon>
                          </div>
                          <p className="hidden sm:block text-gray-40 text-sm font-bold truncate mr-2">{file.original_filename}</p>
                        </a>
                      );
                    })}
                    {extra_project_files && extra_project_files.map((file, key) => {
                      return (
                        <a target='_blank' rel="noopener noreferrer" key={key} href={file.original_file_url}
                          className="flex items-center py-1 mb-1 pr-3 sm:pr-0 rounded text-gray-40">
                          <div className="w-4 flex mr-3">
                            <Icon className="w-full text-xl">file-pdf</Icon>
                          </div>
                          <p className="hidden sm:block text-gray-40 text-sm font-bold truncate mr-2">{file.original_filename}</p>
                        </a>
                      );
                    })}
                  </li>
              }

              {
                project &&
                  <li className="mt-10 pt-5 pb-4 mr-3 sm:mr-6 text-center border border-gray-10 rounded rounded-lg">
                    <div className="rounded overflow-hidden w-20 m-auto">
                      <img className="w-100" src={project.logo} alt={project.company}/>
                    </div>
                    <div className="text-xs opacity-50 mt-2">
                      {project.company}
                    </div>
                  </li>
              }

              {
                !this.state.updating && casting && casting.status === 'new' && (
                  <li className="block sm:flex mt-24 -ml-1 sm:ml-0">
                    <div className="mr-6 block">
                      <IconButton onClick={this.declineCasting} className="w-8 h-8 sm:w-10 sm:h-10 text-lg sm:text-xl bg-gray-15 text-gray-60 mb-2 mx-auto">
                        times
                      </IconButton>
                      <p className="text-sm hidden sm:block">{I18n.t('decline', null, false, true)}</p>
                    </div>
                    <div className="mr-6 block">
                      <IconButton className="w-8 h-8 sm:w-10 sm:h-10 text-lg sm:text-xl bg-secondary-40 text-white mb-2 mx-auto" onClick={this.acceptCasting}>
                        check
                      </IconButton>
                      <p className="text-sm hidden sm:block">{I18n.t('apply', null, false, true)}</p>
                    </div>
                  </li>
                )
              }

            </SubNav>

            <ProjectRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    casting: state.casting.casting,
    casting_fetching: state.casting.fetching,
    casting_error: state.casting.error,

    casting_updating: state.casting.updating,
    casting_updated: state.casting.casting_updated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCastingById: (obj) => dispatch(CastingActions.castingByIdRequest(obj)),
    updateCasting: (obj) => dispatch(CastingActions.updateCastingRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
