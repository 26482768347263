import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

class ProgressBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    progress: PropTypes.string,
  };

  render() {
    const {className, progress} = this.props,
      progressWidth = progress.replace(',', '.'),
      percentageValue = parseFloat(progress.replace('%', ''));
    return (
      <div className={className}>
        <div className="mb-6" style={{width: progressWidth}}>
          <div className="flex justify-right relative">
            <p className="ml-auto text-right text-secondary-40 text-lg font-bold" style={percentageValue > 3 ? {transform: 'translateX(50%)'} : null}>{progress}</p>
            <Icon className="block bottom-0 right-0 -mb-2 -mr-1 absolute text-secondary-40 text-sm">sort-down</Icon>
          </div>
        </div>
        <div className="bg-secondary-20 rounded-full">
          <div className="bg-secondary-40 h-2 rounded-full" style={{width: progressWidth}}/>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
