import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  accountRequest: [],
  accountSuccess: ['account'],
  accountFailure: ['error'],

  accountUpdateRequest: ['obj'],
  accountUpdateSuccess: ['account'],
  accountUpdateFailure: ['error'],

  accountDeleteRequest: ['obj'],
  accountDeleteSuccess: [],
  accountDeleteFailure: ['error'],

  clearAccountState: [],
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  account: null,
  error: null,
  fetching: false,
  updating: false,
  deleting: false,
});

/* ------------- Reducers ------------- */

// we're attempting to account
export const request = (state) => {
  return state.merge({fetching: true});
};

// we've successfully logged in
export const success = (state, data) => {
  const {account} = data;
  return state.merge({fetching: false, error: null, account});
};

export const failure = (state, {error}) => state.merge({fetching: false, updating: false, account: null, error});

export const update_account_request = (state) => {
  return state.merge({updating: true, username: state.username, account: state.account});
};

export const updateSuccess = (state, data) => {
  const {account} = data;
  return state.merge({updating: false, error: null, account});
};

export const updateFailure = (state, {error}) => {
  return state.merge({updating: false, account: state.account, error});
};

export const clear_account_state = () => INITIAL_STATE;

export const deleteRequest = (state) => state.merge({deleting: true});

export const deleteSuccess = (state) => {
  return state.merge({deleting: false, account: null});
};

export const deleteFailure = (state, {error}) => state.merge({deleting: false, error});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCOUNT_REQUEST]: request,
  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,

  [Types.ACCOUNT_UPDATE_REQUEST]: update_account_request,
  [Types.ACCOUNT_UPDATE_SUCCESS]: updateSuccess,
  [Types.ACCOUNT_UPDATE_FAILURE]: updateFailure,

  [Types.ACCOUNT_DELETE_REQUEST]: deleteRequest,
  [Types.ACCOUNT_DELETE_SUCCESS]: deleteSuccess,
  [Types.ACCOUNT_DELETE_FAILURE]: deleteFailure,

  [Types.CLEAR_ACCOUNT_STATE]: clear_account_state,

});

/* ------------- Selectors ------------- */
// Is the current user logged in?
export const isLoggedIn = accountState => accountState.account !== null;

export const getLogin = accountState => accountState.account !== null ? accountState.account.login : 'anonymousUser';
