import { call, put } from 'redux-saga/effects'
import ProjectsActions from '../../Redux/Projects/ProjectsRedux'
import { callApi } from '../CallApiSaga'

// attempts to account
export function * getProjects (api) {
    const apiCall = yield call(api.getProjects);
    const response = yield call(callApi, apiCall);

    // success?
    if (response.ok) {
        yield put(ProjectsActions.projectsSuccess(response.data.data));
    } else {
        yield put(ProjectsActions.projectsFailure('WRONG'))
    }
}

