import React from 'react';
import Title from '../../../../Components/Text/Title';
import ProjectFileItem from '../../../../Components/ListItems/ProjectFileItem';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import ProjectTypeActions from '../../../../Redux/Project/ProjectRedux';
import FetchGroupActions from '../../../../Redux/Fetch/FetchGroupRedux';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import Loading from '../../../../Components/Loading';
import AddMultipleMedia from '../../../../Components/Images/AddMultipleMedia';

class ExtraInfoPage extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
    project: PropTypes.any,
    updateProject: PropTypes.func,
    getGroupFiles: PropTypes.func,
    project_files: PropTypes.any,
    project_fetching: PropTypes.any,
    clearGroupFiles: PropTypes.func,
    uploadMultiple: PropTypes.func,
    uploadSingleImage: PropTypes.func,
    file_uploading: PropTypes.bool,
    file_uploaded: PropTypes.bool,
    file: PropTypes.any,
    group_uploading: PropTypes.bool,
    group_uploaded: PropTypes.bool,
    group: PropTypes.any,
    clearUploadCareState: PropTypes.func,
    project_updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      updating: false,
      groupId: null,
      fetchingGroupFiles: false,
      file_uploading: false,
      group_uploading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.project_fetching) {
      if ((this.props.project !== prevProps.project && !this.state.fetchingGroupFiles) || (!this.props.project_files && !this.state.fetchingGroupFiles && this.props.project)) {
        if (this.props.project.files) {
          let splitted_url_group_files = this.props.project.files.split('/');
          this.setState({
            groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
            fetchingGroupFiles: true,
          }, function () {
            this.props.getGroupFiles({group_id: this.state.groupId});
          });
        }
      }
    }

    if (!this.props.group_uploading) {
      if ((this.props.group !== prevProps.group) && this.state.group_uploading && this.props.group) {
        let splitted_url_group_files = this.props.group.split('/');
        this.setState({group_uploading: false}, function () {
          const {project, group} = this.props;
          let newObj = Object.assign({}, project);
          newObj.files = group;
          this.props.updateProject(project.id, newObj);
          this.props.clearUploadCareState();
        });

        this.setState({
          groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
          fetchingGroupFiles: true,
        }, function () {
          this.props.getGroupFiles({group_id: this.state.groupId});
        });
      }
    }

    if(!this.props.project_updating){
      if ((this.props.project !== prevProps.project && this.state.updating)){
        if(this.props.project.files){
          let splitted_url_group_files = this.props.project.files.split('/');
          this.setState({
            groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
            fetchingGroupFiles: true,
            updating:false,
          }, function () {
            this.props.getGroupFiles({group_id: this.state.groupId});
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({fetchingGroupFiles: false});
    this.props.clearGroupFiles();
    this.props.clearUploadCareState();
  }

  uploadedFile = (file) => {
    const {project} = this.props;
    let newObj = Object.assign({}, project);
    newObj.files = file.cdnUrl;
    this.setState({updating:true}, function (){
      this.props.updateProject(project.id, newObj);
      this.props.clearUploadCareState();
    })
  };

  deleteFile = (uuid) => {
    const {project_files} = this.props;
    if (project_files) {
      let filtered_array = project_files.files.filter((file) => file.uuid !== uuid);
      if (filtered_array.length > 0) {
        let uuid_array = [];
        filtered_array.map((file) => {
          uuid_array.push(file.uuid);
        });
        this.setState({group_uploading: true}, function () {
          this.props.uploadMultiple({data: uuid_array});
        });
      }
    }
  };


  render() {
    const {project_files, project} = this.props, {file_uploading} = this.state;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="text-xl pb-2">{I18n.t('extra project information', null, false, true)}</Title>

        {project_files
          ? project_files.files && project_files.files.length > 0
            ? project_files.files.map((file, key) =>
              <ProjectFileItem className="my-6 max-w-xl" key={key} file={file} deleteFile={this.deleteFile}/>)
            : null
          : null
        }

        <div>
          {project
            ? <AddMultipleMedia className="max-w-xl" files={project ? project.files : null} uploadedFile={this.uploadedFile} disabled={file_uploading}/>
            : <Loading/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.project,
    project_fetching: state.project.fetching,
    project_updating: state.project.updating,
    project_error: state.project.error,

    project_files: state.fetch_group.project_files,
    fetching_group_files: state.fetch_group.fetching,
    error_group_files: state.fetch_group.error,

    file: state.upload_care.file,
    file_uploading: state.upload_care.uploading,
    file_uploaded: state.upload_care.uploaded,
    file_error: state.upload_care.error,

    group: state.upload_care.group,
    group_uploading: state.upload_care.uploading_group,
    group_uploaded: state.upload_care.uploaded_group,
    group_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (id, obj) => dispatch(ProjectTypeActions.updateProjectRequest(id, obj)),
    getGroupFiles: (obj) => dispatch(FetchGroupActions.fetchGroupProjectRequest(obj)),
    uploadMultiple: (obj) => dispatch(UploadCareActions.uploadMultipleRequest(obj)),
    clearGroupFiles: () => dispatch(FetchGroupActions.clearFetchGroupState()),
    deleteFile: (uuid) => dispatch(UploadCareActions.deleteFileRequest(uuid)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraInfoPage);
