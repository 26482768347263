import PropTypes from 'prop-types';
import React from 'react';
import Icon from "../Icon";

class FormError extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.any,
    meta: PropTypes.object,
    positive: PropTypes.bool,
  };

  render() {
    const {className, icon, meta, positive} = this.props;

    return meta.error && (meta.touched || meta.dirty) ?
      <div className="flex items-center -mt-3 mb-3">
        <div className={`rounded-full w-3 h-3 flex justify-center items-center mr-2 ${positive ? 'text-positive' : 'text-negative'}`}>
          <Icon className={`${positive ? 'text-positive' : 'text-negative'} text-xs`}>{icon || 'times'}</Icon>
        </div>
        <p className={`py-2 text-xs ${className || ''}`}>{meta.error}</p>
      </div>
      : null;
  }
}

export default FormError;
