import { call, put } from 'redux-saga/effects'
import SkillsActions from '../../Redux/Talent/SkillsRedux'
import { callApi } from '../CallApiSaga'

export function * getSkills(api,{username}) {
  const apiCall = yield call(api.getSkills,username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(SkillsActions.skillsSuccess(response.data.data));
  } else {
    yield put(SkillsActions.skillsFailure('WRONG'))
  }
}
