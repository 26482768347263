import Moment from 'moment';
import React from 'react';

class Helper {
  static getDateDiff(start, end) {
    return Moment.unix(end).diff(Moment.unix(start), 'day');
  }

  static formatDate(date, format = 'DD/MM/YYYY') {
    return Moment.unix(date).format(format);
  }

  static formatFloatValue(float, round = 2) {
    const value = parseFloat(float);
    let roundedNumber = value.toFixed(round);

    if ((/\.00/).test(roundedNumber)) {
      roundedNumber = Number(roundedNumber);
    }

    if (isNaN(roundedNumber)) {
      return float;
    }

    return roundedNumber.toString().replace('.', ',');
  }

  static formatUrl(url) {
    return /^https?:\/\//.test(url) ? url : 'http://' + url;
  }

  static renderGenderIcon(letter) {
    switch (letter) {
      case 'M':
        return 'mars';
      case 'F':
        return 'venus';
      case 'X':
      default:
        return 'user-secret';
    }
  }

  static renderGenderName(letter) {
    switch (letter) {
      case 'M':
        return 'male';
      case 'F':
        return 'female';
      case 'X':
      default:
        return 'genderless';
    }
  }

  static fileType(mimeType) {
    if (mimeType.includes('image')) {
      return 'image';
    } else if (mimeType.includes('video')) {
      return 'video';
    } else if (mimeType.includes('audio')) {
      return 'audio';
    }

    return 'url';
  }

  static getAgeFromUnix(birthday) {
    return Moment().diff(Moment.unix(birthday), 'years');
  }

  static getAgeFromDate(birthday) {
    return Moment().diff(Moment(birthday), 'years');
  }

  static getUnixFromAge(age) {
    return Moment().subtract(age, 'years').unix();
  }

  static titleCase(string) {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static makeBold(text) {
    return <span className="font-bold">{text}</span>;
  }

  static makeSemibold(text) {
    return <span className="font-semibold">{text}</span>;
  }

  static addBreaksToText(text) {
    if (typeof text !== 'string') {
      return text;
    }

    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }

  static getImagesAndVideoFromMedia(media) {
    let mediaArray = [], filteredMedia;

    if (!media || !media.length) {
      return [];
    }

    filteredMedia = media.filter(file => file.type.includes('image') || file.type.includes('video'));

    filteredMedia.map(file => {
      mediaArray.push({src: file.url, type: file.type, alt: ''});
    });

    return mediaArray;
  }

  static getAudioAndUrlFromMedia(media) {
    let mediaArray = [], filteredMedia;

    if (!media || !media.length) {
      return [];
    }

    filteredMedia = media.filter(file => !file.type.includes('image') && !file.type.includes('video'));

    filteredMedia.map(file => {
      mediaArray.push({src: file.url, type: file.type, alt: ''});
    });

    return mediaArray;
  }

  static ellipsis(input, length) {
    return input.length > length ? `${input.substring(0, length)}...` : input
  }
}

export default Helper;
