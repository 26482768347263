import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AgencyProjectRolePeopleRoutingComponent
  from '../../../Components/Routing/Agency/AgencyProjectRolePeopleRoutingComponent';
import RoleActions from '../../../Redux/Project/RoleRedux';
import ProjectActions from '../../../Redux/Project/ProjectRedux';
import I18n from '../../../Utils/I18n';

class PeoplePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getRoleById: PropTypes.func,
    getProjectId: PropTypes.func,
    project: PropTypes.any,
    role: PropTypes.any,
    role_fetching: PropTypes.bool,
    role_error: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {project, role} = this.props.match.params;
    this.props.getRoleById({project_id: project, role_id: role});
    this.props.getProjectId(project);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.role_fetching) {
      if ((this.props.role_error !== prevProps.role_error)) {
        this.props.history.push(`/agency/projects/`);
      }
    }
  }

  render() {
    const {project, role} = this.props.match.params;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}`} noActiveStyle icon="chevron-left" className="-mt-1">{I18n.t('Role details')}</SubNavItem>

              <hr className="m-3 sm:mr-5 ml-0 border-0 h-px bg-gray-10"/>

              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/search`} icon="search">{I18n.t('Search')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/contacted`} icon="id-badge">{I18n.t('Contacted')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/interested`} icon="info-circle">{I18n.t('Interested')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/audition`} icon="graduation-cap">{I18n.t('Audition')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/shortlist`} icon="star">{I18n.t('Shortlist')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/hired`} icon="handshake">{I18n.t('Hired')}</SubNavItem>
            </SubNav>

            <AgencyProjectRolePeopleRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    role: state.role.role,
    role_fetching: state.role.fetching,
    role_error: state.role.error,

    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleById: (obj) => dispatch(RoleActions.roleByIdRequest(obj)),
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePage);
