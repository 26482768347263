function validateEmail(email) {
  let re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}

function validateUrl(url) {
  let re = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
  return re.test(String(url).toLowerCase());
}

export const required = value => value ? undefined : 'Verplicht';
export const password = value => value ? (value.length > 6 ? undefined : 'Minimum 6 karakters') : 'Verplicht';
export const email = value => value ? (validateEmail(value) ? undefined : 'Geen geldig e-mail') : 'Verplicht';
export const url = value => value && !validateUrl(value) ? 'Geen geldig website' : undefined;

export const hasLength = value => {
  return (value ? (value.length > 0 ? undefined : 'Verplicht') : 'Verplicht');
};
