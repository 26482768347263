import React from 'react';
import BackButton from '../../Components/Buttons/BackButton';
import Card from '../../Components/Card';
import {Field, Form} from 'react-final-form';
import Label from '../../Components/Form/Label';
import EmailField from '../../Components/Form/EmailField';
import FilledButton from '../../Components/Buttons/FilledButton';
import PropTypes from 'prop-types';
import I18n from '../../Utils/I18n';
import ForgotPassActions from "../../Redux/Auth/ForgotPasswordRedux";
import {connect} from "react-redux";
import FormError from "../../Components/Form/FormError";
import {required} from "../../Validators/Form/Form";

class SendResetLinkPage extends React.Component {
  static contextTypes = {t: PropTypes.func};
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    sendReset: PropTypes.any,
    response: PropTypes.any,
    error: PropTypes.any,
    resetting: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      reset: false
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.resetting) {
      if ((this.props.response !== prevProps.response) && this.state.saving) {
        this.setState({saving: false, reset: false});
      } else if ((this.props.error !== prevProps.error) && this.state.saving) {
        this.setState({saving: false, reset: true});
      }
    }
  }

  onSubmit = (value) => {
    this.setState({saving: true, reset: false}, function () {
      this.props.sendReset({email: value.email});
    });
  };

  validate() {

  }

  render() {
    const {t} = this.context;

    return (
      <div className="flex flex-wrap lg:flex-no-wrap lg:justify-center px-4">

        <BackButton to="/login" className="mx-4 lg:mx-0 lg:mt-24"/>

        <Card className="w-full max-w-5xl mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('Forgot your password?')}</h2>
          <Form onSubmit={this.onSubmit} validate={this.validate} validateOnBlur>
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <div className="max-w-md mx-auto w-full mt-4">

                  <Field name="email" validate={required}>
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute h-0 opacity-0">{I18n.t('email', null, true)}</Label>
                        <EmailField input={input} meta={meta} placeholder={t('e.g. john.doe@thecastingdirector.eu')}/>
                      </div>
                    )}
                  </Field>
                  <FormError meta={{touched: !this.state.saving, error: this.props.error}}/>
                  <FormError meta={{touched: !this.state.saving, error: this.props.response}} positive={true} icon={'check'}/>
                  <FilledButton type="submit" disabled={this.state.saving} iconRight icon="chevron-right" className="ml-auto rounded text-white bg-primary-40">
                    {I18n.t('Send email')}
                  </FilledButton>

                </div>
              </form>
            )}
          </Form>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.forgot_password.response,
    error: state.forgot_password.error,
    resetting: state.forgot_password.resetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendReset: (obj) => dispatch(ForgotPassActions.forgotPasswordRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendResetLinkPage);
