import PropTypes from 'prop-types';
import React from 'react';
import {connectSearchBox} from 'react-instantsearch-dom';

class SearchField extends React.Component {
  static propTypes = {
    currentRefinement: PropTypes.any,
    refine: PropTypes.func,
    className: PropTypes.string,
    attribute: PropTypes.any,
  };

  render() {
    const {attribute, currentRefinement, refine} = this.props;

    return <input type="search" value={currentRefinement} id={attribute} className="border border-gray-15 rounded px-4 py-2 text-sm text-gray-80 font-sans w-full" onChange={event => refine(event.currentTarget.value)}/>;
  }
}

export default connectSearchBox(SearchField);
