import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import TextButton from '../Buttons/TextButton';
import {Link} from 'react-router-dom';
import Icon from '../Icon';
import I18n from '../../Utils/I18n';


class MemberItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    member: PropTypes.object,
    onClick: PropTypes.func,
    to: PropTypes.string,
    invitation: PropTypes.bool,
  };

  render() {
    const {className, member, onClick, to, invitation} = this.props;

    return (
      <Card className={`mb-4 p-6 ${className || ''}`}>
        <div className="flex justify-between items-center">
          <p className="text-gray-60 break-all pr-3">{member.email}</p>
          {
            invitation
              ? <div>
                <TextButton className="hidden sm:flex text-gray-40 hover:text-negative" iconLeft onClick={onClick} icon="trash-alt">{I18n.t('delete', null, false, true)}</TextButton>
                <Link to="#" onClick={onClick} className="block sm:hidden">
                  <Icon className="text-gray-40 hover:text-negative">trash-alt</Icon>
                </Link>
              </div>
              : <div>
                <TextButton className="hidden sm:flex text-gray-40 hover:text-positive my-auto" iconLeft to={to} icon="edit">{I18n.t('edit', null, false, true)}</TextButton>
                <Link to={to} className="block sm:hidden">
                  <Icon className="text-gray-40 hover:text-positive">edit</Icon>
                </Link>
              </div>
          }
        </div>
      </Card>
    );
  }
}

export default MemberItem;
