import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import NotificationsRoutingComponent from '../../../Components/Routing/NotificationsRoutingComponent';

class NotificationPage extends React.Component {
  render() {
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to="/notifications/new" icon="bell">New messages</SubNavItem>
              <SubNavItem to="/notifications/read" icon="inbox">Read messages</SubNavItem>
            </SubNav>

            <NotificationsRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

export default NotificationPage;
