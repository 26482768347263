import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import Icon from '../Icon';
import {Link} from 'react-router-dom';

const Color = require('color');


class ColorSelect extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
  };

  onChange(e) {
    const {input} = this.props,
      target = e.target,
      options = document.querySelectorAll(`#options-${input.name} > a > svg`),
      inputField = document.getElementById(input.name);

    input.onChange(target.dataset.option);
    inputField.value = target.dataset.option;

    for (let i = 0, ilen = options.length; i < ilen; ++i) {
      options[i].classList.remove('block');
      options[i].classList.add('hidden');
    }

    target.getElementsByTagName('svg')[0].classList.remove('hidden');
    target.getElementsByTagName('svg')[0].classList.add('block');
  }

  isDark(color) {
    if (!color) {
      return false;
    }

    const parsedColor = Color(color);

    return parsedColor.contrast(Color('white')) > 4;
  }

  renderRadioInput(error = false) {
    const {className, children, input} = this.props;

    return (<div className={`flex items-center ${className || ''}`}>
      <input {...input} type="hidden"/>
      <div id={`options-${input.name}`} className={`flex flex-wrap rounded overflow-hidden border-gray-15 border ${error ? ' has-error' : ''}`}>
        {children.map((option, key) => {
          if (!option) {
            return null;
          }

          return <Link
            to=""
            id={key === 0 ? input.name : null}
            tabIndex="0"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              this.onChange(e);
            }}
            data-option={option}
            key={key}
            style={{backgroundColor: option}}
            className={`flex justify-center items-center w-10 h-10 focus:outline-none focus:shadow-inner ${key < children.length - 1 ? 'border-r border-gray-15 ' : ''}`}
          >
            <Icon className={`text-xl pointer-events-none ${this.isDark(option) ? 'text-white' : 'text-black'} ${option === input.value ? 'block' : 'hidden'}`}>check</Icon>
          </Link>;
        })
        }
      </div>
    </div>);
  }

  render() {
    const {meta} = this.props;

    return meta && meta.error && meta.touched ?
      (<div>
        {this.renderRadioInput(meta.error)}
        <FormError meta={meta}/>
      </div>)
      : this.renderRadioInput();
  }
}

export default ColorSelect;
