import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';
import Icon from '../Icon';

class NavItem extends React.Component {
  static propTypes = {
    to: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    icon: PropTypes.string,
  };

  render() {
    const {to, children, className, icon} = this.props;

    return (
      <li>
        <NavLink
          exact
          to={to || ''}
          isActive={(match, location) => (match && match.isExact) || (location && location.pathname.startsWith(to))}
          activeClassName="active-nav-link bg-primary-20-transparent"
          className={`flex items-center px-4 py-3 mt-1 rounded ${className || ''}`}
        >
          <Icon className="text-xl text-gray-40">{icon}</Icon>
          <p className="pl-5 text-gray-80 font-light">{children}</p>
        </NavLink>
      </li>
    );
  }
}

export default NavItem;
