import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setLocale: ['locale'],
  setTranslationsRequest: [],
  setTranslationsSuccess: ['translations'],
  getLocalesRequest: [],
  getLocalesSuccess: ['locales'],
});

export const TranslationTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  locale: "en",
  version: null,
  toggle: false,
  fetching: false,
  translations: {},
  locales: []
});

/* ------------- Reducers ------------- */

// we're attempting to fetch projects
export const set_locale = (state, data) => {
  return state.merge({locale: data.locale});
};

export const set_translations_request = (state) => state.merge({fetching: true});

// we've successfully fetched the projects
export const set_translations_success = (state, data) => {
  const {translations} = data;
  return state.merge({fetching: false, error: null, toggle: !state.toggle, translations})
};

export const get_locales_request = (state) => state.merge({});

// we've successfully fetched the locales
export const get_locales_success = (state, data) => {
  const {locales} = data;
  return state.merge({locales})
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOCALE]: set_locale,
  [Types.SET_TRANSLATIONS_REQUEST]: set_translations_request,
  [Types.SET_TRANSLATIONS_SUCCESS]: set_translations_success,
  [Types.GET_LOCALES_REQUEST]: get_locales_request,
  [Types.GET_LOCALES_SUCCESS]: get_locales_success,
});
