import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    deleteFileRequest: ['uuid'],
    deleteFileSuccess: [],
    deleteFileFailure: ['error'],

    uploadSingleRequest: ['obj'],
    uploadSingleSuccess: ['file'],
    uploadSingleFailure: ['error'],

    uploadMultipleRequest: ['obj'],
    uploadMultipleSuccess: ['group'],
    uploadMultipleFailure: ['error'],

    clearUploadCareState:[],
});

export const UploadCareTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    file: null,
    group:null,
    error: null,
    deleting: false,
    uploading:false,
    uploaded: false,
    uploading_group:false,
    uploaded_group:false,
});

/* ------------- Reducers ------------- */

export const delete_file_request = (state) => state.merge({ deleting: true });

export const delete_file_success = (state) => {
    return state.merge({ deleting: false, error: null, file:null })
};

export const delete_file_failure = (state, { error }) => state.merge({ deleting: false, file: null, error });

export const clear_upload_care_state = () => INITIAL_STATE;

export const upload_single_request = (state) => state.merge({ uploading: true });

export const upload_single_success = (state, data) => {
    const { file } = data;
    return state.merge({ uploading: false, uploaded: true, error: null, file })
};

export const upload_single_failure = (state, { error }) => state.merge({ file: null, uploading: false, uploaded:false, error });

export const upload_multiple_request = (state) => state.merge({ uploading_group: true });

export const upload_multiple_success = (state, data) => {
    const { group } = data;
    return state.merge({ uploading_group: false, uploaded_group: true, error: null, group })
};

export const upload_multiple_failure = (state, { error }) => state.merge({ files: null, uploading_group: false, uploaded_group:false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.DELETE_FILE_REQUEST]: delete_file_request,
    [Types.DELETE_FILE_SUCCESS]: delete_file_success,
    [Types.DELETE_FILE_FAILURE]: delete_file_failure,

    [Types.UPLOAD_SINGLE_REQUEST]: upload_single_request,
    [Types.UPLOAD_SINGLE_SUCCESS]: upload_single_success,
    [Types.UPLOAD_SINGLE_FAILURE]: upload_single_failure,

    [Types.UPLOAD_MULTIPLE_REQUEST]: upload_multiple_request,
    [Types.UPLOAD_MULTIPLE_SUCCESS]: upload_multiple_success,
    [Types.UPLOAD_MULTIPLE_FAILURE]: upload_multiple_failure,

    [Types.CLEAR_UPLOAD_CARE_STATE]: clear_upload_care_state,
});
