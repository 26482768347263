import { call, put } from 'redux-saga/effects'
import InvoicesActions from '../../Redux/Billing/InvoicesRedux'
import { callApi } from '../CallApiSaga'

export function * getInvoices (api) {
    const apiCall = yield call(api.getInvoices);
    const response = yield call(callApi, apiCall);

    // success?
    if (response.ok) {
        yield put(InvoicesActions.invoicesSuccess(response.data));
    } else {
        yield put(InvoicesActions.invoicesFailure('WRONG'))
    }
}

