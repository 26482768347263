import React from 'react';
import NavItem from '../../../Components/Navigation/NavItem';
import UserArea from '../../Header/UserArea';
import I18n from '../../../Utils/I18n';
import {connect} from "react-redux";
import PropTypes from "prop-types";

class AgencyNav extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
  };
  isOwner() {
    return this.props.account.roles.includes('owner');
  }

  render() {

    return (
      <ul>
        <NavItem to="/agency/dashboard" icon="home">{I18n.t('Dashboard')}</NavItem>
        <NavItem to="/agency/projects" icon="briefcase">{I18n.t('Projects')}</NavItem>
        {this.isOwner() &&  <NavItem to="/agency/employees" icon="users">{I18n.t('Employees')}</NavItem>}
        {this.isOwner() &&  <NavItem to="/agency/agency-info" icon="building">{I18n.t('Agency info')}</NavItem>}
        <NavItem to="/agency/settings" icon="cog">{I18n.t('Settings')}</NavItem>

        <div className="sm:hidden">
          <NavItem to="/notifications" icon="bell" className="mt-6">{I18n.t('Notifications')}</NavItem>
          <li className="flex">
            <UserArea className="pt-4"/>
          </li>
        </div>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
  };
};

export default connect(mapStateToProps)(AgencyNav);
