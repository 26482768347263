import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  registerRequest: ['registerObj'],
  registerSuccess: ['response'],
  registerFailure: ['error'],

  clearRegisterState: [],
});

export const RegisterTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  store: false,
});

/* ------------- Reducers ------------- */

// we're attempting to create an account
export const request = (state) => state.merge({store: true});

// we've successfully created an account
export const success = (state, data) => {
  const {response} = data;
  return state.merge({store: false, error: null, response});
};

export const failure = (state, {error}) => state.merge({store: false, response: null, error});

export const clearRegisterState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REGISTER_REQUEST]: request,
  [Types.REGISTER_SUCCESS]: success,
  [Types.REGISTER_FAILURE]: failure,

  [Types.CLEAR_REGISTER_STATE]: clearRegisterState,

});
