import {all, takeLatest} from 'redux-saga/effects'
import API from '../Services/Api'
import API_UPLOAD_CARE from '../Services/ApiUploadCare'
import API_UPLOAD_CARE_STORE from '../Services/ApiUploadCareStore'

/* ------------- Types ------------- */
import {StartupTypes} from '../Redux/Startup/StartupRedux'
import {LoginTypes} from '../Redux/Auth/LoginRedux'
import {AccountTypes} from '../Redux/Account/AccountRedux'
import { ForgotPasswordTypes } from '../Redux/Auth/ForgotPasswordRedux'
import {RegisterTypes} from '../Redux/Auth/RegisterRedux'
import { ResetPasswordTypes } from '../Redux/Auth/ResetPasswordRedux'
import { PlansTypes } from '../Redux/Billing/PlansRedux'
import { SubscriptionTypes } from '../Redux/Billing/SubscriptionRedux'
import { CardTypes } from '../Redux/Billing/CardRedux'
import {ProjectTypesTypes} from '../Redux/ProjectTypes/ProjectTypesRedux'
import {PropertyTypesTypes} from '../Redux/PropertyTypes/PropertyTypesRedux'
import {ExperienceTypesTypes} from '../Redux/ExperienceTypes/ExperienceTypesRedux'
import {SkillsetCategoriesTypes} from '../Redux/SkillsetCategories/SkillsetCategoriesRedux'
// import { GlobalCastingsTypes } from '../Redux/Castings/GlobalCastingsRedux'
import { AgencyCastingsTypes } from '../Redux/Castings/AgencyCastingsRedux'
import {ExperiencesTypes} from '../Redux/Talent/ExperiencesRedux'
import {ExperienceTypes} from '../Redux/Talent/ExperienceRedux'
import {CastingsTypes} from '../Redux/Talent/CastingsRedux'
import {CastingTypes} from '../Redux/Talent/CastingRedux'
import {PropertiesTypes} from '../Redux/Talent/PropertiesRedux'
import {PropertyTypes} from '../Redux/Talent/PropertyRedux'
import {SkillsTypes} from '../Redux/Talent/SkillsRedux'
import {EducationsTypes} from '../Redux/Talent/EducationsRedux'
import {EducationTypes} from '../Redux/Talent/EducationRedux'
import {NotificationsTypes} from '../Redux/Talent/NotificationsRedux'
// import { SkillsetsTypes } from '../Redux/Skillsets/SkillsetsRedux'
import {SkillTypes} from '../Redux/Talent/SkillRedux'
// import { FetchTypes} from '../Redux/Fetch/FetchRedux'
import { FetchGroupTypes } from '../Redux/Fetch/FetchGroupRedux'
import { TranslationTypes } from '../Redux/Translation/TranslationRedux'
// import { OnboardingTypes } from '../Redux/Onboarding/OnboardingRedux'
// import { PushNotificationsTypes } from '../Redux/PushNotification/PushNotificationRedux'
// import { DynamicLinkTypes } from '../Redux/DynamicLink/DynamicLinkRedux'
import {CouponTypes} from '../Redux/Billing/CouponRedux'
import {UploadCareTypes} from "../Redux/UploadCare/UploadCareRedux";
import {ProjectTypes} from '../Redux/Project/ProjectRedux'
import {RoleTypes} from '../Redux/Project/RoleRedux'
import {ProjectsTypes} from '../Redux/Projects/ProjectsRedux'
import {UserNotificationsTypes} from '../Redux/Talent/UserNotificationsRedux'
import {InvitationsTypes} from "../Redux/Account/InvitationsRedux";
import {UsersTypes} from "../Redux/Account/UsersRedux";
import {UserTypes} from "../Redux/Account/UserRedux";
import {InvoicesTypes} from "../Redux/Billing/InvoicesRedux";
/* ------------- Sagas ------------- */
import {startup} from './Startup/StartupSagas'
import {login, loginLoad, logout, refreshToken, socialLogin} from './Auth/LoginSagas'
import {deleteAccount, getAccount, updateAccount} from './Account/AccountSagas'
import { forgotPassword } from './Auth/ForgotPasswordSagas'
import {register} from './Auth/RegisterSagas'
import { resetPassword } from './Auth/ResetPasswordSagas'
import { getBillingPlans } from './Billing/PlansSagas'
import { chooseSubscription,deleteSubscription,changeSubscription } from './Billing/SubscriptionSagas'
import { updateCard } from './Billing/CardSagas'
import {getPropertyTypeById, getPropertyTypes} from './PropertyTypes/PropertyTypesSagas'
import {getExperienceTypeById, getExperienceTypes} from './ExperienceTypes/ExperienceTypesSagas'
import {getSkillsetCategories, getSkillsetCategoryById} from './SkillsetCategories/SkillsetCategoriesSagas'
import {getProjects} from './Projects/ProjectsSagas'
import {createProject, deleteProject, getProjectById, updateProject} from './Project/ProjectSagas'
// import { getGlobalCastings, refreshGlobalCastings } from './Castings/GlobalCastingsSagas'
import {getExperiences} from './Talent/ExperiencesSagas'
import {createExperience, deleteExperience, getExperienceById, updateExperience} from './Talent/ExperienceSagas'
import {getCastings} from './Talent/CastingsSagas'
import {getCastingById, updateCasting} from './Talent/CastingSagas'
import {getProperties} from './Talent/PropertiesSagas'
import {updateProperty} from './Talent/PropertySagas'
import {getSkills} from './Talent/SkillsSagas'
import {getEducations} from './Talent/EducationsSagas'
import {createEducation, deleteEducation} from './Talent/EducationSagas'
import {getNotifications, updateAllNotifications, updateNotifications} from './Talent/NotificationsSagas'
// import { getSkillsets } from './Skillsets/SkillsetsSagas'
import {deleteSkill, saveSkill, updateSkill} from './Talent/SkillSagas'
import {getReadNotifications, getUnreadNotifications, markNotificationsRead} from "./Talent/UserNotificationsSagas";
import {getProjectTypeById, getProjectTypes} from "./ProjectTypes/ProjectTypesSagas";
import {createInvitation, deleteInvitation, getInvitations} from "./Account/InvitationsSagas";
import {getUser, getUsers} from "./Account/UsersSagas";
import {deleteUser, updatePemissions} from "./Account/UserSagas";
import {createRole, deleteRole, getRoleById, updateRole} from "./Project/RoleSagas";
// import { fetchMedia } from './Fetch/FetchSagas'
import { fetchProject, fetchRole } from './Fetch/FetchGroupSagas'
import {getLocales, getTranslations, setLocale} from './Translation/TranslationSagas'
import {createCastings, getAllCastingsForRole, getPageCastingsForRole, getAllDeclinedCastingsForRole, getDeclinedPageCastingsForRole, getCastingByIdForAgency} from "./Castings/AgencyCastingsSagas";
// import { cancelOnboardingStack } from './Onboarding/OnboardingSagas'
// import { navigatePushNotification } from './PushNotification/PushNotificationSagas'
// import { navigateDynamicLink } from './DynamicLink/DynamicLinkSagas'
import { submitCoupon } from "./Billing/CouponSagas";
import { deleteFile, uploadSingle, uploadMultiple } from './UploadCare/UploadCareSagas'
import { getInvoices } from './Billing/InvoicesSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();
const api_upload_care = API_UPLOAD_CARE.create();
const api_upload_care_store = API_UPLOAD_CARE_STORE.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),

    //LOGIN
    takeLatest(LoginTypes.LOGIN_LOAD, loginLoad, api),
    takeLatest(LoginTypes.LOGIN_REQUEST, login, api),
    takeLatest(LoginTypes.LOGOUT_REQUEST, logout, api),
    takeLatest(LoginTypes.REFRESH_TOKEN, refreshToken, api),
    takeLatest(LoginTypes.SOCIAL_LOGIN_REQUEST,socialLogin,api),

    //FORGOT PASSWORD
     takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api),

    //RESET PASSWORD
     takeLatest(ResetPasswordTypes.RESET_PASSWORD_REQUEST, resetPassword,api),

    //REGISTER
    takeLatest(RegisterTypes.REGISTER_REQUEST, register, api),

    //ACCOUNT
    takeLatest(AccountTypes.ACCOUNT_REQUEST, getAccount, api),
    takeLatest(AccountTypes.ACCOUNT_UPDATE_REQUEST, updateAccount, api),
    takeLatest(AccountTypes.ACCOUNT_DELETE_REQUEST, deleteAccount, api),

    //BILLING PLANS
    takeLatest(PlansTypes.PLANS_REQUEST,getBillingPlans,api),

    //BILLING SUBSCRIPTIONS
    takeLatest(SubscriptionTypes.CREATE_SUBSCRIPTION_REQUEST,chooseSubscription,api),
    takeLatest(SubscriptionTypes.DELETE_SUBSCRIPTION_REQUEST,deleteSubscription,api),
    takeLatest(SubscriptionTypes.CHANGE_SUBSCRIPTION_REQUEST,changeSubscription,api),

    //BILLING CARD
    takeLatest(CardTypes.UPDATE_CARD_REQUEST,updateCard,api),

    //BILLING COUPON
    takeLatest(CouponTypes.COUPON_REQUEST,submitCoupon,api),

    //BILLING INVOICES
    takeLatest(InvoicesTypes.INVOICES_REQUEST, getInvoices,api),

    //PROJECT TYPES
    takeLatest(ProjectTypesTypes.PROJECT_TYPES_REQUEST, getProjectTypes, api),
    takeLatest(ProjectTypesTypes.PROJECT_TYPE_BY_ID_REQUEST, getProjectTypeById, api),

    //PROPERTY TYPES
    takeLatest(PropertyTypesTypes.PROPERTY_TYPES_REQUEST, getPropertyTypes, api),
    takeLatest(PropertyTypesTypes.PROPERTY_TYPE_BY_ID_REQUEST, getPropertyTypeById, api),

    //EXPERIENCE TYPES
    takeLatest(ExperienceTypesTypes.EXPERIENCE_TYPES_REQUEST, getExperienceTypes, api),
    takeLatest(ExperienceTypesTypes.EXPERIENCE_TYPE_BY_ID_REQUEST, getExperienceTypeById, api),

    //SKILLSET CATEGORIES
    takeLatest(SkillsetCategoriesTypes.SKILLSET_CATEGORIES_REQUEST, getSkillsetCategories, api),
    takeLatest(SkillsetCategoriesTypes.SKILLSET_CATEGORY_BY_ID_REQUEST, getSkillsetCategoryById, api),

    //PROJECT
    takeLatest(ProjectTypes.PROJECT_BY_ID_REQUEST, getProjectById, api),
    takeLatest(ProjectTypes.CREATE_PROJECT_REQUEST, createProject, api),
    takeLatest(ProjectTypes.UPDATE_PROJECT_REQUEST, updateProject, api),
    takeLatest(ProjectTypes.DELETE_PROJECT_REQUEST, deleteProject, api),

    //PROJECTS
    takeLatest(ProjectsTypes.PROJECTS_REQUEST, getProjects, api),

    //GLOBAL CASTINGS
    // takeLatest(GlobalCastingsTypes.GLOBAL_CASTINGS_REQUEST,getGlobalCastings,api),
    // takeLatest(GlobalCastingsTypes.GLOBAL_REFRESH_CASTINGS_REQUEST,refreshGlobalCastings,api),

    //EXPERIENCES
    takeLatest(ExperiencesTypes.EXPERIENCES_REQUEST, getExperiences, api),

    //EXPERIENCE
    takeLatest(ExperienceTypes.EXPERIENCE_CREATE_REQUEST, createExperience, api),
    takeLatest(ExperienceTypes.EXPERIENCE_DELETE_REQUEST, deleteExperience, api),
    takeLatest(ExperienceTypes.EXPERIENCE_BY_ID_REQUEST, getExperienceById, api),
    takeLatest(ExperienceTypes.EXPERIENCE_UPDATE_REQUEST, updateExperience, api),

    //CASTINGS
    takeLatest(CastingsTypes.NEW_CASTINGS_REQUEST, getCastings, api),
    takeLatest(CastingsTypes.CURRENT_CASTINGS_REQUEST, getCastings, api),

    //CASTING
    takeLatest(CastingTypes.CASTING_BY_ID_REQUEST, getCastingById, api),
    takeLatest(CastingTypes.UPDATE_CASTING_REQUEST,updateCasting,api),

    //AGENCY CASTINGS
    takeLatest(AgencyCastingsTypes.CREATE_CASTINGS_REQUEST, createCastings, api),
    takeLatest(AgencyCastingsTypes.GET_CASTINGS_REQUEST, getAllCastingsForRole, api),
    takeLatest(AgencyCastingsTypes.GET_PAGE_CASTINGS_REQUEST, getPageCastingsForRole, api),
    takeLatest(AgencyCastingsTypes.GET_DECLINED_CASTINGS_REQUEST, getAllDeclinedCastingsForRole, api),
    takeLatest(AgencyCastingsTypes.GET_DECLINED_PAGE_CASTINGS_REQUEST, getDeclinedPageCastingsForRole, api),
    takeLatest(AgencyCastingsTypes.GET_CASTING_REQUEST, getCastingByIdForAgency, api),

    //PROPERTIES
    takeLatest(PropertiesTypes.PROPERTIES_REQUEST, getProperties, api),

    //PROPERTY
    takeLatest(PropertyTypes.PROPERTY_UPDATE_REQUEST, updateProperty, api),

    //SKILLS
    takeLatest(SkillsTypes.SKILLS_REQUEST, getSkills, api),

    //EDUCATIONS
    takeLatest(EducationsTypes.EDUCATIONS_REQUEST, getEducations, api),

    //EDUCATION
    takeLatest(EducationTypes.EDUCATION_CREATE_REQUEST, createEducation, api),
    takeLatest(EducationTypes.EDUCATION_DELETE_REQUEST, deleteEducation, api),

    //NOTIFICATIONS
    takeLatest(NotificationsTypes.NOTIFICATIONS_REQUEST, getNotifications, api),
    takeLatest(NotificationsTypes.NOTIFICATIONS_UPDATE_REQUEST, updateNotifications, api),
    takeLatest(NotificationsTypes.NOTIFICATIONS_ALL_UPDATE_REQUEST, updateAllNotifications, api),

    //USER NOTIFICATIONS
    takeLatest(UserNotificationsTypes.UNREAD_NOTIFICATIONS_REQUEST, getUnreadNotifications, api),
    takeLatest(UserNotificationsTypes.READ_NOTIFICATIONS_REQUEST, getReadNotifications, api),
    takeLatest(UserNotificationsTypes.MARK_READ_REQUEST, markNotificationsRead, api),

    //SKILLSETS
    // takeLatest(SkillsetsTypes.SKILLSETS_REQUEST,getSkillsets,api),

    //SKILL
    takeLatest(SkillTypes.SKILL_UPDATE_REQUEST, updateSkill, api),
    takeLatest(SkillTypes.SKILL_DELETE_REQUEST, deleteSkill, api),
    takeLatest(SkillTypes.SKILL_SAVE_REQUEST, saveSkill, api),

    //INVITATIONS
    takeLatest(InvitationsTypes.CREATE_INVITATION_REQUEST, createInvitation, api),
    takeLatest(InvitationsTypes.GET_INVITATIONS_REQUEST, getInvitations, api),
    takeLatest(InvitationsTypes.DELETE_INVITATION_REQUEST, deleteInvitation, api),

    //USERS
    takeLatest(UsersTypes.GET_USERS_REQUEST, getUsers, api),
    takeLatest(UsersTypes.GET_USER_REQUEST, getUser, api),
    takeLatest(UserTypes.USER_UPDATE_REQUEST, updatePemissions, api),
    takeLatest(UserTypes.USER_DELETE_REQUEST, deleteUser, api),

    //ROLES
    takeLatest(RoleTypes.ROLE_BY_ID_REQUEST, getRoleById, api),
    takeLatest(RoleTypes.CREATE_ROLE_REQUEST, createRole, api),
    takeLatest(RoleTypes.UPDATE_ROLE_REQUEST, updateRole, api),
    takeLatest(RoleTypes.DELETE_ROLE_REQUEST, deleteRole, api),

    //FETCH
    // takeLatest(FetchTypes.FETCH_REQUEST,fetchMedia,api_fetch_upload_care),

    //FETCH GROUP
    takeLatest(FetchGroupTypes.FETCH_GROUP_PROJECT_REQUEST,fetchProject,api_upload_care),
    takeLatest(FetchGroupTypes.FETCH_GROUP_ROLE_REQUEST,fetchRole,api_upload_care),

    //DELETE FILE UPLOADCARE
    takeLatest(UploadCareTypes.DELETE_FILE_REQUEST,deleteFile,api_upload_care),

    //UPLOAD
    takeLatest(UploadCareTypes.UPLOAD_SINGLE_REQUEST,uploadSingle,api_upload_care_store),
    takeLatest(UploadCareTypes.UPLOAD_MULTIPLE_REQUEST, uploadMultiple, api_upload_care_store),

    //TRANSLATIONS
    takeLatest(TranslationTypes.SET_TRANSLATIONS_REQUEST,getTranslations,api),
    takeLatest(TranslationTypes.SET_LOCALE,setLocale,api),
    takeLatest(TranslationTypes.GET_LOCALES_REQUEST,getLocales,api),

    //ONBOARDING
    // takeLatest(OnboardingTypes.CANCEL_ONBOARDING_STACK,cancelOnboardingStack),

    //PUSHNOTIFICATIONS
    // takeLatest(PushNotificationsTypes.SET_PUSH_NOTIFICATION, navigatePushNotification),

    //DYNAMICLINK
    // takeLatest(DynamicLinkTypes.SET_DYNAMIC_LINK,navigateDynamicLink),

  ])
}
