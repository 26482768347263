import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import Icon from '../Icon';
import {Link} from 'react-router-dom';

class IconSelect extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
    options: PropTypes.array,
  };

  static contextTypes = {
    t:PropTypes.func,
  };

  onChange(e) {
    const {input} = this.props,
      target = e.target,
      option = target.dataset.option,
      options = document.querySelectorAll(`#options-${input.name} > a`),
      inputField = document.getElementById(input.name),
      label = document.getElementById(`label-${input.name}`);

    e.preventDefault();

    inputField.value = option;
    label.innerHTML = this.findLabel(option);
    input.onChange(option);

    for (let i = 0, ilen = options.length; i < ilen; ++i) {
      options[i].classList.remove('bg-positive');
      options[i].classList.remove('text-white');
      options[i].classList.add('bg-white');
      options[i].classList.add('text-gray-60');
    }

    target.classList.remove('bg-white');
    target.classList.remove('text-gray-60');
    target.classList.add('bg-positive');
    target.classList.add('text-white');
  }

  renderRadioInput(error = false) {
    const {className, children, input, options} = this.props,
      iconOptions = options || children;

    return <div className={`flex items-center ${className || ''}`}>
      <input {...input} type="hidden" id={input.name}/>
      <div id={`options-${input.name}`} className={`flex flex-wrap rounded overflow-hidden border-gray-15 border${error ? ' has-error' : ''}`}>
        {iconOptions.map((option, key) => {
          return <Link
            to="#"
            tabIndex="0"
            role="button"
            aria-label={option.value}
            onClick={(e) => {
              e.preventDefault();
              this.onChange(e);
            }}
            data-option={option.value}
            key={key}
            className={`py-2 px-3 block focus:outline-none focus:shadow-inner ${key < options.length - 1 ? 'border-r border-gray-15 ' : ''}${option.value === input.value ? 'bg-positive text-white' : 'bg-white text-gray-60'}`}
          >
            <Icon className="text-xl block pointer-events-none">{option.icon}</Icon>
          </Link>;
        })
        }
      </div>
      <p id={`label-${input.name}`} className="text-sm text-gray-60 px-4">{this.findLabel(input.value)}</p>
    </div>;
  }

  findLabel(value) {
    const {options} = this.props, {t} = this.context;
    let i = 0, ilen = options.length, label;

    for (; i < ilen; ++i) {
      if (options[i].value === value) {
        label = options[i].label;
      }
    }

    return t(label);
  }

  render() {
    const {meta} = this.props;

    return meta && meta.error && meta.touched ?
      <div>
        {this.renderRadioInput(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderRadioInput();
  }
}

export default IconSelect;
