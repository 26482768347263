import React from 'react';
import Title from '../../../Components/Text/Title';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CastingItem from '../../../Components/ListItems/CastingItem';
import CastingsActions from '../../../Redux/Talent/CastingsRedux';
import I18n from '../../../Utils/I18n';
import Body from '../../../Components/Text/Body';
import Loading from '../../../Components/Loading';

class ProjectsPage extends React.Component {
  static  propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    new_castings: PropTypes.any,
    current_castings: PropTypes.any,
    account: PropTypes.any,
    getNewCastings: PropTypes.func,
    getCurrentCastings: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {account} = this.props;
    this.setState({fetchedProjects: true}, function () {
      this.props.getNewCastings({username: account.username, status: 'new', page: 1, per_page: 100});
      this.props.getCurrentCastings({
        username: account.username,
        status: ['selected', 'audition', 'shortlist', 'hired'],
        page: 1,
        per_page: 100,
      });
    });
  }

  renderCastings(castings) {
    return castings
      ? castings.length
        ? castings.map((casting, key) =>
          <div key={key} className="w-full sm:w-1/2 xl:w-1/3 sm:pr-5 mb-5">
            <CastingItem className="w-full mb-5 h-full flex flex-col" casting={casting} history={this.props.history}/>
          </div>)
        : <Body>{I18n.t('You have no projects yet')}</Body>
      : <Loading/>;
  }

  render() {
    const {new_castings, current_castings} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Title className="pl-6 pb-5 pt-2 text-center lg:text-left">{I18n.t('New Projects')}</Title>

        <div className="sm:flex flex-wrap sm:-mr-5 max-w-6xl">
          {this.renderCastings(new_castings)}
        </div>

        <Title className="pl-6 pb-5 pt-12 text-center lg:text-left">{I18n.t('My projects')}</Title>
        <div className="sm:flex flex-wrap sm:-mr-5 max-w-6xl">
          {this.renderCastings(current_castings)}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    new_castings: state.castings.new_castings ? state.castings.new_castings : [],
    new_castings_fetching: state.castings.fetching,
    new_castings_error: state.castings.error,

    current_castings: state.castings.current_castings ? state.castings.current_castings : [],
    current_castings_fetching: state.castings.fetching,
    current_castings_error: state.castings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewCastings: (obj) => dispatch(CastingsActions.newCastingsRequest(obj)),
    getCurrentCastings: (obj) => dispatch(CastingsActions.currentCastingsRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
