import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../Card';
import Body from '../Text/Body';
import Helper from '../../Utils/Helper';
import TextButton from '../Buttons/TextButton';
import {connect} from 'react-redux';
import I18n from '../../Utils/I18n';

class SubscriptionItem extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    className: PropTypes.string,
    coupon: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    subscription: PropTypes.object,
    deleteSubscription: PropTypes.func,
    deleted: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {cancelDate: null};
  }

  calculateNewPrice(price, discount) {
    if (discount.amount_off) {
      return price - discount.amount_off;
    }

    if (discount.percent_off) {
      const percent = discount.percent_off;
      return price - (price * (percent / 100));
    }
    return price;
  }

  renderPrice() {
    const {subscription, coupon} = this.props,
      calculatedPrice = coupon ? this.calculateNewPrice(subscription.price / 100, coupon) : (!isNaN(subscription.price) ? subscription.price / 100 : subscription.price),
      price = Helper.formatFloatValue(calculatedPrice);

    if (isNaN(price)) {
      return price;
    }

    return `€ ${price}`;
  }

  renderDate() {
    const { account } = this.props;
    if (account ? account.account.subscription !== null : false) {
      const { ends_at } = account.account.subscription;
      return <span className="font-semibold whitespace-no-wrap">{ends_at}</span>;
    }
  }

  render() {
    const {account, className, disabled, onClick, selected, subscription, deleted} = this.props;
    return account
    && account.account.subscription
    && account.account.subscription.stripe_plan === subscription.stripe_id
      ? <div className={`block group${className ? ` ${className}` : ''}`}>
        <Card className="mb-10 px-6 py-5 bg-primary-10">
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex-1">
              <h3 className="text-lg">{subscription.title}</h3>
              <Body>{subscription.description}</Body>
            </div>
            <div className="flex flex-wrap pt-4 w-full sm:w-auto">
              <p className="px-10 text-xl font-light">{this.renderPrice()}</p>
              {deleted ?
                <p className="text-sm max-w-64">{I18n.t('The subscription will be canceled on :date', {date: this.renderDate()})}</p>
                :
                <TextButton onClick={() => this.props.deleteSubscription()} iconLeft icon="times" className="text-gray-40 hover:text-negative">{I18n.t('Cancel subscription')}</TextButton>
              }
            </div>
          </div>
        </Card>
      </div>
      :
      <Link to="#" onClick={onClick} className={`block group${className ? ` ${className}` : ''}${disabled ? ' pointer-events-none' : ''}`}>
        <Card className={`mb-10 px-6 py-5${selected ? ' border border-primary-40 -m-px' : ''}${disabled ? ' shadow-none bg-gray-10 opacity-50' : ''}`}>
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex-1">
              <h3 className="text-lg">{subscription.title}</h3>
              <Body>{subscription.description}</Body>
            </div>
            <div className="flex flex-wrap pt-4 w-full sm:w-auto">
              <p className="pl-10 text-xl font-light">{this.renderPrice()}</p>
            </div>
          </div>
        </Card>
      </Link>
      ;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    coupon: state.coupon.coupon,
    coupon_fetching: state.coupon.fetching,
    coupon_error: state.coupon.error,
  };
};

export default connect(mapStateToProps)(SubscriptionItem);
