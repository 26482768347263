import {Redirect, Route} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import RouteActions from '../../Redux/Route/RouteRedux';
import PropTypes from 'prop-types';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    account: PropTypes.any,
    location: PropTypes.any,
    setLastRoute: PropTypes.func,
    path: PropTypes.any,
  };

  componentDidMount() {
    this.props.setLastRoute(this.props.location.pathname);
  }

  render() {
    const {account, location} = this.props;

    return account
      ? <Route {...this.props} />
      : <Redirect name="Login" to={{pathname: '/login', state: {from: location}, name: 'Login'}}/>;
  }
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLastRoute: (route) => dispatch(RouteActions.setLastRoute(route)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
