import { call, put } from 'redux-saga/effects'
import CouponActions from '../../Redux/Billing/CouponRedux'
import { callApi } from '../CallApiSaga'

export function * submitCoupon (api,{obj}) {
    const apiCall = yield call(api.getCoupon,obj);
    const response = yield call(callApi, apiCall);

    // success?
    if (response.ok) {
        yield put(CouponActions.couponSuccess(response.data,obj.coupon));
    } else {
        yield put(CouponActions.couponFailure(response.data ? response.data: 'WRONG'));
    }
}
