import React from 'react';
import Title from '../../../../Components/Text/Title';
import AddButton from '../../../../Components/Buttons/AddButton';
import RoleItem from '../../../../Components/ListItems/RoleItem';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import ProjectActions from '../../../../Redux/Project/ProjectRedux';
import Loading from '../../../../Components/Loading';

class RolesPage extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
    project: PropTypes.any,
    getProjectId: PropTypes.func,
  };

  componentDidMount() {
    const {project} = this.props.match.params;

    this.props.getProjectId(project);
  }

  render() {
    const {project} = this.props;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
        <Title className="text-xl">{I18n.t('Project roles')}</Title>

        <div className="sm:flex flex-wrap">
          {project
            ? (project.roles
              ? project.roles.map((role, key) => <RoleItem role={role} key={key} className="sm:w-1/2 sm:pr-6 py-3"/>)
              : null)
            : <Loading/>
          }

          <div className="sm:w-1/2 py-3 sm:pr-6">
            {project
              ? <AddButton to={`/agency/projects/${project.id}/project-roles/add/overview`} className="w-full"/>
              : <Loading/>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.project,
    project_fetching: state.project.fetching,
    project_updating: state.project.updating,
    project_error: state.project.error,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);
