import PropTypes from 'prop-types';
import React from 'react';

class Body extends React.Component {
    static propTypes = {
        className: PropTypes.string,
      children: PropTypes.any,
    };

    render() {
        const {className, children} = this.props;

        return (
            <p className={`text-gray-40 text-sm ${className || ''}`}>
              {children}
            </p>
        );
    }
}

export default Body;
