import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  educationsRequest: ['username'],
  educationsSuccess: ['educations'],
  educationsFailure: ['error'],

  clearEducationsState: [],
});

export const EducationsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  educations: null,
  educations_error: null,
  educations_fetching: false,
});

/* ------------- Reducers ------------- */

// Educations request
export const educations_request = (state) => state.merge({educations_fetching: true});

// Educations Types success
export const educations_success = (state, data) => {
  const {educations} = data;
  return state.merge({educations_fetching: false, educations_error: null, educations})
};

export const educations_failure = (state, {error}) => state.merge({
  educations_fetching: false,
  educations: null,
  educations_error: error
});

export const clearEducationsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDUCATIONS_REQUEST]: educations_request,
  [Types.EDUCATIONS_SUCCESS]: educations_success,
  [Types.EDUCATIONS_FAILURE]: educations_failure,

  [Types.CLEAR_EDUCATIONS_STATE]: clearEducationsState,

});
