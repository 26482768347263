import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    experienceTypesRequest: [],
    experienceTypesSuccess: ['experience_types'],
    experienceTypesFailure: ['error'],

    experienceTypeByIdRequest: [],
    experienceTypeByIdSuccess: ['experience_type'],
    experienceTypeByIdFailure: ['error'],

    clearExperienceTypesState:[],
});

export const ExperienceTypesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    experience_types: null,
    experience_type:null,
    error: null,
    fetching: false,
});

/* ------------- Reducers ------------- */

// Experience Types request
export const experience_types_request = (state) => state.merge({ fetching: true });

// Experience Types success
export const experience_types_success = (state, data) => {
    const { experience_types } = data;
    return state.merge({ fetching: false, error: null, experience_types })
};

export const experience_Types_Failure = (state, { error }) => state.merge({ fetching: false, experience_types: null, error });

// Project Type request
export const experience_type_by_id_request = (state) => state.merge({ fetching: true });

// Project Type success
export const experience_type_by_id_success = (state, data) => {
    const { experience_type} = data;
    return state.merge({ fetching: false, error: null, experience_type })
};

export const experience_type_by_id_failure = (state, { error }) => state.merge({ fetching: false, experience_type: null, error });

export const clearExperienceTypesState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.EXPERIENCE_TYPES_REQUEST]: experience_types_request,
    [Types.EXPERIENCE_TYPES_SUCCESS]: experience_types_success,
    [Types.EXPERIENCE_TYPES_FAILURE]: experience_Types_Failure,

    [Types.EXPERIENCE_TYPE_BY_ID_REQUEST]: experience_type_by_id_request,
    [Types.EXPERIENCE_TYPE_BY_ID_SUCCESS]: experience_type_by_id_success,
    [Types.EXPERIENCE_TYPE_BY_ID_FAILURE]: experience_type_by_id_failure,

    [Types.CLEAR_EXPERIENCE_TYPES_STATE]: clearExperienceTypesState,

});
