import {call, put} from 'redux-saga/effects'
import UsersActions from '../../Redux/Account/UsersRedux'
import {callApi} from '../CallApiSaga'

export function* getUsers(api) {
  const apiCall = yield call(api.getUsers);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UsersActions.getUsersSuccess(response.data));
  } else {
    yield put(UsersActions.getUsersFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

export function* getUser(api, {username}) {
  const apiCall = yield call(api.getUser, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UsersActions.getUserSuccess(response.data));
  } else {
    yield put(UsersActions.getUserFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

