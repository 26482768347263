import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import UserNotificationsActions from '../../Redux/Talent/UserNotificationsRedux';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
//Routes
import TalentRoute from '../../Components/Routes/TalentRoute';
import AgencyRoute from '../../Components/Routes/AgencyRoute';
import PrivateRoute from '../Routes/PrivateRoute';
// Pages
import LaunchScreen from '../../Containers/Launch/LaunchScreen';
import Error404 from '../../Containers/Errors/Error404';
// Auth
import LoginPage from '../../Containers/Auth/LoginPage';
import ResetPasswordPage from '../../Containers/Auth/ResetPasswordPage';
import TalentRegisterPage from '../../Containers/Auth/Register/TalentRegisterPage';
import AgencyRegisterPage from '../../Containers/Auth/Register/AgencyRegisterPage';
import TalentRegisterConfirm from '../../Containers/Auth/Register/TalentRegisterConfirm';
import AgencyRegisterConfirm from '../../Containers/Auth/Register/AgencyRegisterConfirm';
import SendResetLinkPage from '../../Containers/Auth/SendResetLinkPage';
import VerifiedPage from '../../Containers/Auth/Register/VerifiedPage';
// Talent
import TalentDashboardPage from '../../Containers/Talent/DashboardPage';
import ProjectsPage from '../../Containers/Talent/Project/ProjectsPage';
import SettingsPage from '../../Containers/Talent/Settings/SettingsPage';
import ProfilePage from '../../Containers/Talent/Profile/ProfilePage';
import NotificationPage from '../../Containers/Common/Notifications/NotificationPage';
import ProjectPage from '../../Containers/Talent/Project/ProjectPage';
import PortfolioPage from '../../Containers/Talent/Portfolio/PortfolioPage';
import PortfoliosPage from '../../Containers/Talent/Portfolio/PortfoliosPage';
import AddPortfolioPage from '../../Containers/Talent/Portfolio/AddPortfolioPage';
// Agency
import AgencyDashboardPage from '../../Containers/Agency/DashboardPage';
import AgencyProjectsPage from '../../Containers/Agency/Project/ProjectsPage';
import AgencyProjectPage from '../../Containers/Agency/Project/ProjectPage';
import AgencySettingsPage from '../../Containers/Agency/Settings/SettingsPage';
import AddProjectPage from '../../Containers/Agency/Project/AddProjectPage';
import StaffPage from '../../Containers/Agency/Staff/StaffPage';
import AgencyInfoPage from '../../Containers/Agency/AgencyInfo/AgencyInfoPage';
import RolePage from '../../Containers/Agency/Role/RolePage';
import PeoplePage from '../../Containers/Agency/People/PeoplePage';
import InvitationRegisterPage from '../../Containers/Auth/Register/InvitationRegisterPage';
import InvitationRegisterConfirm from '../../Containers/Auth/Register/InvitationRegisterConfirm';
import StaffUserPage from '../../Containers/Agency/Staff/StaffUserPage';
import AddRolePage from '../../Containers/Agency/Role/AddRolePage';
import CastingPage from '../../Containers/Agency/Casting/CastingPage';


class RoutingComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    getUnreadNotifications: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    location: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.account && this.props.account.username) {
        this.props.getUnreadNotifications(this.props.account.username);
      }
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" params={{name: 'LaunchScreen'}} component={LaunchScreen}/>

        {/* // TODO: CHECK THE ACCOUNT AND REDIRECT */}
        <Route exact path="/dashboard" render={() => {
        }}/>


        {/*Redirects for coming from notifications*/}
        <Route exact path="/agency/projects/:project/roles/:role" render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/project-roles/${match.params.role}`}/>}/>
        <Route exact path="/agency/projects/:project/roles/:role/castings/:casting" render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/project-roles/${match.params.role}/castings/${match.params.casting}`}/>}/>

        <Route exact path="/projects/castings/:casting" render={({match}) =>
          <Redirect to={`/talent/projects/${match.params.casting}`}/>}/>
        <Route exact path="/projects" render={() =>
          <Redirect to={`/talent/projects/`}/>}/>

        {/* COMMON */}
        <PrivateRoute exact path="/notifications" params={{name: 'Notifications'}} render={() =>
          <Redirect to="/notifications/new"/>}/>
        <PrivateRoute exact path="/notifications/new" params={{name: 'Notifications'}} component={NotificationPage}/>
        <PrivateRoute exact path="/notifications/read" params={{name: 'Notifications'}} component={NotificationPage}/>

        {/*TALENT*/}
        <TalentRoute exact path="/talent/dashboard" params={{name: 'Dashboard'}} component={TalentDashboardPage}/>

        <TalentRoute exact path="/talent/projects" params={{name: 'Projects'}} component={ProjectsPage}/>
        <TalentRoute exact path="/talent/projects/:casting" params={{name: 'Projects'}} render={({match}) =>
          <Redirect to={`/talent/projects/${match.params.casting}/overview`}/>}/>
        <TalentRoute exact path="/talent/projects/:casting/overview" params={{name: 'Projects'}} component={ProjectPage}/>
        <TalentRoute exact path="/talent/projects/:casting/your-role" params={{name: 'Projects'}} component={ProjectPage}/>
        <TalentRoute exact path="/talent/projects/:casting/audition" params={{name: 'Projects'}} component={ProjectPage}/>
        <TalentRoute exact path="/talent/projects/:casting/chatroom" params={{name: 'Projects'}} component={ProjectPage}/>

        <TalentRoute exact path="/talent/experiences" params={{name: 'Portfolio'}} component={PortfoliosPage}/>
        <TalentRoute exact path="/talent/experiences/add" params={{name: 'Portfolio'}} render={() =>
          <Redirect to="/talent/experiences/add/overview"/>}/>
        <TalentRoute exact path="/talent/experiences/add/overview" params={{name: 'Portfolio'}} component={AddPortfolioPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences" params={{name: 'Portfolio'}} render={({match}) =>
          <Redirect to={`/talent/experiences/${match.params.experiences}/overview`}/>}/>
        <TalentRoute exact path="/talent/experiences/:experiences/overview" params={{name: 'Portfolio'}} component={PortfolioPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/media" params={{name: 'Portfolio'}} component={PortfolioPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/links" params={{name: 'Portfolio'}} component={PortfolioPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/delete" params={{name: 'Portfolio'}} component={PortfolioPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/overview/edit" params={{name: 'Portfolio'}} component={PortfolioPage}/>

        <TalentRoute exact path="/talent/profile" params={{name: 'Profile'}} render={() =>
          <Redirect to="/talent/profile/personalia"/>}/>
        <TalentRoute exact path="/talent/profile/personalia" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/about-me" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/photos" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/appearance" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/skills" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/languages" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/drivers-license" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/social-media" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/education" params={{name: 'Profile'}} component={ProfilePage}/>
        <TalentRoute exact path="/talent/profile/profile-layout" params={{name: 'Profile'}} component={ProfilePage}/>

        <TalentRoute exact path="/talent/settings" params={{name: 'Settings'}} render={() =>
          <Redirect to="/talent/settings/username"/>}/>
        <TalentRoute exact path="/talent/settings/username" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/password" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/subscription" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/subscription/:planId/checkout" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/billing-details" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/invoices" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/notifications" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/visibility" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/language" params={{name: 'Settings'}} component={SettingsPage}/>
        <TalentRoute exact path="/talent/settings/delete" params={{name: 'Settings'}} component={SettingsPage}/>

        {/*AGENCY*/}
        <AgencyRoute exact path="/agency/dashboard" params={{name: 'Dashboard'}} component={AgencyDashboardPage}/>

        <AgencyRoute exact path="/agency/projects" params={{name: 'Projects'}} component={AgencyProjectsPage}/>
        <AgencyRoute exact path="/agency/projects/add" params={{name: 'Add a new project'}} render={() =>
          <Redirect to="/agency/projects/add/overview"/>}/>
        <AgencyRoute exact path="/agency/projects/add/overview" params={{name: 'Add a new project'}} component={AddProjectPage}/>

        <AgencyRoute exact path="/agency/projects/:project" params={{name: 'Add a new project'}} render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/overview`}/>}/>
        <AgencyRoute exact path="/agency/projects/:project/overview" params={{name: 'Add a new project'}} component={AgencyProjectPage}/>
        <AgencyRoute exact path="/agency/projects/:project/extra-info" params={{name: 'Add a new project'}} component={AgencyProjectPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles" params={{name: 'Add a new project'}} component={AgencyProjectPage}/>
        <AgencyRoute exact path="/agency/projects/:project/delete" params={{name: 'Add a new project'}} component={AgencyProjectPage}/>

        <AgencyRoute exact path="/agency/projects/:project/project-roles/add/overview" params={{name: 'Project title'}} component={AddRolePage}/>

        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role" params={{name: 'Project title'}} render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/project-roles/${match.params.role}/overview`}/>}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/overview" params={{name: 'Project title'}} component={RolePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/overview/edit" params={{name: 'Project title'}} component={RolePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/briefing" params={{name: 'Project title'}} component={RolePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/delete" params={{name: 'Project title'}} component={RolePage}/>

        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/castings/:casting" params={{name: 'Talent'}} render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/project-roles/${match.params.role}/castings/${match.params.casting}/overview`}/>}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/castings/:casting/overview" params={{name: 'Talent'}} component={CastingPage}/>

        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people" render={({match}) =>
          <Redirect to={`/agency/projects/${match.params.project}/project-roles/${match.params.role}/people/search`}/>}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/search" params={{name: 'Project title'}} component={PeoplePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/contacted" params={{name: 'Project title'}} component={PeoplePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/interested" params={{name: 'Project title'}} component={PeoplePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/audition" params={{name: 'Project title'}} component={PeoplePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/shortlist" params={{name: 'Project title'}} component={PeoplePage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/hired" params={{name: 'Project title'}} component={PeoplePage}/>

        <AgencyRoute exact path="/agency/employees" params={{name: 'Staff members'}} component={StaffPage}/>
        <AgencyRoute exact path="/agency/employees/:username" params={{name: 'Staff member'}} component={StaffUserPage}/>

        <AgencyRoute exact path="/agency/agency-info" params={{name: 'Agency information'}} component={AgencyInfoPage}/>

        <AgencyRoute exact path="/agency/settings" params={{name: 'Settings'}} render={() =>
          <Redirect to="/agency/settings/username"/>}/>
        <AgencyRoute exact path="/agency/settings/username" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/password" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/subscription" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/invoices" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/notifications" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/visibility" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/language" params={{name: 'Settings'}} component={AgencySettingsPage}/>
        <AgencyRoute exact path="/agency/settings/delete" params={{name: 'Settings'}} component={AgencySettingsPage}/>

        <Route exact path="/login" params={{name: 'Login'}} component={LoginPage}/>
        <Route exact path="/linkedin" params={{name: 'Login'}} component={LinkedInPopUp}/>
        <Route exact path="/forgot-password" params={{name: 'Forgot Password'}} component={SendResetLinkPage}/>
        <Route exact path="/password/reset/:token" params={{name: 'Reset Password'}} component={ResetPasswordPage}/>
        <Route exact path="/register/talent" params={{name: 'Register'}} component={TalentRegisterPage}/>
        <Route exact path="/register/talent/verify" params={{name: 'Confirm'}} component={TalentRegisterConfirm}/>
        <Route exact path="/register/agency" params={{name: 'Register'}} component={AgencyRegisterPage}/>
        <Route exact path="/register/agency/activation" params={{name: 'Activate'}} component={AgencyRegisterConfirm}/>
        <Route exact path="/register/invitation/thanks" params={{name: 'Register'}} component={InvitationRegisterConfirm}/>
        <Route exact path="/verified" params={{name: 'Verified'}} component={VerifiedPage}/>
        <Route exact path="/register/invitation/:invitation" params={{name: 'Register'}} component={InvitationRegisterPage}/>


        <Route params={{name: 'Page not found'}} component={Error404}/>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    unread_notifications: state.user_notifications.unread_notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUnreadNotifications: (username) => dispatch(UserNotificationsActions.unreadNotificationsRequest(username)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RoutingComponent);
