import React from 'react';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Title from '../../../../Components/Text/Title';
import Select from '../../../../Components/Form/Select';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import {required} from '../../../../Validators/Form/Form';
import TranslationActions from '../../../../Redux/Translation/TranslationRedux';
import I18n from '../../../../Utils/I18n';

class LanguagePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
    locales: PropTypes.any,
    getLocales: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.getLocales();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true, saved: false}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  render() {
    const {account, locales} = this.props;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form
          onSubmit={this.onSubmit}
          validateOnBlur
          initialValues={{
            locale: account.locale,
          }}
        >
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="flex-1">
              <Title className="text-xl pb-2">{I18n.t('Language')}</Title>

              <Field name="locale" validate={required}>
                {({input, meta}) => (
                  <div className="flex-1 pr-5">
                    <Label input={input}>{I18n.t('Change your language', null, true)}</Label>
                    <Select input={input} meta={meta} className="w-full max-w-sm">
                      {locales && Object.keys(locales).map((item, key) => {
                        return <option key={key} value={item}>{locales[item]}</option>;
                      })}
                    </Select>
                  </div>
                )}
              </Field>

              <div className="mt-10 mb-3">
                <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('Change your language')}
                </FilledButton>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
    locales: state.translations.locales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
    getLocales: () => dispatch(TranslationActions.getLocalesRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage);
