import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AgencyProjectRoleRoutingComponent from '../../../Components/Routing/Agency/AgencyProjectRoleRoutingComponent';
import ProjectActions from '../../../Redux/Project/ProjectRedux';
import I18n from '../../../Utils/I18n';

class AddRolePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getProjectId: PropTypes.func,
    casting: PropTypes.any,
    project: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {project} = this.props.match.params;

    this.props.getProjectId(project);
  }

  render() {
    const {project} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible">
          <div className="flex w-full">
            <SubNav>
              {project &&
              <SubNavItem to={`/agency/projects/${project.id}`} noActiveStyle icon="chevron-left" className="-mt-1">{I18n.t('Project details')}</SubNavItem>}

              <hr className="m-3 sm:mr-5 ml-0 border-0 h-px bg-gray-10"/>

              {project &&
              <SubNavItem to={`/agency/projects/${project.id}/project-roles/add/overview`} icon="info-circle">{I18n.t('Overview')}</SubNavItem>}
            </SubNav>

            <AgencyProjectRoleRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    project: state.project.project,
    project_fetching: state.project.fetching,
    project_error: state.project.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRolePage);
