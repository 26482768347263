import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import I18n from '../../Utils/I18n';
import Card from '../Card';
import uploadcare from 'uploadcare-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';

class AddMultipleMedia extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    uploadable: PropTypes.bool,
    uploadedFile: PropTypes.func,
    disabled: PropTypes.bool,
    files: PropTypes.any,
  };

  selectUploadCareFile = async (uploadedFile, files) => {
    uploadcare.start({publicKey: process.env.UPLOADCARE_PUBLIC_KEY, effects: 'blur,sharp,grayscale'});
    uploadcare.registerTab('preview', uploadcareTabEffects);
    try {
      const file = uploadcare.openDialog(files ? await uploadcare.loadFileGroup(files) : null,
        {imagesOnly: false, crop: '', rotate: '', multiple: true},
      );

      file.done(
        groupInstance => {
          let groupPromise = groupInstance.promise();

          groupPromise.done(function (fileGroupInfo) {
            uploadedFile(fileGroupInfo);
          });

          groupPromise.fail(function () {
            alert('Upload failed');
          });
        },
      );
    } catch (e) {
      //
    }
  };

  render() {
    const {className, disabled, uploadedFile, files} = this.props;

    return <div className={disabled ? 'opacity-25' : ''}>
      <a onClick={() => this.selectUploadCareFile(uploadedFile, files)} className="group cursor-pointer">
        <Card className={`flex justify-center items-center p-6${className ? ` ${className}` : ''}`}>
          <Icon className="text-positive text-4xl">plus</Icon>
          <p className="absolute h-0 opacity-0">{I18n.t('Add')}</p>
        </Card>
      </a>
      <input type="file" name="file" id="file" className="appearance-none hidden" onChange={uploadedFile}/>
    </div>;
  }
}

export default AddMultipleMedia;
