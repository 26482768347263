import React from 'react';
import SocialLogin from 'react-social-login';
import PropTypes from 'prop-types';
import FilledButton from './FilledButton';

const FacebookButton = ({children, triggerLogin, ...props}) => (
  <FilledButton icon={['fab', 'facebook-square']} className="rounded mb-5 text-white bg-facebook" onClick={triggerLogin} {...props}>
    {children}
  </FilledButton>
);

FacebookButton.propTypes = {
  children: PropTypes.any,
  triggerLogin: PropTypes.any,
};

export default SocialLogin(FacebookButton);
