import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  userUpdateRequest: ['obj'],
  userUpdateSuccess: ['account'],
  userUpdateFailure: ['error'],

  userDeleteRequest: ['username'],
  userDeleteSuccess: [],
  userDeleteFailure: ['error'],

  clearUserState: [],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: null,
  updating: false,
  update_success: false,
  deleting: false,
  delete_success: false,
});

/* ------------- Reducers ------------- */


export const update_user_request = (state) => {
  return state.merge({updating: true, error: null, update_success: false});
};

export const update_user_success = (state) => {
  return state.merge({updating: false, error: null, update_success: true});
};

export const update_user_failure = (state, {error}) => {
  return state.merge({updating: false, error: error, update_success: false})
};

export const delete_user_request = (state) => state.merge({deleting: true, delete_success: false});

export const delete_user_success = (state) => {
  return state.merge({deleting: false, delete_success: true});
};

export const delete_user_failure = (state, {error}) => state.merge({
  deleting: false,
  error: error,
  delete_success: false
});

export const clear_user_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_UPDATE_REQUEST]: update_user_request,
  [Types.USER_UPDATE_SUCCESS]: update_user_success,
  [Types.USER_UPDATE_FAILURE]: update_user_failure,

  [Types.USER_DELETE_REQUEST]: delete_user_request,
  [Types.USER_DELETE_SUCCESS]: delete_user_success,
  [Types.USER_DELETE_FAILURE]: delete_user_failure,

  [Types.CLEAR_USER_STATE]: clear_user_state,

});

