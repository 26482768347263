// CheckoutForm.js
import React from 'react';
import AccountActions from '../../Redux/Account/AccountRedux';
import CardActions from '../../Redux/Billing/CardRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CardElement} from 'react-stripe-elements';
import Title from '../Text/Title';
import FilledButton from '../Buttons/FilledButton';
import I18n from '../../Utils/I18n';

class BillingDetailsForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    match: PropTypes.any,
    stripe: PropTypes.any,
    getAccount: PropTypes.func,
    updateCard: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    // Within the context of `Elements`, this call to createPaymentMethod knows from which Element to
    // create the PaymentMethod, since there's only one in this group.
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    this.props.stripe.createToken({type: 'card'}).then((response) => {
      if(response.token){
        this.setState({submitSubscription: true}, function () {
          this.props.updateCard({token: response.token.id});
        });
      }
    }, () => {
      //
    });
  };

  render() {
    const {plan} = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <Title className="pb-3">{I18n.t('Billing details')}</Title>
          <CardElement className="input-field" hidePostalCode={true} style={{base: {fontSize: '16px'}}}/>
        </label>
        <div className="flex mt-10">
          <FilledButton className="bg-secondary-40 rounded text-white" type="submit">{I18n.t(plan ? 'Checkout' : 'Update card')}</FilledButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
    updateCard: (cardObj) => dispatch(CardActions.updateCardRequest(cardObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsForm);
