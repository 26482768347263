import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  unreadNotificationsRequest: ['username'],
  unreadNotificationsSuccess: ['notifications'],
  unreadNotificationsFailure: ['error'],

  readNotificationsRequest: ['username'],
  readNotificationsSuccess: ['notifications'],
  readNotificationsFailure: ['error'],

  markReadRequest: ['username'],
  markReadSuccess: [],
  markReadFailure: ['error'],


  clearNotificationsState: [],
});

export const UserNotificationsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  unread_notifications: null,
  unread_notifications_error: null,
  unread_notifications_fetching: false,

  read_notifications: null,
  read_notifications_error: null,
  read_notifications_fetching: false,

  mark_read_success: false,
  mark_read_error: null,
  mark_read_posting: false,
});

/* ------------- Reducers ------------- */

export const unread_notifications_request = (state) => state.merge({unread_notifications_fetching: true});

export const unread_notifications_success = (state, data) => {
  const {notifications} = data;
  return state.merge({
    unread_notifications_fetching: false,
    unread_notifications_error: null,
    unread_notifications: notifications
  })
};

export const unread_notifications_failure = (state, {error}) => state.merge({
  unread_notifications_fetching: false,
  unread_notifications: null,
  unread_notifications_error: error
});

export const read_notifications_request = (state) => state.merge({read_notifications_fetching: true});

export const read_notifications_success = (state, data) => {
  const {notifications} = data;
  return state.merge({
    read_notifications_fetching: false,
    read_notifications_error: null,
    read_notifications: notifications
  })
};

export const read_notifications_failure = (state, {error}) => state.merge({
  read_notifications_fetching: false,
  read_notifications: null,
  read_notifications_error: error
});

export const mark_read_request = (state) => state.merge({
  mark_read_success: false,
  mark_read_error: null,
  mark_read_posting: true,
});

export const mark_read_success = (state) => {
  return state.merge({
    mark_read_success: true,
    mark_read_error: null,
    mark_read_posting: false,
    read_notifications: []
  })
};

export const mark_read_failure = (state, {error}) => state.merge({
  mark_read_success: false,
  mark_read_posting: false,
  mark_read_error: error
});


export const clearNotificationsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UNREAD_NOTIFICATIONS_REQUEST]: unread_notifications_request,
  [Types.UNREAD_NOTIFICATIONS_SUCCESS]: unread_notifications_success,
  [Types.UNREAD_NOTIFICATIONS_FAILURE]: unread_notifications_failure,

  [Types.READ_NOTIFICATIONS_REQUEST]: read_notifications_request,
  [Types.READ_NOTIFICATIONS_SUCCESS]: read_notifications_success,
  [Types.READ_NOTIFICATIONS_FAILURE]: read_notifications_failure,

  [Types.MARK_READ_REQUEST]: mark_read_request,
  [Types.MARK_READ_SUCCESS]: mark_read_success,
  [Types.MARK_READ_FAILURE]: mark_read_failure,

  [Types.CLEAR_NOTIFICATIONS_STATE]: clearNotificationsState,

});
