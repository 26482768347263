import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  skillsetCategoriesRequest: [],
  skillsetCategoriesSuccess: ['skillset_categories'],
  skillsetCategoriesFailure: ['error'],

  skillsetCategoryByIdRequest: [],
  skillsetCategoryByIdSuccess: ['skillset_category'],
  skillsetCategoryByIdFailure: ['error'],

  clearSkillsetCategoriesState: [],
});

export const SkillsetCategoriesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  skillset_categories: null,
  skillset_category: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// Skillset Categories request
export const skillset_categories_request = (state) => state.merge({fetching: true});

// Skillset Categories success
export const skillse_categories_success = (state, data) => {
  const {skillset_categories} = data;
  return state.merge({fetching: false, error: null, skillset_categories})
};

export const skillset_categories_failure = (state, {error}) => state.merge({
  fetching: false,
  skillset_categories: null,
  error
});

// Skillset Category request
export const skillsetCategoryById_request = (state) => state.merge({fetching: true});

// Skillset Category success
export const skillsetCategoryById_success = (state, data) => {
  const {skillset_category} = data;
  return state.merge({fetching: false, error: null, skillset_category})
};

export const skillsetCategoryById_failure = (state, {error}) => state.merge({
  fetching: false,
  skillset_category: null,
  error
});

export const clearSkillsetCategoriesState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SKILLSET_CATEGORIES_REQUEST]: skillset_categories_request,
  [Types.SKILLSET_CATEGORIES_SUCCESS]: skillse_categories_success,
  [Types.SKILLSET_CATEGORIES_FAILURE]: skillset_categories_failure,

  [Types.SKILLSET_CATEGORY_BY_ID_REQUEST]: skillsetCategoryById_request,
  [Types.SKILLSET_CATEGORY_BY_ID_SUCCESS]: skillsetCategoryById_success,
  [Types.SKILLSET_CATEGORY_BY_ID_FAILURE]: skillsetCategoryById_failure,

  [Types.CLEAR_SKILLSET_CATEGORIES_STATE]: clearSkillsetCategoriesState,

});
