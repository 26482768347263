import PropTypes from 'prop-types';
import React from 'react';
import Title from '../Text/Title';
import Body from '../Text/Body';
import TextButton from '../Buttons/TextButton';
import Card from '../Card';
import I18n from '../../Utils/I18n';

class PortfolioItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    experience: PropTypes.any,
  };

  render() {
    const {className, experience} = this.props;

    return (
      <Card className={`pt-8 h-full -mb-px ${className || ''}`}>
        <div className="px-6 flex flex-col h-full">
          <Title className="pb-3">{experience.company}</Title>
          <Body className="leading-relaxed max-h-16 overflow-hidden">{experience.description}</Body>
          <div className="w-full flex justify-between items-bottom py-8 mt-auto">
            <TextButton to={`/talent/experiences/${experience.id}`} icon="chevron-right" className="mt-auto ml-auto text-gray-40 text-center">{I18n.t('Portfolio details')}</TextButton>
          </div>
        </div>
      </Card>
    );
  }
}

export default PortfolioItem;
