import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  createInvitationRequest: ['email'],
  createInvitationSuccess: [],
  createInvitationFailure: ['error'],

  getInvitationsRequest: [],
  getInvitationsSuccess: ['data'],
  getInvitationsFailure: ['error'],

  deleteInvitationRequest: ['invitation_code'],
  deleteInvitationSuccess: [],
  deleteInvitationFailure: ['error'],

  clearProjectState: [],

});

export const InvitationsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  invitations: null,
  fetching: false,
  get_error: null,
  created: false,
  creating: false,
  create_error: null,
  deleted: false,
  deleting: false,
  delete_error: null,
});

/* ------------- Reducers ------------- */

export const create_invitation_request = (state) => state.merge({
  creating: true,
  created: false,
  create_error: null
});

export const create_invitation_success = (state) => {
  return state.merge({
    creating: false,
    created: true,
    create_error: null
  })
};

export const create_invitation_failure = (state, {error}) => state.merge({
  creating: false,
  created: false,
  create_error: error
});

export const get_invitations_request = (state) => state.merge({
  fetching: true,
  get_error: null,
});

export const get_invitations_success = (state, {data}) => {
  return state.merge({
    invitations: data.data,
    fetching: false,
    get_error: null,
  })
};

export const get_invitations_failure = (state, {error}) => state.merge({
  fetching: false,
  get_error: error,
});

export const delete_invitation_request = (state) => {
  return state.merge({
    deleting: true,
    deleted: false,
    delete_error: null
  });
};

export const delete_invitation_success = (state) => {
  return state.merge({
    deleting: false,
    deleted: true,
    delete_error: null
  })
};

export const delete_invitation_failure = (state, {error}) => state.merge({
  deleting: false,
  deleted: false,
  delete_error: error
});


export const clear_project_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_INVITATION_REQUEST]: create_invitation_request,
  [Types.CREATE_INVITATION_SUCCESS]: create_invitation_success,
  [Types.CREATE_INVITATION_FAILURE]: create_invitation_failure,

  [Types.GET_INVITATIONS_REQUEST]: get_invitations_request,
  [Types.GET_INVITATIONS_SUCCESS]: get_invitations_success,
  [Types.GET_INVITATIONS_FAILURE]: get_invitations_failure,

  [Types.DELETE_INVITATION_REQUEST]: delete_invitation_request,
  [Types.DELETE_INVITATION_SUCCESS]: delete_invitation_success,
  [Types.DELETE_INVITATION_FAILURE]: delete_invitation_failure,

  [Types.CLEAR_PROJECT_STATE]: clear_project_state,
});
