/* eslint-disable import/default */
// Keep at top of imports
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import createStore, { history } from './Redux'
import RootContainer from './Containers/Root/RootContainer';
import './Styles/styles.css'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./favicon.ico'); // Tell webpack to load favicon.ico

const store = createStore();

render(
  <AppContainer>
    <RootContainer store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./Containers/Root/RootContainer', () => {
    const NewRoot = require('./Containers/Root/RootContainer');
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
