import React from 'react';
import {connect} from 'react-redux';
import UsersActions from '../../../Redux/Account/UsersRedux';
import UserActions from '../../../Redux/Account/UserRedux';
import ProjectsActions from '../../../Redux/Projects/ProjectsRedux';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import Title from '../../../Components/Text/Title';
import Body from '../../../Components/Text/Body';
import Checkbox from '../../../Components/Form/Checkbox';
import Icon from '../../../Components/Icon';
import FilledButton from '../../../Components/Buttons/FilledButton';
import Card from '../../../Components/Card';
import I18n from '../../../Utils/I18n';

class StaffUserPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getUser: PropTypes.func,
    getProjects: PropTypes.func,
    updatePermissions: PropTypes.func,
    deleteUser: PropTypes.func,
    user: PropTypes.any,
    projects: PropTypes.any,
    error: PropTypes.any,
    updating: PropTypes.bool,
    update_success: PropTypes.bool,
    deleting: PropTypes.bool,
    delete_success: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      deleting: false,
      saved: false,
    };
  }

  componentDidMount() {
    const {username} = this.props.match.params;

    this.props.getUser(username);
    this.props.getProjects();
  }

  componentDidUpdate(prevProps) {
    //update permissions
    if (!this.props.updating) {
      if ((this.props.update_success !== prevProps.update_success) && this.state.updating) {
        this.setState({updating: false, saved: true}, function () {
        });
      } else if ((this.props.error !== prevProps.error) && this.state.updating) {
        this.setState({updating: false, saved: false});
      }
    }
    //delete permissions
    if (!this.props.deleting) {
      if ((this.props.delete_success !== prevProps.delete_success) && this.state.deleting) {
        this.setState({deleting: false}, function () {
          this.props.history.push('/agency/employees');
        });
      } else if ((this.props.error !== prevProps.error) && this.state.deleting) {
        this.setState({deleting: false});
      }
    }
  }

  onSubmit = (values) => {
    const permissions = Object.keys(values).filter(function (x) {
      return values[x] === true;
    });
    const obj = {
      permissions: permissions,
      username: this.props.user.username,
    };
    this.setState({updating: true, saved: false}, function () {
      this.props.updatePermissions(obj);
    });
  };

  convertValuesToInitialValues = (values) => {
    let obj = {};
    values.forEach((item) => {
      obj[item] = true;
    });
    return obj;
  };

  onDelete = (e) => {
    e.preventDefault();
    if (confirm('Are you sure you want to delete this user?')) {
      this.setState({deleting: true}, function () {
        this.props.deleteUser(this.props.user.username);
      });
    }
  };

  render() {
    const {user, projects} = this.props;
    const initialValues = user ? this.convertValuesToInitialValues(user.permissions) : {};
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">

          <Form
            onSubmit={this.onSubmit}
            validateOnBlur
            initialValues={initialValues}
          >
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit} className="flex-1">
                <Title>{user && user.email}</Title>
                <h3 className="text-sm text-gray-40 pb-8 font-semibold">{I18n.t('Projects access', null, true)}</h3>

                <div className="flex items-center max-w-3xl">
                  <div className="flex-1">
                    <h4 className="py-1 pr-5">{I18n.t('all projects', null, false, true)}</h4>
                  </div>

                  <Field name="view_all_projects" type="checkbox">
                    {({input}) => (
                      <div className="pr-5">
                        <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">eye</Icon></Checkbox>
                      </div>
                    )}
                  </Field>

                  <Field name="edit_all_projects" type="checkbox">
                    {({input}) => (
                      <div>
                        <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">edit</Icon></Checkbox>
                      </div>
                    )}
                  </Field>
                </div>

                <hr className="my-10 mx-3 sm:mr-5 ml-0 border-0 h-px bg-gray-10 max-w-3xl"/>

                {projects && projects.map(
                  function (project, key) {
                    return (
                      <div key={key} className="flex max-w-3xl pb-3 mb-5">
                        <div className="flex-1">
                          <h3 className="py-1 pr-5">{project.title}</h3>
                          <Body>{project.company}</Body>
                        </div>

                        <Field name={`view_project_${project.id}`} type="checkbox">
                          {({input}) => (
                            <div className="pr-5">
                              <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">eye</Icon></Checkbox>
                            </div>
                          )}
                        </Field>

                        <Field name={`edit_project_${project.id}`} type="checkbox">
                          {({input}) => (
                            <div>
                              <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">edit</Icon></Checkbox>
                            </div>
                          )}
                        </Field>
                      </div>
                    );
                  },
                )}

                <div className="flex flex-wrap justify-between mt-10 -mr-2 max-w-3xl mb-3">
                  <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                    {I18n.t('save permissions', null, false, true)}
                  </FilledButton>

                  <FilledButton onClick={this.onDelete} icon="trash" className="rounded text-gray-40 hover:text-negative">
                    {I18n.t('Delete employee')}
                  </FilledButton>
                </div>
              </form>
            )}
          </Form>

        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    projects: state.projects.projects,
    error: state.user.error,
    updating: state.user.updating,
    update_success: state.user.update_success,
    deleting: state.user.deleting,
    delete_success: state.user.delete_success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (username) => dispatch(UsersActions.getUserRequest(username)),
    getProjects: () => dispatch(ProjectsActions.projectsRequest()),
    updatePermissions: (obj) => dispatch(UserActions.userUpdateRequest(obj)),
    deleteUser: (username) => dispatch(UserActions.userDeleteRequest(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffUserPage);
