import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SettingsRoutingComponent from '../../../Components/Routing/SettingsRoutingComponent';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import I18n from '../../../Utils/I18n';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class SettingsPage extends React.Component {
  static propTypes = {
    account: PropTypes.object,
  };

  render() {
    const {account} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to="/talent/settings/username" icon="user">{I18n.t('Username')}</SubNavItem>
              <SubNavItem to="/talent/settings/password" icon="shield-alt">{I18n.t('Password')}</SubNavItem>
              <SubNavItem to="/talent/settings/subscription" icon="handshake">{I18n.t('Subscription')}</SubNavItem>
              {account.subscription !== null
                ? <SubNavItem to="/talent/settings/billing-details" icon="credit-card">{I18n.t('Billing details')}</SubNavItem>
                : null
              }
              <SubNavItem to="/talent/settings/invoices" icon="file-invoice">{I18n.t('Invoices')}</SubNavItem>
              <SubNavItem to="/talent/settings/notifications" icon="bell">{I18n.t('Notifications')}</SubNavItem>
              <SubNavItem to="/talent/settings/visibility" icon="eye">{I18n.t('Visibility')}</SubNavItem>
              <SubNavItem to="/talent/settings/language" icon="globe-europe">{I18n.t('Language')}</SubNavItem>
              <SubNavItem to="/talent/settings/delete" icon="trash-alt">{I18n.t('delete account', null, false, true)}</SubNavItem>
            </SubNav>

            <SettingsRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

export default connect(mapStateToProps)(SettingsPage);
