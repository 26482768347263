import React from 'react';
import Card from '../../../Components/Card';
import I18n from '../../../Utils/I18n';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Helper from '../../../Utils/Helper';

class VerifiedPage extends React.Component {
    static contextTypes = {
        t: PropTypes.func,
    };

    render() {
        const {t} = this.context;
        return (
            <div className="flex justify-center px-4">

                <Card className="w-full max-w-5xl mx-4 p-16 mt-24">
                    <h2 className="font-light text-primary-80 text-2xl text-center pb-6">{I18n.t('Registration successful!')}</h2>

                    <Link to={'/login'} className="block font-light text-center text-lg max-w-md mx-auto">
                        {I18n.t('Please :action to continue.', {action: Helper.makeSemibold(t('login'))})}
                    </Link>
                </Card>

            </div>
        );
    }
}

export default VerifiedPage;
