import {call, put} from 'redux-saga/effects';
import PropertyTypeActions, {
  propertyTypeByIdFailure,
  propertyTypeByIdSuccess,
  propertyTypesFailure,
} from '../../Redux/PropertyTypes/PropertyTypesRedux';
import {callApi} from '../CallApiSaga';

export function* getPropertyTypes(api) {
  const apiCall = yield call(api.getPropertyTypes);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(PropertyTypeActions.propertyTypesSuccess(response.data.data));
  } else {
    yield put(propertyTypesFailure('WRONG'));
  }
}

export function* getPropertyTypeById(api, {id}) {
  const apiCall = yield call(api.getPropertyTypeById, id);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(propertyTypeByIdSuccess(response.data));
  } else {
    yield put(propertyTypeByIdFailure('WRONG'));
  }
}
