import { call, put } from 'redux-saga/effects'
import ProjectActions from '../../Redux/Project/ProjectRedux'
import { callApi } from '../CallApiSaga'

export function returnError(response) {
    return response.data ? (response.data.message ? response.data : {message:'Error',status:500}) : {message:'Error',status:500};
}


export function * getProjectById (api,{id}) {
    const apiCall = yield call(api.getProjectById,id);
    const response = yield call(callApi, apiCall);
    // success?
    if (response.ok) {
        yield put(ProjectActions.projectByIdSuccess(response.data));
    } else {
        yield put(ProjectActions.projectByIdFailure('WRONG'))
    }
}

export function * createProject (api,{obj}){
    const apiCall = yield call(api.createProject,obj);
    const response = yield call(callApi, apiCall);
    // success?

    if (response.ok) {
        yield put(ProjectActions.createProjectSuccess(response.data));
    } else {
        yield put(ProjectActions.createProjectFailure(returnError(response)));
    }
}

export function * updateProject (api,{id,obj}){
    const apiCall = yield call(api.updateProject,id, obj);
    const response = yield call(callApi, apiCall);
    // success?

    if (response.ok) {
        yield put(ProjectActions.updateProjectSuccess(response.data));
    } else {
        yield put(ProjectActions.updateProjectFailure(returnError(response)));
    }
}

export function * deleteProject (api,{id}){
    const apiCall = yield call(api.deleteProject,id);
    const response = yield call(callApi, apiCall);
    // success?

    if (response.ok) {
        yield put(ProjectActions.deleteProjectSuccess(null));
    } else {
        yield put(ProjectActions.deleteProjectFailure(returnError(response)));
    }
}
