import PropTypes from 'prop-types';
import React from 'react';
import Select from '../Form/Select';
import {connect} from 'react-redux';
import TranslationActions from '../../Redux/Translation/TranslationRedux';
import I18n from '../../Utils/I18n';

class LanguageSwitch extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    getLocales: PropTypes.func,
    setLocale: PropTypes.func,
    locales: PropTypes.any,
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getLocales();
  }

  onSelectLanguage = (e) => {
    this.props.setLocale(e.target.value);
  };

  render() {
    const {className, locales, locale} = this.props;

    return (
      <div>
        <label htmlFor="languages" className="absolute h-0 opacity-0" aria-label="languages">{I18n.t('Languages', null, true)}</label>
        <Select input={{value: locale, name: 'languages'}} className={`${className ? ` ${className}` : ''}`} onSelect={this.onSelectLanguage}>
          {locales && Object.keys(locales).map((item, key) => {
            return <option key={key} value={item}>{locales[item]}</option>;
          })}
        </Select></div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locales: state.translations.locales,
    locale: state.translations.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocales: () => dispatch(TranslationActions.getLocalesRequest()),
    setLocale: (locale) => dispatch(TranslationActions.setLocale(locale)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
