import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  plansRequest: [],
  plansSuccess: ['plans'],
  plansFailure: ['error'],

  clearPlansState: [],
});

export const PlansTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  plans: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// we're attempting to fetch billing plants
export const request = (state) => state.merge({fetching: true});

// we've successfully fetched the billing plans
export const success = (state, data) => {
  const {plans} = data;
  return state.merge({fetching: false, error: null, plans});
};

export const failure = (state, {error}) => state.merge({fetching: false, plans: null, error});

export const clearPlansState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PLANS_REQUEST]: request,
  [Types.PLANS_SUCCESS]: success,
  [Types.PLANS_FAILURE]: failure,

  [Types.CLEAR_PLANS_STATE]: clearPlansState,

});
