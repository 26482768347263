import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  skillsRequest: ['username'],
  skillsSuccess: ['skills'],
  skillsFailure: ['error'],

  clearSkillsState:[],
});

export const SkillsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  skills: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// Skills request
export const skills_request = (state) => state.merge({ fetching: true });

// Skills success
export const skills_success = (state, data) => {
  const { skills } = data;
  return state.merge({ fetching: false, error: null, skills })
};

export const skills_failure = (state, { error }) => state.merge({ fetching: false, skills: null, error });

export const clearSkillsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SKILLS_REQUEST]: skills_request,
  [Types.SKILLS_SUCCESS]: skills_success,
  [Types.SKILLS_FAILURE]: skills_failure,

  [Types.CLEAR_SKILLS_STATE]: clearSkillsState,

});
