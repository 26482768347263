class Castings {

  static filterCastingByStatus(results, status) {
    if (!results) return;
    return results.filter((item) => {
      return item.status === status;
    });
  }

  static nextStatusForCurrentStatus(status) {
    switch (status) {
      case 'selected': {
        return 'audition'
      }
      case 'audition': {
        return 'shortlist'
      }
      case 'shortlist': {
        return 'hired'
      }

    }
  }
}

export default Castings;
