import {call, put} from 'redux-saga/effects'
import skillsetCategoriesActions from '../../Redux/SkillsetCategories/SkillsetCategoriesRedux'
import {callApi} from '../CallApiSaga'

export function* getSkillsetCategories(api) {
  const apiCall = yield call(api.getSkillsetCategories);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(skillsetCategoriesActions.skillsetCategoriesSuccess(response.data.data));
  } else {
    yield put(skillsetCategoriesActions.skillsetCategoriesFailure('WRONG'))
  }
}

export function* getSkillsetCategoryById(api, {id}) {
  const apiCall = yield call(api.getSkillsetCategoryById, id);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(skillsetCategoriesActions.skillsetCategoryByIdSuccess(response.data));
  } else {
    yield put(skillsetCategoriesActions.skillsetCategoryByIdFailure('WRONG'))
  }
}
