import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';

class TextArea extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    input: PropTypes.any,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
  };

  renderTextArea(error = false) {
    const {children, className, input, placeholder} = this.props;

    return <textarea {...input} id={input.name} placeholder={placeholder} aria-labelledby={input.name} className={`input-field${error ? ' has-error' : ''} ${className || ''}`}>{input.value || children}</textarea>;
  }

  render() {
    const {meta} = this.props;

    return meta && meta.error && meta.submitFailed ?
      <div>
        {this.renderTextArea(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderTextArea();
  }
}

export default TextArea;
