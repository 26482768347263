import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import BillingDetailsForm from '../../../../Components/Form/BillingDetailsForm';
import {Elements, injectStripe, StripeProvider} from 'react-stripe-elements';
import StripeScriptLoader from 'react-stripe-script-loader'

const StripeCheckout = injectStripe(BillingDetailsForm);

class BillingDetailsPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    match: PropTypes.any,
    card_updating: PropTypes.bool,
    card_updated: PropTypes.bool,
    card:PropTypes.any,
    card_error: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (!this.props.card_updating) {
      if ((this.props.card !== prevProps.card && this.props.card_updated)){
        alert('card updated')
      }else if ((this.props.card_error !== prevProps.card_error)) {
        alert('Something went wrong updating the card')
      }
    }
  }

  render() {
    return (
      <div className="ml-3 md:mx-8 flex-1">
        <StripeScriptLoader
            uniqueId='myUniqueId'
            script='https://js.stripe.com/v3/'
            loader="Loading..."
        >
          <StripeProvider apiKey={process.env.STRIPE_KEY}>
            <Elements>
              <StripeCheckout/>
            </Elements>
          </StripeProvider>
        </StripeScriptLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    card: state.card.response,
    card_updating: state.card.updating,
    card_updated: state.card.updated,
    card_error: state.card.error,
  };
};

export default connect(mapStateToProps)(BillingDetailsPage);
