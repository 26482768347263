import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  projectByIdRequest: ['id'],
  projectByIdSuccess: ['project'],
  projectByIdFailure: ['error'],

  createProjectRequest: ['obj'],
  createProjectSuccess: ['project'],
  createProjectFailure: ['error'],

  updateProjectRequest: ['id', 'obj'],
  updateProjectSuccess: ['project'],
  updateProjectFailure: ['error'],

  deleteProjectRequest: ['id'],
  deleteProjectSuccess: [],
  deleteProjectFailure: ['error'],

  clearProjectState: [],

  clearProjectErrors: [],

});

export const ProjectTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  store: false,
  updating: false,
  deleting: false,
  project: null,
  error: null,
});

/* ------------- Reducers ------------- */

export const request = (state) => state.merge({fetching: true, error: null});

export const success = (state, data) => {
  const {project} = data;
  return state.merge({fetching: false, error: null, project})
};

export const failure = (state, {error}) => state.merge({fetching: false, project: null, error});

export const create_request = (state) => state.merge({store: true, project: null});

export const create_success = (state, data) => {
  const {project} = data;
  return state.merge({store: false, error: null, project})
};

export const create_failure = (state, {error}) => state.merge({store: false, project: null, error});

export const update_request = (state) => state.merge({updating: true});

export const update_success = (state, data) => {
  const {project} = data;
  return state.merge({updating: false, error: null, project})
};

export const update_failure = (state, {error}) => state.merge({updating: false, error});

export const delete_request = (state) => state.merge({deleting: true});

export const delete_success = (state) => {
  return state.merge({deleting: false, error: null, project: null})
};

export const delete_failure = (state, {error}) => state.merge({deleting: false, error});

export const clear_project_state = () => INITIAL_STATE;

export const clear_project_error = (state) => {
  return state.merge({error: null, project: state.project})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROJECT_BY_ID_REQUEST]: request,
  [Types.PROJECT_BY_ID_SUCCESS]: success,
  [Types.PROJECT_BY_ID_FAILURE]: failure,

  [Types.CREATE_PROJECT_REQUEST]: create_request,
  [Types.CREATE_PROJECT_SUCCESS]: create_success,
  [Types.CREATE_PROJECT_FAILURE]: create_failure,

  [Types.UPDATE_PROJECT_REQUEST]: update_request,
  [Types.UPDATE_PROJECT_SUCCESS]: update_success,
  [Types.UPDATE_PROJECT_FAILURE]: update_failure,

  [Types.DELETE_PROJECT_REQUEST]: delete_request,
  [Types.DELETE_PROJECT_SUCCESS]: delete_success,
  [Types.DELETE_PROJECT_FAILURE]: delete_failure,

  [Types.CLEAR_PROJECT_STATE]: clear_project_state,
  [Types.CLEAR_PROJECT_ERRORS]: clear_project_error,
});
