import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../Utils/I18n';
import TextButton from './TextButton';

class PDFDownloadButton extends React.Component {
  static propTypes = {
    project_id: PropTypes.number.isRequired,
    role_id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    token: PropTypes.string,
  };

  createUrl() {
    const {project_id, role_id, status, token} = this.props;
    return process.env.API_URL + '/projects/' + project_id + '/roles/' + role_id + '/pdf?status=' + status + '&token=' + token;
  }

  render() {
    return (
      <TextButton
        to={this.createUrl()}
        external
        iconLeft
        icon="file-pdf"
        className="text-gray-40 hover:text-primary-40 -mt-4 pl-4"
      >
        {I18n.t('Download PDF')}
      </TextButton>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.authToken,
  };
};

export default connect(mapStateToProps)(PDFDownloadButton);
