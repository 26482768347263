// a library to wrap and simplify api calls
import apisauce from 'apisauce';
// import i18n from 'react-i18next'

// our "constructor"
const create = (baseURL = process.env.API_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Language': i18n.locale ? i18n.locale : 'en',
    },
    // 10 second timeout...
    timeout: 600000,
  });


  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const setLocale = (locale) => api.setHeader('Content-Language', locale);
  const setAuthToken = (userAuth) => api.setHeader('Authorization', 'Bearer ' + userAuth);
  const removeAuthToken = () => api.setHeader('Authorization', '');
  const login = (userAuth) => api.post('auth/login', userAuth);
  const refreshToken = () => api.get('auth/refresh');
  const getAccount = () => api.get('auth/user');
  const updateAccount = (obj) => api.put(`users/${obj.username}`, obj.account);
  const deleteAccount = (obj) => api.delete(`account/${obj.id}`);
  const forgotPassword = (obj) => api.post('auth/password/email', obj);
  const resetPassword = (obj) => api.post('auth/password/reset', obj);
  const register = (obj) => api.post('auth/register', obj);
  const getPlans = () => api.get('billing/plans?sort=id');
  const chooseSubscription = (subscriptionObj) => api.post('billing/subscription', subscriptionObj);
  const deleteSubscription = () => api.delete('billing/subscription');
  const changeSubscription = (obj) => api.put('billing/subscription', obj);
  const getCoupon = (obj) => api.get(`billing/coupons/${obj.coupon}`);
  const updateCard = (obj) => api.put('billing/card', obj);
  const getProjectTypes = () => api.get('projectTypes');
  const getProjectTypeById = (id) => api.get('projectTypes/' + id);
  const getPropertyTypes = () => api.get('propertyTypes');
  const getPropertyTypeById = (id) => api.get('propertyTypes/' + id);
  const getExperienceTypes = () => api.get('experienceTypes');
  const getExperienceTypeById = (id) => api.get('experienceTypes/' + id);
  const getSkillsetCategories = () => api.get('skillsetCategories?include=skillsets.options&page[size]=1000');
  const getSkillsetCategoryById = (id) => api.get('skillsetCategories/' + id);
  const getProjects = () => api.get('projects?sort=-created_at');
  const getCastings = (obj) => api.get(`users/${obj.username}/castings?page[number]=[${obj.page}]&per_page=${obj.per_page}&filter[status]=${obj.status}&sort=created_at&include=role.project.type,role.project.owner`);
  const getExperiences = (username) => api.get(`users/${username}/experiences`);
  const createExperience = (obj) => api.post(`users/${obj.username}/experiences`, obj.experience);
  const deleteExperience = (obj) => api.delete(`users/${obj.username}/experiences/${obj.experience}`);
  const getGlobalCastings = (obj) => api.get(`users/${obj.username}/castings?page[number]=${obj.page}&per_page=${obj.per_page}&filter[status]=${obj.status}&sort=created_at&include=role.project.type,role.project.owner`);
  const getCastingById = (obj) => api.get(`users/${obj.username}/castings/${obj.casting_id}?include=role.project.type`);
  const getProperties = (username) => api.get(`users/${username}/properties`);
  const updateProperty = (obj) => api.put(`users/${obj.username}/properties`, obj.properties);
  const getSkills = (username) => api.get(`users/${username}/skills?include=skillset.category`);
  const getEducations = (username) => api.get(`users/${username}/educations`);
  const createEducation = (obj) => api.post(`users/${obj.username}/educations`, obj.education);
  const deleteEducation = (obj) => api.delete(`users/${obj.username}/educations/${obj.education}`);
  const getNotifications = (username) => api.get(`users/${username}/notification_settings`);
  const getExperienceById = (obj) => api.get(`users/${obj.username}/experiences/${obj.experience}`);
  const updateExperience = (obj) => api.put(`users/${obj.username}/experiences/${obj.experience_id}`, obj.experience);
  const getSkillsets = (obj) => api.get(`skillsets?page[number]=${obj.page}&page[size]=800&include=category,options`);
  const updateSkill = (obj) => api.put(`users/${obj.username}/skills`, {skills: obj.skills});
  const saveSkill = (obj) => api.post(`users/${obj.username}/skill`, {skill: obj.skill});
  const deleteSkill = (obj) => api.delete(`users/${obj.username}/skill`, {skill: obj.skill});
  const updateCasting = (obj) => api.put(`projects/${obj.project}/roles/${obj.role}/castings/${obj.casting}`, obj.data);
  const updateNotifications = (obj) => api.post(`users/${obj.username}/notification_settings`, obj.notification_settings);
  const socialLogin = (obj) => api.post(`auth/socialite/login/${obj.provider}`, {token: obj.token});
  const getTranslations = () => api.get(`i18n/translations`);
  const updateAllNotifications = (obj) => api.put(`/users/${obj.username}/notification_settings`, obj);
  const getProjectById = (id) => api.get(`projects/${id}`);
  const createProject = (obj) => api.post(`projects`, obj);
  const updateProject = (id, obj) => api.put(`projects/${id}`, obj);
  const deleteProject = (id, obj) => api.delete(`projects/${id}`, obj);
  const getUnreadNotifications = (username) => api.get(`users/${username}/notifications`);
  const getReadNotifications = (username) => api.get(`users/${username}/notifications/read`);
  const markNotificationsRead = (username) => api.post(`users/${username}/notifications`);
  const createInvitation = (email) => api.post('account/invitations', {email: email});
  const getInvitations = () => api.get('account/invitations');
  const deleteInvitation = (invitation_code) => api.delete(`account/invitations/${invitation_code}`);
  const getUsers = () => api.get('account/users');
  const getUser = (username) => api.get(`account/users/${username}`);
  const deleteUser = (username) => api.delete(`account/users/${username}`);
  const updatePemissions = (obj) => api.put(`account/users/${obj.username}`, {permissions: obj.permissions});
  const createRole = (obj) => api.post(`/projects/${obj.project_id}/roles`, obj.role);
  const getRoleById = (obj) => api.get(`/projects/${obj.project_id}/roles/${obj.role_id}`);
  const updateRole = (obj) => api.put(`/projects/${obj.project_id}/roles/${obj.role_id}`, obj.role);
  const deleteRole = (obj) => api.delete(`/projects/${obj.project_id}/roles/${obj.role_id}`);
  const getLocales = () => api.get(`/i18n/locales`);
  const createCastings = (obj) => api.post(`/projects/${obj.project_id}/roles/${obj.role_id}/castings`, {users: obj.users});
  const getAllCastingsForRole = (obj) => api.get(`/projects/${obj.project_id}/roles/${obj.role_id}/castings?filter[status]=${obj.status}&include=talent,role&page[size]=24`);
  const getPageCastingsForRole = (obj) => api.get(obj.link);
  const getAllDeclinedCastingsForRole = (obj) => api.get(`/projects/${obj.project_id}/roles/${obj.role_id}/castings?filter[status]=${obj.status}&include=talent,role&page[size]=24`);
  const getDeclinedPageCastingsForRole = (obj) => api.get(obj.link);
  const getCastingByIdForAgency = (obj) => api.get(`/projects/${obj.project_id}/roles/${obj.role_id}/castings/${obj.casting_id}`);
  const getInvoices = () => api.get('/billing/invoices');

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the api created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    login,
    removeAuthToken,
    setAuthToken,
    setLocale,
    refreshToken,
    getAccount,
    updateAccount,
    forgotPassword,
    register,
    resetPassword,
    getPlans,
    chooseSubscription,
    deleteSubscription,
    changeSubscription,
    updateCard,
    getProjectTypes,
    getProjectTypeById,
    getPropertyTypes,
    getPropertyTypeById,
    getExperienceTypes,
    getExperienceTypeById,
    getSkillsetCategories,
    getSkillsetCategoryById,
    getProjects,
    getCastings,
    getExperiences,
    createExperience,
    deleteExperience,
    getGlobalCastings,
    getCastingById,
    getProperties,
    updateProperty,
    getSkills,
    getEducations,
    createEducation,
    deleteEducation,
    getNotifications,
    getExperienceById,
    updateExperience,
    getSkillsets,
    updateSkill,
    saveSkill,
    deleteSkill,
    updateCasting,
    updateNotifications,
    getTranslations,
    socialLogin,
    updateAllNotifications,
    deleteAccount,
    getCoupon,
    getUnreadNotifications,
    getProjectById,
    createProject,
    updateProject,
    deleteProject,
    getReadNotifications,
    markNotificationsRead,
    createInvitation,
    getInvitations,
    deleteInvitation,
    getUsers,
    getUser,
    updatePemissions,
    deleteUser,
    createRole,
    getRoleById,
    deleteRole,
    updateRole,
    getLocales,
    createCastings,
    getAllCastingsForRole,
    getPageCastingsForRole,
    getAllDeclinedCastingsForRole,
    getDeclinedPageCastingsForRole,
    getCastingByIdForAgency,
    getInvoices
  };
};

// let's return back our create method as the default.
export default {
  create,
};
