import { call, put } from 'redux-saga/effects'
import ResetPasswordActions from '../../Redux/Auth/ResetPasswordRedux'

// Forgot Password
export function * resetPassword (api, {resetObj}) {

    const response = yield call(api.resetPassword, resetObj);

    // success?
    if (response.ok) {
        yield put(ResetPasswordActions.resetPasswordSuccess(response.data));
    } else {
        yield put(ResetPasswordActions.resetPasswordFailure(response.data ? response.data : 'Server Error'))
    }
}
