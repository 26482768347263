import PropTypes from 'prop-types';
import React from 'react';
import Body from './Body';

class LabeledContent extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    label: PropTypes.string,
  };

  render() {
    const {children, className, label} = this.props;

    return (
      <div className={`flex flex-wrap items-center`}>
        <label className={`block text-sm text-gray-40 font-bold pr-1 ${className || ''}`}>{label}</label><Body className="whitespace-no-wrap">{children}</Body>
      </div>
    );
  }
}

export default LabeledContent;
