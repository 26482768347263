import React from 'react';
import Title from '../../../../Components/Text/Title';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MediaCollection from '../../../../Components/Lists/MediaCollection';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import I18n from '../../../../Utils/I18n';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddMedia from '../../../../Components/Images/AddMedia';

class PhotosPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.func,
    uploadSingleImage: PropTypes.func,
    clearUploadCareState: PropTypes.func,
    file_uploading: PropTypes.bool,
    file_uploaded: PropTypes.bool,
    file: PropTypes.any,

  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      image_uploading: false,
      saved: false,
    };
  }

  componentWillUnmount() {
    this.props.clearUploadCareState();
  }

  uploadedFile = (file) => {
    const {account} = this.props;
    const photos = account ? (account.photos != null ? account.photos : []) : [];
    let mediaArray = [];
    photos.map((file) => {
      mediaArray.push(file);
    });

    mediaArray.push(file.cdnUrl);

    this.props.updateAccount({
      username: account.username,
      account: {
        photos: mediaArray,
      },
    });
  };

  formatForMediaCollection = () => {
    let mediaArray = [];
    const {account} = this.props;

    if (!account.photos) {
      return [];
    }

    if (account.photos.length > 0) {
      account.photos.map((file) => {
        mediaArray.push({
          src: file,
          alt: '',
          type: 'image',
        });
      });
    }

    return mediaArray;
  };

  deleteMedia = (path) => {
    const {account} = this.props;

    if (account.photos) {
      const mediaArray = account.photos.filter((photo) => photo !== path);
      this.props.updateAccount({
        username: account.username,
        account: {
          photos: mediaArray,
        },
      });
    }
  };

  render() {
    const {file_uploading} = this.state;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="text-xl pb-2">{I18n.t('Photos')}</Title>
        <div className="flex flex-wrap">
          <MediaCollection deleteMedia={this.deleteMedia} collection={this.formatForMediaCollection()} deletable/>
        </div>

        <AddMedia onlyImages={true} className="max-w-xl" uploadedFile={this.uploadedFile} disabled={file_uploading}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    file: state.upload_care.file,
    file_uploading: state.upload_care.uploading,
    file_uploaded: state.upload_care.uploaded,
    file_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (obj) => dispatch(AccountActions.accountUpdateRequest(obj)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosPage);
