import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PortfolioItem from '../../../Components/ListItems/PortfolioItem';
import ExperiencesActions from '../../../Redux/Talent/ExperiencesRedux';
import AddButton from '../../../Components/Buttons/AddButton';
import Loading from '../../../Components/Loading';

class PortfoliosPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getExperiences: PropTypes.any,
    experiences: PropTypes.any,
  };

  componentDidMount() {
    const {account} = this.props;
    this.props.getExperiences(account.username);
  }

  render() {
    const {experiences} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <div className="sm:flex flex-wrap max-w-6xl">
          <div className="min-w-64 min-h-48 mb-5 sm:pr-5 sm:flex-initial w-full sm:w-1/2 xl:w-1/3">
            <AddButton to="/talent/experiences/add/overview" className="min-w-64 min-h-48 mb-5 sm:flex-initial w-full"/>
          </div>
          {experiences
            ? experiences.map((experience, key) => {
              return (
                <div key={key} className="w-full sm:w-1/2 xl:w-1/3 sm:pr-5 mb-5">
                  <PortfolioItem className="w-full" experience={experience}/>
                </div>
              );
            })
            : <Loading/>
          }
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    experiences: state.experiences.experiences ? state.experiences.experiences : [],
    experiences_fetching: state.experiences.fetching,
    experiences_error: state.experiences.error,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExperiences: (username) => dispatch(ExperiencesActions.experiencesRequest(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfoliosPage);
