import {call, put} from 'redux-saga/effects'
import ExperiencesActions from '../../Redux/Talent/ExperiencesRedux'
import {callApi} from '../CallApiSaga'

export function* getExperiences(api, {username}) {
  const apiCall = yield call(api.getExperiences, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(ExperiencesActions.experiencesSuccess(response.data.data));
  } else {
    yield put(ExperiencesActions.experiencesFailure('WRONG'))
  }
}
