import PropTypes from 'prop-types';
import React from 'react';
import Title from '../Text/Title';
import TextButton from '../Buttons/TextButton';
import FilledButton from '../Buttons/FilledButton';
import Card from '../Card';
import LabeledContent from '../Text/LabeledContent';
import Helper from '../../Utils/Helper';
import I18n from '../../Utils/I18n';
import CastingActions from '../../Redux/Talent/CastingRedux';
import {connect} from 'react-redux';
import SingleImage from '../Images/SingleImage';
import Body from '../Text/Body';

class CastingItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    updateCasting: PropTypes.func,
    casting: PropTypes.any,
    casting_updating: PropTypes.bool,
    casting_updated: PropTypes.bool,
    casting_error: PropTypes.any,
    updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      updating: false,
    };
  }

  static contextTypes = {
    t: PropTypes.func,
  };

  isNew() {
    return this.props.casting.status === 'new';
  }

  acceptCasting = (e) => {
    e.preventDefault();
    this.changeStatusOfCasting('selected');
  };

  declineCasting = (e) => {
    e.preventDefault();
    this.changeStatusOfCasting('declined');
  };

  changeStatusOfCasting(status) {
    const {casting} = this.props;
    const obj = {
      project: casting.role.project.id,
      role: casting.role.id,
      casting: casting.id,
      data: {
        status: status,
      },
    };
    this.setState({updating: true}, function () {
      this.props.updateCasting(obj);
    });
  }

  componentDidUpdate(prevProps) {
    const {casting} = this.props;
    const self = this;
    if (!this.props.casting_updating) {
      if (this.props.casting_updated && (this.props.casting_updated !== prevProps.casting_updated) && this.state.updating) {
        this.setState({updating: false}, function () {
          self.props.history.push(`/talent/projects/${casting.id}`);
        });
      } else if ((this.props.casting_error !== prevProps.casting_error) && this.state.updating) {
        this.setState({updating: false}, function () {
          alert(this.props.casting_error.message);
        });
      }
    }
  }

  render() {
    const {className, casting} = this.props;
    const {t} = this.context;
    const {project} = casting.role;

    return (
      <Card className={`justify-between -mb-px ${className || ''}`}>

        <div className="flex h-full flex-col justify-between px-6 py-8">
          <div className="flex justify-between items-center mb-4">

            <div className="items-stretch"><Title>{project ? project.title : null}</Title></div>
            {
              project &&
                <div className="rounded-lg ml-4 overflow-hidden w-20 flex-shrink-0 flex justify-center items-center">
                  <img src={ project.logo } alt={ project.title } className="w-100 max-h-full rounded-lg overflow-hidden" />
                </div>
            }

          </div>
          <div>

            <div className="mb-8">

              <LabeledContent className="min-w-32" label={`${t('status')}:`}>{casting.status}</LabeledContent>
              <LabeledContent className="min-w-32" label={`${t('project type')}:`}>{project ? project.type.title : null}</LabeledContent>
              {
                project &&
                  <LabeledContent className="min-w-32 whitespace-no-wrap" label={`${t('project duration')}:`}>
                    {`${project.starts_at && project.ends_at ? Helper.getDateDiff(project.starts_at, project.ends_at) : t('unknown')}`}
                  </LabeledContent>
              }
              {
                project &&
                  <LabeledContent className="min-w-32 whitespace-no-wrap" label={`${t('production dates')}:`}>
                    {`${project.starts_at && project.ends_at ? (`${Helper.formatDate(project.starts_at)} - ${Helper.formatDate(project.ends_at)}`) : t('unknown')}`}
                  </LabeledContent>
              }

            </div>

            {
              project && project.owner.name &&
                <div className="mb-8 pt-3 pb-4 border-gray-10 border-t border-b flex justify-between">

                  <div className="mr-auto">

                    <Body>{I18n.t('assignment from')}</Body>
                    <p className='text-sm'>{ project.owner.name }</p>

                  </div>

                  <SingleImage className="max-w-8 max-h-8 ml-4 my-auto w-full" alt={project.owner.name}>{ project.owner.avatar }</SingleImage>

                </div>
            }

            <div>

              <TextButton to={`/talent/projects/${casting ? casting.id : null}`} icon="chevron-right" className="ml-auto text-gray-40 text-right">
                {I18n.t('Project details')}
              </TextButton>

            </div>

          </div>
        </div>

        {
          this.isNew() &&
            <div className="flex w-full">
              <FilledButton disabled={this.state.updating} type={'button'} onClick={this.declineCasting} className="w-full text-center bg-gray-10 text-gray-50 hover:bg-gray-15 hover:text-gray-60">{I18n.t('decline', null, true)}</FilledButton>
              <FilledButton disabled={this.state.updating} type={'button'} onClick={this.acceptCasting} className="w-full text-center bg-secondary-30 text-secondary-60 hover:bg-secondary-40 hover:text-secondary-80">{I18n.t('apply', null, true)}</FilledButton>
            </div>
        }

      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    casting_error: state.casting.error,

    casting_updating: state.casting.updating,
    casting_updated: state.casting.casting_updated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCasting: (obj) => dispatch(CastingActions.updateCastingRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CastingItem);
