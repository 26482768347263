import {call, put} from 'redux-saga/effects';
import LoginActions from '../../Redux/Auth/LoginRedux';
import AccountActions from '../../Redux/Account/AccountRedux';
import RouteActions from '../../Redux/Route/RouteRedux';

export const selectAuthToken = (state) => state.login.authToken;

// let stack = null;

export async function _storeTokens(auth_token) {
  try {
    await localStorage.setItem('auth_token', auth_token);
  } catch (error) {
    // Error saving data
  }
}

export async function _removeTokens() {
  try {
    await localStorage.removeItem('auth_token');
  } catch (error) {
    // Error saving data
  }
}

// attempts to login
export function* login(api, {username, password}) {
  const authObj = {
    username: username,
    password: password,
  };

  const response = yield call(api.login, authObj);
  // success?
  if (response.ok) {
    yield _storeTokens(response.data.access_token);
    yield call(api.setAuthToken, response.data.access_token);
    yield put(LoginActions.loginSuccess(response.data.access_token));
    yield put(AccountActions.accountRequest());
    yield put({type: 'RELOGIN_OK'});
  } else {
    yield put(LoginActions.loginFailure(response.data));
  }
}

// attempts to login
export function* socialLogin(api, {provider, token}) {

  if (provider === 'linkedin') {
    yield _storeTokens(token);
    yield call(api.setAuthToken, token);
    yield put(LoginActions.loginSuccess(token));
    yield put(AccountActions.accountRequest());
    yield put({type: 'RELOGIN_OK'});
    return;
  }

  const socialObj = {
    token: token,
    provider: provider,
  };

  const response = yield call(api.socialLogin, socialObj);
  // success?
  if (response.ok) {
    yield _storeTokens(response.data.access_token);
    yield call(api.setAuthToken, response.data.access_token);
    yield put(LoginActions.loginSuccess(response.data.access_token));
    yield put(AccountActions.accountRequest());
    yield put({type: 'RELOGIN_OK'});
  } else {
    yield put(LoginActions.loginFailure('Error'));
  }
}

//attempts to logout
export function* logout(api) {
  yield _removeTokens();
  yield call(api.removeAuthToken);
  yield put(AccountActions.clearAccountState());
  yield put(LoginActions.logoutSuccess());
  yield put(RouteActions.clearRouteState());
  yield put({type: 'RELOGIN_ABORT'});
}

// loads the login
export function* loginLoad(api) {
  let token = yield localStorage.getItem('auth_token');

  if (token) {
    yield call(api.setAuthToken, token);
    yield put(AccountActions.accountRequest());
    yield put(LoginActions.loginLoadSuccess(token));
  } else {
    yield put(LoginActions.loginLoadFailure('Not authenticated'));
  }
}


export function* refreshToken(api) {

  const response = yield call(api.refreshToken);
  // success?
  if (response.ok) {
    yield _storeTokens(response.data.access_token);
    yield call(api.setAuthToken, response.data.access_token);
    yield put(LoginActions.loginSuccess(response.data.access_token));
    yield put(AccountActions.accountRequest());
  } else {
    yield _removeTokens();
    yield call(api.removeAuthToken);
    yield put(LoginActions.loginFailure('WRONG'));
    yield put(AccountActions.accountRequest());
  }
}
