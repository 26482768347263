import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';

class RoundedImage extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    src: PropTypes.any,
    hoverable: PropTypes.bool,
  };

  render() {
    const {alt, children, className, hoverable, src} = this.props;
    const image = src || children;

    return (
      <div className={`flex justify-center items-center rounded-full overflow-hidden shadow relative ${className || ''}`}>
        {image ?
          <img className="flex w-full h-full object-cover" src={image} alt={alt || ''}/>
          : <Icon className="text-gray-20">user-circle</Icon>
        }
        {hoverable ?
          <div className="transition-o opacity-0 group-hover:opacity-100 flex justify-center items-center absolute inset-0 bg-black-transparent rounded-full">
            <Icon className="text-white text-3xl">info</Icon>
          </div>
          : null
        }
      </div>
    );
  }
}

export default RoundedImage;
