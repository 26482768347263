import { call, put } from 'redux-saga/effects'
import FetchGroupActions from '../../Redux/Fetch/FetchGroupRedux'


export function * fetchProject (api,{obj}) {

  const response = yield call(api.fetchGroupMedia,obj);

  // success?
  if (response.ok) {
    yield put(FetchGroupActions.fetchGroupProjectSuccess(response.data));
  } else {
    yield put(FetchGroupActions.fetchGroupProjectFailure('WRONG'))
  }
}

export function * fetchRole (api,{obj}) {

  const response = yield call(api.fetchGroupMedia,obj);

  // success?
  if (response.ok) {
    yield put(FetchGroupActions.fetchGroupRoleSuccess(response.data));
  } else {
    yield put(FetchGroupActions.fetchGroupRoleFailure('WRONG'))
  }
}
