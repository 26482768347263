import {call, put} from 'redux-saga/effects';
import NotificationsActions from '../../Redux/Talent/NotificationsRedux';
import {callApi} from '../CallApiSaga';

export function* getNotifications(api, {username}) {
  const apiCall = yield call(api.getNotifications, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(NotificationsActions.notificationsSuccess(response.data));
  } else {
    yield put(NotificationsActions.notificationsFailure('WRONG'));
  }
}

export function* updateNotifications(api, {obj}) {
  const apiCall = yield call(api.updateNotifications, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(NotificationsActions.notificationsUpdateSuccess(true));
    yield put(NotificationsActions.notificationsSuccess(response.data));
  } else {
    yield put(NotificationsActions.notificationsUpdateFailure('WRONG'));
  }
}

export function* updateAllNotifications(api, {obj}) {
  const apiCall = yield call(api.updateAllNotifications, obj);
  yield call(callApi, apiCall);
}
