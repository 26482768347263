import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AgencyAddProjectRoutingComponent from '../../../Components/Routing/Agency/AgencyAddProjectRoutingComponent';
import I18n from '../../../Utils/I18n';

class ProjectPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getCastingById: PropTypes.func,
    casting: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to="/agency/projects/add/overview" icon="info-circle">{I18n.t('Overview')}</SubNavItem>
            </SubNav>

            <AgencyAddProjectRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

export default connect(mapStateToProps)(ProjectPage);
