import { call, put } from 'redux-saga/effects'
import RegisterActions from '../../Redux/Auth/RegisterRedux'

// Forgot Password
export function * register (api, {registerObj}) {

    const response = yield call(api.register, registerObj);

    // success?
    if (response.ok) {
        yield put(RegisterActions.registerSuccess(response.data));
    } else {
        yield put(RegisterActions.registerFailure(response.data ? response.data : 'Server Error'))
    }
}
