import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../../../Components/Icon';
import TextField from '../../../../Components/Form/TextField';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import I18n from '../../../../Utils/I18n';
import {url} from '../../../../Validators/Form/Form';

class SocialMediaPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (values) => {
    let obj = {
      socials: values,
    };
    this.setState({updating: true, saved: false}, function () {
      this.props.updateAccount({account: obj, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  render() {
    const {account} = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        validateOnBlur
        initialValues={account.socials}
      >
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="flex-1 pl-3 md:px-10 pb-16">
            <Title className="text-xl pb-2">{I18n.t('Social media')}</Title>

            <div className="md:flex">
              <Field name="facebook" validate={url}>
                {({input, meta}) => (
                  <div className="flex-1 flex items-baseline md:pr-5">
                    <Label input={input}><Icon className="text-xl mr-3">{['fab', 'facebook-square']}</Icon></Label>
                    <TextField input={input} meta={meta} wrapperClassName="w-full" placeholder="https://www.facebook.com/username"/>
                  </div>
                )}
              </Field>
              <Field name="twitter" validate={url}>
                {({input, meta}) => (
                  <div className="flex-1 flex items-baseline">
                    <Label input={input}><Icon className="text-xl mr-3">{['fab', 'twitter']}</Icon></Label>
                    <TextField input={input} meta={meta} wrapperClassName="w-full" placeholder="https://www.twitter.com/username"/>
                  </div>
                )}
              </Field>
            </div>

            <div className="md:flex">
              <Field name="instagram" validate={url}>
                {({input, meta}) => (
                  <div className="flex-1 flex items-baseline md:pr-5">
                    <Label input={input}><Icon className="text-xl mr-3">{['fab', 'instagram']}</Icon></Label>
                    <TextField input={input} meta={meta} wrapperClassName="w-full" placeholder="https://www.instagram.com/username"/>
                  </div>
                )}
              </Field>
              <Field name="youtube" validate={url}>
                {({input, meta}) => (
                  <div className="flex-1 flex items-baseline">
                    <Label input={input}><Icon className="text-xl mr-3">{['fab', 'youtube']}</Icon></Label>
                    <TextField input={input} meta={meta} wrapperClassName="w-full" placeholder="https://www.youtube.com/username"/>
                  </div>
                )}
              </Field>
            </div>

            <Field name="imdb" validate={url}>
              {({input, meta}) => (
                <div className="md:w-1/2 flex items-baseline md:pr-2">
                  <Label input={input}><Icon className="text-xl mr-3">{['fab', 'imdb']}</Icon></Label>
                  <TextField input={input} meta={meta} wrapperClassName="w-full" placeholder="https://www.imdb.com/username"/>
                </div>
              )}
            </Field>

            <div className="mb-3 mt-6">
              <FilledButton type="submit" icon="edit" disabled={this.state.updating} saved={this.state.saved} className="rounded text-white bg-secondary-40">
                {I18n.t('save', null, false, true)}
              </FilledButton>
            </div>

          </form>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaPage);
