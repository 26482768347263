import { call, put } from 'redux-saga/effects'
import CastingActions from '../../Redux/Talent/CastingRedux'
import { callApi } from '../CallApiSaga'

export function * getCastingById (api,{obj}) {
  const apiCall = yield call(api.getCastingById,obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(CastingActions.castingByIdSuccess(response.data));
  } else {
    yield put(CastingActions.castingByIdFailure('WRONG'))
  }
}

export function * updateCasting (api,{obj}){
  const apiCall = yield call(api.updateCasting,obj);
  const response = yield call(callApi, apiCall);
  // success?

  if (response.ok) {
    yield put(CastingActions.updateCastingSuccess(true));
  } else {
    yield put(CastingActions.updateCastingFailure(response.data.message ? {message:response.data.message, status: response.status} : {message:'Error',status:500}))
  }
}
