import React from 'react';
import PropTypes from 'prop-types';

class Logo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const {className} = this.props;

    return (
      <div className="block">
        <img className={`h-12 ${className || ''}`} src="/styles/graphics/logo.svg" alt="The Casting Director"/>
      </div>
    );
  }
}

export default Logo;
