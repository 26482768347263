import PropTypes from 'prop-types';
import React from 'react';
import {connectRefinementList} from 'react-instantsearch-dom';
import Icon from '../Icon';
import {Link} from 'react-router-dom';
import I18n from '../../Utils/I18n';


class DropdownList extends React.Component {
  static propTypes = {
    attribute: PropTypes.string,
    className: PropTypes.string,
    items: PropTypes.array,
    refine: PropTypes.func,
    currentRefinement: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {SelectDropdownOpen: false};
  }


  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({SelectDropdownOpen: !this.state.SelectDropdownOpen});
  };

  render() {
    const {attribute, className, currentRefinement, items, refine} = this.props;
    const {SelectDropdownOpen} = this.state;

    return (
      <div className={`block relative ${className || ''}`}>
        <Link to=""
              onClick={this.toggleDropdown}
              aria-haspopup="listbox"
              aria-labelledby={`${attribute} list-toggle`}
              id="list-toggle"
              className="block w-full sm:pr-16 focus:outline-none focus:shadow-outline input-field"
        >
          <p>{currentRefinement.length} {I18n.t('selected')}</p>
          <div className="pointer-events-none absolute border-l inset-y-0 flex items-center right-0 px-2 sm:px-4 border-gray-15">
            <Icon className="text-gray-60 -mt-2">sort-down</Icon>
          </div>
        </Link>
        <div className={`absolute bg-white rounded shadow-md border border-gray-10 my-1 z-50 -mt-2 w-full focus:outline-none focus:shadow-outline overflow-scroll max-h-64${SelectDropdownOpen ? '' : ' hidden'}`}
             tabIndex={SelectDropdownOpen ? '0' : '-1'}
             role="listbox"
             aria-expanded={SelectDropdownOpen ? true : null}
             aria-labelledby="list-toggle"
             id="options-list"
             onBlur={this.toggleDropdown}
        >
          <ul>
            {items.map((item, key) => {
              return (
                <li key={key}>
                  <Link
                    to=""
                    role="option"
                    aria-selected={item.isRefined}
                    onClick={(e) => {
                      e.preventDefault();
                      refine(item.value);
                    }}
                    className={`py-2 px-3 block hover:bg-primary-20 focus:outline-none focus:bg-primary-20 text-gray-60 ${item.isRefined ? 'pl-2' : 'pl-8'}`}
                  >
                    {item.isRefined ?
                      <Icon className="mr-2">check</Icon>
                      : null
                    }
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default connectRefinementList(DropdownList);
