import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  couponRequest: ['obj'],
  couponSuccess: ['coupon','couponCode'],
  couponFailure: ['error'],

  clearCouponState: [],
});

export const CouponTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  coupon: null,
  error: null,
  fetching: false,
  couponCode:null,
});

/* ------------- Reducers ------------- */

// we're attempting to fetch billing plants
export const request = (state) => state.merge({fetching: true});

// we've successfully fetched the billing plans
export const success = (state, data) => {
  const {coupon,couponCode} = data;
  return state.merge({fetching: false, error: null, coupon, couponCode});
};

export const failure = (state, {error}) => state.merge({fetching: false, coupon: null, error});

export const clear_coupon_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COUPON_REQUEST]: request,
  [Types.COUPON_SUCCESS]: success,
  [Types.COUPON_FAILURE]: failure,

  [Types.CLEAR_COUPON_STATE]: clear_coupon_state,

});
