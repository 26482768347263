import {put} from 'redux-saga/effects'
import LoginActions from '../../Redux/Auth/LoginRedux'
import TranslationActions from "../../Redux/Translation/TranslationRedux";


// process STARTUP actions
export function* startup() {
  yield put(LoginActions.loginLoad());
  yield put(TranslationActions.setTranslationsRequest());
}
