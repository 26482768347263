import {call, put} from 'redux-saga/effects'
import TranslationActions from '../../Redux/Translation/TranslationRedux'
import {setLanguage, setTranslations} from 'redux-i18n'

// attempts to account
export function* getTranslations(api) {

  const response = yield call(api.getTranslations);

  // success?
  if (response.ok) {
    yield put(setTranslations(response.data));
    yield put(TranslationActions.setTranslationsSuccess(response.data));
  }
}

export function* setLocale(api, {locale}) {
  yield put(setLanguage(locale));
  yield call(api.setLocale, locale);
}

export function* getLocales(api) {

  const response = yield call(api.getLocales);

  // success?
  if (response.ok) {
    yield put(TranslationActions.getLocalesSuccess(response.data));
  }
}
