import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Icon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
  };

  render() {
    const {children, className} = this.props;

    return <FontAwesomeIcon icon={children} className={className}/>
  }
}

export default Icon;
