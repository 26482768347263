import React from 'react';
import Title from '../../../../Components/Text/Title';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import Select from '../../../../Components/Form/Select';
import TextArea from '../../../../Components/Form/TextArea';
import _ from 'lodash';
import ExperienceTypeActions from '../../../../Redux/ExperienceTypes/ExperienceTypesRedux';
import ExperienceActions from '../../../../Redux/Talent/ExperienceRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';

class PortfolioOverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    getExperienceTypes: PropTypes.func,
    updateExperience: PropTypes.func,
    experience_types: PropTypes.any,
    saving: PropTypes.bool,
    experience: PropTypes.any,
    experience_error: PropTypes.any,
    experience_updating: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };

    this.form = null;
  }

  componentDidMount() {
    this.props.getExperienceTypes();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.experience_updating) {
      if ((this.props.experience !== prevProps.experience) && this.state.saving) {
        this.onSuccess();
      } else if ((this.props.experience_error !== prevProps.experience_error) && this.state.saving) {
        this.onError(this.props.experience_error);
      }
    }
  }

  onSubmit = (values) => {
    this.setState({saving: true}, function () {
      this.props.updateExperience({
        experience: values,
        experience_id: this.props.experience.id,
        username: this.props.account.username,
      });
    });
  };

  onError = () => {
    this.setState({saving: false});
  };

  onSuccess = () => {
    this.setState({saving: false}, function () {
      this.props.history.push(`/talent/experiences/${this.props.experience.id}`);
    });
  };

  validate() {
    const {saving} = this.state, {experience_error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = experience_error ? {...experience_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
        if (this.form.getFieldState('startYear').dirtySinceLastSubmit || this.form.getFieldState('endYear').dirtySinceLastSubmit) {
          delete errors['startYear'];
          delete errors['endYear'];
        }
      });
    }

    return errors;
  }

  render() {
    const {experience_types, experience} = this.props, {t} = this.context, year = new Date().getFullYear();

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={experience}
      >
        {({handleSubmit, form}) => {
          this.form = form;

          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16">
              <Title className="text-xl pb-2">{I18n.t('Portfolio details')}</Title>
              <Field name="title">
                {({input, meta}) => (
                  <div className="pb-3 sm:w-4/5">
                    <Label input={input}>{I18n.t('experience title', null, true)}</Label>
                    <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('experience title'))}/>
                  </div>
                )}
              </Field>

              <div className="sm:flex pb-3">
                <Field name="role">
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-5">
                      <Label input={input}>{I18n.t('your role', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('your role'))}/>
                    </div>
                  )}
                </Field>
                <Field name="type_id">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('experience type', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option>-</option>
                        {experience_types && experience_types.map((item, key) => {
                          return (<option key={key} value={item.id}>{item.title}</option>);
                        })}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex pb-3">
                <Field name="company">
                  {({input, meta}) => (
                    <div className="flex-1 md:pr-5">
                      <Label input={input}>{I18n.t('on behalf of', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('on behalf of'))}/>
                    </div>
                  )}
                </Field>
                <Field name="startYear">
                  {({input, meta}) => (
                    <div className="flex-1 md:w-1/4 md:pr-2">
                      <Label input={input}>{I18n.t('from', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option>-</option>
                        {_.range(year, (year - 50)).map((year, key) =>
                          <option value={year} key={key}>{year}</option>)}
                      </Select>
                    </div>
                  )}
                </Field>
                <Field name="endYear">
                  {({input, meta}) => (
                    <div className="flex-1 md:w-1/4 md:pl-3">
                      <Label input={input}>{I18n.t('to', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option>-</option>
                        {_.range((year + 5), (year - 50)).map((year, key) =>
                          <option value={year} key={key}>{year}</option>)}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>

              <Field name="description">
                {({input, meta}) => (
                  <div className="sm:w-4/5">
                    <Label input={input} className="text-gray-60 text-lg font-semibold text-xl pt-10 pb-2 -ml-4">
                      {I18n.t('Description')}
                    </Label>
                    <TextArea input={input} meta={meta} className="min-h-48"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('save', null, false, true)}
                </FilledButton>
              </div>
            </form>
          )
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    experience_types: state.experience_types.experience_types,
    experience: state.experience.experience,
    experience_error: state.experience.error,
    experience_updating: state.experience.updating,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExperienceTypes: () => dispatch(ExperienceTypeActions.experienceTypesRequest()),
    updateExperience: (object) => dispatch(ExperienceActions.experienceUpdateRequest(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioOverviewPage);
