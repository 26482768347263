import React from 'react';
import Title from '../../../../Components/Text/Title';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Body from '../../../../Components/Text/Body';
import {connect} from 'react-redux';
import ProjectTypeActions from '../../../../Redux/Project/ProjectRedux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';

class DeletePage extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
    project: PropTypes.any,
    deleteProject: PropTypes.func,
    deleting: PropTypes.bool,
    project_error: PropTypes.any,
    project_deleting: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidUpdate(prevProps) {
    //delete permissions
    if (!this.props.project_deleting) {
      if ((this.props.project !== prevProps.project) && this.state.deleting) {
        this.setState({deleting: false}, function () {
          this.props.history.push('/agency/projects');
        });
      } else if ((this.props.project_error !== prevProps.project_error) && this.state.deleting) {
        this.setState({deleting: false});
      }
    }
  }

  onDelete = (e) => {
    e.preventDefault();

    if (confirm('Are you sure you want to delete this project?')) {
      const { project } = this.props;
      if(project){
        this.setState({deleting: true}, function () {
          this.props.deleteProject(project.id);
        });
      }
    }
  };

  render() {
    const {project} = this.props;

    return (
      <div className="flex-1 pl-3 sm:px-8">
        <Title className="text-xl pb-2">{I18n.t('Are you sure?')}</Title>
        <Body className="text-secondary-40 ">
          {I18n.t('Are you sure you want to delete :thing?', {thing: project ? Helper.makeBold(project.title) : null})}
          <br/>
          {I18n.t('There\'s no way back so make sure you\'re sure.')}
        </Body>

        <div className="flex mt-10">
          <FilledButton onClick={this.onDelete} icon="trash-alt" className="rounded text-white bg-negative">
            {I18n.t('Yes, delete it!')}
          </FilledButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.project,
    project_error: state.project.error,
    project_deleting: state.project.deleting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch(ProjectTypeActions.deleteProjectRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePage);
