import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    createSubscriptionRequest: ['obj'],
    createSubscriptionSuccess: ['response'],
    createSubscriptionFailure: ['error'],

    deleteSubscriptionRequest: [],
    deleteSubscriptionSuccess:['response'],
    deleteSubscriptionFailure:['error'],

    changeSubscriptionRequest: ['obj'],
    changeSubscriptionSuccess:['response'],
    changeSubscriptionFailure:['error'],

    clearSubscriptionState:[],
});

export const SubscriptionTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    response: null,
    error: null,
    creating:false,
    fetching: false,
    updating: false,
    updated:false,
    deleting:false,
    deleted:false,
});

/* ------------- Reducers ------------- */

// choose as subsription request
export const create_subscription_request = (state) => state.merge({ creating: true });

// successfully chose a subscription
export const create_subscription_success = (state, data) => {
    const { response } = data;
    return state.merge({ creating: false, error: null, response })
};

export const create_subscription_failure = (state, { error }) => state.merge({ creating: false, response: null, error });

// delete a subsription request
export const delete_subscription_request = (state) => state.merge({ deleting: true });

// successfully deleted a subscription
export const delete_subscription_success = (state, data) => {
    const { response } = data;
    return state.merge({ deleting: false, error: null, response, deleted: true })
};

export const delete_subscription_failure = (state, { error }) => state.merge({ deleting: false, response: null, error });

// change a subsription request
export const change_subscription_request = (state) => state.merge({ updating: true });

// successfully changed a subscription
export const change_subscription_success = (state, data) => {
    const { response } = data;
    return state.merge({ updating: false, error: null, response, updated:true })
};

export const change_subscription_failure = (state, { error }) => state.merge({ updating: false, response: null, error });

export const clear_subscription_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CREATE_SUBSCRIPTION_REQUEST]: create_subscription_request,
    [Types.CREATE_SUBSCRIPTION_SUCCESS]: create_subscription_success,
    [Types.CREATE_SUBSCRIPTION_FAILURE]: create_subscription_failure,

    [Types.DELETE_SUBSCRIPTION_REQUEST]: delete_subscription_request,
    [Types.DELETE_SUBSCRIPTION_SUCCESS]: delete_subscription_success,
    [Types.DELETE_SUBSCRIPTION_FAILURE]: delete_subscription_failure,

    [Types.CHANGE_SUBSCRIPTION_REQUEST]: change_subscription_request,
    [Types.CHANGE_SUBSCRIPTION_SUCCESS]: change_subscription_success,
    [Types.CHANGE_SUBSCRIPTION_FAILURE]: change_subscription_failure,


    [Types.CLEAR_SUBSCRIPTION_STATE]: clear_subscription_state,

});
