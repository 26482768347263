import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import Select from '../../../../Components/Form/Select';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import TextArea from '../../../../Components/Form/TextArea';
import DatePicker from '../../../../Components/Form/DatePicker';
import ProjectTypesTypesActions from '../../../../Redux/ProjectTypes/ProjectTypesRedux';
import ProjectTypeActions from '../../../../Redux/Project/ProjectRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Toggle from '../../../../Components/Form/Toggle';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddSingleImage from '../../../../Components/Images/AddSingleImage';
import Loading from '../../../../Components/Loading';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    getProjectTypes: PropTypes.func,
    updateProject: PropTypes.func,
    project_types: PropTypes.array,
    project: PropTypes.any,
    project_updating: PropTypes.bool,
    project_error: PropTypes.any,
    uploadSingleImage: PropTypes.func,
    logo_uploading: PropTypes.bool,
    logo_uploaded: PropTypes.bool,
    logo: PropTypes.any,
    clearUploadCareState: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      logo: null,
      updating: false,
      saved: false,
      logo_uploading: false,
    };

    this.form = null;
  }

  componentDidMount() {
    this.props.getProjectTypes();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.project_updating) {
      if ((this.props.project !== prevProps.project) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.project_error !== prevProps.project_error) && this.state.updating) {
        this.onError(this.props.project_error);
      }
    }

    if (!this.props.logo_uploading) {
      if ((this.props.logo !== prevProps.logo) && this.state.logo_uploading) {
        this.setState({logo_uploading: false, logo: this.props.logo}, function () {
          let newObj = Object.assign({}, this.props.project);
          newObj.logo = this.props.logo;
          this.props.updateProject(this.props.project.id, newObj);
        });
      }
    }
  }

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  onSubmit = (value) => {
    const {project} = this.props;

    this.setState({updating: true, saved: false}, function () {
      this.props.updateProject(project.id, value);
    });
  };

  validate() {
    const {updating} = this.state, {project_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = project_error ? {...project_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  uploadedFile = (file) => {
    this.setState({updating: true,logo: this.props.logo}, function () {
      let newObj = Object.assign({}, this.props.project);
      newObj.logo = file;
      this.props.updateProject(this.props.project.id, newObj);
    });
  };

  componentWillUnmount() {
    this.setState({file: null});
  }

  render() {
    const {project_types} = this.props, {t} = this.context, {logo_uploading} = this.state;
    let project = {...this.props.project};

    if (project) {
      project.company_visible = project.company_visible ? '1' : '0';
    }

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={project}
      >
        {({handleSubmit, form}) => {
          this.form = form;

          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
              <Title className="text-xl pb-2">{I18n.t('Project details')}</Title>
              <Field name="title" validate={required}>
                {({input, meta}) => (
                  <div className="pb-3 sm:w-4/5">
                    <Label input={input}>{I18n.t('project title', null, true)}</Label>
                    <TextField input={input} meta={meta} placeholder={t('project title')}/>
                  </div>
                )}
              </Field>

              <div className="sm:flex pb-3">
                <Field name="company" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-5">
                      <Label input={input}>{I18n.t('company', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={t('company')}/>
                    </div>
                  )}
                </Field>
                <Field name="type_id" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('project type', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option>-</option>
                        {project_types.map((type, key) => {
                          return (
                            <option key={key} value={type.id}>{type.title}</option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex pb-3">
                <Field name="starts_at" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:w-1/4 md:pr-2">
                      <Label input={input}>{I18n.t('from', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
                <Field name="ends_at" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:w-1/4 md:pl-3">
                      <Label input={input}>{I18n.t('to', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>

                <Field name="company_visible" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:pl-3">
                      <Label input={input}>{I18n.t('Visibility', null, true)}</Label>
                      <Toggle input={input} meta={meta} on="public" off="private"/>
                    </div>
                  )}
                </Field>
              </div>

              <Title className="text-xl pt-10 pb-2">{I18n.t('logo', null, false, true)}</Title>

              {project ?
                <AddSingleImage
                  alt="project logo"
                  aspectRatio={1}
                  disabled={logo_uploading}
                  uploadable
                  uploadedFile={this.uploadedFile}
                  className="max-w-20 max-h-20 mb-2"
                  image={project.logo}
                />
                : <Loading/>
              }

              <Field name="description" validate={required}>
                {({input, meta}) => (
                  <div className="sm:w-4/5">
                    <Label input={input} className="text-gray-60 text-lg font-semibold text-xl pt-10 pb-2 -ml-4">{I18n.t('Description')}</Label>
                    <TextArea input={input} meta={meta} className="min-h-48"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" icon="edit" disabled={this.state.updating} saved={this.state.saved} className="rounded text-white bg-secondary-40">
                  {I18n.t('save project', null, false, true)}
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project_types: state.project_types.project_types ? state.project_types.project_types : [],
    project_types_fetching: state.project_types.fetching,
    project_types_error: state.project_types.error,

    project: state.project.project,
    project_fetching: state.project.fetching,
    project_updating: state.project.updating,
    project_error: state.project.error,

    logo: state.upload_care.file,
    logo_uploading: state.upload_care.uploading,
    logo_uploaded: state.upload_care.uploaded,
    logo_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectTypes: () => dispatch(ProjectTypesTypesActions.projectTypesRequest()),
    updateProject: (id, obj) => dispatch(ProjectTypeActions.updateProjectRequest(id, obj)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
