import React from 'react';
import {connect} from 'react-redux';
import I18n from '../../../Utils/I18n';
import PropTypes from 'prop-types';
import Helper from "../../../Utils/Helper";
import {maxLength} from "../../../Constants/Breadcrumbs";

const ProjectRoleBreadcrumb = ({name}) => <span>{I18n.t(name ? Helper.ellipsis(name, maxLength) : 'add', null, false, true)}</span>;

ProjectRoleBreadcrumb.propTypes = {
  name: PropTypes.string,
};

// find the role in the store with the `id` from the route
function mapStateToProps(state) {
  const name = state.role.role ? state.role.role.type : null;
  return {
    name,
  };
}

export default connect(mapStateToProps)(ProjectRoleBreadcrumb);
