import React from 'react';
import Translations from '../Components/Translations';

class I18n {
  static t(key, params = null, uppercase = false, titlecase = false) {
    return <Translations params={params} uppercase={uppercase} titlecase={titlecase}>{key}</Translations>;
  }
}

export default I18n;
