import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import I18n from '../../Utils/I18n';
import uploadcare from 'uploadcare-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';

class AddMedia extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    uploadable: PropTypes.bool,
    uploadedFile: PropTypes.func,
    disabled: PropTypes.bool,
    onlyImages: PropTypes.bool,
  };

  selectUploadCareFile = async (uploadedFile,onlyImages) => {
    uploadcare.start({
      publicKey: process.env.UPLOADCARE_PUBLIC_KEY,
      effects: 'blur,sharp,grayscale',
    });
    uploadcare.registerTab('preview', uploadcareTabEffects);
    try {
      const file = await uploadcare.openDialog(null,
        {imagesOnly: onlyImages, crop: '', rotate: ''},
      );

      uploadedFile(file);
    } catch (e) {
      //
    }
  };

  render() {
    const {className, disabled, uploadedFile, onlyImages} = this.props;

    return <div className={disabled ? 'opacity-25' : ''}>
      <a onClick={() => this.selectUploadCareFile(uploadedFile, onlyImages)} className={`cursor-pointer flex${className ? ` ${className}` : ''}`}>
        <div className="flex items-center px-4 py-2 bg-secondary-40 text-white rounded">
          <Icon className="mr-3 text-sm">plus</Icon>
          <p className="font-bold text-sm w-full">{I18n.t('Add media')}</p>
        </div>
      </a>
      <input type="file" name="file" id="file" className="appearance-none hidden" onChange={uploadedFile}/>
    </div>;
  }
}

export default AddMedia;
