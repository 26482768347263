import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ColorSelect from '../../../../Components/Form/ColorSelect';
import NumberField from '../../../../Components/Form/NumberField';
import Toggle from '../../../../Components/Form/Toggle';
import PropertyTypesActions from '../../../../Redux/PropertyTypes/PropertyTypesRedux';
import PropertiesActions from '../../../../Redux/Talent/PropertiesRedux';
import PropertyActions from '../../../../Redux/Talent/PropertyRedux';
import _ from 'lodash';
import Select from '../../../../Components/Form/Select';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';
import AccountActions from '../../../../Redux/Account/AccountRedux';

class AppearancePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    property_types: PropTypes.any,
    property_types_fetching: PropTypes.bool,
    property_types_error: PropTypes.any,
    getPropertyTypes: PropTypes.func,
    getProperties: PropTypes.func,
    properties: PropTypes.any,
    properties_fetching: PropTypes.bool,
    properties_error: PropTypes.any,
    updateProperties: PropTypes.func,
    updating: PropTypes.bool,
    property: PropTypes.any,
    property_fetching: PropTypes.bool,
    property_updated: PropTypes.bool,
    property_error: PropTypes.any,
    getAccount: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.getPropertyTypes();
    this.props.getProperties(this.props.account.username);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.property_fetching) {
      if (this.props.property_updated && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.property_error !== prevProps.property_error) && this.state.updating) {
        this.onError(this.props.property_error);
      }
    }
  }

  onSubmit = (obj) => {
    obj = this.removeIdFromFormFields(obj);
    this.setState({updating: true, saved: false}, function () {
      this.props.updateProperties({properties: obj, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true}, ()=>{
      this.props.getAccount();
    });
  };


  addIdToPropertiesName(properties) {
    return properties && properties.map(function (item) {
      return {
        ...item,
        name: 'id' + item.id,
      };
    });
  }

  removeIdFromFormFields(fields) {
    fields = _.mapKeys(fields, function (value, key) {
      return key.replace('id', '');
    });
    return fields;
  }

  convertPropertiesToInitialValues(properties) {
    var object = {};
    properties.forEach(function (item) {
      object['id' + item.type_id] = item.value;
    });
    return object;
  }

  renderDropdownField(property_type) {
    return (<Field key={property_type.id} name={property_type.name}>
      {({input, meta}) => (
        <div className="md:pr-5 pb-3 flex-1">
          <Label input={input}>{property_type.title.toUpperCase()}</Label>
          <Select input={input} meta={meta} className="w-full">
            <option value="">-</option>
            {property_type.options.map((option, i) => {
              return (<option key={i} value={option}>{option}</option>);
            })}
          </Select>
        </div>
      )}
    </Field>);
  }

  renderNumberField(property_type) {
    return (<Field key={property_type.id} name={property_type.name}>
      {({input, meta}) => (
        <div className="md:pr-5 pb-3 flex-1">
          <Label input={input}>{property_type.title.toUpperCase()}</Label>
          <NumberField input={input} meta={meta} placeholder={property_type.instructions}/>
        </div>
      )}
    </Field>);
  }

  renderLightswitchField(property_type) {
    return (<Field key={property_type.id} name={property_type.name}>
      {({input, meta}) => (
        <div className="md:pr-5 pb-3 w-full">
          <Label input={input}>{property_type.title.toUpperCase()}</Label>
          <Toggle input={input} meta={meta}/>
        </div>
      )}
    </Field>);
  }

  renderColorField(property_type) {
    return (<Field key={property_type.id} name={property_type.name}>
      {({input, meta}) => (
        <div className="md:pr-5 pb-5 w-full">
          <Label input={input}>{property_type.title.toUpperCase()}</Label>
          <ColorSelect input={input} meta={meta}>
            {property_type.options}
          </ColorSelect>
        </div>
      )}
    </Field>);
  }

  render() {
    const {properties, property_types} = this.props;
    const formatted_property_types = Array.isArray(property_types) ? this.addIdToPropertiesName(property_types) : [];
    const initialValues = Array.isArray(properties) ? this.convertPropertiesToInitialValues(properties) : [];
    return (
      <Form
        onSubmit={this.onSubmit}
        validateOnBlur
        initialValues={initialValues}
      >
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16">
            <Title className="text-xl pb-2">{I18n.t('What are your physical characteristics?')}</Title>

            <div className="md:flex flex-wrap pb-3">
              {formatted_property_types
                ? formatted_property_types.map((property_type) => {
                  switch (property_type.type) {
                    case 'number':
                      return (this.renderNumberField(property_type));
                    case 'colors':
                      return (this.renderColorField(property_type));
                    case 'lightswitch':
                      return (this.renderLightswitchField(property_type));
                    case 'buttons':
                    case 'dropdown':
                      return (this.renderDropdownField(property_type));
                  }
                })
                : <Loading/>
              }
            </div>

            <div className="mt-10">
              <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                {I18n.t('save', null, false, true)}
              </FilledButton>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    property_types: state.property_types.property_types,
    property_types_fetching: state.property_types.property_types_fetching,
    property_types_error: state.property_types.property_types_error,
    properties: state.properties.properties,
    properties_fetching: state.properties.properties_fetching,
    properties_error: state.properties.properties_error,
    property: state.property.property,
    property_fetching: state.property.property_fetching,
    property_error: state.property.property_error,
    property_updated: state.property.updated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
    getPropertyTypes: () => dispatch(PropertyTypesActions.propertyTypesRequest()),
    getProperties: (username) => dispatch(PropertiesActions.propertiesRequest(username)),
    updateProperties: (obj) => dispatch(PropertyActions.propertyUpdateRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppearancePage);
