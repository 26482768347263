import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  roleByIdRequest: ['obj'],
  roleByIdSuccess: ['role'],
  roleByIdFailure: ['error'],

  createRoleRequest: ['obj'],
  createRoleSuccess: ['role'],
  createRoleFailure: ['error'],

  updateRoleRequest: ['obj'],
  updateRoleSuccess: ['role'],
  updateRoleFailure: ['error'],

  deleteRoleRequest: ['obj'],
  deleteRoleSuccess: [],
  deleteRoleFailure: ['error'],

  clearRoleState: [],

});

export const RoleTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  store: false,
  updating: false,
  deleting: false,
  role: null,
  error: null,
  fetching: null,
});

/* ------------- Reducers ------------- */

export const request = (state) => state.merge({fetching: true, error: null});

export const success = (state, data) => {
  const {role} = data;
  return state.merge({fetching: false, error: null, role})
};

export const failure = (state, {error}) => state.merge({fetching: false, role: null, error});

export const create_request = (state) => state.merge({store: true, role: null});

export const create_success = (state, data) => {
  const {role} = data;
  return state.merge({store: false, error: null, role})
};

export const create_failure = (state, {error}) => state.merge({store: false, role: null, error});

export const update_request = (state) => state.merge({updating: true});

export const update_success = (state, data) => {
  const {role} = data;
  return state.merge({updating: false, error: null, role})
};

export const update_failure = (state, {error}) => state.merge({updating: false, role: null, error});

export const delete_request = (state) => state.merge({deleting: true});

export const delete_success = (state) => {
  return state.merge({deleting: false, error: null, role: null})
};

export const delete_failure = (state, {error}) => state.merge({deleting: false, error});

export const clear_role_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ROLE_BY_ID_REQUEST]: request,
  [Types.ROLE_BY_ID_SUCCESS]: success,
  [Types.ROLE_BY_ID_FAILURE]: failure,

  [Types.CREATE_ROLE_REQUEST]: create_request,
  [Types.CREATE_ROLE_SUCCESS]: create_success,
  [Types.CREATE_ROLE_FAILURE]: create_failure,

  [Types.UPDATE_ROLE_REQUEST]: update_request,
  [Types.UPDATE_ROLE_SUCCESS]: update_success,
  [Types.UPDATE_ROLE_FAILURE]: update_failure,

  [Types.DELETE_ROLE_REQUEST]: delete_request,
  [Types.DELETE_ROLE_SUCCESS]: delete_success,
  [Types.DELETE_ROLE_FAILURE]: delete_failure,

  [Types.CLEAR_ROLE_STATE]: clear_role_state,
});
