import React from 'react';
import {connect} from 'react-redux';
import I18n from '../../../Utils/I18n';
import PropTypes from 'prop-types';
import Helper from "../../../Utils/Helper";
import {maxLength} from "../../../Constants/Breadcrumbs";

const AgencyCastingBreadcrumb = ({plans, match}) => {
  const {planId} = match.params;
  const plan = plans.find(x => x.id === parseInt(planId));
  const name = plan ? plan.title : null;
  return (<span>{name ? Helper.ellipsis(name, maxLength) : I18n.t('add', null, false, true)}</span>);
};

AgencyCastingBreadcrumb.propTypes = {
  name: PropTypes.string,
  plans: PropTypes.any,
  match: PropTypes.any,
};

// find the casting in the store with the `id` from the route
function mapStateToProps(state) {
  const plans = state.plans.plans ? state.plans.plans : [];
  return {
    plans
  };
}

export default connect(mapStateToProps)(AgencyCastingBreadcrumb);
