import PropTypes from 'prop-types';
import React from 'react';
import {connectRange} from 'react-instantsearch-dom';
import Icon from '../Icon';

class NumberRange extends React.Component {
  static propTypes = {
    attribute: PropTypes.string,
    currentRefinement: PropTypes.any,
    refine: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const {attribute, currentRefinement, min, max, refine} = this.props;

    return (
      <div className="flex flex-wrap">
        <div className="flex-1 -mb-3">
          <input
            type="number"
            value={currentRefinement.min || 0}
            className="input-field"
            id={attribute}
            onChange={event =>
              refine({
                ...currentRefinement,
                min: event.currentTarget.value,
              })} placeholder={min} min={min} step={1} max={max}/>
        </div>

        <Icon className="mx-2 my-auto text-gray-20">angle-double-right</Icon>

        <div className="flex-1 -mb-3">
          <input
            type="number"
            value={currentRefinement.max || ''}
            className="input-field"
            onChange={event =>
              refine({
                ...currentRefinement,
                max: event.currentTarget.value,
              })} placeholder={max} min={min} step={1} max={max}/>
        </div>
      </div>
    );
  }
}

export default connectRange(NumberRange);
