import React from 'react';
import Title from '../../../../Components/Text/Title';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Body from '../../../../Components/Text/Body';
import {connect} from 'react-redux';
import RoleActions from '../../../../Redux/Project/RoleRedux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';

class DeletePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    project: PropTypes.any,
    role_deleting: PropTypes.bool,
    role: PropTypes.any,
    role_error: PropTypes.any,
    deleteRole: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidUpdate(prevProps) {
    //delete role
    if (!this.props.role_deleting) {
      if ((this.props.role !== prevProps.role) && this.state.deleting) {
        this.setState({deleting: false}, function () {
          this.props.history.push(`/agency/projects/${this.props.project.id}/project-roles`);
        });
      } else if ((this.props.role_error !== prevProps.role_error) && this.state.deleting) {
        this.setState({deleting: false});
      }
    }
  }

  onDelete = (e) => {
    e.preventDefault();
    const {role, project} = this.props;
    const obj = {
      role_id: role.id,
      project_id: project.id,
    };
    if (confirm('Are you sure you want to delete this role?')) {
      this.setState({deleting: true}, function () {
        this.props.deleteRole(obj);
      });
    }
  };

  render() {
    return (
      <div className="flex-1 pl-3 sm:px-8">
        <Title className="text-xl pb-2">{I18n.t('Are you sure?')}</Title>
        <Body className="text-secondary-40 ">
          {I18n.t('Are you sure you want to delete :thing?', {thing: Helper.makeBold(I18n.t('this role'))})}
          <br/>
          {I18n.t('There\'s no way back so make sure you\'re sure.')}
        </Body>

        <div className="flex mt-10">
          <FilledButton onClick={this.onDelete} icon="trash-alt" className="rounded text-white bg-negative">
            {I18n.t('Yes, delete it!')}
          </FilledButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.project,
    role_deleting: state.role.deleting,
    role: state.role.role,
    role_error: state.role.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRole: (obj) => dispatch(RoleActions.deleteRoleRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePage);
