import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {Link} from 'react-router-dom';
import Icon from '../Icon';
import ProjectBreadcrumb from './BreadCrumbs/ProjectBreadcrumb';
import I18n from '../../Utils/I18n';
import ProjectRoleBreadcrumb from './BreadCrumbs/ProjectRoleBreadcrumb';
import PortfolioBreadcrumb from './BreadCrumbs/PortfolioBreadcrumb';
import CastingBreadcrumb from './BreadCrumbs/CastingBreadcrumb';
import AgencyCastingBreadcrumb from './BreadCrumbs/AgencyCastingBreadcrumb';
import PropTypes from "prop-types";
import SubscriptionCheckoutBreadcrumb from "./BreadCrumbs/SubscriptionCheckoutBreadcrumb";

// define some custom breadcrumbs for certain routes (optional)
const routes = [
  {path: '/', breadcrumb: 'Dashboard'},
  {path: '/talent', breadcrumb: null},
  {path: '/talent/dashboard', breadcrumb: null},
  {path: '/agency', breadcrumb: null},
  {path: '/agency/dashboard', breadcrumb: null},
  {path: '/agency/projects/:project', breadcrumb: ProjectBreadcrumb},
  {path: '/agency/projects/:project/project-roles/:role', breadcrumb: ProjectRoleBreadcrumb},
  {path: '/agency/projects/:project/project-roles/:role/castings/:casting', breadcrumb: AgencyCastingBreadcrumb},
  {path: '/agency/projects/:project/project-roles/:role/people', breadcrumb: 'talent'},
  {path: '/agency/projects/:project/project-roles/:role/castings', breadcrumb: 'talent'},
  {path: '/talent/experiences/:experience', breadcrumb: PortfolioBreadcrumb},
  {path: '/talent/projects/:casting', breadcrumb: CastingBreadcrumb},
  {path: '/talent/projects/:casting/your-role', breadcrumb: 'your role'},
  {path: '/talent/settings/subscription/:planId', breadcrumb: null},
  {path: '/talent/settings/subscription/:planId/checkout', breadcrumb: SubscriptionCheckoutBreadcrumb},
  {path: '*/delete', breadcrumb: 'delete'},
  {path: '*/overview', breadcrumb: null},
];

const Breadcrumbs = ({breadcrumbs}) => {
  const slicedBreadcrumbs = breadcrumbs.slice(-4);
  return (
    <div>
      <h1
        className="font-light text-xl sm:text-2xl text-primary-80 pt-2 sm:pt-0">{renderTranslated(slicedBreadcrumbs.slice(-1).pop().breadcrumb)}</h1>
      <div className="hidden sm:flex">
        {
          slicedBreadcrumbs.map(({
                                   match,
                                   breadcrumb,
                                   // other props are available during render, such as `location`
                                   // and any props found in your route objects will be passed through too
                                 }, key) => {
            return (
              <div key={match.url}>
                <Link to={match.url} className="text-gray-30 text-sm">
                  {renderTranslated(breadcrumb)}
                </Link>
                {key < slicedBreadcrumbs.length - 1 &&
                <Icon className="mx-3 text-gray-20 text-xs">chevron-right</Icon>
                }
              </div>
            );
          })
        }
      </div>
    </div>
  )
};

const renderTranslated = (breadcrumb) => {
  try {
    return <span>{I18n.t(breadcrumb.props.children.replace('-', ' '), null, false, true)}</span>;
  } catch (e) {
    return breadcrumb;
  }
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default withBreadcrumbs(routes, {disableDefaults: false})(Breadcrumbs);

