import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import TalentRoute from '../../Components/Routes/TalentRoute';
// Routes
import OverviewPage from '../../Containers/Talent/Project/SubPages/OverviewPage';
import YourRolePage from '../../Containers/Talent/Project/SubPages/YourRolePage';
import AuditionPage from "../../Containers/Talent/Project/SubPages/AuditionPage";
import ChatRoomPage from '../../Containers/Talent/Project/SubPages/ChatRoomPage';

class ProjectRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <TalentRoute exact path="/talent/projects/:project/overview" params={{name: 'Projects'}} component={OverviewPage}/>
        <TalentRoute exact path="/talent/projects/:project/your-role" params={{name: 'Projects'}} component={YourRolePage}/>
        <TalentRoute exact path="/talent/projects/:casting/audition" params={{name: 'Projects'}} component={AuditionPage}/>
        <TalentRoute exact path="/talent/projects/:casting/chatroom" params={{name: 'Projects'}} component={ChatRoomPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(ProjectRoutingComponent));
