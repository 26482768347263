import React from 'react';
import FilledButton from '../../Components/Buttons/FilledButton';
import Card from '../../Components/Card';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginActions from '../../Redux/Auth/LoginRedux';
import {connect} from 'react-redux';
import {Field, Form} from 'react-final-form';
import Label from '../../Components/Form/Label';
import TextField from '../../Components/Form/TextField';
import Checkbox from '../../Components/Form/Checkbox';
import PasswordField from '../../Components/Form/PasswordField';
import I18n from '../../Utils/I18n';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import FormError from '../../Components/Form/FormError';
import FacebookButton from '../../Components/Buttons/FacebookButton';
import Icon from '../../Components/Icon';

class LoginPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    attemptLogin: PropTypes.func,
    error: PropTypes.any,
    attemptSocialLogin: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitLogin: false,
      hasLoginError: false,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleSocialLoginLinkedIn);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {submitLogin} = this.state;

    if (!newProps.fetching) {
      if (newProps.error && submitLogin) {
        this.setState({submitLogin: false, hasLoginError: true});
      } else if (newProps.login && submitLogin) {
        this.setState({submitLogin: false, hasLoginError: false}, function () {
          this.props.history.push('/');
        });
      }
    }
  }

  onSubmit = (value) => {
    this.setState({submitLogin: true, hasLoginError: false}, function () {
      this.props.attemptLogin(value.username, value.password);
    });
  };

  validate() {
  }

  handleSocialLoginFacebook = (user) => {
    const {attemptSocialLogin} = this.props;
    this.setState({submitLogin: true}, function () {
      attemptSocialLogin('facebook', user._token.accessToken);
    });
  };

  handleSocialLoginLinkedIn = (event) => {
    const {attemptSocialLogin} = this.props;
    if (event.data.token && event.data.provider === 'linkedin') {
      this.setState({submitLogin: true}, function () {
        attemptSocialLogin('linkedin', event.data.token);
      })
    }
  };

  handleSocialLoginFailure = (err) => {
    return err;
  };

  render() {
    const {t} = this.context;

    return (
      <div className="flex flex-col-reverse lg:flex-row justify-between sm:mx-6">

        <Card className="flex-1 mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('New to The Casting Director?')}</h2>
          <h3 className="font-semibold text-primary-80 text-2xl text-center pb-6">{I18n.t('Register now!')}</h3>

          <div className="flex flex-col">
            <FilledButton to="/register/talent" iconRight className="rounded mb-5 mx-auto text-white bg-secondary-40"
                          icon="hand-spock">{I18n.t('I\'m a talent')}</FilledButton>
            <FilledButton to="/register/agency" iconRight className="rounded mb-5 mx-auto text-white bg-secondary-40"
                          icon="briefcase">{I18n.t('I\'m an agency')}</FilledButton>
          </div>
        </Card>

        <Card className="flex-1 mx-4 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('Welcome back!')}</h2>
          <h3 className="font-semibold text-primary-80 text-2xl text-center pb-6">{I18n.t('Login with...')}</h3>

          <div className="max-w-2xs mx-auto">
            <FacebookButton
              provider='facebook'
              appId={process.env.FB_ID}
              onLoginSuccess={this.handleSocialLoginFacebook}
              onLoginFailure={this.handleSocialLoginFailure}
            >
              Facebook
            </FacebookButton>
            <LinkedIn
              className="appearance-none text-left w-full"
              clientId={process.env.LINKEDIN_ID}
              scope={'r_emailaddress,r_liteprofile,w_member_social'}
              onFailure={this.handleSocialLoginFailure}
              onSuccess={this.handleSocialLoginLinkedIn}
              redirectUri={process.env.API_URL + '/auth/socialite/callback/linkedin/'}
            >
              <div className="flex items-center px-4 py-2 rounded mb-5 text-white bg-linkedin">
                <Icon className="mr-3 text-sm">{['fab', 'linkedin']}</Icon>
                <p className="font-bold text-sm w-full">Linkedin</p>
              </div>
            </LinkedIn>
          </div>
        </Card>

        <Card className="flex-1 mx-4 pb-8 p-8 mt-6 lg:p-16 lg:mt-24">
          <h2 className="font-light text-primary-80 text-2xl text-center">{I18n.t('Welcome back!')}</h2>
          <h3 className="font-semibold text-primary-80 text-2xl text-center pb-6">{I18n.t('Login now')}</h3>

          <Form onSubmit={this.onSubmit} validate={this.validate.bind(this)} validateOnBlur>
            {({handleSubmit, form}) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit} className="max-w-xs mx-auto">
                  <Field name="username">
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute opacity-0 h-0">{I18n.t('Username', null, true)}</Label>
                        <TextField input={input} meta={meta} placeholder={t('Username')}/>
                      </div>
                    )}
                  </Field>
                  <Field name="password">
                    {({input, meta}) => (
                      <div>
                        <Label input={input} className="absolute opacity-0 h-0">{I18n.t('Password', null, true)}</Label>
                        <PasswordField input={input} meta={meta} placeholder={t('Password')}/>
                      </div>
                    )}
                  </Field>

                  <FormError className={'flex-1'} meta={{
                    touched: this.state.hasLoginError,
                    error: 'A user with this username or password could not be found',
                  }}/>

                  <div className="flex flex-wrap justify-between items-center mt-3">
                    <Field name="remember_me" type="checkbox">
                      {({input}) => (
                        <Checkbox input={input} className="text-sm">{I18n.t('Remember me')}</Checkbox>
                      )}
                    </Field>
                    <FilledButton disabled={this.state.submitLogin} type="submit" iconRight icon="chevron-right"
                                  className="rounded text-white bg-primary-40">
                      {I18n.t('Login')}
                    </FilledButton>
                  </div>
                </form>
              );
            }}
          </Form>

          <Link to="/forgot-password" className="block text-xs mt-10 text-center text-gray-40">
            {I18n.t('Forgot your password?')}
          </Link>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.account,

    fetching: state.login.fetching,
    error: state.login.error,
    login: state.login.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptLogin: (username, password) => dispatch(LoginActions.loginRequest(username, password)),
    attemptSocialLogin: (provider, token) => dispatch(LoginActions.socialLoginRequest(provider, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
