import {call, put} from 'redux-saga/effects'
import RoleActions from '../../Redux/Project/RoleRedux'
import {callApi} from '../CallApiSaga'

export function* getRoleById(api, {obj}) {
  const apiCall = yield call(api.getRoleById, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(RoleActions.roleByIdSuccess(response.data));
  } else {
    yield put(RoleActions.roleByIdFailure('WRONG'))
  }
}

export function* createRole(api, {obj}) {
  const apiCall = yield call(api.createRole, obj);
  const response = yield call(callApi, apiCall);
  // success?

  if (response.ok) {
    yield put(RoleActions.createRoleSuccess(response.data));
  } else {
    yield put(RoleActions.createRoleFailure(response.data.message ? {
      message: response.data.message,
      status: response.status
    } : {message: 'Error', status: 500}))
  }
}

export function* updateRole(api, {obj}) {

  const apiCall = yield call(api.updateRole, obj);
  const response = yield call(callApi, apiCall);
  // success?

  if (response.ok) {
    yield put(RoleActions.updateRoleSuccess(response.data));
  } else {
    yield put(RoleActions.updateRoleFailure(response.data.message ? {
      message: response.data.message,
      status: response.status
    } : {message: 'Error', status: 500}))
  }
}

export function* deleteRole(api, {obj}) {
  const apiCall = yield call(api.deleteRole, obj);
  const response = yield call(callApi, apiCall);
  // success?

  if (response.ok) {
    yield put(RoleActions.deleteRoleSuccess(null));
  } else {
    yield put(RoleActions.deleteRoleFailure(response.data.message ? {
      message: response.data.message,
      status: response.status
    } : {message: 'Error', status: 500}))
  }
}
