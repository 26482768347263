import PropTypes from 'prop-types';
import React from 'react';

class SubNav extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
    };

    render() {
        const {children, className} = this.props;

        return (
          <nav className={`border-r border-gray-10 sm:w-48 ${className || ''}`}>
            <ul>
              {children}
            </ul>
          </nav>
        );
    }
}

export default SubNav;
