import PropTypes from 'prop-types';
import React from 'react';

class Title extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      children: PropTypes.any,
    };

    render() {
        const {className, children} = this.props;

        return (
            <h2 className={`text-gray-60 text-lg font-semibold ${className || ''}`}>
                {children}
            </h2>
        );
    }
}

export default Title;
