import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import PhoneInput from 'react-phone-number-input';

class PhoneNumber extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
    placeholder: PropTypes.string,
  };

  renderPhoneInput(error = false) {
    const {className, input, placeholder} = this.props;

    return <div className="relative inline-block w-full">
      <PhoneInput
        {...input}
        placeholder={placeholder}
        className={`${error ? ' has-error' : ''} ${className || ''}`}
        aria-labelledby={input.name}
        onBlur={input.onBlur}
      />
    </div>;
  }

  render() {
    const {meta} = this.props;

    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderPhoneInput(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderPhoneInput();
  }
}

export default PhoneNumber;
