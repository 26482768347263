import {call, put} from 'redux-saga/effects';
import UserNotificationsActions from '../../Redux/Talent/UserNotificationsRedux';
import {callApi} from '../CallApiSaga';

export function* getUnreadNotifications(api, {username}) {
  const apiCall = yield call(api.getUnreadNotifications, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UserNotificationsActions.unreadNotificationsSuccess(response.data));
  } else {
    yield put(UserNotificationsActions.unreadNotificationsFailure('WRONG'));
  }
}

export function* getReadNotifications(api, {username}) {
  const apiCall = yield call(api.getReadNotifications, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UserNotificationsActions.readNotificationsSuccess(response.data));
  } else {
    yield put(UserNotificationsActions.readNotificationsFailure('WRONG'));
  }
}

export function* markNotificationsRead(api, {username}) {
  const apiCall = yield call(api.markNotificationsRead, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UserNotificationsActions.markReadSuccess());
  } else {
    yield put(UserNotificationsActions.readNotificationsFailure('WRONG'));
  }
}


