import React from 'react';
import I18n from '../../Utils/I18n';
import {required} from '../../Validators/Form/Form';
import Label from './Label';
import DatePicker from './DatePicker';
import Select from './Select';
import {shootingDatesStatuses} from '../../Constants/Roles';
import {Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import FilledButton from '../Buttons/FilledButton';
import PropTypes from 'prop-types';
import TextButton from '../Buttons/TextButton';

class CastingDates extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {t} = this.context;

    return (
      <div>
        <FieldArray name="dates">
          {({fields}) =>
            fields.map((name, index) => (
              <div key={name} className="md:flex flex-wrap xl:flex-no-wrap pb-3">
                <Field name={`${name}.starts_at`} validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/3 md:pr-5">
                      <Label input={input}>{I18n.t('start shooting date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
                <Field name={`${name}.ends_at`} validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/3 md:pr-5">
                      <Label input={input}>{I18n.t('end shooting date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
                <Field name={`${name}.status`} validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/3">
                      <Label input={input}>{I18n.t('Shooting date status', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option value="">-</option>
                        {shootingDatesStatuses.map((item, key) => {
                          return (<option key={key} value={item}>{t(item)}</option>);
                        })}
                      </Select>
                    </div>
                  )}
                </Field>
                {fields.length > 1 &&
                <TextButton
                  onClick={() => fields.remove(index)}
                  className="flex items-center text-gray-40 hover:text-negative xl:px-5 xl:py-3 xl:mt-5 mb-4 xl:mb-auto xl:-mr-5"
                  icon="trash-alt"
                  iconLeft
                >
                  <span className="xl:hidden">{I18n.t('delete')}</span>
                </TextButton>
                }
              </div>
            ))
          }
        </FieldArray>
        <div className="md:flex flex-wrap xl:flex-no-wrap pb-3">
          <div className="flex">
            <FilledButton
              icon="plus"
              onClick={(e) => {
                e.preventDefault();
                this.props.push('dates', undefined);
              }}
              className="bg-secondary-40 text-white rounded mb-auto mt-6"
            >
              {I18n.t('add', null, false, true)}
            </FilledButton>
          </div>
        </div>
      </div>
    );
  }
}

export default CastingDates;
