import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import ProfileRoutingComponent from '../../../Components/Routing/ProfileRoutingComponent';
import I18n from '../../../Utils/I18n';
import TextButton from '../../../Components/Buttons/TextButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class ProfilePage extends React.Component {
  static propTypes = {
    account: PropTypes.object,
  };

  render() {
    const {account} = this.props;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to="/talent/profile/personalia" icon="info-circle">{I18n.t('Personalia')}</SubNavItem>
              <SubNavItem to="/talent/profile/about-me" icon="comment-dots">{I18n.t('About me')}</SubNavItem>
              <SubNavItem to="/talent/profile/photos" icon="images">{I18n.t('Photos')}</SubNavItem>
              <SubNavItem to="/talent/profile/appearance" icon="smile">{I18n.t('Appearance')}</SubNavItem>
              <SubNavItem to="/talent/profile/skills" icon="hand-spock">{I18n.t('Skills')}</SubNavItem>
              <SubNavItem to="/talent/profile/social-media" icon="icons">{I18n.t('Social media')}</SubNavItem>
              <SubNavItem to="/talent/profile/education" icon="graduation-cap">{I18n.t('Education')}</SubNavItem>
              <SubNavItem to="/talent/profile/profile-layout" icon="tools">{I18n.t('Profile layout')}</SubNavItem>

              <TextButton icon="user" iconLeft external to={`https://talents.thecastingdirector.eu/${account.username}`} className="mt-8 text-gray-40">
                <span className="hidden sm:block">
                  {I18n.t('View profile')}
                </span>
              </TextButton>
            </SubNav>

            <ProfileRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
  };
};

export default connect(mapStateToProps)(ProfilePage);
