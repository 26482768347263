import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import BaseButton from './BaseButton';
import Card from '../Card';
import I18n from '../../Utils/I18n';

class AddButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    iconLeft: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.string,
  };

  render() {
    const {className, onClick, to} = this.props;

    return <BaseButton to={to} onClick={onClick} className={`flex-1 h-full ${className || ''}`}>
      <Card className="flex justify-center items-center p-6 w-full">
        <Icon className="text-positive text-4xl">plus</Icon>
        <p className="absolute h-0 opacity-0">{I18n.t('add', null, false, true)}</p>
      </Card>
    </BaseButton>;
  }
}

export default AddButton;
