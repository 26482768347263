import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import OverviewPage from '../../../Containers/Agency/Role/SubPages/OverviewPage';
import AddOverviewPage from '../../../Containers/Agency/Role/Add/OverviewPage';
import EditOverviewPage from '../../../Containers/Agency/Role/Edit/OverviewPage';
import ExtraInfoPage from '../../../Containers/Agency/Role/SubPages/ExtraInfoPage';
import DeletePage from '../../../Containers/Agency/Role/SubPages/DeletePage';

class AgencyProjectRoleRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/add/overview" params={{name: 'Projects'}} component={AddOverviewPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/overview" params={{name: 'Projects'}} component={OverviewPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/overview/edit" params={{name: 'Projects'}} component={EditOverviewPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/briefing" params={{name: 'Projects'}} component={ExtraInfoPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/delete" params={{name: 'Projects'}} component={DeletePage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencyProjectRoleRoutingComponent));
