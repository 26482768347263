import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Select from '../../../../Components/Form/Select';
import EmailField from '../../../../Components/Form/EmailField';
import IconSelect from '../../../../Components/Form/IconSelect';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';
import {country, gender} from '../../../../Constants/PersonalInfo';
import AddSingleImage from '../../../../Components/Images/AddSingleImage';
import BirthdayPicker from '../../../../Components/Form/BirthdayPicker';
import moment from 'moment';
import PhoneNumber from '../../../../Components/Form/PhoneNumber';
import VatField from '../../../../Components/Form/VatField'

class PersonalInfoPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
    uploadSingleImage: PropTypes.func,
    avatar_uploading: PropTypes.bool,
    avatar_uploaded: PropTypes.bool,
    avatar: PropTypes.any,
    clearUploadCareState: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      avatar_uploading: false,
      saved: false,
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUploadCareState();
  }

  onSubmit = (value) => {
    this.setState({updating: true, saved: false}, function () {
      value.birthday = moment(value.birthday, 'DD/MM/YYYY').format('DD-MM-YYYY');
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  uploadedFile = (file) => {
    this.setState({updating: true}, function () {
      let newObj = Object.assign({}, this.props.account);
      newObj.avatar = file;
      this.props.updateAccount({account: newObj, username: this.props.account.username});
    });
  };

  validate() {
    const {updating} = this.state, {account_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = account_error ? {...account_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {account} = this.props, {t} = this.context, {avatar_uploading} = this.state;

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={{
          firstname: account.firstname,
          lastname: account.lastname,
          birthday: moment(account.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          gender: account.gender,
          mobile: account.mobile,
          phone: account.phone,
          email: account.email,
          agent: account.agent,
          street: account.street,
          number: account.number,
          bus: account.bus,
          city: account.city,
          postcode: account.postcode,
          country: account.country,
          vat: account.account.vat,
        }}
      >
        {({handleSubmit, form}) => {
          this.form = form;

          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16">
              <Title className="text-xl pb-2">{I18n.t('Edit your information')}</Title>
              <div className="xl:flex">
                <div className="flex-1">
                  <div className="sm:flex">
                    <Field name="firstname">
                      {({input, meta}) => (
                        <div className="pb-3 flex-1 sm:pr-5">
                          <Label input={input}>{I18n.t('first name', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder={t('first name')}/>
                        </div>
                      )}
                    </Field>
                    <Field name="lastname">
                      {({input, meta}) => (
                        <div className="pb-3 flex-1">
                          <Label input={input}>{I18n.t('last name', null, true)}</Label>
                          <TextField input={input} meta={meta} placeholder={t('last name')}/>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="md:flex">
                    <Field name="birthday">
                      {({input, meta}) => (
                        <div className="flex-1 md:pr-5">
                          <Label input={input}>{I18n.t('birthday', null, true)}</Label>
                          <BirthdayPicker input={input} meta={meta}/>
                        </div>
                      )}
                    </Field>
                    <Field name="gender">
                      {({input, meta}) => (
                        <div className="flex-1">
                          <Label input={input}>{I18n.t('gender', null, true)}</Label>
                          <IconSelect input={input} meta={meta} options={gender}/>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="sm:w-48 sm:pl-4 pt-4 sm:pt-0">
                  <Label className="pr-4 w-32 xl:ml-auto text-center" input={{name: t('avatar')}}>
                    {I18n.t('avatar', null, true)}
                  </Label>

                  <AddSingleImage
                    alt={t('avatar')}
                    disabled={avatar_uploading}
                    avatar
                    uploadable
                    uploadedFile={this.uploadedFile}
                    className="w-32 h-32 rounded-full"
                    image={account.avatar}
                  />
                </div>
              </div>

              <Title className="text-xl pt-10 pb-2">{I18n.t('Contact info')}</Title>
              <div className="sm:flex pb-3">
                <Field name="mobile">
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-5">
                      <Label input={input}>{I18n.t('phone', null, true)}</Label>
                      <PhoneNumber input={input} meta={meta} placeholder={t('phone')}/>
                    </div>
                  )}
                </Field>
                <Field name="phone">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('mobile', null, true)}</Label>
                      <PhoneNumber input={input} meta={meta} placeholder={t('mobile')}/>
                    </div>
                  )}
                </Field>
              </div>
              <Field name="email">
                {({input, meta}) => (
                  <div className="sm:w-9/12 pb-3">
                    <Label input={input}>{I18n.t('email', null, true)}</Label>
                    <EmailField input={input} meta={meta} placeholder={t('e.g. john.doe@thecastingdirector.eu')}/>
                  </div>
                )}
              </Field>
              <div className="sm:flex">
                <Field name="vat">
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-5">
                      <Label input={input}>{I18n.t('vat', null, true)}</Label>
                      <VatField input={input} meta={meta} placeholder={t('e.g. BE0999999999')}/>
                    </div>
                  )}
                </Field>
                <Field name="agent">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('agent', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={t('e.g. The Casting Director')}/>
                    </div>
                  )}
                </Field>
              </div>


              <Title className="text-xl pt-10 pb-2">{I18n.t('address', null, false, true)}</Title>

              <div className="md:flex pb-3">
                <Field name="street">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('street', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('street'))}/>
                    </div>
                  )}
                </Field>
                <Field name="number">
                  {({input, meta}) => (
                    <div className="flex-1 md:px-5">
                      <Label input={input}>{I18n.t('number', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder="10"/>
                    </div>
                  )}
                </Field>
                <Field name="bus">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('box', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder="1"/>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex">
                <Field name="postcode">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('zipcode', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder="1000"/>
                    </div>
                  )}
                </Field>
                <Field name="city">
                  {({input, meta}) => (
                    <div className="flex-1 md:px-5">
                      <Label input={input}>{I18n.t('city', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('city'))}/>
                    </div>
                  )}
                </Field>
                <Field name="country">
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('country', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option value="">—</option>
                        {country.map(country => <option key={country} value={country}>{t(country)}</option>)}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>

              <div className="mt-10">
                <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('save', null, false, true)}
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    avatar: state.upload_care.file,
    avatar_uploading: state.upload_care.uploading,
    avatar_uploaded: state.upload_care.uploaded,
    avatar_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoPage);
