import {call, put} from 'redux-saga/effects'
import UserActions from '../../Redux/Account/UserRedux'
import {callApi} from '../CallApiSaga'


export function* updatePemissions(api, {obj}) {
  const apiCall = yield call(api.updatePemissions, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UserActions.userUpdateSuccess(response.data));
  } else {
    yield put(UserActions.userUpdateFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

export function* deleteUser(api, {username}) {
  const apiCall = yield call(api.deleteUser, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(UserActions.userDeleteSuccess());
  } else {
    yield put(UserActions.userDeleteFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}
