import * as DateTime from 'react-datetime';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import FormError from './FormError';
import moment from 'moment';

class DatePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
    noUnixTimestamp: PropTypes.bool,
  };

  handleChange = (newDate) => {
    const {input, noUnixTimestamp} = this.props;

    if (!newDate) {
      input.value = newDate;
      input.onChange(input.value);

      return this.setState({date: input.value});
    }

    if (moment(newDate, 'YYYY-MM-DD').isValid()) {
      if (noUnixTimestamp) {
        input.value = newDate.format('YYYY-MM-DD');
      }

      if (!noUnixTimestamp) {
        input.value = newDate.unix();
      }

      input.onChange(input.value);

      return this.setState({date: input.value});
    }
  };

  renderDatePicker(error = false) {
    const {className, input, noUnixTimestamp} = this.props;

    return <div className="relative inline-block w-full">
      <DateTime
        inputProps={{
          className: `block w-full sm:pr-16 rounded text-sm focus:outline-none focus:shadow-outline input-field${error ? ' has-error' : ''} ${className || ''}`,
          id: input.name,
        }}
        value={input.value ? moment(input.value, noUnixTimestamp ? 'YYYY-MM-DD' : 'X') : null}
        className="inline-block relative w-full"
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        onChange={this.handleChange}
        onBlur={input.onBlur}
        aria-labelledby={input.name}
      />
      <div className={`pointer-events-none absolute border rounded-r inset-y-0 flex items-center right-0 px-2 sm:px-4 mb-3 ${error ? 'border-negative' : 'border-gray-15'}`}>
        <Icon className="text-gray-60">calendar-alt</Icon>
      </div>
    </div>;
  }

  render() {
    const {meta} = this.props;

    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderDatePicker(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderDatePicker();
  }
}

export default DatePicker;
