import React from 'react';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import PropTypes from 'prop-types';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';

class UsernamePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.any,
    account_error: PropTypes.object,
    account_fetching: PropTypes.bool,
    updating: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.account_fetching) {
      if ((this.props.account !== prevProps.account) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.account_error !== prevProps.account_error) && this.state.updating) {
        this.onError(this.props.account_error);
      }
    }
  }

  onSubmit = (value) => {
    this.setState({updating: true}, function () {
      this.props.updateAccount({account: value, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  validate() {
    const {updating} = this.state, {account_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = account_error ? {...account_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {account} = this.props;
    const {t} = this.context;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form
          onSubmit={this.onSubmit}
          validate={this.validate.bind(this)}
          validateOnBlur
          initialValues={{
            username: account.username,
          }}
        >
          {({handleSubmit, form}) => {
            this.form = form;
            return (
              <form onSubmit={handleSubmit}>
                <Field name="username" validate={required}>
                  {({input, meta}) => (
                    <div className="w-full">
                      <Label input={input} className="text-xl font-semibold -ml-3 pb-2">{I18n.t('Username')}</Label>
                      <TextField input={input} meta={meta} className="max-w-md block" placeholder={t('Username')}/>
                      <div className="pt-2 pb-12">
                        <p className="text-sm break-all">
                          https://talents.thecastingdirector.eu/<span className="font-bold">{input.value}</span>
                        </p>
                      </div>
                    </div>
                  )}
                </Field>
                <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('save', null, false, true)}
                </FilledButton>
              </form>
            );
          }}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(AccountActions.accountUpdateRequest(account)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsernamePage);
