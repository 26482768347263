import PropTypes from 'prop-types';
import React from 'react';
import I18n from '../Utils/I18n';
import Body from './Text/Body';

class Loading extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const {className} = this.props;

    return <Body className={`${className ? ` ${className}` : ''}`}>{I18n.t('Loading...')}</Body>;
  }
}

export default Loading;
