import React from 'react';
import {Field, Form} from 'react-final-form';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import Checkbox from '../../../../Components/Form/Checkbox';
import Icon from '../../../../Components/Icon';
import PropTypes from 'prop-types';
import NotificationsActions from '../../../../Redux/Talent/NotificationsRedux';
import {connect} from 'react-redux';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';

class NotificationsPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updating: PropTypes.bool,
    getNotifications: PropTypes.func,
    updateNotifications: PropTypes.func,
    notifications: PropTypes.any,
    notifications_error: PropTypes.any,
    notifications_fetching: PropTypes.bool,
    notifications_updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.getNotifications(this.props.account.username);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.notifications_fetching) {
      if ((this.props.notifications !== prevProps.notifications) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.notifications_error !== prevProps.notifications_error) && this.state.updating) {
        this.onError(this.props.notifications_error);
      }
    }
  }

  onSubmit = (values) => {
    const postValues = this.convertFormValuesToPostValues(values);
    this.setState({updating: true, saved: false}, function () {
      this.props.updateNotifications({
        notification_settings: {notification_settings: postValues},
        username: this.props.account.username,
      });
    });
  };

  onError = () => {
    this.setState({updating: false, saved: false});
  };

  onSuccess = () => {
    this.setState({updating: false, saved: true});
  };

  convertNotificationsToInitialValues(notifications) {
    let object = {};
    if (!notifications) {
      return null;
    }
    Object.keys(notifications).map((item) => {
      object[item] = {};
      notifications[item].available_channels.forEach((channel) => {
        object[item][channel] = notifications[item].channels.includes(channel);
      });
    });
    return object;
  }

  convertFormValuesToPostValues(values) {
    let postValues = {};
    Object.keys(values).map((notification) => {
      postValues[notification] = Object.keys(values[notification]).filter((channel) => {
        return values[notification][channel] !== false;
      });
    });
    return postValues;
  }

  render() {
    const {notifications} = this.props;
    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form
          onSubmit={this.onSubmit}
          validateOnBlur
          initialValues={this.convertNotificationsToInitialValues(notifications)}
        >
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="flex-1">
              <Title className="text-xl pb-2">{I18n.t('My notifications')}</Title>

              {notifications
                ? Object.keys(notifications).map(
                  function (item, key) {
                    return (
                      <div key={key} className="flex flex-wrap max-w-3xl pb-3 mb-5">
                        <div className="flex-1">
                          <h3 className="text-xs py-1">{I18n.t(notifications[item].name)}</h3>
                          <Body>{I18n.t(notifications[item].description)}</Body>
                        </div>
                        {notifications[item].available_channels.map((method, key) => {
                          switch (method) {
                            case 'mail':
                              return (
                                <Field key={key} name={item + '[mail]'} type="checkbox">
                                  {({input}) => (
                                    <div className="pr-5">
                                      <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">envelope</Icon></Checkbox>
                                    </div>
                                  )}
                                </Field>
                              );

                            case 'database':
                              return (
                                <Field key={key} name={item + '[database]'} type="checkbox">
                                  {({input}) => (
                                    <div className="pr-5">
                                      <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 -ml-px">bell</Icon></Checkbox>
                                    </div>
                                  )
                                  }
                                </Field>
                              );

                            case 'App\\Channels\\BeamsChannel':
                              return (
                                <Field key={key} name={item + '[App\\Channels\\BeamsChannel]'} type="checkbox">
                                  {({input}) => (
                                    <div className="pr-5">
                                      <Checkbox input={input} className="flex flex-col-reverse"><Icon className="text-lg text-gray-40 mb-3 ml-px">mobile-alt</Icon></Checkbox>
                                    </div>
                                  )}
                                </Field>
                              );
                          }
                        })
                        }
                      </div>
                    );
                  },
                )
                : <Loading/>
              }

              <div className="mt-10 mb-3">
                <FilledButton type="submit" disabled={this.state.updating} saved={this.state.saved} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('save', null, false, true)}
                </FilledButton>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    notifications: state.notifications.notifications,
    notifications_error: state.notifications.notifications_error,
    notifications_fetching: state.notifications.notifications_fetching,
    notifications_updating: state.notifications.notifications_updating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (username) => dispatch(NotificationsActions.notificationsRequest(username)),
    updateNotifications: (obj) => dispatch(NotificationsActions.notificationsUpdateRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
