import PropTypes from 'prop-types';
import React from 'react';
import TextButton from './TextButton';

class FilledButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    iconRight: PropTypes.bool,
    noText: PropTypes.bool,
    onClick: PropTypes.func,
    saved: PropTypes.bool,
    to: PropTypes.string,
    type: PropTypes.string,
  };

  render() {
    const {className, children, disabled, external, icon, iconRight, noText, onClick, saved, to, type} = this.props;

    return <TextButton className={`px-4 py-2 ${className || ''}`} external={external} noText={noText} icon={icon} disabled={disabled} type={type} iconLeft={!iconRight} onClick={onClick} saved={saved} to={to}>{children}</TextButton>;
  }
}

export default FilledButton;
