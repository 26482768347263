import {call, put} from 'redux-saga/effects'
import SkillActions from '../../Redux/Talent/SkillRedux'
import {callApi} from '../CallApiSaga'

export function* updateSkill(api, {obj}) {
  const apiCall = yield call(api.updateSkill, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SkillActions.skillUpdateSuccess(response.data));
  } else {
    yield put(SkillActions.skillUpdateFailure(response.data ? response.data.message : 'WRONG'))
  }
}

export function* deleteSkill(api, {obj}) {
  const apiCall = yield call(api.deleteSkill, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SkillActions.skillDeleteSuccess(response.data));
  } else {
    yield put(SkillActions.skillDeleteFailure(response.data ? response.data.message : 'WRONG'))
  }
}


export function* saveSkill(api, {obj}) {
  const apiCall = yield call(api.saveSkill, obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(SkillActions.skillSaveSuccess(response.data));
  } else {
    yield put(SkillActions.skillSaveFailure(response.data ? response.data.message : 'WRONG'))
  }
}
