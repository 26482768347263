import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import TalentRoute from '../../Components/Routes/TalentRoute';
// Routes
import PortfolioOverviewPage from '../../Containers/Talent/Portfolio/Add/PortfolioOverviewPage';

class PortfolioRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <TalentRoute exact path="/talent/experiences/add/overview" params={{name: 'Portfolio'}} component={PortfolioOverviewPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(PortfolioRoutingComponent));
