import { call, put } from 'redux-saga/effects'
import PlansActions from '../../Redux/Billing/PlansRedux'
import { callApi } from '../CallApiSaga'

export function * getBillingPlans (api) {
    const apiCall = yield call(api.getPlans);
    const response = yield call(callApi, apiCall);

    // success?
    if (response.ok) {
        yield put(PlansActions.plansSuccess(response.data.data));
    } else {
        yield put(PlansActions.plansFailure('WRONG'))
    }
}

