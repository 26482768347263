import { call, put } from 'redux-saga/effects'
import ForgotPasswordActions from '../../Redux/Auth/ForgotPasswordRedux'

// Forgot Password
export function * forgotPassword (api, {forgotObj}) {

    const response = yield call(api.forgotPassword, forgotObj);

    // success?
    if (response.ok) {
        yield put(ForgotPasswordActions.forgotPasswordSuccess(response.data));
    } else {
        yield put(ForgotPasswordActions.forgotPasswordFailure(response.data ? response.data : 'Server Error'))
    }
}
