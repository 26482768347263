import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  updateCardRequest: ['cardObj'],
  updateCardSuccess: ['response'],
  updateCardFailure: ['error'],

  clearCardState: [],
});

export const CardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  response: null,
  error: null,
  updating: false,
  updated:false,
});

/* ------------- Reducers ------------- */

// update card request
export const update_card_Request = (state) => state.merge({updating: true, updated:false});

// update card success
export const update_card_success = (state, data) => {
  const {response} = data;
  return state.merge({updating: false, error: null, response, updated:true});
};

export const update_card_failure = (state, {error}) => state.merge({updating: false, response: null, error});

export const clearCardState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_CARD_REQUEST]: update_card_Request,
  [Types.UPDATE_CARD_SUCCESS]: update_card_success,
  [Types.UPDATE_CARD_FAILURE]: update_card_failure,


  [Types.CLEAR_CARD_STATE]: clearCardState,

});
