import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import PlansActions from '../../../../Redux/Billing/PlansRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import SubscriptionItem from '../../../../Components/ListItems/SubscriptionItem';
import SubscriptionActions from '../../../../Redux/Billing/SubscriptionRedux';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import CouponActions from '../../../../Redux/Billing/CouponRedux';
import {required} from '../../../../Validators/Form/Form';
import Loading from '../../../../Components/Loading';
import Helper from '../../../../Utils/Helper';

class SubscriptionPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    getSubscriptions: PropTypes.func,
    plans: PropTypes.any,
    subscription_deleted: PropTypes.bool,
    subsctiption_deleting: PropTypes.bool,
    getAccount: PropTypes.func,
    deleteSubscription: PropTypes.func,
    submitCoupon: PropTypes.func,
    coupon_fetching: PropTypes.any,
    coupon_error: PropTypes.any,
    coupon: PropTypes.any,
    changeSubscription: PropTypes.func,
    subscription_updating: PropTypes.bool,
    subscription_updated: PropTypes.bool,
    clearSubscriptionState: PropTypes.func,
    clearCouponState: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: null,
      loading: false,
      deleteSub: false,
      submitCoupon: false,
      discount: null,
      changeSubmit: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getSubscriptions();
    const {account} = this.props;
    const personal_account = account ? account.account : null;
    if (personal_account) {
      personal_account.subscription ? this.setState({selectedPlan: personal_account.subscription.stripe_plan}) : null;
    }
  }

  componentWillUnmount() {
    this.props.clearSubscriptionState();
  }

  onSubmit(value) {
    this.setState({submitCoupon: true}, function () {
      this.props.clearCouponState();
      this.props.submitCoupon({coupon: value.coupon});
    });
  }

  onClick(planId) {
    if (planId === this.state.selectedPlan) {
      this.setState({selectedPlan: null});

      return;
    }

    this.setState({selectedPlan: planId});
  }

  componentDidUpdate(prevProps) {
    const {deleteSub, submitCoupon, changeSubmit} = this.state;
    if (!this.props.subsctiption_deleting) {
      if (this.props.subscription_deleted && deleteSub) {
        this.setState({deleteSub: false, loading: false}, function () {
          this.props.getAccount();
        });
      }
    }

    if (!this.props.coupon_fetching) {
      if (this.props.coupon_error !== prevProps.coupon_error && submitCoupon) {
        this.setState({submitCoupon: false, discount: null}, function () {
          alert(this.props.coupon_error);
        });
      }

      if (this.props.coupon && submitCoupon) {
        this.setState({submitCoupon: false, discount: this.props.coupon}, function () {

        });
      }
    }

    if (!this.props.subscription_updating) {
      if (this.props.subscription_updated && changeSubmit) {
        this.setState({changeSubmit: false}, function () {
          this.props.getAccount();
          this.props.clearSubscriptionState();
        });
      }
    }
  }

  onError = () => {
    this.setState({updating: false});
  };

  deleteSubscription = () => {
    this.setState({loading: true, deleteSub: true}, function () {
      this.props.deleteSubscription();
    });
  };

  chooseSubscription(plan) {
    const {account} = this.props;
    if (account ? account.account.subscription === null : false) {
      this.props.history.push(`/talent/settings/subscription/${plan.id}/checkout`);
    } else {
      this.setState({changeSubmit: true}, function () {
        this.props.changeSubscription({plan_id: plan.id});
      });
    }
  }

  goToCheckoutPage(planId) {
    const {account} = this.props;
    if (account ? account.account.subscription === null : false) {
      this.props.history.push(`/talent/settings/subscription/${planId}/checkout`);
    } else {
      this.setState({changeSubmit: true}, function () {
        this.props.changeSubscription({plan_id: planId});
      });
    }
  }

  render() {
    const {plans, subscription_deleted} = this.props,
      {selectedPlan} = this.state,
      {t} = this.context;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Form onSubmit={this.onSubmit} validateOnBlur>
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <Title className="text-xl pb-4">{I18n.t('My subscription info')}</Title>

              <div className="sm:flex mb-10">
                <Field name="coupon" validate={required}>
                  {({input, meta}) => (
                    <div className="w-full max-w-md">
                      <Label input={input} className="">{I18n.t('Use a coupon', null, true)}</Label>
                      <TextField input={input} meta={meta} className="block"/>
                    </div>
                  )}
                </Field>

                <FilledButton type={'submit'} className='bg-positive rounded text-white sm:ml-3 mt-6 mb-auto'>{I18n.t('Validate coupon')}</FilledButton>
              </div>

            </form>
          )}
        </Form>
        <Form onSubmit={this.onSubmit} validateOnBlur>
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit}>

              {plans
                ? plans.map((plan, key) =>
                  <SubscriptionItem
                    deleted={subscription_deleted}
                    deleteSubscription={this.deleteSubscription}
                    subscription={plan}
                    key={key}
                    onClick={() => this.onClick(plan.id)}
                    selected={plan.id === selectedPlan}
                  />,
                )
                : <Loading/>
              }

              <SubscriptionItem subscription={{
                title: Helper.titleCase(t('family')),
                description: t('Register your children or other family members all together.'),
                stripe_id: 'family',
                price: t('Coming soon'),
              }} disabled={true}/>

              <div className='flex'>
                {selectedPlan &&
                <FilledButton onClick={() => this.goToCheckoutPage(selectedPlan)} className='bg-positive rounded text-white mb-3'>{I18n.t('Checkout')}</FilledButton>
                }
              </div>

            </form>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    plans: state.plans.plans ? state.plans.plans : [],
    error_plans: state.plans.error,
    fetching_plans: state.plans.fetching,

    subscription: state.subscription.response,
    subscription_creating: state.subscription.creating,
    subscription_updating: state.subscription.updating,
    subscription_updated: state.subscription.updated,
    subscription_deleting: state.subscription.deleting,
    subscription_deleted: state.subscription.deleted,

    coupon: state.coupon.coupon,
    coupon_fetching: state.coupon.fetching,
    coupon_error: state.coupon.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: () => dispatch(PlansActions.plansRequest()),
    deleteSubscription: (obj) => dispatch(SubscriptionActions.deleteSubscriptionRequest(obj)),
    changeSubscription: (obj) => dispatch(SubscriptionActions.changeSubscriptionRequest(obj)),
    getAccount: () => dispatch(AccountActions.accountRequest()),
    submitCoupon: (obj) => dispatch(CouponActions.couponRequest(obj)),
    clearSubscriptionState: () => dispatch(SubscriptionActions.clearSubscriptionState()),
    clearCouponState: () => dispatch(CouponActions.clearCouponState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
