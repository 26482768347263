import React from 'react';
import Title from '../../../../Components/Text/Title';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Body from '../../../../Components/Text/Body';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ExperienceActions from '../../../../Redux/Talent/ExperienceRedux';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';
import AccountActions from '../../../../Redux/Account/AccountRedux';

class PortfolioDeletePage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    experience: PropTypes.any,
    experience_delete: PropTypes.bool,
    experience_delete_success: PropTypes.bool,
    experience_error: PropTypes.any,
    deleteExperience: PropTypes.func,
    deleting: PropTypes.bool,
    getAccount: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.experience_delete) {
      if (this.props.experience_delete_success !== prevProps.experience_delete_success && this.state.deleting) {
        this.onSuccess();
      } else if ((this.props.experience_error !== prevProps.experience_error) && this.state.deleting) {
        this.onError(this.props.experience_error);
      }
    }
  }

  onDelete = (e) => {
    e.preventDefault();
    const {experience, account} = this.props;
    this.setState({deleting: true}, function () {
      this.props.deleteExperience({experience: experience.id, username: account.username});
    });

    return false;
  };

  onError = () => {
    this.setState({deleting: false});
  };

  onSuccess = () => {
    this.setState({deleting: false}, function () {
      this.props.history.push(`/talent/experiences`);
      this.props.getAccount();
    });
  };

  render() {
    const {experience, experience_delete} = this.props;

    if (experience) {
      return (
        <div className="flex-1 pl-3 sm:px-8">
          <Title className="text-xl pb-2">{I18n.t('Are you sure?')}</Title>
          <Body className="text-secondary-40 ">
            {I18n.t('Are you sure you want to delete :thing?', {thing: Helper.makeBold(experience.title)})}
            <br/>
            {I18n.t('You can\'t undo this action...')}
          </Body>

          <div className="flex mt-10">
            <FilledButton disabled={experience_delete} onClick={(e) => this.onDelete(e)} icon="trash-alt" className="rounded text-white bg-negative">{I18n.t('Yes, delete it!')}</FilledButton>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    experience: state.experience.experience,
    experience_delete: state.experience.delete,
    experience_delete_success: state.experience.delete,
    experience_error: state.experience.deleteSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
    deleteExperience: (object) => dispatch(ExperienceActions.experienceDeleteRequest(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDeletePage);
