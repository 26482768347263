import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import {Link} from 'react-router-dom';
import Icon from '../Icon';
import Helper from '../../Utils/Helper';
import RoundedImage from "../Images/RoundedImage";
import {connectHits} from 'react-instantsearch-dom';

class CustomHits extends React.Component {
  static propTypes = {
    hits: PropTypes.array,
    onClick: PropTypes.func,
    selected: PropTypes.array,
    className: PropTypes.any
  };

  render() {
    const {hits, className, selected, onClick} = this.props;

    return (
      <div className={`md:flex flex-wrap flex-1`}>
        {hits.map(hit => (
          <div key={hit.objectID} className={className || ''}>
            <Card className="mb-4">
              <div className={`xl:flex items-center pt-px ${selected.includes(hit.id) ? 'bg-gray-15' : ''}`} data-style-id={hit.id}>
                <a target="_blank" rel="noreferrer noopener" href={`https://talents.thecastingdirector.eu/${hit.username}`}
                      className="block m-4 mb-0 xl:mb-4 rounded-full group">
                  <RoundedImage className="w-20 h-20 m-auto pointer-events-none bg-white text-6xl" hoverable src={hit.avatar}/>
                </a>
                <Link to="#" className="block p-4 pt-0 xl:pl-0 xl:pt-8 xl:pb-8 flex-1" data-id={hit.id}
                      onClick={() => onClick(hit)}>
                  <div className="pointer-events-none flex-1">
                    <p className="text-lg text-center xl:text-left">{hit.firstname} {hit.lastname}</p>
                    <div className="flex items-center justify-center xl:justify-start">
                      <Icon className="text-lg mr-2 text-gray-40">{Helper.renderGenderIcon(hit.gender)}</Icon>
                      <p
                        className="text-gray-40 text-sm">{hit.city ? `${hit.city} | ` : ''}{hit.birthday ? `${Helper.getAgeFromUnix(hit.birthday)} | ` : ''}{hit.locale.toUpperCase()}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </Card>
          </div>
        ))}
      </div>
    );
  }
}

export default connectHits(CustomHits);
