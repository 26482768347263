import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProjectActions from '../../../Redux/Project/ProjectRedux';
import AgencyProjectRoutingComponent from '../../../Components/Routing/Agency/AgencyProjectRoutingComponent';
import I18n from '../../../Utils/I18n';

class ProjectPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getProjectId: PropTypes.func,
    project: PropTypes.any,
    project_fetching: PropTypes.bool,
    project_error: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
    };
  }

  componentDidMount() {
    const {project} = this.props.match.params;

    this.setState({fetching: true}, () => {
      this.props.getProjectId(project);
    });
  }

  componentDidUpdate(prevProps) {
    const {fetching} = this.state;
    if (!this.props.project_fetching) {
      if ((this.props.project_error !== prevProps.project_error) && fetching) {
        this.setState({fetching: false}, () => {
          this.props.history.push(`/agency/projects/`);
        });
      } else if (this.props.project !== prevProps.project) {
        this.setState({fetching: false});
      }
    }
  }

  componentWillUnmount() {
    this.setState({fetching: false});
  }

  render() {
    const {project} = this.props.match.params;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card
          className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to={`/agency/projects/${project}/overview`}
                          icon="info-circle">{I18n.t('Overview')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/extra-info`}
                          icon="file-pdf">{I18n.t('Extra info')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/project-roles`}
                          icon="users">{I18n.t('Project roles')}</SubNavItem>
              <SubNavItem to={`/agency/projects/${project}/delete`}
                          icon="trash-alt">{I18n.t('delete', null, false, true)}</SubNavItem>
            </SubNav>

            <AgencyProjectRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    project: state.project.project,
    project_fetching: state.project.fetching,
    project_error: state.project.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
