import React from 'react';
import Title from '../../../../Components/Text/Title';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AgencyCastingsActions from '../../../../Redux/Castings/AgencyCastingsRedux';
import SearchResultItem from '../../../../Components/ListItems/SearchResultItem';
import I18n from '../../../../Utils/I18n';
import Body from '../../../../Components/Text/Body';
import Loading from '../../../../Components/Loading';
import Pagination from '../../../../Components/Navigation/Pagination';

class InterestedPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    selected: PropTypes.any,
    castings_fetching: PropTypes.bool,
    castings: PropTypes.any,
    links: PropTypes.any,
    meta: PropTypes.any,
    declinedCastings: PropTypes.object,
    declinedLinks: PropTypes.object,
    declinedMeta: PropTypes.object,
    castings_error: PropTypes.any,
    getAllCastingsForRole: PropTypes.func,
    getPageCastingsForRole: PropTypes.func,
    getAllDeclinedCastingsForRole: PropTypes.func,
    getDeclinedPageCastingsForRole: PropTypes.func,
    clearCastingsState: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {project, role} = this.props.match.params,
      obj = {project_id: project, role_id: role, status: ['new']},
      obj2 = {project_id: project, role_id: role, status: ['declined']};

    this.props.getAllCastingsForRole(obj);
    this.props.getAllDeclinedCastingsForRole(obj2);
  }

  componentWillUnmount() {
    this.props.clearCastingsState();
  }

  renderResults(results) {
    const {castings_fetching} = this.props;

    return results && !castings_fetching
      ? results.length
        ? results.map((result, key) =>
          <SearchResultItem key={key} result={result} className="min-w-48 md:w-1/2 md:pr-4 text-6xl mb-4"/>)
        : <Body>{I18n.t('Currently no talents in this stage')}</Body>
      : <Loading/>;
  }

  handlePagination = link => {
    const obj = {link: link};

    this.props.getPageCastingsForRole(obj);
  };

  handleDeclinedPagination = link => {
    const obj = {link: link};

    this.props.getDeclinedPageCastingsForRole(obj);
  };

  render() {
    const {castings, links, meta, declinedCastings, declinedLinks, declinedMeta} = this.props;

    return (
      <div className="flex-1 max-w-6xl">
        <div className="flex-1 pl-3 sm:px-10 pb-16 flex flex-col-reverse lg:flex-row">
          <div className="xl:pr-4 flex-1">
            <div className="flex items-baseline min-w-64">
              <Title className="text-lg pb-4">{I18n.t('Contacted')}</Title>
              {castings &&
              <Body className="md:pr-4 ml-2">{meta && meta.total} {I18n.t('search results')}</Body>
              }
            </div>

            <div className="pb-4">
              <div className="md:flex flex-wrap flex-1">
                {this.renderResults(castings)}
              </div>
            </div>

            <Pagination links={links} handlePagination={this.handlePagination}/>

          </div>
        </div>

        <div className="flex-1 pl-3 sm:px-10 pb-16 flex flex-col-reverse lg:flex-row">
          <div className="xl:pr-4 flex-1">
            <div className="flex items-baseline min-w-64">
              <Title className="text-lg pb-4">{I18n.t('Casting Declined')}</Title>
              {declinedCastings &&
              <Body className="md:pr-4 ml-2">{declinedMeta && declinedMeta.total} {I18n.t('search results')}</Body>
              }
            </div>

            <div className="pb-4">
              <div className="md:flex flex-wrap flex-1">
                {this.renderResults(declinedCastings)}
              </div>
            </div>

            <Pagination links={declinedLinks} handlePagination={this.handleDeclinedPagination}/>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    castings: state.agency_castings.castings,
    links: state.agency_castings.links,
    meta: state.agency_castings.meta,
    declinedCastings: state.agency_castings.declinedCastings,
    declinedLinks: state.agency_castings.declinedLinks,
    declinedMeta: state.agency_castings.declinedMeta,
    castings_error: state.agency_castings.castings,
    castings_fetching: state.agency_castings.castings_fetching,

    role: state.role.role,
    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetCastingsRequest(obj)),
    getPageCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetPageCastingsRequest(obj)),
    getAllDeclinedCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetDeclinedCastingsRequest(obj)),
    getDeclinedPageCastingsForRole: (obj) => dispatch(AgencyCastingsActions.GetDeclinedPageCastingsRequest(obj)),
    clearCastingsState: () => dispatch(AgencyCastingsActions.clearCastingsState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterestedPage);
