import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Card';
import {connect} from 'react-redux';
import AccountActions from '../../Redux/Account/AccountRedux';
import TalentNav from './Talent/TalentNav';
import AgencyNav from './Agency/AgencyNav';
import Icon from '../Icon';
import {Link} from 'react-router-dom';

class Nav extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
    getAccount: PropTypes.func,
    account: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {navIsOpen: false};
  }

  componentDidMount() {

  }

  toggleNav() {
    this.setState(state => ({
      navIsOpen: !state.navIsOpen,
    }));
  }

  render() {
    const {account} = this.props;
    const {navIsOpen} = this.state;

    return (
      <div className={account ? 'flex lg:block lg:w-1/4 lg:min-w-64' : 'hidden'}>
        <Card className="ml-5 mr-6 px-4 py-5 h-128 hidden lg:block">
          {account &&
          <nav>
            {account.account.type === 'talent' &&
            <TalentNav/>
            }
            {account.account.type === 'agency' &&
            <AgencyNav/>
            }
          </nav>
          }
        </Card>
        <Card className={`mx-3 mb-4 px-4 py-5 block lg:hidden overflow-visible${navIsOpen ? ' w-full' : ''}`}>
          {account && navIsOpen
            ? <nav>
              <div className="clearfix">
                <Link to="" onClick={(e) => {
                  e.preventDefault();
                  this.toggleNav();
                }} className="block -mt-2 mb-2">
                  <Icon className="text-gray-40">times-circle</Icon>
                </Link>
              </div>
              {account.account.type === 'talent' &&
              <TalentNav/>
              }
              {account.account.type === 'agency' &&
              <AgencyNav/>
              }
            </nav>
            : <Link to="" onClick={(e) => {
              e.preventDefault();
              this.toggleNav();
            }} className="block -my-5 -mx-4 py-2 px-4">
              <Icon>bars</Icon>
            </Link>
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Nav);
