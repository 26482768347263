import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import AgencySettingsRoutingComponent from '../../../Components/Routing/Agency/AgencySettingsRoutingComponent';
import I18n from '../../../Utils/I18n';

class SettingsPage extends React.Component {
  render() {
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 max-w-6xl">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to="/agency/settings/username" icon="user">{I18n.t('Username')}</SubNavItem>
              <SubNavItem to="/agency/settings/password" icon="shield-alt">{I18n.t('Password')}</SubNavItem>
              <SubNavItem to="/agency/settings/notifications" icon="bell">{I18n.t('Notifications')}</SubNavItem>
              <SubNavItem to="/agency/settings/visibility" icon="eye">{I18n.t('Visibility')}</SubNavItem>
              <SubNavItem to="/agency/settings/language" icon="globe-europe">{I18n.t('Language')}</SubNavItem>
              <SubNavItem to="/agency/settings/delete" icon="trash-alt">{I18n.t('delete account', null, false, true)}</SubNavItem>
            </SubNav>

            <AgencySettingsRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

export default SettingsPage;
