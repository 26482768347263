import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ChatWindow from '../../../../Components/ChatWindow';
import ChatMessage from '../../../../Components/ListItems/ChatMessage';
import {ChatManager, TokenProvider} from '@pusher/chatkit-client';
import Title from '../../../../Components/Text/Title';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';

class ChatRoomPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    casting: PropTypes.any,
    authToken: PropTypes.any,
    account_fetching: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      currentRoom: {users: []},
      messages: [],
      users: [],
      initializedChat: false,
    };
    this.addMessage = this.addMessage.bind(this);
  }

  componentDidUpdate() {
    const {account, authToken, casting} = this.props;
    const {initializedChat} = this.state;
    if (!this.props.account_fetching) {
      if ((this.props.account && this.props.casting && !initializedChat)) {
        this.setState({initializedChat: true});
        const chatManager = new ChatManager({
          instanceLocator: process.env.CHATKIT_INSTANCE_LOCATOR,
          userId: account ? account.uuid : null,
          tokenProvider: new TokenProvider({
            url: process.env.CHATKIT_URL,
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
          }),
        });

        chatManager
          .connect()
          .then(currentUser => {
            this.setState({currentUser: currentUser});

            return currentUser.subscribeToRoom({
              roomId: casting ? casting.chatroom_id : null,
              messageLimit: 100,
              hooks: {
                onMessage: message => {
                  this.setState({
                    messages: [...this.state.messages, message],
                  }, function () {
                    const chatWindow = document.getElementById('ChatWindow');
                    chatWindow.scrollTo(0, chatWindow.scrollHeight);
                  });
                },
              },
            });
          })
          .then(currentRoom => {
            this.setState({
              currentRoom,
              users: currentRoom.userIds,
            });
          })
          .catch(error => window.console.error(error));
      }
    }
  }


  addMessage(text) {
    this.state.currentUser.sendMessage({
      text,
      roomId: this.state.currentRoom.id,
    })
      .catch(error => window.console.error('error', error));

  }

  render() {
    const {account} = this.props;
    const {messages} = this.state;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="pb-4">{I18n.t('Chat')}</Title>
        <ChatWindow addMessage={this.addMessage} className="w-full max-w-2xl min-h-64 max-h-128">
          {messages
            ? messages.map((message, key) =>
              <ChatMessage ownMessage={message.senderId === account.uuid} key={key}>{message.text}</ChatMessage>)
            : <Loading/>
          }
        </ChatWindow>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    casting: state.casting.casting,
    casting_fetching: state.casting.fetching,
    casting_error: state.casting.error,

    authToken: state.login.authToken,
  };
};

export default connect(mapStateToProps)(ChatRoomPage);
