import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CheckoutForm from '../../../../Components/Form/CheckoutForm';
import {Elements, injectStripe, StripeProvider} from 'react-stripe-elements';
import StripeScriptLoader from 'react-stripe-script-loader'

const StripeCheckout = injectStripe(CheckoutForm);

class SubscriptionCheckoutPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    match: PropTypes.any,
    plans: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      planId: null,
      selectedPlan:null,
    };
  }

  componentDidMount() {
    const {planId} = this.props.match.params;
    //If the plan is not loaded in state or wrong ID redirect back to subscriptions.
    if (!this.props.plans.find(x => x.id === parseInt(planId))) {
      this.props.history.push(`/talent/settings/subscription`);
    }

    this.setState({planId: planId, selectedPlan: this.props.plans.find(x => x.id === parseInt(planId))});
  }

  render() {
    const {planId, selectedPlan} = this.state;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <StripeScriptLoader
            uniqueId='myUniqueId'
            script='https://js.stripe.com/v3/'
            loader="Loading..."
        >
          <StripeProvider apiKey={process.env.STRIPE_KEY}>
            <Elements>
              <StripeCheckout history={this.props.history} planId={planId} selectedPlan={selectedPlan}/>
            </Elements>
          </StripeProvider>
        </StripeScriptLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    plans: state.plans.plans ? state.plans.plans : [],
  };
};

export default connect(mapStateToProps)(SubscriptionCheckoutPage);
