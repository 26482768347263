import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AccountActions from "../../Redux/Account/AccountRedux";

class LaunchScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if(this.props.login_error) {
            this.props.history.push('/login')
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if(!newProps.loadingLogin) {
            if(newProps.loginLoaded && newProps.account) {
                const { account } = newProps.account;
                if(account.type === 'talent'){
                    this.props.history.push(newProps.last_route ? newProps.last_route : '/talent/dashboard')
                }else if(account.type === 'agency'){
                    this.props.history.push(newProps.last_route ? newProps.last_route : '/agency/dashboard')
                }
            }else if(newProps.account_error || !newProps.authToken) {
                this.props.history.push('/login')
            }
        }
    }

    render() {
        return (
            <div className="flex flex-col-reverse lg:flex-row justify-between">
               
            </div>
        );
    }
}

LaunchScreen.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account:PropTypes.any,
    account_error: PropTypes.any,
    last_route: PropTypes.any,
    authToken:PropTypes.string,
    login:PropTypes.any,
    clearAccountState: PropTypes.func,
    login_error: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        account:state.account.account,
        account_error:state.account.error,
        loadingLogin:state.login.loading,
        loginLoaded: state.login.loginLoaded,
        last_route:state.route.last_route,
        authToken:state.login.authToken,

        login_fetching: state.login.fetching,
        login_error: state.login.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearAccountState: () => dispatch(AccountActions.clearAccountState()),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(LaunchScreen);
