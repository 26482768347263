import React from 'react';
import Title from '../../../../Components/Text/Title';
import ProjectFileItem from '../../../../Components/ListItems/ProjectFileItem';
import I18n from '../../../../Utils/I18n';
import RoleActions from '../../../../Redux/Project/RoleRedux';
import FetchGroupActions from '../../../../Redux/Fetch/FetchGroupRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../../Components/Loading';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddMultipleMedia from '../../../../Components/Images/AddMultipleMedia';

class ExtraInfoPage extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
    role: PropTypes.any,
    project: PropTypes.any,
    updateRole: PropTypes.func,
    getGroupFiles: PropTypes.func,
    role_files: PropTypes.any,
    role_fetching: PropTypes.any,
    clearGroupFiles: PropTypes.func,
    uploadMultiple: PropTypes.func,
    uploadSingleImage: PropTypes.func,
    file_uploading: PropTypes.bool,
    file_uploaded: PropTypes.bool,
    file: PropTypes.any,
    group_uploading: PropTypes.bool,
    group_uploaded: PropTypes.bool,
    group: PropTypes.any,
    clearUploadCareState: PropTypes.func,
    role_updating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      updating: false,
      groupId: null,
      fetchingGroupFiles: false,
      file_uploading: false,
      group_uploading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.role_fetching) {
      if ((this.props.role !== prevProps.role && !this.state.fetchingGroupFiles) || (!this.props.role_files && !this.state.fetchingGroupFiles)) {
        if (this.props.role.files) {
          let splitted_url_group_files = this.props.role.files.split('/');
          this.setState({
            groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
            fetchingGroupFiles: true,
          }, function () {
            this.props.getGroupFiles({group_id: this.state.groupId});
          });
        }
      }
    }

    if (!this.props.file_uploading) {
      if ((this.props.file !== prevProps.file) && this.state.file_uploading) {
        this.setState({file_uploading: false, group_uploading: true}, function () {
          let data = [];
          if (this.props.role_files) {
            this.props.role_files.files.map((file) => {
              data.push(file.uuid);
            });
          }
          data.push(this.props.file.split('/')[3]);
          this.props.uploadMultiple({data: data});
        });
      }
    }

    if (!this.props.group_uploading) {
      if ((this.props.group !== prevProps.group) && this.state.group_uploading && this.props.group) {
        let splitted_url_group_files = this.props.group.split('/');
        this.setState({group_uploading: false}, function () {
          const {project, group, role} = this.props;
          let newObj = Object.assign({}, role);
          newObj.files = group;
          this.props.updateRole({project_id: project.id, role_id: role.id, role: newObj});
          this.props.clearUploadCareState();
        });

        this.setState({
          groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
          fetchingGroupFiles: true,
        }, function () {
          this.props.getGroupFiles({group_id: this.state.groupId});
        });
      }
    }

    if(!this.props.role_updating){
      if ((this.props.role !== prevProps.role && this.state.updating)) {
        if(this.props.role.files){
          let splitted_url_group_files = this.props.role.files.split('/');
          this.setState({
            groupId: splitted_url_group_files[splitted_url_group_files.length - 2],
            fetchingGroupFiles: true,
            updating:false,
          }, function () {
            this.props.getGroupFiles({group_id: this.state.groupId});
          });
        }else{
          this.setState({updating:false}, function (){
            this.props.clearGroupFiles();
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({fetchingGroupFiles: false});
    this.props.clearGroupFiles();
    this.props.clearUploadCareState();
  }

  uploadedFile = (file) => {
    const {project, role} = this.props;
    let newObj = Object.assign({}, role);
    newObj.files = file.cdnUrl;
    this.setState({updating: true}, function () {
      this.props.updateRole({project_id: project.id, role_id: role.id, role: newObj});
      this.props.clearUploadCareState();
    });
  };

  deleteFile = (uuid) => {
    const {role_files, role, project} = this.props;
    if (role_files) {
      let filtered_array = role_files.files.filter((file) => file.uuid !== uuid);
      if (filtered_array.length > 0) {
        let uuid_array = [];
        filtered_array.map((file) => {
          uuid_array.push(file.uuid);
        });
        this.setState({group_uploading: true}, function () {
          this.props.uploadMultiple({data: uuid_array});
        });
      }else{
        let newObj = Object.assign({}, role);
        newObj.files = null;
        this.setState({updating: true}, function () {
          this.props.updateRole({project_id: project.id, role_id: role.id, role: newObj});
          this.props.clearUploadCareState();
        });
      }
    }
  };

  render() {
    const {role_files, role} = this.props, {file_uploading} = this.state;
    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="text-xl pb-2">{I18n.t('extra project information', null, false, true)}</Title>

        {role_files
          ? role_files.files && role_files.files.length > 0
            ? role_files.files.map((file, key) =>
              <ProjectFileItem className="my-6 max-w-xl" key={key} file={file} deleteFile={this.deleteFile}/>)
            : null
          : null
        }

        <div>
          {role
            ? <AddMultipleMedia files={role ? role.files : null} className="max-w-xl" uploadedFile={this.uploadedFile} disabled={file_uploading}/>
            : <Loading/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.role.role,
    role_fetching: state.role.fetching,
    role_updating: state.role.updating,
    role_error: state.role.error,

    project: state.project.project,

    role_files: state.fetch_group.role_files,
    fetching_group_files: state.fetch_group.fetching,
    error_group_files: state.fetch_group.error,

    file: state.upload_care.file,
    file_uploading: state.upload_care.uploading,
    file_uploaded: state.upload_care.uploaded,
    file_error: state.upload_care.error,

    group: state.upload_care.group,
    group_uploading: state.upload_care.uploading_group,
    group_uploaded: state.upload_care.uploaded_group,
    group_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRole: (obj) => dispatch(RoleActions.updateRoleRequest(obj)),
    getGroupFiles: (obj) => dispatch(FetchGroupActions.fetchGroupRoleRequest(obj)),
    clearGroupFiles: () => dispatch(FetchGroupActions.clearFetchGroupState()),
    uploadMultiple: (obj) => dispatch(UploadCareActions.uploadMultipleRequest(obj)),
    deleteFile: (uuid) => dispatch(UploadCareActions.deleteFileRequest(uuid)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraInfoPage);
