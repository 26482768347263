import {call, put} from 'redux-saga/effects'
import AccountActions from '../../Redux/Account/AccountRedux'
import {callApi} from '../CallApiSaga'
import {_removeTokens} from "../Auth/LoginSagas";
import TranslationActions from "../../Redux/Translation/TranslationRedux";
import LoginActions from "../../Redux/Auth/LoginRedux";

// attempts to account
export function* getAccount(api) {
  const apiCall = yield call(api.getAccount);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(AccountActions.accountSuccess(response.data));
    yield put(TranslationActions.setLocale(response.data.locale));
  } else {
    yield put(AccountActions.accountFailure('Account-Fetch-Fail'))
  }
}

export function* updateAccount(api, {obj}) {
  const apiCall = yield call(api.updateAccount, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AccountActions.accountUpdateSuccess(response.data));
    yield put(TranslationActions.setLocale(response.data.locale));
  } else {
    yield put(AccountActions.accountUpdateFailure(response.data.message ? response.data : {message: 'Error'}))
  }
}

export function* deleteAccount(api, {obj}) {
  const apiCall = yield call(api.deleteAccount, obj);
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    yield _removeTokens();
    yield call(api.removeAuthToken);
    yield put(AccountActions.clearAccountState());

    yield put({type: 'RELOGIN_ABORT'});
    //yield put(NavigationActions.navigate({routeName: 'loginStack'}));
    yield put(AccountActions.accountDeleteSuccess());
    yield put(LoginActions.logoutRequest());
  } else {
    yield put(AccountActions.accountDeleteFailure(response.data.message ? response.data.message : 'Error'))
  }
}

