import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import {NavLink} from 'react-router-dom';
import I18n from '../../Utils/I18n';

class Notifications extends React.Component {
  static propTypes = {
    amount: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const {amount, className} = this.props;

    return (
      <NavLink to='/notifications' activeClassName="active-nav-link bg-primary-20-transparent" className={`relative p-3 rounded-full ${className || ''}`}>
        {amount ?
          <div className="absolute -right-3 -top-3 flex justify-center items-center rounded-full w-5 h-5 bg-negative mt-3 mr-3">
            <p className="text-white text-2xs">{amount}</p>
          </div>
          :
          null
        }
        <Icon className="block">bell</Icon>
        <p className="absolute h-0 opacity-0">{I18n.t('Notifications')}</p>
      </NavLink>
    );
  }
}

export default Notifications;
