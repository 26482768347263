import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../Icon';

class BackButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  render() {
    const {className, onClick, to} = this.props;

    return (
      <Link className={`block ${className || ''}`} to={to || ''} onClick={onClick}>
        <Icon className="text-white text-2xl font-bold my-4 mx-2">chevron-left</Icon>
      </Link>
    );
  }
}

export default BackButton;
