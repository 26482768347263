import React from 'react';
import {connect} from 'react-redux';
import I18n from '../../../Utils/I18n';
import PropTypes from 'prop-types';
import Helper from "../../../Utils/Helper";
import {maxLength} from "../../../Constants/Breadcrumbs";

const PortfolioBreadcrumb = ({name}) => <span>{name ? Helper.ellipsis(name, maxLength) : I18n.t('add', null, false, true)}</span>;

PortfolioBreadcrumb.propTypes = {
  name: PropTypes.string,
};

// find the experience / portfolio in the store with the `id` from the route
function mapStateToProps(state) {
  const name = state.experience.experience ? state.experience.experience.title : null;
  return {
    name,
  };
}

export default connect(mapStateToProps)(PortfolioBreadcrumb);
