import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import TextField from '../../../../Components/Form/TextField';
import Select from '../../../../Components/Form/Select';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import TextArea from '../../../../Components/Form/TextArea';
import DatePicker from '../../../../Components/Form/DatePicker';
import ProjectTypesTypesActions from '../../../../Redux/ProjectTypes/ProjectTypesRedux';
import ProjectTypeActions from '../../../../Redux/Project/ProjectRedux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Toggle from '../../../../Components/Form/Toggle';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';
import FormError from '../../../../Components/Form/FormError';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddSingleImage from '../../../../Components/Images/AddSingleImage';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    getProjectTypes: PropTypes.func,
    createProject: PropTypes.func,
    project_types: PropTypes.array,
    project_store: PropTypes.bool,
    project: PropTypes.any,
    project_error: PropTypes.any,
    clearProjectErrors: PropTypes.func,
    uploadSingleImage: PropTypes.func,
    logo_uploading: PropTypes.bool,
    logo_uploaded: PropTypes.bool,
    logo: PropTypes.any,
    clearUploadCareState: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      logo: null,
      saving: false,
      logo_uploading: false,
    };

    this.form = null;
  }

  componentDidMount() {
    this.props.getProjectTypes();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.project_store) {
      if (this.props.project && (this.props.project !== prevProps.project) && this.state.saving) {
        this.setState({saving: false}, function () {
          this.props.history.push(`/agency/projects/${this.props.project.id}/overview`);
        });
      } else if ((this.props.project_error !== prevProps.project_error) && this.state.saving) {
        this.setState({saving: false});
      }
    }

    if (!this.props.logo_uploading) {
      if ((this.props.logo !== prevProps.logo) && this.state.logo_uploading) {
        this.setState({logo_uploading: false, logo: this.props.logo});
      }
    }
  }

  onSubmit = (value) => {
    const {logo} = this.state;
    Object.assign(value, logo ? {logo: logo} : {logo: null});
    this.setState({saving: true}, function () {
      this.props.createProject(value);
    });
  };

  uploadedFile = (file) => {
    this.setState({logo: file});
  };

  componentWillUnmount() {
    this.setState({files: []});
  }

  validate() {
    const {saving} = this.state, {project_error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = project_error ? {...project_error.errors} : {};


    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
      });
    }

    return errors;
  }

  render() {
    const {project_types} = this.props,
      {t} = this.context,
      {logo, logo_uploading} = this.state;

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={{}}
      >
        {({handleSubmit, form}) => {
          this.form = form;

          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
              <Title className="text-xl pb-2">{I18n.t('Project details')}</Title>
              <Field name="title" validate={required}>
                {({input, meta}) => (
                  <div className="pb-3 sm:w-4/5">
                    <Label input={input}>{I18n.t('project title', null, true)}</Label>
                    <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('project title'))}/>
                  </div>
                )}
              </Field>

              <div className="sm:flex pb-3">
                <Field name="company" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-5">
                      <Label input={input}>{I18n.t('company', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={Helper.titleCase(t('company'))}/>
                    </div>
                  )}
                </Field>
                <Field name="type_id" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1">
                      <Label input={input}>{I18n.t('project type', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option>-</option>
                        {project_types.map((type, key) => {
                          return (
                            <option key={key} value={type.id}>{type.title}</option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex pb-3">
                <Field name="starts_at" validate={required}>
                  {({input, meta}) => {
                    return (
                      <div className="md:w-1/4 md:pr-2">
                        <Label input={input}>{I18n.t('from', null, true)}</Label>
                        <DatePicker input={input} meta={meta} className="w-full"/>
                      </div>
                    );
                  }}
                </Field>
                <Field name="ends_at" validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/4 md:pl-3">
                      <Label input={input}>{I18n.t('to', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>

                <Field name="company_visible" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:pl-3">
                      <Label input={input}>{I18n.t('Visibility', null, true)}</Label>
                      <Toggle input={input} meta={meta} on="public" off="private"/>
                    </div>
                  )}
                </Field>
              </div>

              <Title className="text-xl pt-10 pb-2">{I18n.t('logo', null, false, true)}</Title>

              <AddSingleImage
                alt="project logo"
                aspectRatio={1}
                disabled={logo_uploading}
                uploadable
                uploadedFile={this.uploadedFile}
                className="max-w-20 max-h-20 mb-2"
                image={logo}
              />

              <div id="single-image">
                <Field name="logo">
                  {({meta}) => (
                    <div className="flex-1 md:pl-3 mt-2">
                      <FormError className={'flex-1'} meta={meta}/>
                    </div>
                  )}
                </Field>
              </div>

              <Field name="description" validate={required}>
                {({input, meta}) => (
                  <div className="sm:w-4/5">
                    <Label input={input}
                           className="text-gray-60 text-lg font-semibold text-xl pt-10 pb-2 -ml-4">{I18n.t('Description')}</Label>
                    <TextArea input={input} meta={meta} className="min-h-48"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" disabled={this.state.saving} icon="edit"
                              className="rounded text-white bg-secondary-40">
                  {I18n.t('save project', null, false, true)}
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project_types: state.project_types.project_types ? state.project_types.project_types : [],
    project_types_fetching: state.project_types.fetching,
    project_types_error: state.project_types.error,
    project_store: state.project.store,
    project: state.project.project,
    project_error: state.project.error,

    logo: state.upload_care.file,
    logo_uploading: state.upload_care.uploading,
    logo_uploaded: state.upload_care.uploaded,
    logo_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectTypes: () => dispatch(ProjectTypesTypesActions.projectTypesRequest()),
    createProject: (obj) => dispatch(ProjectTypeActions.createProjectRequest(obj)),
    clearProjectErrors: () => dispatch(ProjectTypeActions.clearProjectErrors()),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
