import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DeletableItem from '../../../../Components/ListItems/DeletableItem';
import TextField from '../../../../Components/Form/TextField';
import NumberField from '../../../../Components/Form/NumberField';
import EducationsActions from '../../../../Redux/Talent/EducationsRedux';
import EducationActions from '../../../../Redux/Talent/EducationRedux';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';
import Body from '../../../../Components/Text/Body';
import AccountActions from '../../../../Redux/Account/AccountRedux';

class EducationPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    educations: PropTypes.array,
    educations_fetching: PropTypes.bool,
    educations_error: PropTypes.any,
    getEducations: PropTypes.func,
    saveEducation: PropTypes.func,
    deleteEducation: PropTypes.func,
    education: PropTypes.any,
    education_error: PropTypes.any,
    education_store: PropTypes.bool,
    education_delete: PropTypes.bool,
    education_delete_success: PropTypes.bool,
    saving: PropTypes.bool,
    deleting: PropTypes.bool,
    getAccount: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      deleting: false,
    };

    this.form = null;
  }

  componentDidMount() {
    this.props.getEducations(this.props.account.username);
  }

  componentDidUpdate(prevProps) {
    //Saving
    if (!this.props.education_store) {
      if ((this.props.education !== prevProps.education) && this.state.saving) {
        this.onSuccess();
      } else if ((this.props.education_error !== prevProps.education_error) && this.state.saving) {
        this.onError(this.props.education_error);
      }
    }

    //Deleting
    if (!this.props.education_delete) {
      if ((this.props.education_delete_success !== prevProps.education_delete_success) && this.state.deleting) {
        this.onSuccess();
      } else if ((this.props.education_error !== prevProps.education_error) && this.state.deleting) {
        this.onError(this.props.education_error);
      }
    }

  }

  onSubmit = (value) => {
    this.setState({saving: true}, function () {
      this.props.saveEducation({education: value, username: this.props.account.username});
    });
  };

  onDelete = (id) => {
    this.setState({deleting: true}, function () {
      this.props.deleteEducation({education: id, username: this.props.account.username});
    });
  };

  onError = () => {
    this.setState({saving: false, deleting: false});
  };

  onSuccess = () => {
    this.setState({saving: false, deleting: false}, function () {
      this.props.getEducations(this.props.account.username);
      this.props.getAccount();
    });
  };

  validate() {
    const {updating} = this.state, {education_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = education_error ? {...education_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        if (this.form.getFieldState(field).dirtySinceLastSubmit) {
          delete errors[field];
        }
        try {
          if (this.form.getFieldState('startYear').dirtySinceLastSubmit || this.form.getFieldState('endYear').dirtySinceLastSubmit) {
            delete errors['startYear'];
            delete errors['endYear'];
          }
        } catch (e) {
          //
        }
      });
    }

    return errors;
  }

  render() {
    const {educations} = this.props;
    const {t} = this.context;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">

        <Title className="text-xl pb-2">{I18n.t('My education')}</Title>
        <Body className="mb-4 -mt-2">{I18n.t('Enter your education and courses here')}</Body>
        <div className="flex flex-wrap mb-4">
          {Array.isArray(educations)
            ? educations.map((education) => {
              return (
                <DeletableItem key={education.id} className="py-3" onClick={() => this.onDelete(education.id)}>
                  <p className="text-gray-40">{education.startYear}-{education.endYear}</p>
                  <p>{education.title}</p>
                  <p className="text-xs">{education.location}</p>
                </DeletableItem>
              );
            })
            : null
          }
        </div>

        <Form
          onSubmit={this.onSubmit}
          validate={this.validate.bind(this)}
          validateOnBlur
        >
          {({handleSubmit, form}) => {
            this.form = form;
            return (
            <form onSubmit={handleSubmit}>
              <div className="md:flex">
                <Field name="location" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:pr-3">
                      <Label input={input}>{I18n.t('Institute', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={t('Which institute?')}/>
                    </div>
                  )}
                </Field>
                <Field name="title" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 md:pr-3">
                      <Label input={input}>{I18n.t('Course', null, true)}</Label>
                      <TextField input={input} meta={meta} placeholder={t('What course?')}/>
                    </div>
                  )}
                </Field>

                <Field name="startYear" validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/5 md:pr-3">
                      <Label input={input}>{I18n.t('start year', null, true)}</Label>
                      <NumberField input={input} meta={meta} placeholder={t('Starting?')}/>
                    </div>
                  )}
                </Field>
                <Field name="endYear" validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/5 md:pr-3">
                      <Label input={input}>{I18n.t('end year', null, true)}</Label>
                      <NumberField input={input} meta={meta} placeholder={t('Ending?')}/>
                    </div>
                  )}
                </Field>
              </div>

              <div className="mt-6 mb-3">
                <FilledButton type="submit" icon="plus" className="rounded text-white bg-secondary-40">
                  {I18n.t('Add education')}
                </FilledButton>
              </div>

            </form>
          );
          }}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
    educations: state.educations.educations,
    educations_fetching: state.educations.educations_fetching,
    educations_error: state.educations.educations_error,
    education: state.education.education,
    education_error: state.education.education_error,
    education_store: state.education.education_store,
    education_delete: state.education.education_delete,
    education_delete_success: state.education.education_delete_success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
    getEducations: (username) => dispatch(EducationsActions.educationsRequest(username)),
    saveEducation: (obj) => dispatch(EducationActions.educationCreateRequest(obj)),
    deleteEducation: (obj) => dispatch(EducationActions.educationDeleteRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationPage);
