import React from 'react';
import Title from '../../Components/Text/Title';
import ProjectList from '../../Components/Lists/ProjectList';
import I18n from '../../Utils/I18n';

class DashboardPage extends React.Component {
  render() {
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Title className="pl-6 pb-5 pt-2 text-center lg:text-left">{I18n.t('Projects')}</Title>

        <ProjectList/>
      </main>
    );
  }
}

export default DashboardPage;
