import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    propertyTypesRequest: [],
    propertyTypesSuccess: ['property_types'],
    propertyTypesFailure: ['error'],

    propertyTypeByIdRequest: [],
    propertyTypeByIdSuccess: ['property_type'],
    propertyTypeByIdFailure: ['error'],

    clearPropertyTypesState:[],
});

export const PropertyTypesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    property_types: null,
    property_type:null,
    error: null,
    fetching: false,
});

/* ------------- Reducers ------------- */

// Property Types request
export const property_types_request = (state) => state.merge({ fetching: true });

// Property Types success
export const property_types_success = (state, data) => {
    const { property_types } = data;
    return state.merge({ fetching: false, error: null, property_types })
};

export const propertyTypesFailure = (state, { error }) => state.merge({ fetching: false, property_types: null, error });

// Property Type request
export const propertyTypeByIdRequest = (state) => state.merge({ fetching: true });

// Property Type success
export const propertyTypeByIdSuccess = (state, data) => {
    const { property_type} = data;
    return state.merge({ fetching: false, error: null, property_type })
};

export const propertyTypeByIdFailure = (state, { error }) => state.merge({ fetching: false, property_type: null, error });

export const clearPropertyTypesState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROPERTY_TYPES_REQUEST]: property_types_request,
    [Types.PROPERTY_TYPES_SUCCESS]: property_types_success,
    [Types.PROPERTY_TYPES_FAILURE]: propertyTypesFailure,

    [Types.PROPERTY_TYPE_BY_ID_REQUEST]: propertyTypeByIdRequest,
    [Types.PROPERTY_TYPE_BY_ID_SUCCESS]: propertyTypeByIdSuccess,
    [Types.PROPERTY_TYPE_BY_ID_FAILURE]: propertyTypeByIdFailure,

    [Types.CLEAR_PROPERTY_TYPES_STATE]: clearPropertyTypesState,

});
