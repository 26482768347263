import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  propertiesRequest: ['username'],
  propertiesSuccess: ['properties'],
  propertiesFailure: ['error'],

  clearPropertiesState:[],
});

export const PropertiesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  properties: null,
  properties_error: null,
  properties_fetching: false,
});

/* ------------- Reducers ------------- */

// Properties request
export const properties_request = (state) => state.merge({ fetching: true });

// Properties success
export const properties_success = (state, data) => {
  const { properties } = data;
  return state.merge({ properties_fetching: false, properties_error: null, properties })
};

export const properties_failure = (state, { error }) => state.merge({ properties_fetching: false, properties_properties: null, error });

export const clearPropertiesState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROPERTIES_REQUEST]: properties_request,
  [Types.PROPERTIES_SUCCESS]: properties_success,
  [Types.PROPERTIES_FAILURE]: properties_failure,

  [Types.CLEAR_PROPERTIES_STATE]: clearPropertiesState,

});
