import React from 'react';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';
import Helper from '../../../../Utils/Helper';

class PortfolioOverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    experience: PropTypes.any,
  };

  render() {
    const {experience} = this.props;

    return experience ?
      <div className="flex-1 sm:flex flex-wrap pl-3 sm:px-10 pb-16">
        <div className="sm:w-7/12 pb-20">
          <Title className="text-xl pb-2">{experience.title}</Title>
          <Body>
            {Helper.addBreaksToText(experience.description)}
          </Body>
        </div>
        <div className="sm:w-5/12 sm:pl-10">
          <Title className="text-xl pb-2">{I18n.t('Role')}</Title>
          <Body>{experience.role}</Body>
          <Title className="text-xl pt-8 pb-2">{I18n.t('experience type', null, false, true)}</Title>
          <Body>{experience.type.title}</Body>
          <Title className="text-xl pt-8 pb-2">{I18n.t('on behalf of', null, false, true)}</Title>
          <Body>{experience.company}</Body>
          <Title className="text-xl pt-8 pb-2">{I18n.t('Period')}</Title>
          <Body>{`${experience.startYear} - ${experience.endYear}`}</Body>
        </div>
        <div className="flex mt-10">
          <FilledButton icon="edit" to={`/talent/experiences/${experience && experience.id}/overview/edit`} className="bg-secondary-40 rounded text-white">{I18n.t('Edit')}</FilledButton>
        </div>
      </div>
      : <div className="flex-1 sm:flex flex-wrap pl-3 sm:px-10 pb-16">
        <Loading/>
      </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    experience: state.experience.experience,
    experience_fetching: state.experience.fetching,
    experience_error: state.experience.error,
  };
};

export default connect(mapStateToProps, null)(PortfolioOverviewPage);
