import { call, put } from 'redux-saga/effects'
import EducationActions from '../../Redux/Talent/EducationRedux'
import { callApi } from '../CallApiSaga'

export function * createEducation (api,{obj}) {

  const apiCall = yield call(api.createEducation,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(EducationActions.educationCreateSuccess(response.data));
  } else {
    yield put(EducationActions.educationCreateFailure(response.data ? response.data: 'WRONG'))
  }
}

export function * deleteEducation (api,{obj}) {

  const apiCall = yield call(api.deleteEducation,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(EducationActions.educationDeleteSuccess(true));
  } else {
    yield put(EducationActions.educationDeleteFailure(response.data ? response.data: 'WRONG'))
  }
}
