import {combineReducers} from 'redux';
import configureStore from '../Store/configureStore'
import rootSaga from '../Sagas/'
import {createBrowserHistory} from "history";
import {connectRouter} from "connected-react-router";
import {i18nState} from "redux-i18n"

export const history = createBrowserHistory();
const connectRouterHistory = connectRouter(history);

/* ------------- Assemble The Reducers ------------- */
export const rootReducer = combineReducers({
  i18nState,
  router: connectRouterHistory,
  token: require('./Authentication/TokenRedux').reducer,
  account: require('./Account/AccountRedux').reducer,
  login: require('./Auth/LoginRedux').reducer,
  register: require('./Auth/RegisterRedux').reducer,
  appState: require('./AppState/AppStateRedux').reducer,
  forgot_password: require('./Auth/ForgotPasswordRedux').reducer,
  reset_password: require('./Auth/ResetPasswordRedux').reducer,
  castings: require('./Talent/CastingsRedux').reducer,
  route: require('./Route/RouteRedux').reducer,
  casting: require('./Talent/CastingRedux').reducer,
  experiences: require('./Talent/ExperiencesRedux').reducer,
  experience: require('./Talent/ExperienceRedux').reducer,
  property_types: require('./PropertyTypes/PropertyTypesRedux').reducer,
  properties: require('./Talent/PropertiesRedux').reducer,
  property: require('./Talent/PropertyRedux').reducer,
  educations: require('./Talent/EducationsRedux').reducer,
  education: require('./Talent/EducationRedux').reducer,
  notifications: require('./Talent/NotificationsRedux').reducer,
  skillset_categories: require('./SkillsetCategories/SkillsetCategoriesRedux').reducer,
  experience_types: require('./ExperienceTypes/ExperienceTypesRedux').reducer,
  skills: require("./Talent/SkillsRedux").reducer,
  user_notifications: require('./Talent/UserNotificationsRedux').reducer,
  skill: require('./Talent/SkillRedux').reducer,
  project_types: require('./ProjectTypes/ProjectTypesRedux').reducer,
  project: require('./Project/ProjectRedux').reducer,
  projects: require('./Projects/ProjectsRedux').reducer,
  invitations: require('./Account/InvitationsRedux').reducer,
  users: require('./Account/UsersRedux').reducer,
  user: require('./Account/UserRedux').reducer,
  role: require('./Project/RoleRedux').reducer,
  translations: require('./Translation/TranslationRedux').reducer,
  agency_castings: require('./Castings/AgencyCastingsRedux').reducer,
  plans: require('./Billing/PlansRedux').reducer,
  subscription:require('./Billing/SubscriptionRedux').reducer,
  fetch_group:require('./Fetch/FetchGroupRedux').reducer,
  upload_care:require('./UploadCare/UploadCareRedux').reducer,
  coupon:require('./Billing/CouponRedux').reducer,
  invoices:require('./Billing/InvoicesRedux').reducer,
  card:require('./Billing/CardRedux').reducer,
});

export default () => {
  let {store} = configureStore(rootReducer, rootSaga);

  return store
}
