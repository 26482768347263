import React from 'react';
import Title from '../../../../Components/Text/Title';
import MediaCollection from '../../../../Components/Lists/MediaCollection';
import PropTypes from 'prop-types';
import ExperienceActions from '../../../../Redux/Talent/ExperienceRedux';
import {connect} from 'react-redux';
import I18n from '../../../../Utils/I18n';
import Body from '../../../../Components/Text/Body';
import Loading from '../../../../Components/Loading';
import Helper from '../../../../Utils/Helper';
import FetchGroupActions from '../../../../Redux/Fetch/FetchGroupRedux';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddMedia from '../../../../Components/Images/AddMedia';

class PortfolioMediaPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    experience: PropTypes.any,
    updateExperience: PropTypes.any,
    uploadSingleImage: PropTypes.func,
    clearUploadCareState: PropTypes.func,
    file_uploading: PropTypes.bool,
    file_uploaded: PropTypes.bool,
    file: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      updating: false,
      file_uploading: false,
      uploadedFileType:null,
    };
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    this.props.clearUploadCareState();
  }

  uploadedFile = (file) => {
    const {experience, account} = this.props;
    const files = experience ? (experience.media != null ? experience.media : []) : [];
    let mediaArray = [];
    files.map((file) => {
      mediaArray.push(file);
    });

    mediaArray.push({url:file.cdnUrl, type: Helper.fileType(file.mimeType)});

    this.props.updateExperience({
      username: account.username,
      experience_id: experience.id,
      experience: {...experience, media: mediaArray},
    });
  };

  deleteMedia = (path) => {
    const {account, experience} = this.props;

    if (experience.media) {
      const mediaArray = experience.media.filter((file) => file.url !== path);
      this.props.updateExperience({
        username: account.username,
        experience_id: experience.id,
        experience: {...experience, media: mediaArray},
      });
    }
  };

  render() {
    const {experience} = this.props, {file_uploading} = this.state;
    return (
      <div className="flex-1 pl-3 sm:px-8">
        <Title className="text-xl pb-4">{I18n.t('Media')}</Title>
        <div className="flex flex-wrap">
          {experience
              ? (Helper.getImagesAndVideoFromMedia(experience.media).length > 0 ?
                  <div>
                    <MediaCollection deleteMedia={this.deleteMedia} collection={Helper.getImagesAndVideoFromMedia(experience.media)} deletable/>
                  </div> : <Body className="mb-4">{I18n.t('No media present')}</Body>)
            : <Loading/>
          }
        </div>

        <div className="pt-12">
          <AddMedia onlyImages={false} className="max-w-xl" uploadedFile={this.uploadedFile} disabled={file_uploading}/>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    experience: state.experience.experience,
    experience_fetching: state.experience.fetching,
    experience_error: state.experience.error,

    file: state.upload_care.file,
    file_uploading: state.upload_care.uploading,
    file_uploaded: state.upload_care.uploaded,
    file_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExperienceById: (obj) => dispatch(ExperienceActions.experienceByIdRequest(obj)),
    updateExperience: (obj) => dispatch(ExperienceActions.experienceUpdateRequest(obj)),

    getGroupFiles: (obj) => dispatch(FetchGroupActions.fetchGroupRoleRequest(obj)),
    clearGroupFiles: () => dispatch(FetchGroupActions.clearFetchGroupState()),
    uploadMultiple: (obj) => dispatch(UploadCareActions.uploadMultipleRequest(obj)),
    deleteFile: (uuid) => dispatch(UploadCareActions.deleteFileRequest(uuid)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioMediaPage);
