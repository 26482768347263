import React from 'react';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import LabeledContent from '../../../../Components/Text/LabeledContent';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helper from '../../../../Utils/Helper';
import I18n from '../../../../Utils/I18n';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    casting: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const {casting} = this.props;
    const {t} = this.context;
    const role = casting ? casting.role : null;
    const project = role ? role.project : null;
    const type = project ? project.type : null;

    return (
      <div className="flex-1 sm:flex pl-3 sm:px-10 pb-16">
        <div className="sm:w-7/12 mb-10">
          <Title className="text-xl pb-2">{I18n.t('Project information')}</Title>
          <Body>
            {project ? Helper.addBreaksToText(project.description) : null}
          </Body>
        </div>

        <div className="sm:w-5/12 sm:pl-10">
          <Title className="text-xl pb-2">{I18n.t('production dates', null, false, true)}</Title>
          <LabeledContent className="w-20" label={`${Helper.titleCase(t('from'))}:`}>{(project && project.starts_at) ? Helper.formatDate(project.starts_at) : I18n.t('unknown')}</LabeledContent>
          <LabeledContent className="w-20" label={`${Helper.titleCase(t('to'))}:`}>{(project && project.ends_at) ? Helper.formatDate(project.ends_at) : I18n.t('unknown')}</LabeledContent>
          <LabeledContent className="w-20" label={`${Helper.titleCase(t('status'))}:`}>{casting && casting.status}</LabeledContent>

          <Title className="text-xl pt-8 pb-2">{I18n.t('Project details')}</Title>
          <LabeledContent label={`${t('project type')}:`}>{type ? type.title : null}</LabeledContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    casting: state.casting.casting,
    casting_fetching: state.casting.fetching,
    casting_error: state.casting.error,
  };
};

export default connect(mapStateToProps)(OverviewPage);
