import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NotificationItem from '../../../../Components/ListItems/NotificationItem';
import UserNotificationsActions from '../../../../Redux/Talent/UserNotificationsRedux';
import Loading from '../../../../Components/Loading';

class ReadMessagesPage extends React.Component {
  static propTypes = {
    account: PropTypes.any,
    history: PropTypes.any,
    read_notifications: PropTypes.any,
    getReadNotifications: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getReadNotifications(this.props.account.username);
  }

  render() {
    const {read_notifications} = this.props;
    return (
      <div className="flex-1 pl-3 sm:px-8">
        <h2 className="text-gray-60 text-lg font-semibold pb-6">Read messages</h2>
        {
          read_notifications
            ? read_notifications.map((notification, key) => {
              return <NotificationItem key={key} className={read_notifications[key + 1] ? '' : 'border-b'} notification={notification}/>;
            })
            : <Loading/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    read_notifications: state.user_notifications.read_notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReadNotifications: (username) => dispatch(UserNotificationsActions.readNotificationsRequest(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadMessagesPage);
