import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import TalentRoute from '../../Components/Routes/TalentRoute';
// Routes
import PortfolioOverviewPage from '../../Containers/Talent/Portfolio/SubPages/PortfolioOverviewPage';
import PortfolioMediaPage from '../../Containers/Talent/Portfolio/SubPages/PortfolioMediaPage';
import PortfolioLinksPage from '../../Containers/Talent/Portfolio/SubPages/PortfolioLinksPage';
import PortfolioDeletePage from '../../Containers/Talent/Portfolio/SubPages/PortfolioDeletePage';
//edit
import PortfolioOverviewEditPage from '../../Containers/Talent/Portfolio/Edit/PortfolioOverviewPage';

class PortfolioRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <TalentRoute exact path="/talent/experiences/:experiences/overview" params={{name: 'Portfolio'}} component={PortfolioOverviewPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/overview/edit" params={{name: 'Portfolio'}} component={PortfolioOverviewEditPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/media" params={{name: 'Portfolio'}} component={PortfolioMediaPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/links" params={{name: 'Portfolio'}} component={PortfolioLinksPage}/>
        <TalentRoute exact path="/talent/experiences/:experiences/delete" params={{name: 'Portfolio'}} component={PortfolioDeletePage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(PortfolioRoutingComponent));
