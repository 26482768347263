import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import OverviewPage from '../../../Containers/Agency/Casting/SubPages/OverviewPage';

class AgencyProjectRolePeopleCastingRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/castings/:casting/overview" params={{name: 'Talent'}} component={OverviewPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencyProjectRolePeopleCastingRoutingComponent));
