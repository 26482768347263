import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import BaseButton from './BaseButton';

class TextButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    iconLeft: PropTypes.bool,
    noText: PropTypes.bool,
    onClick: PropTypes.func,
    saved: PropTypes.bool,
    to: PropTypes.string,
    type: PropTypes.string,
  };

  render() {
    const {className, children, disabled, external, icon, iconLeft, noText, onClick, saved, to, type} = this.props;

    return type ?
      saved ?
        <div className="flex flex-wrap items-center">
          <button type={type} onClick={onClick} disabled={disabled} className={`flex items-center disabled:opacity-25 disabled:cursor-not-allowed mr-3 ${className || ''}`}>
            {iconLeft && icon &&
            <Icon className={`${noText ? '' : 'mr-3 '}text-sm`}>{icon}</Icon>
            }

            <p className="font-bold text-sm w-full">{children}</p>

            {!iconLeft && icon &&
            <Icon className={`${noText ? '' : 'ml-3 '}text-sm`}>{icon}</Icon>
            }
          </button>
          <p className="text-positive"><Icon className="mr-2">check</Icon>saved</p>
        </div>
        :
        <button type={type} onClick={onClick} disabled={disabled} className={`flex items-center disabled:opacity-25 disabled:cursor-not-allowed ${className || ''}`}>
          {iconLeft && icon &&
          <Icon className={`${noText ? '' : 'mr-3 '}text-sm`}>{icon}</Icon>
          }

          <p className="font-bold text-sm w-full">{children}</p>

          {!iconLeft && icon &&
          <Icon className={`${noText ? '' : 'ml-3 '}text-sm`}>{icon}</Icon>
          }
        </button>
      :
      <BaseButton to={to} onClick={onClick} external={external} className={`items-center ${className || ''}`}>
        {iconLeft && icon &&
        <Icon className={`${noText ? '' : 'mr-3 '}text-sm`}>{icon}</Icon>
        }

        <p className="font-bold text-sm w-full">{children}</p>

        {!iconLeft && icon &&
        <Icon className={`${noText ? '' : 'ml-3 '}text-sm`}>{icon}</Icon>
        }
      </BaseButton>;
  }
}

export default TextButton;
