import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

class BaseButton extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
    className: PropTypes.string,
    external: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  render() {
    const {children, className, external, onClick, to} = this.props;

    return (
      external ?
        <a href={to || '#'} onClick={onClick} rel="noopener noreferrer" target="_blank" className={`flex ${className || ''}`}>
          {children}
        </a>
        : <Link to={to || '#'} onClick={onClick} className={`flex ${className || ''}`}>
          {children}
        </Link>
    );
  }
}

export default BaseButton;
