import PropTypes from 'prop-types';
import React from 'react';
import FilledButton from '../Buttons/FilledButton';
import I18n from '../../Utils/I18n';
import {connectHits} from 'react-instantsearch-dom';

class SelectAllButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hits: PropTypes.array,
    onClick: PropTypes.func,
    selectAllHits: PropTypes.func,
  };

  render() {
    const {className, hits} = this.props;

    return (
      <div className={`flex${className ? ` ${className}` : ''}`}>
        <FilledButton icon="chevron-right" onClick={() => this.props.selectAllHits(hits)} iconRight className={`bg-primary-40 text-white rounded${className ? ` ${className}` : ''}`}>
          {I18n.t('Select all visible users')}
        </FilledButton>
      </div>
    );
  }
}

export default connectHits(SelectAllButton);
