import { call, put } from 'redux-saga/effects'
import PropertyActions from '../../Redux/Talent/PropertyRedux'
import { callApi } from '../CallApiSaga'

export function * updateProperty (api,{obj}){
  const apiCall = yield call(api.updateProperty,obj);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(PropertyActions.propertyUpdateSuccess(true));
  } else {
    yield put(PropertyActions.propertyUpdateFailure('WRONG'))
  }
}

