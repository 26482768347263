import {call, put} from 'redux-saga/effects'
import AgencyCastingsActions from '../../Redux/Castings/AgencyCastingsRedux'
import {callApi} from '../CallApiSaga'

export function* createCastings(api, {obj}) {
  const apiCall = yield call(api.createCastings, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.CreateCastingsSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.CreateCastingsFailure(response.data))
  }
}

export function* getAllCastingsForRole(api, {obj}) {
  const apiCall = yield call(api.getAllCastingsForRole, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.GetCastingsSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.GetCastingsFailure(response.data))
  }
}

export function* getPageCastingsForRole(api, {obj}) {
  const apiCall = yield call(api.getPageCastingsForRole, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.GetPageCastingsSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.GetPageCastingsFailure(response.data))
  }
}

export function* getAllDeclinedCastingsForRole(api, {obj}) {
  const apiCall = yield call(api.getAllDeclinedCastingsForRole, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.GetDeclinedCastingsSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.GetDeclinedCastingsFailure(response.data))
  }
}

export function* getDeclinedPageCastingsForRole(api, {obj}) {
  const apiCall = yield call(api.getDeclinedPageCastingsForRole, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.GetDeclinedPageCastingsSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.GetDeclinedPageCastingsFailure(response.data))
  }
}

export function* getCastingByIdForAgency(api, {obj}) {
  const apiCall = yield call(api.getCastingByIdForAgency, obj);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(AgencyCastingsActions.GetCastingSuccess(response.data));
  } else {
    yield put(AgencyCastingsActions.GetCastingFailure(response.data))
  }
}
