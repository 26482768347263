import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    projectTypesRequest: [],
    projectTypesSuccess: ['project_types'],
    projectTypesFailure: ['error'],

    projectTypeByIdRequest: [],
    projectTypeByIdSuccess: ['project_type'],
    projectTypeByIdFailure: ['error'],

    clearProjectTypesState:[],
});

export const ProjectTypesTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    project_types: null,
    project_type:null,
    error: null,
    fetching: false,
});

/* ------------- Reducers ------------- */

// Project Types request
export const project_types_request = (state) => state.merge({ fetching: true });

// Project Types success
export const project_types_success = (state, data) => {
    const { project_types } = data;
    return state.merge({ fetching: false, error: null, project_types })
};

export const project_types_failure = (state, { error }) => state.merge({ fetching: false, project_types: null, error });

// Project Type request
export const project_type_by_id_request = (state) => state.merge({ fetching: true });

// Project Type success
export const project_type_by_id_success = (state, data) => {
    const { project_type} = data;
    return state.merge({ fetching: false, error: null, project_type })
};

export const project_type_by_id_failure = (state, { error }) => state.merge({ fetching: false, project_type: null, error });

export const clear_project_types_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROJECT_TYPES_REQUEST]: project_types_request,
    [Types.PROJECT_TYPES_SUCCESS]: project_types_success,
    [Types.PROJECT_TYPES_FAILURE]: project_types_failure,

    [Types.PROJECT_TYPE_BY_ID_REQUEST]: project_type_by_id_request,
    [Types.PROJECT_TYPE_BY_ID_SUCCESS]: project_type_by_id_success,
    [Types.PROJECT_TYPE_BY_ID_FAILURE]: project_type_by_id_failure,

    [Types.CLEAR_PROJECT_TYPES_STATE]: clear_project_types_state,

});
