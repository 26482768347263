import React from 'react';
import Card from '../../../Components/Card';
import SubNav from '../../../Components/Navigation/SubNav';
import SubNavItem from '../../../Components/Navigation/SubNavItem';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AgencyProjectRolePeopleCastingRoutingComponent
  from '../../../Components/Routing/Agency/AgencyProjectRolePeopleCastingRoutingComponent';
import I18n from '../../../Utils/I18n';
import AgencyCastingActions from '../../../Redux/Castings/AgencyCastingsRedux';

class CastingPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    role: PropTypes.any,
    project: PropTypes.any,
    getCastingById: PropTypes.func,
    casting: PropTypes.any,
    casting_error: PropTypes.any,
    casting_fetching: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {project, role, casting} = this.props.match.params;

    this.props.getCastingById({project_id: project, role_id: role, casting_id: casting});
  }

  componentDidUpdate(prevProps) {
    const {casting} = this.props;
    const {project, role} = this.props.match.params;

    if (!this.props.casting_fetching) {
      if ((this.props.casting_error !== prevProps.casting_error)) {
        this.props.history.push(`/agency/projects/`);
      }

      if (casting.role_id !== parseInt(role) || casting.role.project_id !== parseInt(project)) {
        this.props.history.push(`/agency/projects/${casting.role.project_id}/project-roles/${casting.role_id}/castings/${casting.id}`);
      }
    }
  }

  render() {
    const {project, role, casting} = this.props.match.params;

    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <Card
          className="w-full flex flex-wrap justify-between px-3 py-4 sm:px-6 sm:py-8 mb-5 sm:min-h-128 overflow-visible">
          <div className="flex w-full">
            <SubNav>
              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/people/contacted`} noActiveStyle icon="chevron-left" className="-mt-1">
                {I18n.t('Talent')}
              </SubNavItem>

              <hr className="m-3 sm:mr-5 ml-0 border-0 h-px bg-gray-10"/>

              <SubNavItem to={`/agency/projects/${project}/project-roles/${role}/castings/${casting}/overview`} icon="info-circle">
                {I18n.t('Overview')}
              </SubNavItem>
            </SubNav>

            <AgencyProjectRolePeopleCastingRoutingComponent/>
          </div>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    role: state.role.role,
    role_fetching: state.role.fetching,
    role_error: state.role.error,

    project: state.project.project,

    casting: state.agency_castings.casting,
    casting_fetching: state.agency_castings.fetching,
    casting_error: state.agency_castings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCastingById: (obj) => dispatch(AgencyCastingActions.GetCastingRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CastingPage);
