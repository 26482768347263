import React from 'react';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Title from '../../../../Components/Text/Title';
import Body from '../../../../Components/Text/Body';
import I18n from '../../../../Utils/I18n';
import Helper from '../../../../Utils/Helper';
import PropTypes from 'prop-types';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import {connect} from 'react-redux';

class DeletePage extends React.Component {
  static  propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    deleteAccount: PropTypes.func,
    deleting: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  onClick = (e) => {
    const {account} = this.props, {t} = this.context,
      text = t('Are you sure you want to delete :thing?', {thing: t('your account')});
    e.preventDefault();

    if (confirm(text)) {
      this.props.deleteAccount({id: account.account.id});
    }
  };

  render() {
    const {deleting} = this.props;

    return (
      <div className="ml-3 md:mx-8 flex-1">
        <Title className="text-xl pb-2">{I18n.t('Are you sure?')}</Title>
        <Body className="text-secondary-40 ">
          {I18n.t('Are you sure you want to delete :thing?', {thing: Helper.makeBold(I18n.t('your account'))})}
          <br/>
          {I18n.t('Use this button to completely delete your account and all data')}
        </Body>

        <div className="flex mt-10">
          <FilledButton icon="trash-alt" onClick={this.onClick} type={'button'} disabled={deleting} className="rounded text-white bg-negative">{I18n.t('Delete your account')}</FilledButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    deleting: state.account.deleting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAccount: (obj) => dispatch(AccountActions.accountDeleteRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePage);
