import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  newCastingsRequest: ['obj'],
  newCastingsSuccess: ['castings'],
  newCastingsFailure: ['error'],

  currentCastingsRequest: ['obj'],
  currentCastingsSuccess: ['castings'],
  currentCastingsFailure: ['error'],

  clearCastingsState:[],
});

export const CastingsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  new_castings: null,
  current_castings:null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// Castings request
export const new_castings_request = (state) => state.merge({ fetching: true});

export const current_castings_request = (state) => state.merge({ fetching: true});


// Castings success
export const new_castings_success = (state, data) => {
  const { castings } = data;
  return state.merge({ fetching: false, error: null, new_castings:castings, current_castings:state.current_castings })
};

// Castings success
export const current_castings_success = (state, data) => {
  const { castings } = data;
  return state.merge({ fetching: false, error: null, current_castings:castings, new_castings:state.new_castings })
};

export const new_castings_failure = (state, data) => {

  const { error } = data;

  return state.merge({ fetching: false, new_castings:null , error });
};

export const current_castings_failure = (state, data) => {

  const { error } = data;

  return state.merge({ fetching: false, current_castings:null , error });
};

export const clearCastingsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.NEW_CASTINGS_REQUEST]: new_castings_request,
  [Types.NEW_CASTINGS_SUCCESS]: new_castings_success,
  [Types.NEW_CASTINGS_FAILURE]: new_castings_failure,

  [Types.CURRENT_CASTINGS_REQUEST]: current_castings_request,
  [Types.CURRENT_CASTINGS_SUCCESS]: current_castings_success,
  [Types.CURRENT_CASTINGS_FAILURE]: current_castings_failure,

  [Types.CLEAR_CASTINGS_STATE]: clearCastingsState,

});
