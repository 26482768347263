import PropTypes from 'prop-types';
import React from 'react';
import ProjectItem from '../ListItems/ProjectItem';
import AddButton from '../Buttons/AddButton';
import ProjectsActions from '../../Redux/Projects/ProjectsRedux';
import {connect} from 'react-redux';
import Loading from '../Loading';

class ProjectList extends React.Component {
  static  propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    projects: PropTypes.any,
    account: PropTypes.any,
    getProjects: PropTypes.func,
    addButton: PropTypes.bool,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      fetchedProjects: false,
    };
  }

  componentDidMount() {
    this.setState({fetchedProjects: true}, function () {
      this.props.getProjects();
    });
  }

  canViewProject(project) {
    const {account} = this.props;
    return (
      account.roles.includes('owner') ||
      account.permissions.includes('view_all_projects') ||
      account.permissions.includes('view_project_' + project.id) ||
      account.permissions.includes('edit_project_' + project.id) ||
      account.permissions.includes('edit_all_projects'));
  }

  render() {
    const {addButton, className, projects, account} = this.props;
    return (
      <div className={`sm:flex flex-wrap sm:-mr-5 max-w-6xl ${className || ''}`}>
        {addButton && account.roles.includes('owner')
          ? <div className="w-full sm:w-1/2 xl:w-1/3 sm:pr-5 mb-5">
            <AddButton to="/agency/projects/add/overview" className="flex h-full min-w-64 min-h-48 mb-5 sm:flex-initial w-full"/>
          </div>
          : null
        }
        {projects
          ? projects.map((project, key) => {
            if (!this.canViewProject(project)) {
              return;
            }

            return (
              <div key={key} className="w-full sm:w-1/2 xl:w-1/3 sm:pr-5 mb-5">
                <ProjectItem className="w-full mb-5" project={project}/>
              </div>
            );
          })
          : <Loading/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    projects: state.projects.projects ? state.projects.projects : [],
    projects_fetching: state.projects.fetching,
    projects_error: state.projects.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: () => dispatch(ProjectsActions.projectsRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
