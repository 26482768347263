import { call, put } from 'redux-saga/effects'
import UploadCareActions from '../../Redux/UploadCare/UploadCareRedux'
import API_UPLOAD_CARE from '../../Services/ApiUploadCare'
const api_upload_care = API_UPLOAD_CARE.create();

export function * deleteFile (api,{uuid}) {

    const response = yield call(api.deleteFile,uuid);
    // success?
    if (response.ok) {
        yield put(UploadCareActions.deleteFileSuccess(response.data));
    } else {
        yield put(UploadCareActions.deleteFileFailure('WRONG'))
    }
}

export function * uploadSingle (api,{obj}) {
    let body = new FormData();
    body.append('file', obj);
    body.append('UPLOADCARE_PUB_KEY', process.env.UPLOADCARE_PUBLIC_KEY);
    body.append('UPLOADCARE_STORE', '1');

    const response_store = yield call(api.uploadDirect,body);
    if(response_store.ok) {
        const response_fetch = yield call(api_upload_care.fetchMedia,{uuid:response_store.data.file});
        if(response_fetch.ok){
            yield put(UploadCareActions.uploadSingleSuccess(response_fetch.data.original_file_url));
        }
    }
}

export function * uploadMultiple (api,{obj}) {
    let body = new FormData();
    obj.data.map((file,key) => {
        body.append(`files[${key}]`, file);
    });

    body.append('pub_key', process.env.UPLOADCARE_PUBLIC_KEY);
    const response_store = yield call(api.uploadMultiple,body);
    if (response_store.ok) {
        yield put(UploadCareActions.uploadMultipleSuccess(response_store.data.cdn_url));
    } else {
        yield put(UploadCareActions.uploadMultipleFailure('WRONG'))
    }

}
