import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import Select from '../../../../Components/Form/Select';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Body from '../../../../Components/Text/Body';
import skillsetCategoriesActions from '../../../../Redux/SkillsetCategories/SkillsetCategoriesRedux';
import skillsActions from '../../../../Redux/Talent/SkillsRedux';
import skillActions from '../../../../Redux/Talent/SkillRedux';
import _ from 'lodash';
import DeletableItem from '../../../../Components/ListItems/DeletableItem';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';
import Loading from '../../../../Components/Loading';
import TextField from "../../../../Components/Form/TextField";
import AccountActions from '../../../../Redux/Account/AccountRedux';

class SkillsPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    getSkillsetCategories: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    skillset_categories: PropTypes.array,
    error: PropTypes.any,
    fetching: PropTypes.bool,
    getUserSkills: PropTypes.func,
    skills: PropTypes.array,
    updating: PropTypes.bool,
    deleting: PropTypes.bool,
    saving: PropTypes.bool,
    saveSuccess: PropTypes.bool,
    deleteSuccess: PropTypes.bool,
    saveSkill: PropTypes.func,
    deleteSkill: PropTypes.func,
    savingSkill: PropTypes.bool,
    deletingSkill: PropTypes.bool,
    getAccount: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      levelOptions: [],
      savingSkill: false,
      saved: false,
      deletingSkill: false,
      categorizedSkills: [],
    };
  }

  componentDidMount() {
    this.props.getSkillsetCategories();
    this.props.getUserSkills(this.props.account.username);
  }

  componentDidUpdate(prevProps) {
    const {skillset_categories, skills} = this.props;
    if (prevProps.skillset_categories !== skillset_categories) {
      let options = [];
      skillset_categories.map((item) => {
        options[item.id] = [];
      });
      this.setState({levelOptions: options});
    }

    if (prevProps.skills !== skills) {
      this.setState({categorizedSkills: _.groupBy(skills, 'skillset.category.id')});
    }

    //saving
    if (!this.props.saving) {
      if (this.props.saveSuccess && (prevProps.saveSuccess !== this.props.saveSuccess) && this.state.savingSkill) {
        this.setState({savingSkill: false}, function () {
          this.props.getUserSkills(this.props.account.username);
          this.props.getAccount();
        });
      }
    }

    //deleting
    if (!this.props.deleting) {
      if (this.props.deleteSuccess && (prevProps.deleteSuccess !== this.props.deleteSuccess) && this.state.deletingSkill) {
        this.setState({deletingSkill: false}, function () {
          this.props.getUserSkills(this.props.account.username);
          this.props.getAccount();
        });
      }
    }
  }

  onSubmit = (values) => {
    if (!values.skillset_option_id) {
      return;
    }
    this.setState({savingSkill: true}, function () {
      this.props.saveSkill({username: this.props.account.username, skill: values.skillset_option_id});
    });
  };

  onDelete = (skillset_option_id) => {
    this.setState({deletingSkill: true}, function () {
      this.props.deleteSkill({username: this.props.account.username, skill: skillset_option_id});
    });
  };

  handleSkillSetSelect(skillset_category_id, skillset_id) {
    const {skillset_categories} = this.props;
    const {levelOptions} = this.state;
    const category = skillset_categories.find(x => x.id === skillset_category_id);
    const skillset = category.skillsets.find(x => x.id === parseInt(skillset_id));

    levelOptions[skillset_category_id] = skillset ? skillset.options : [];
    this.setState({levelOptions: levelOptions});
  }

  render() {
    const {skillset_categories} = this.props,
      {levelOptions, categorizedSkills} = this.state,
      {t} = this.context;

    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="text-xl mb-3">{I18n.t('Skills')}</Title>
        {skillset_categories
          ? skillset_categories.map((skillset_category, key) => {
            return (
              <div key={key}>
                <h3 className="text-md">{t(skillset_category.title)}</h3>
                <Body className="pb-4">{t(skillset_category.instructions)}</Body>

                <div className="flex flex-wrap mb-4">
                  {categorizedSkills[skillset_category.id]
                    ? categorizedSkills[skillset_category.id].map((item, key) =>
                      <DeletableItem onClick={() => this.onDelete(item.id)}
                                     key={key}>{I18n.t(item.skillset.title)} {(item.title !== 'default') && `- ${t(item.title)}`}</DeletableItem>)
                    : null
                  }
                </div>

                <Form
                  onSubmit={this.onSubmit}
                  validateOnBlur
                >
                  {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                      <div className="md:flex flex-wrap">
                        <Field name="skillset_id" validate={required}>
                          {({input, meta}) => (
                            <div className="pb-3 flex-1 md:pr-5">
                              <Label input={input}>{I18n.t('Option', null, true)}</Label>
                              <Select input={input} meta={meta} className="w-full"
                                      onSelect={(e) => this.handleSkillSetSelect(skillset_category.id, e.target.value)}>
                                <option>{t('Make a choice')}</option>
                                {skillset_category.skillsets
                                  ? skillset_category.skillsets.map((skillset, key) =>
                                    <option key={key} value={skillset.id}>{t(skillset.title)}</option>)
                                  : null
                                }
                              </Select>
                            </div>
                          )}
                        </Field>
                        {
                          (levelOptions[skillset_category.id] && (levelOptions[skillset_category.id].length === 1)) ?
                            (
                              <Field name="skillset_option_id" type={'hidden'}
                                     initialValue={levelOptions[skillset_category.id][0].id}>
                                {({input, meta}) => {
                                  return (
                                    <div className="pb-3 flex-1 md:pr-5">
                                      <TextField input={input} meta={meta}/>
                                    </div>
                                  )
                                }}
                              </Field>
                            ) : (
                              <Field name="skillset_option_id">
                                {({input, meta}) => {
                                  return (
                                    <div className="pb-3 flex-1 md:pr-5">
                                      <Label input={input}
                                             disabled={levelOptions[skillset_category.id] && !levelOptions[skillset_category.id].length}>{I18n.t('Level', null, true)}</Label>
                                      <Select input={input} meta={meta} className="w-full"
                                              disabled={levelOptions[skillset_category.id] && !levelOptions[skillset_category.id].length}>
                                        <option>{t('Make a choice')}</option>
                                        {levelOptions[skillset_category.id]
                                          ? levelOptions[skillset_category.id].map((item, key) =>
                                            <option key={key} value={item.id}>{item.title}</option>)
                                          : null
                                        }
                                      </Select>
                                    </div>
                                  )
                                }}
                              </Field>
                            )
                        }
                      </div>
                      <div className="mb-16 mt-auto">
                        <FilledButton type="submit" icon="plus"
                                      className="rounded text-white bg-secondary-40">{I18n.t('add', null, false, true)}</FilledButton>
                      </div>

                    </form>
                  )}
                </Form>
              </div>
            );
          })
          : <Loading/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,
    skillset_categories: state.skillset_categories.skillset_categories,
    skills: state.skills.skills,
    error: state.skill.error,
    updating: state.skill.updating,
    deleting: state.skill.deleting,
    saving: state.skill.saving,
    saveSuccess: state.skill.saveSuccess,
    deleteSuccess: state.skill.deleteSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(AccountActions.accountRequest()),
    getSkillsetCategories: () => dispatch(skillsetCategoriesActions.skillsetCategoriesRequest()),
    getUserSkills: (username) => dispatch(skillsActions.skillsRequest(username)),
    saveSkill: (object) => dispatch(skillActions.skillSaveRequest(object)),
    deleteSkill: (object) => dispatch(skillActions.skillDeleteRequest(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsPage);
