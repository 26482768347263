import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getUsersRequest: [],
  getUsersSuccess: ['data'],
  getUsersFailure: ['error'],

  getUserRequest: ['username'],
  getUserSuccess: ['data'],
  getUserFailure: ['error'],

  clearProjectState: [],

});

export const UsersTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  users: null,
  user: null,
  fetching: false,
  get_error: null,
});

/* ------------- Reducers ------------- */

export const get_users_request = (state) => state.merge({
  fetching: true,
  get_error: null,
});

export const get_users_success = (state, {data}) => {
  return state.merge({
    users: data.data,
    fetching: false,
    get_error: null,
  })
};

export const get_users_failure = (state, {error}) => state.merge({
  fetching: false,
  get_error: error,
});


export const get_user_request = (state) => state.merge({
  fetching: true,
  get_error: null,
});

export const get_user_success = (state, {data}) => {
  return state.merge({
    user: data,
    fetching: false,
    get_error: null,
  })
};

export const get_user_failure = (state, {error}) => state.merge({
  fetching: false,
  get_error: error,
});



export const clear_project_state = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: get_users_request,
  [Types.GET_USERS_SUCCESS]: get_users_success,
  [Types.GET_USERS_FAILURE]: get_users_failure,

  [Types.GET_USER_REQUEST]: get_user_request,
  [Types.GET_USER_SUCCESS]: get_user_success,
  [Types.GET_USER_FAILURE]: get_user_failure,


  [Types.CLEAR_PROJECT_STATE]: clear_project_state,
});
