import React from 'react';
import Title from '../../../../Components/Text/Title';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MediaCollection from '../../../../Components/Lists/MediaCollection';
import AccountActions from '../../../../Redux/Account/AccountRedux';
import I18n from '../../../../Utils/I18n';
import UploadCareActions from '../../../../Redux/UploadCare/UploadCareRedux';
import AddMedia from '../../../../Components/Images/AddMedia';

class PageLayoutPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    updateAccount: PropTypes.func,
    banner: PropTypes.any,
    banner_uploading: PropTypes.bool,
    banner_uploaded: PropTypes.bool,
    uploadSingleImage: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      banner_uploading: false,
      saved: false,
    };
  }

  componentDidUpdate() {

  }

  uploadedFile = (file) => {
    this.setState({updating: true}, function () {
      let newObj = Object.assign({}, this.props.account);
      newObj.banner = file.cdnUrl;
      this.props.updateAccount({account: newObj, username: this.props.account.username});
    });
  };

  formatForMediaCollection = () => {
    let mediaArray = [];
    const {account} = this.props;
    if (account.banner) {
      mediaArray.push({
        src: account.banner,
        alt: '',
        type: 'image',
      });
    }
    return mediaArray;
  };

  render() {
    const {banner_uploading} = this.state;
    return (
      <div className="flex-1 pl-3 sm:px-10 pb-16">
        <Title className="text-xl pb-2">{I18n.t('Edit your profile layout')}</Title>
        <div className="flex flex-wrap">
          <MediaCollection collection={this.formatForMediaCollection()}/>
        </div>

        <AddMedia onlyImages={true} className="max-w-xl" uploadedFile={this.uploadedFile} disabled={banner_uploading}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    account_fetching: state.account.fetching,
    account_error: state.account.error,

    banner: state.upload_care.file,
    banner_uploading: state.upload_care.uploading,
    banner_uploaded: state.upload_care.uploaded,
    banner_error: state.upload_care.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (obj) => dispatch(AccountActions.accountUpdateRequest(obj)),
    uploadSingleImage: (obj) => dispatch(UploadCareActions.uploadSingleRequest(obj)),
    clearUploadCareState: () => dispatch(UploadCareActions.clearUploadCareState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayoutPage);
