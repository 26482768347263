import PropTypes from 'prop-types';
import React from 'react';
import Carousel, {Modal, ModalGateway} from 'react-images';
import BaseButton from '../Buttons/BaseButton';
import IconButton from '../Buttons/IconButton';
import Icon from '../Icon';
import Loading from '../Loading';
import Helper from '../../Utils/Helper';

class MediaCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modalIsOpen: false, selectedIndex: 0};
  }

  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    collection: PropTypes.any,
    deletable: PropTypes.bool,
    deleteMedia: PropTypes.func,
  };

  toggleModal = (selectedIndex) => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      selectedIndex,
    }));
  };

  view = ({data}) => {
    if (Helper.fileType(data.type) === 'video') {
      return <video className="block mx-auto" src={data.src} controls/>;
    }
    return <img className="block mx-auto" src={data.src} alt={data.alt}/>;
  };

  render() {
    const {className, children, collection, deletable, deleteMedia} = this.props;
    const {modalIsOpen, selectedIndex} = this.state;

    const imageCollection = collection || children;

    return (
      <div>
        <ModalGateway>
          {modalIsOpen ?
            <Modal onClose={this.toggleModal}>
              {imageCollection && imageCollection.length > 0 ?
                <Carousel views={imageCollection} components={{Footer: null, View: this.view}} currentIndex={selectedIndex}/>
                :
                <Carousel views={imageCollection} components={{Footer: null, View: this.view}}/>}
            </Modal>
            : null}
        </ModalGateway>
        <div className="flex flex-wrap">
          {imageCollection ? imageCollection.map((image, key) => {
              return (
                <div className={`min-w-32 rounded-lg overflow-hidden group relative sm:max-h-32 sm:h-32 sm:w-auto mr-2 sm:mr-5 mb-5 shadow ${className || ''}`} key={key}>
                  {Helper.fileType(image.type) === 'video'
                    ?
                    <video className="block rounded-lg max-h-full max-w-full" src={image.src} poster={image.src} muted autoPlay loop/>
                    : <img className="block rounded-lg max-h-full max-w-full" src={image.src} alt={image.alt}/>
                  }
                  <div className="absolute rounded-lg flex justify-center items-center bg-black-transparent inset-0 opacity-0 transition-o group-hover:opacity-100">
                    {Helper.fileType(image.type) === 'video' ?
                      <BaseButton onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal(key);
                      }} className="rounded-full w-6 h-6 justify-center items-center p-5 text-positive text-3xl bg-white"><Icon className="ml-1">caret-right</Icon></BaseButton>
                      :
                      <IconButton onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal(key);
                      }} className="bg-white p-5 text-positive">search</IconButton>
                    }
                    {deletable &&
                    <IconButton onClick={() => {
                      deleteMedia(image.src);
                    }} className="bg-white p-5 text-negative ml-2">trash-alt</IconButton>
                    }
                  </div>

                </div>);
            })
            : <Loading/>
          }
        </div>
      </div>
    );
  }
}

export default MediaCollection;
