import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import UsernamePage from '../../../Containers/Agency/Settings/SubPages/UsernamePage';
import PasswordPage from '../../../Containers/Agency/Settings/SubPages/PasswordPage';
import NotificationsPage from '../../../Containers/Agency/Settings/SubPages/NotificationsPage';
import LanguagePage from '../../../Containers/Agency/Settings/SubPages/LanguagePage';
import DeletePage from '../../../Containers/Agency/Settings/SubPages/DeletePage';
import VisibilityPage from '../../../Containers/Agency/Settings/SubPages/VisibilityPage';

class AgencySettingsRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/settings/username" params={{name: 'Settings'}} component={UsernamePage}/>
        <AgencyRoute exact path="/agency/settings/password" params={{name: 'Settings'}} component={PasswordPage}/>
        <AgencyRoute exact path="/agency/settings/notifications" params={{name: 'Settings'}} component={NotificationsPage}/>
        <AgencyRoute exact path="/agency/settings/visibility" params={{name: 'Settings'}} component={VisibilityPage}/>
        <AgencyRoute exact path="/agency/settings/language" params={{name: 'Settings'}} component={LanguagePage}/>
        <AgencyRoute exact path="/agency/settings/delete" params={{name: 'Settings'}} component={DeletePage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencySettingsRoutingComponent));
