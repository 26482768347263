import {call, put} from 'redux-saga/effects'

import {callApi} from '../CallApiSaga'
import PropertiesActions from "../../Redux/Talent/PropertiesRedux";

export function* getProperties(api, {username}) {
  const apiCall = yield call(api.getProperties, username);
  const response = yield call(callApi, apiCall);
  // success?
  if (response.ok) {
    yield put(PropertiesActions.propertiesSuccess(response.data.data));
  } else {
    yield put(PropertiesActions.propertiesFailure('WRONG'))
  }
}
