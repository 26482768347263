import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import Icon from '../Icon';

class Select extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.any,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
  };

  renderSelect(error = false) {
    const {children, className, disabled, input, onSelect} = this.props;

    return (<div className={`inline-block relative ${className || ''}`}>

      <select
        {...input}
        disabled={disabled}
        aria-disabled={disabled}
        aria-labelledby={input.name}
        onChange={(data) => {
          onSelect && onSelect(data);

          if (input.onChange) {
            input.onChange(data);
          }

        }}
        id={input ? input.name : null}
        className={`appearance-none w-full sm:pr-16 disabled:opacity-0 focus:outline-none focus:shadow-outline input-field bg-white${error ? ' has-error' : ''}`}
      >
        {children}
      </select>

      <div role="presentation" className={`pointer-events-none absolute border-l inset-y-0 flex items-center right-0 px-2 mb-3 sm:px-4${disabled ? ' opacity-0' : ''} ${error ? 'border-negative' : 'border-gray-15'}`}>
        <Icon role="presentation" className={`text-gray-60 -mt-2${disabled ? ' opacity-0' : ''}`}>sort-down</Icon>
      </div>
    </div>);
  }

  render() {
    const {meta} = this.props;
    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderSelect(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderSelect();
  }
}

export default Select;
