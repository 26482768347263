import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  experienceCreateRequest: ['obj'],
  experienceCreateSuccess: ['experience'],
  experienceCreateFailure: ['error'],

  experienceDeleteRequest: ['obj'],
  experienceDeleteSuccess: ['response'],
  experienceDeleteFailure: ['error'],

  experienceUpdateRequest: ['obj'],
  experienceUpdateSuccess: ['experience'],
  experienceUpdateFailure: ['error'],

  experienceByIdRequest: ['obj'],
  experienceByIdSuccess: ['experience'],
  experienceByIdFailure: ['error'],
});

export const ExperienceTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  experience: null,
  error: null,
  store: false,
  delete: false,
  fetching: false,
  updating: false,
  deleteSuccess: false,
});

/* ------------- Reducers ------------- */

export const create_request = (state) => state.merge({store: true});

export const create_success = (state, data) => {
  const {experience} = data;
  return state.merge({store: false, error: null, experience})
};

export const create_failure = (state, {error}) => state.merge({store: false, experience: null, error: error});

export const delete_request = (state) => state.merge({delete: true, deleteSuccess: false});

export const delete_success = (state, data) => {
  const {response} = data;
  return state.merge({delete: false, deleteSuccess: response, error: null});
};

export const delete_failure = (state, {error}) => state.merge({delete: false, deleteSuccess: false, error: error});

export const update_request = (state) => state.merge({updating: true});

export const update_success = (state, data) => {
  const {experience} = data;
  return state.merge({updating: false, error: null, experience})
};

export const update_failure = (state, {error}) => state.merge({updating: false, error: error});

export const experience_request = (state) => state.merge({fetching: true});

export const experience_success = (state, data) => {
  const {experience} = data;
  return state.merge({fetching: false, error: null, experience})
};

export const experience_failure = (state, {error}) => state.merge({fetching: false, experience: null, error: error});


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EXPERIENCE_CREATE_REQUEST]: create_request,
  [Types.EXPERIENCE_CREATE_SUCCESS]: create_success,
  [Types.EXPERIENCE_CREATE_FAILURE]: create_failure,

  [Types.EXPERIENCE_DELETE_REQUEST]: delete_request,
  [Types.EXPERIENCE_DELETE_SUCCESS]: delete_success,
  [Types.EXPERIENCE_DELETE_FAILURE]: delete_failure,

  [Types.EXPERIENCE_UPDATE_REQUEST]: update_request,
  [Types.EXPERIENCE_UPDATE_SUCCESS]: update_success,
  [Types.EXPERIENCE_UPDATE_FAILURE]: update_failure,

  [Types.EXPERIENCE_BY_ID_REQUEST]: experience_request,
  [Types.EXPERIENCE_BY_ID_SUCCESS]: experience_success,
  [Types.EXPERIENCE_BY_ID_FAILURE]: experience_failure,
});
