import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import AgencyRoute from '../../Routes/AgencyRoute';
// Routes
import InterestedPage from '../../../Containers/Agency/People/SubPages/InterestedPage';
import AuditionsPage from '../../../Containers/Agency/People/SubPages/AuditionsPage';
import ShortlistPage from '../../../Containers/Agency/People/SubPages/ShortlistPage';
import HiredPage from '../../../Containers/Agency/People/SubPages/HiredPage';
import AlgoliaSearchPage from '../../../Containers/Agency/People/SubPages/AlgoliaSearchPage';
import ContactedPage from '../../../Containers/Agency/People/SubPages/ContactedPage';

class AgencyProjectRolePeopleRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/search" params={{name: 'Projects'}} component={AlgoliaSearchPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/contacted" params={{name: 'Projects'}} component={ContactedPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/interested" params={{name: 'Projects'}} component={InterestedPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/audition" params={{name: 'Projects'}} component={AuditionsPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/shortlist" params={{name: 'Projects'}} component={ShortlistPage}/>
        <AgencyRoute exact path="/agency/projects/:project/project-roles/:role/people/hired" params={{name: 'Projects'}} component={HiredPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(AgencyProjectRolePeopleRoutingComponent));
