// a library to wrap and simplify api calls
import apisauce from 'apisauce'

// our "constructor"
const create = (baseURL = process.env.API_FETCH_UPLOADCARE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    headers: {
      'Authorization': 'Uploadcare.Simple ' + process.env.UPLOADCARE_PUBLIC_KEY + ':' + process.env.UPLOADCARE_PRIVATE_KEY,
      'Accept': 'application/vnd.uploadcare-v0.5+json'
    },
    // here are some default headers
    // 10 second timeout...
    timeout: 60000,
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const fetchGroupMedia = (obj) => api.get('groups/' + obj.group_id + '/');
  const fetchMedia = (obj) => api.get('files/' + obj.uuid + '/', {
    headers: {
      'Authorization': 'Uploadcare.Simple ' + process.env.UPLOADCARE_PUBLIC_KEY + ':' + process.env.UPLOADCARE_PRIVATE_KEY,
      'Accept': 'application/vnd.uploadcare-v0.5+json'
    }
  });
  const deleteFile = (uuid) => api.delete('files/' + uuid + '/');

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    fetchGroupMedia,
    deleteFile,
    fetchMedia,
  }
};

// let's return back our create method as the default.
export default {
  create
}
