import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  educationCreateRequest: ['obj'],
  educationCreateSuccess: ['education'],
  educationCreateFailure: ['error'],

  educationDeleteRequest: ['obj'],
  educationDeleteSuccess: ['response'],
  educationDeleteFailure: ['error'],
});

export const EducationTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  education: null,
  education_error: null,
  education_store: false,
  education_delete: false,
  education_delete_success: false,
});

/* ------------- Reducers ------------- */

export const createRequest = (state) => state.merge({education_store: true, education_error:null});

export const createSuccess = (state, data) => {
  const {education} = data;
  return state.merge({education_store: false, education_error: null, education: education})
};

export const createFailure = (state, {error}) => state.merge({
  education_store: false,
  education: null,
  education_error: error
});

export const deleteRequest = (state) => state.merge({education_delete: true, education_delete_success: false});

export const deleteSuccess = (state, data) => {
  const {response} = data;
  return state.merge({education_delete: false, education_delete_success: response, education_error: null});
};

export const deleteFailure = (state, {error}) => state.merge({
  education_delete: false,
  education_delete_success: false,
  education_error: error
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDUCATION_CREATE_REQUEST]: createRequest,
  [Types.EDUCATION_CREATE_SUCCESS]: createSuccess,
  [Types.EDUCATION_CREATE_FAILURE]: createFailure,

  [Types.EDUCATION_DELETE_REQUEST]: deleteRequest,
  [Types.EDUCATION_DELETE_SUCCESS]: deleteSuccess,
  [Types.EDUCATION_DELETE_FAILURE]: deleteFailure,
});
