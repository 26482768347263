import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import BaseButton from './BaseButton';

class OutlineButton extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    className: PropTypes.string,
    completed: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  render() {
    const {children, className, completed, onClick, to} = this.props;

    return (
      completed ?
        <BaseButton to={to} onClick={onClick} className={`rounded border border-positive py-2 px-3 items-center overflow-hidden ${className || ''}`}>
          <Icon className="text-lg mr-3 text-positive">check</Icon>
          <p className="text-xs sm:text-sm text-gray-60 w-full">{children}</p>
        </BaseButton>
        :
        <BaseButton to={to} onClick={onClick} className={`rounded border border-gray-40 py-2 px-3 items-center overflow-hidden ${className || ''}`}>
          <Icon className="text-lg ml-1 mr-3 text-gray-40">times</Icon>
          <p className="text-xs sm:text-sm text-gray-60 w-full">{children}</p>
        </BaseButton>
    );
  }
}

export default OutlineButton;
