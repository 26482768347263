import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  castingByIdRequest: ['obj'],
  castingByIdSuccess: ['casting'],
  castingByIdFailure: ['error'],

  updateCastingRequest: ['obj'],
  updateCastingSuccess: ['casting_updated'],
  updateCastingFailure: ['error'],

  clearCastingState: [],

});

export const CastingTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  updating: false,
  casting: null,
  casting_updated: null,
  error: null,
});

/* ------------- Reducers ------------- */

export const request = (state) => state.merge({fetching: true});

export const success = (state, data) => {
  const {casting} = data;
  return state.merge({fetching: false, error: null, casting})
};

export const failure = (state, {error}) => state.merge({fetching: false, casting: null, error});

export const updateRequest = (state) => state.merge({updating: true, casting_updated: null, error: null});

export const updateSuccess = (state, data) => {
  const {casting_updated} = data;
  return state.merge({updating: false, error: null, casting_updated})
};

export const updateFailure = (state, {error}) => state.merge({updating: false, casting_updated: null, error});

export const clearCastingState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CASTING_BY_ID_REQUEST]: request,
  [Types.CASTING_BY_ID_SUCCESS]: success,
  [Types.CASTING_BY_ID_FAILURE]: failure,

  [Types.UPDATE_CASTING_REQUEST]: updateRequest,
  [Types.UPDATE_CASTING_SUCCESS]: updateSuccess,
  [Types.UPDATE_CASTING_FAILURE]: updateFailure,

  [Types.CLEAR_CASTING_STATE]: clearCastingState,
});
