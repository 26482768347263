import PropTypes from 'prop-types';
import React from 'react';
import FormError from './FormError';
import InputMask from 'react-input-mask';

class BirthdayPicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.any,
  };

  renderDatePicker(error = false) {
    const {className, input} = this.props;
    return <div className="relative inline-block w-full">
      <InputMask
        {...input}
        mask="99/99/9999"
        onBlur={input.onBlur}
        aria-labelledby={input.name}
        className={`input-field${error ? ' has-error' : ''} ${className || ''}`}
      />
    </div>;
  }

  render() {
    const {meta} = this.props;
    return meta && ((meta.error && meta.submitFailed) || (meta.error && meta.submitSucceeded)) ?
      <div>
        {this.renderDatePicker(meta.error)}
        <FormError meta={meta}/>
      </div>
      : this.renderDatePicker();
  }
}

export default BirthdayPicker;
