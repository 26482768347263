import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import Select from '../../../../Components/Form/Select';
import TextArea from '../../../../Components/Form/TextArea';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import DatePicker from '../../../../Components/Form/DatePicker';
import NumberField from '../../../../Components/Form/NumberField';
import {roleTypes} from '../../../../Constants/Roles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import RoleActions from '../../../../Redux/Project/RoleRedux';
import {required} from '../../../../Validators/Form/Form';
import I18n from '../../../../Utils/I18n';
import arrayMutators from 'final-form-arrays';
import CastingDates from '../../../../Components/Form/CastingDates';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    account: PropTypes.any,
    saving: PropTypes.bool,
    createRole: PropTypes.func,
    project: PropTypes.any,
    role_store: PropTypes.bool,
    role: PropTypes.any,
    role_error: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };

    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.role_store) {
      if ((this.props.role !== prevProps.role) && this.state.saving) {
        this.onSuccess();
      } else if ((this.props.role_error !== prevProps.role_error) && this.state.saving) {
        this.onError();
      }
    }
  }

  onError = () => {
    this.setState({saving: false});
  };

  onSuccess = () => {
    this.setState({saving: false}, function () {
      const {role} = this.props;

      this.props.history.push(`/agency/projects/${role.project_id}/project-roles/${role.id}/overview`);
    });
  };

  onSubmit = (values) => {
    const obj = {
      role: values,
      project_id: this.props.project.id,
    };

    this.setState({saving: true}, function () {
      this.props.createRole(obj);
    });
  };

  validate() {
    const {saving} = this.state, {role_error} = this.props;
    let errors;

    if (saving) {
      return {};
    }

    errors = role_error ? {...role_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        try {
          if (this.form.getFieldState(field).dirtySinceLastSubmit) {
            delete errors[field];
          }
        } catch (e) {
          return null;
        }
      });
    }

    return errors;
  }

  render() {
    const {t} = this.context;

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={{dates: [{starts_at: null, ends_at: null, status: null}]}}
        mutators={{...arrayMutators}}
      >
        {({handleSubmit, form, form: {mutators: {push}}}) => {
          this.form = form;
          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
              <Title className="text-xl pb-2">{I18n.t('Role details')}</Title>

              <div className="sm:flex pb-3">
                <Field name="type" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-3">
                      <Label input={input}>{I18n.t('type of project role', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option value="">-</option>
                        {roleTypes.map((item, key) => <option key={key} value={item}>{t(item)}</option>)}
                      </Select>
                    </div>
                  )}
                </Field>
                <Field name="amount" validate={required}>
                  {({input, meta}) => (
                    <div className="flex-1 sm:pl-2">
                      <Label input={input}>{I18n.t('amount', null, true)}</Label>
                      <NumberField input={input} meta={meta} placeholder={1} min={1} step={1}/>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex pb-3">
                <Field name="starts_at" validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/3">
                      <Label input={input}>{I18n.t('casting start date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
                <Field name="ends_at" validate={required}>
                  {({input, meta}) => (
                    <div className="md:w-1/3 md:px-5">
                      <Label input={input}>{I18n.t('casting end date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
              </div>

              <Title className="text-xl pb-2">{I18n.t('Shooting dates')}</Title>
              <CastingDates push={push}/>


              <Field name="description">
                {({input, meta}) => (
                  <div className="sm:w-4/5">
                    <Label input={input}
                           className="text-gray-60 text-lg font-semibold text-xl pt-10 pb-2 -ml-4">{I18n.t('Role information')}</Label>
                    <TextArea input={input} meta={meta} className="min-h-48"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" disabled={this.state.saving} icon="edit"
                              className="rounded text-white bg-secondary-40">
                  {I18n.t('save role', null, false, true)}
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
    project: state.project.project,
    role_store: state.role.store,
    role: state.role.role,
    role_error: state.role.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRole: (obj) => dispatch(RoleActions.createRoleRequest(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
