import {createActions, createReducer} from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  loginRequest: ['username', 'password'],
  loginSuccess: ['authToken'],
  loginFailure: ['error'],

  socialLoginRequest: ['provider', 'token'],
  socialLoginSuccess: ['authToken'],
  socialLoginFailure: ['error'],

  logoutRequest: null,
  logoutSuccess: null,

  loginLoad: [],
  loginLoadSuccess: ['authToken'],
  loginLoadFailure: ['error'],
  refreshToken: null,

  clearLoginState: [],

});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  authToken: null,
  refreshToken: null,
  error: null,
  fetching: false,
  loading: false,
  loginLoaded: false,
});

/* ------------- Reducers ------------- */

// we're attempting to login
export const request = (state) => state.merge({fetching: true});

// we've successfully logged in
export const success = (state, data) => {
  const {authToken, refreshToken} = data;
  return state.merge({fetching: false, error: null, authToken, refreshToken, loginLoaded: true, loading: false});
};

// we've had a problem logging in
export const failure = (state, {error}) => state.merge({fetching: false, error, authToken: null, refreshToken: null});

// we're attempting to load token from startup sagas
export const load = (state) => state.merge({loading: true});

export const loadSuccess = (state, data) => {
  const {authToken, refreshToken} = data;
  return state.merge({loading: false, authToken, refreshToken, loginLoaded: true});
};

// we've had a problem logging in
export const loadFailure = (state, {error}) => state.merge({
  fetching: false,
  error,
  authToken: null,
  refreshToken: null,
  loginLoad: false,
  loading: false
});

// we need to logout, meaning clear access tokens and account
export const logout_request = state => state;

// we've logged out
export const logout_success = () => INITIAL_STATE;

export const clear_login_state = () => INITIAL_STATE;


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,

  [Types.SOCIAL_LOGIN_REQUEST]: request,
  [Types.SOCIAL_LOGIN_SUCCESS]: success,
  [Types.SOCIAL_LOGIN_FAILURE]: failure,

  [Types.LOGIN_LOAD]: load,
  [Types.LOGIN_LOAD_SUCCESS]: loadSuccess,
  [Types.LOGIN_LOAD_FAILURE]: loadFailure,
  [Types.LOGOUT_REQUEST]: logout_request,
  [Types.LOGOUT_SUCCESS]: logout_success,
  [Types.REFRESH_TOKEN]: request,

  [Types.CLEAR_LOGIN_STATE]: clear_login_state,

});
