/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import React from 'react';
import {hot} from 'react-hot-loader';
import Nav from '../Components/Navigation/Nav';
import Header from '../Components/Header/Header';
import RoutingComponent from "../Components/Routing/RoutingComponent";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab}  from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import Footer from '../Components/Footer/Footer';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

library.add(fab, fas);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://f5ae4e169c344e32bc91331f1280eadf@sentry.antwerpfactory.com//42"});
    ReactGA.initialize('UA-150794553-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    return (
      <div className="font-sans min-h-screen flex flex-col">
        <Header/>
        <div className="lg:flex mb-8">
          <Nav />
          <div className="w-full">
            <RoutingComponent/>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
