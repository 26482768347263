import React from 'react';
import ProjectList from '../../../Components/Lists/ProjectList';
import ProjectActions from "../../../Redux/Project/ProjectRedux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

class ProjectsPage extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func,
    clearProjectState: PropTypes.func,
  };

  componentDidMount() {
    this.props.clearProjectState();
  }

  render() {
    return (
      <main className="mx-3 lg:ml-0 lg:mr-5">
        <ProjectList addButton/>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearProjectState: () => dispatch(ProjectActions.clearProjectState()),
  };
};

export default connect(null, mapDispatchToProps)(ProjectsPage);
