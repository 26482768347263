import React from 'react';
import Title from '../../../../Components/Text/Title';
import {Field, Form} from 'react-final-form';
import Label from '../../../../Components/Form/Label';
import Select from '../../../../Components/Form/Select';
import TextArea from '../../../../Components/Form/TextArea';
import FilledButton from '../../../../Components/Buttons/FilledButton';
import DatePicker from '../../../../Components/Form/DatePicker';
import NumberField from '../../../../Components/Form/NumberField';
import PropTypes from 'prop-types';
import RoleActions from '../../../../Redux/Project/RoleRedux';
import {connect} from 'react-redux';
import ProjectActions from '../../../../Redux/Project/ProjectRedux';
import {roleTypes} from '../../../../Constants/Roles';
import I18n from '../../../../Utils/I18n';
import CastingDates from '../../../../Components/Form/CastingDates';
import arrayMutators from 'final-form-arrays';

class OverviewPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    account: PropTypes.any,
    getRoleById: PropTypes.func,
    getProjectId: PropTypes.func,
    updateRole: PropTypes.func,
    role: PropTypes.any,
    role_updating: PropTypes.bool,
    role_error: PropTypes.any,
    project: PropTypes.any,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
    };

    this.form = null;
  }

  componentDidMount() {
    const {project, role} = this.props.match.params;

    this.props.getRoleById({project_id: project, role_id: role});
  }

  componentDidUpdate(prevProps) {
    if (!this.props.role_updating) {
      if ((this.props.role !== prevProps.role) && this.state.updating) {
        this.onSuccess();
      } else if ((this.props.role_error !== prevProps.role_error) && this.state.updating) {
        this.onError();
      }
    }
  }

  onError = () => {
    this.setState({updating: false});
  };

  onSuccess = () => {
    this.setState({updating: false}, function () {
      const {role} = this.props;

      this.props.history.push(`/agency/projects/${role.project_id}/project-roles/${role.id}/overview`);
    });
  };

  onSubmit = (values) => {
    const obj = {
      role: values,
      project_id: this.props.role.project_id,
      role_id: this.props.role.id,
    };

    this.setState({updating: true}, function () {
      this.props.updateRole(obj);
    });
  };

  validate() {
    const {updating} = this.state, {role_error} = this.props;
    let errors;

    if (updating) {
      return {};
    }

    errors = role_error ? {...role_error.errors} : {};

    if (this.form) {
      this.form.getRegisteredFields().forEach((field) => {
        try {
          if (this.form.getFieldState(field).dirtySinceLastSubmit) {
            delete errors[field];
          }
        } catch (e) {
          return null;
        }
      });
    }

    return errors;
  }

  render() {
    const {role} = this.props, {t} = this.context;

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate.bind(this)}
        validateOnBlur
        initialValues={role}
        mutators={{...arrayMutators}}
      >
        {({handleSubmit, form, form: {mutators: {push}}}) => {
          this.form = form;

          return (
            <form onSubmit={handleSubmit} className="flex-1 pl-3 sm:px-10 pb-16 max-w-6xl">
              <Title className="text-xl pb-2">{I18n.t('Role details')}</Title>

              <div className="sm:flex pb-3">
                <Field name="type">
                  {({input, meta}) => (
                    <div className="flex-1 sm:pr-3">
                      <Label input={input}>{I18n.t('type of project role', null, true)}</Label>
                      <Select input={input} meta={meta} className="w-full">
                        <option value=''>-</option>
                        {roleTypes.map((item, key) => <option key={key} value={item}>{t(item)}</option>)}
                      </Select>
                    </div>
                  )}
                </Field>
                <Field name="amount">
                  {({input, meta}) => (
                    <div className="flex-1 sm:pl-2">
                      <Label input={input}>{I18n.t('amount', null, true)}</Label>
                      <NumberField input={input} meta={meta} placeholder={1} min={1} step={1}/>
                    </div>
                  )}
                </Field>
              </div>

              <div className="md:flex pb-3">
                <Field name="starts_at">
                  {({input, meta}) => (
                    <div className="md:w-1/3">
                      <Label input={input}>{I18n.t('casting start date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
                <Field name="ends_at">
                  {({input, meta}) => (
                    <div className="md:w-1/3 md:px-5">
                      <Label input={input}>{I18n.t('casting end date', null, true)}</Label>
                      <DatePicker input={input} meta={meta} className="w-full"/>
                    </div>
                  )}
                </Field>
              </div>

              <Title className="text-xl pb-2">{I18n.t('Shooting dates')}</Title>
              <CastingDates push={push}/>

              <Field name="description">
                {({input, meta}) => (
                  <div className="sm:w-4/5">
                    <Label input={input} className="text-gray-60 text-lg font-semibold text-xl pt-10 pb-2 -ml-4">
                      {I18n.t('Role information')}
                    </Label>
                    <TextArea input={input} meta={meta} className="min-h-48"/>
                  </div>
                )}
              </Field>

              <div className="mt-10">
                <FilledButton type="submit" disabled={this.state.updating} icon="edit" className="rounded text-white bg-secondary-40">
                  {I18n.t('save role', null, false, true)}
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.role.role,
    role_updating: state.role.updating,
    role_error: state.role.error,

    project: state.project.project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleById: (obj) => dispatch(RoleActions.roleByIdRequest(obj)),
    updateRole: (obj) => dispatch(RoleActions.updateRoleRequest(obj)),
    getProjectId: (id) => dispatch(ProjectActions.projectByIdRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
