import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';
import Icon from '../Icon';

class SubNavItem extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    icon: PropTypes.string,
    noActiveStyle: PropTypes.bool,
    to: PropTypes.string,
  };

  render() {
    const {children, className, icon, noActiveStyle, to} = this.props;

    return (
      <li>
        <NavLink
          exact
          to={to || ''}
          activeClassName={noActiveStyle ? '' : 'active-sub-nav-link'}
          className={`flex items-center py-1 mb-1 pr-3 sm:pr-0 rounded ${className || ''}`}
          isActive={(match, location) => (match && match.isExact) || (location && location.pathname.startsWith(to))}
        >
          <div className="w-4 flex">
            <Icon className="text-xl text-gray-40 max-w-full">{icon}</Icon>
          </div>
          <p className="hidden sm:block pl-3 text-gray-40 text-sm font-bold">{children}</p>
        </NavLink>
      </li>
    );
  }
}

export default SubNavItem;
