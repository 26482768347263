import {Switch, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
//Pages
import PrivateRoute from '../Routes/PrivateRoute';
// Routes
import NewMessagesPage from '../../Containers/Common/Notifications/SubPages/NewMessagesPage';
import ReadMessagesPage from '../../Containers/Common/Notifications/SubPages/ReadMessagesPage';

class NotificationsRoutingComponent extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path="/notifications/new" params={{name: 'Notfications'}} component={NewMessagesPage}/>
        <PrivateRoute exact path="/notifications/read" params={{name: 'Notfications'}} component={ReadMessagesPage}/>
      </Switch>
    );
  }
}

export default withRouter(connect()(NotificationsRoutingComponent));
