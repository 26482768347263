import {createActions, createReducer} from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  notificationsRequest: ['username'],
  notificationsSuccess: ['notifications'],
  notificationsFailure: ['error'],

  notificationsAllUpdateRequest: ['obj'],

  notificationsUpdateRequest: ['obj'],
  notificationsUpdateSuccess: ['update_success'],
  notificationsUpdateFailure: ['error'],

  clearNotificationsState: [],
});

export const NotificationsTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notifications: null,
  notifications_error: null,
  notifications_fetching: false,
  notifications_updating: false,
  update_success: false
});

/* ------------- Reducers ------------- */

// Notifications request
export const notifications_request = (state) => state.merge({notifications_fetching: true});

// Properties success
export const notifications_success = (state, data) => {
  const {notifications} = data;
  return state.merge({notifications_fetching: false, notifications_error: null, notifications})
};

export const notifications_failure = (state, {error}) => state.merge({
  notifications_fetching: false,
  notifications: null,
  notifications_error: error
});

// Notifications update
export const notifications_update_request = (state) => state.merge({notifications_updating: true});

export const notificationsAllUpdateRequest = (state) => state.merge(state);

// Notifications success
export const notifications_update_success = (state, data) => {
  const {update_success} = data;
  return state.merge({notifications_updating: false, notifications_error: null, update_success})
};

export const notifications_update_failure = (state, {error}) => state.merge({
  notifications_updating: false,
  update_success: false,
  notifications_error: error
});

export const clearNotificationsState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.NOTIFICATIONS_REQUEST]: notifications_request,
  [Types.NOTIFICATIONS_SUCCESS]: notifications_success,
  [Types.NOTIFICATIONS_FAILURE]: notifications_failure,

  [Types.NOTIFICATIONS_ALL_UPDATE_REQUEST]: notificationsAllUpdateRequest,
  [Types.NOTIFICATIONS_UPDATE_REQUEST]: notifications_update_request,
  [Types.NOTIFICATIONS_UPDATE_SUCCESS]: notifications_update_success,
  [Types.NOTIFICATIONS_UPDATE_FAILURE]: notifications_update_failure,

  [Types.CLEAR_NOTIFICATIONS_STATE]: clearNotificationsState,

});
